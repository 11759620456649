import styled from '@emotion/styled';
import { ToastViewport } from '@kintent/glide';

const StyledToastViewport = styled(ToastViewport)`
  position: fixed;
  top: 24px;
  right: 64px;
  z-index: 10000;
`;

export const Styled = {
  StyledToastViewport,
};
