import styled from '@emotion/styled';
import { Badge, base, flat, translucent, Typography } from '@kintent/glide';

import HomePageContainer from './HomePageContainer';
import { Flex } from '../../../../components/Flex';

export const PolicySectionContainer = styled(HomePageContainer)`
  margin-top: 72px;

  & svg {
    transform: translateY(2px);
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 24px;
  column-gap: 24px;
`;

export const Card = styled(Flex)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '10%')};
  background: white;
  padding: 20px 24px 44px 24px;
`;

export const ViewAllLink = styled(Typography)`
  color: ${({ theme }) => theme.components.header.primaryCTAButton.background};

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const PolicyLengthLabel = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '40%')};
`;

export const CountBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
`;
