import React from 'react';
import styled from '@emotion/styled';
import { flat, Typography } from '@kintent/glide';

import { ReactComponent as LockedFileIcon } from '../assets/locked-file.svg';
import { Flex } from './Flex';
import { GlideButton } from './GlideButton';

const EmptyStateContainer = styled(Flex)`
  width: 100%;
  border-radius: 8px;
  padding: 32px;
  background-color: ${({ theme }) => flat(theme.color.system.gray, '10%')};
  text-align: center;
`;

export class PDFViewerErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(err) {
    console.error(err);
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children, setContactDialog, teamName } = this.props;

    if (hasError) {
      return (
        <EmptyStateContainer
          gap="12px"
          direction="column"
          alignItems="center"
        >
          <LockedFileIcon />
          <Typography as="b">Policy is not available for preview</Typography>
          <Typography strength="80%">
            Sorry we`re having some trouble loading this preview. Please contact the {teamName} owner for help.
          </Typography>
          <GlideButton
            variant="primary"
            onClick={() => setContactDialog(true)}
          >
            Contact us
          </GlideButton>
        </EmptyStateContainer>
      );
    }

    return children;
  }
}

export default PDFViewerErrorBoundary;
