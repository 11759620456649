import React, { useCallback, useMemo } from 'react';
import { Typography } from '@kintent/glide';
import { useNavigation } from 'react-navi';

import * as Styles from './DocumentList.styles';
import { Helmet } from '../../../../../components/Helmet';
import { useAuthService, usePolicyList, useTeamDocumentList } from '../../../../../lib/state';
import { getPublicDocuments, getPublicPolicies, transformDocuments } from '../../../../../lib/utils';

function GridItem({ artifact }) {
  const { navigate } = useNavigation();

  const handleNavigationToDocumentDetail = useCallback(() => {
    navigate(`/documents/${artifact.id}?type=${artifact.type}`);
  }, [artifact, navigate]);

  return (
    <Styles.ListItem
      role="button"
      onClick={handleNavigationToDocumentDetail}
    >
      <Typography as="p">{artifact.title}</Typography>
    </Styles.ListItem>
  );
}

function DocumentList() {
  const { currentTeam } = useAuthService();
  const [policies] = usePolicyList();
  const [documents] = useTeamDocumentList(currentTeam?.id);

  const publicPolicies = useMemo(() => getPublicPolicies(policies), [policies]);
  const publicDocuments = useMemo(() => getPublicDocuments(documents), [documents]);

  const allPublicArtifacts = useMemo(
    () => transformDocuments(publicPolicies, publicDocuments),
    [publicPolicies, publicDocuments]
  );

  return (
    <>
      <Helmet pageTitle="Documents" />
      <Styles.Grid>
        {allPublicArtifacts?.map((artifact) => {
          return (
            <GridItem
              key={artifact.id}
              artifact={artifact}
            />
          );
        })}
      </Styles.Grid>
    </>
  );
}

export default DocumentList;
