// Package modules
import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@kintent/glide';

// Local modules
import { useCurrentRoute } from 'react-navi';
import { Button } from '../design-system';
import microsoftBgImage from '../assets/sso/ms-symbollockup_signin_light@2x.png';
import { HOST, MICROSOFT_SSO_CLIENT_ID, MICROSOFT_SSO_ENDPOINT, SSO_PROVIDER } from '../lib/constants';

// Styled components
const MicrosoftSigninLink = styled(Button)`
  outline-offset: 1px;

  border-width: 0;
  border-radius: 0;
  width: 205px;
  height: 39px;

  background-image: url(${microsoftBgImage});
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    box-shadow: 0 2px 3.5px 0 rgba(29, 94, 83, 0.29), 0 2.5px 11.5px 0 rgba(49, 81, 76, 0.2),
      0 0.3px 0.8px 0.1px rgba(8, 29, 26, 0.26);
  }

  &:active,
  &:focus {
    box-shadow: none;
    outline: 2px solid ${({ theme }) => theme.palette.royal};
  }
`;

const StyledTypography = styled(Typography)`
  position: absolute;

  clip: rect(0, 0, 0, 0);
  overflow: hidden;

  border-width: 0;
  height: 1px;
  margin: -1px;
  padding: 0;
  width: 1px;

  white-space: nowrap;
`;

export function MicrosoftSso(props) {
  const { url } = useCurrentRoute();

  const microsoftOAuthLogin = async () => {
    const pathname = url.pathname.replace(/\/$/, '');
    const state = {
      provider: SSO_PROVIDER.MICROSOFT,
      trustShareProgramUrl: `${window.location.protocol}//${window.location.host}${pathname}`,
    };

    if ('invitation' in url.query) {
      state.invitation = url.query.invitation;
    }

    const qs = new URLSearchParams({
      client_id: MICROSOFT_SSO_CLIENT_ID,
      redirect_uri: `${HOST}/auth/trustshare-oauth`,
      response_type: 'code',
      scope: ['https://graph.microsoft.com/user.read'].join(' '),
      state: btoa(JSON.stringify(state)),
    });

    window.location.href = `${MICROSOFT_SSO_ENDPOINT}${qs}`;
  };
  return (
    <MicrosoftSigninLink
      variant="quiet"
      onClick={microsoftOAuthLogin}
      {...props}
    >
      <StyledTypography>Sign in with Google</StyledTypography>
    </MicrosoftSigninLink>
  );
}
