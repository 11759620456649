/** @jsxImportSource @emotion/react */
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Button as BaseButton } from 'reakit';
import { css } from '@emotion/react';

// Local modules
import { InlineFlex } from '../components/Flex';
import { hexOpacity } from '../lib/utils';

// Constants
export const VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  FAB: 'fab',
  QUIET: 'quiet',
  TERTIARY: 'tertiary',
};

// Styled components
const StyledButton = styled(BaseButton)`
  cursor: pointer;
  border: 0px;
  border-radius: ${({ theme }) => theme.radius.lg};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.lg};

  color: var(--foreground, ${({ theme }) => theme.components.button.text.primary});
  background: var(--background, ${({ theme }) => theme.components.button.background.primary});

  font-size: 0.875rem; // 14px
  font-weight: 500;
  line-height: 1.128;
  letter-spacing: 0.5px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  // Disable default a tag styles when button is used as a Link/a tag
  &:link,
  &:visited {
    text-decoration: none;
  }
`;

const PrimaryStyle = ({ palette, radius, components }) => css`
  position: relative;

  --background: ${components.button.background.primary};
  --foreground: ${components.button.text.primary};

  box-shadow: inset -2px -2px 4px 1px ${hexOpacity(palette.white, 0.6)};

  &:hover {
    box-shadow: ${components.button.states.primary.hover.boxShadow};
  }

  &:active {
    box-shadow: ${components.button.states.primary.active.boxShadow};
  }

  &:focus {
    outline: none;
    box-shadow: inset 0px -2px 4px 1px ${components.button.background.primary};

    &:before {
      content: '';
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      border-radius: ${radius.lg};
      border: 1px solid ${components.button.background.primary};
    }
  }
`;

const SecondaryStyle = ({ palette, radius }) => css`
  position: relative;

  --background: ${palette.white};
  --foreground: ${palette.navy};

  border: 1px solid ${palette.navy};

  &:hover {
    --background: ${palette.bone};
    box-shadow: 0px 1px 3px 1px ${hexOpacity(palette.stone, 0.4)};
  }

  &:focus {
    outline: none;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      border-radius: ${radius.lg};
      border: 1px solid ${palette.ariel};
    }
  }
`;

const FabStyle = ({ palette, radius }) => css`
  --background: none;
  --foreground: ${palette.chalkboard};

  position: relative;
  font-weight: normal;

  box-shadow: inset 0px 1px 4px 0px ${hexOpacity(palette.chalkboard, 0.2)};
  background: linear-gradient(270deg, ${palette.pebble} 0%, ${palette.arctic} 100%);

  &:hover {
    box-shadow: 0px 1px 3px 1px ${hexOpacity(palette.stone, 0.4)};
  }

  &:active {
    box-shadow: inset 0px 2px 4px 1px ${hexOpacity(palette.stone, 0.4)};
  }

  &:focus {
    outline: none;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      border-radius: ${radius.lg};
      border: 1px solid ${palette.ariel};
    }
  }
`;

const QuietStyle = ({ palette }) => css`
  --background: none;
  --foreground: ${palette.navy};

  position: relative;

  height: 32px;

  border: 1px solid transparent;
  padding: 4px 8px;

  &:active,
  &:focus {
    outline: none;
    border: 1px solid ${hexOpacity(palette.navy, 0.7)};
  }
`;

const TertiaryStyle = ({ palette, components }) => css`
  --background: ${palette.dove};
  --foreground: ${components.button.text.tertiary};
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  border: solid 1.5px var(--foreground);

  &:active,
  &:focus {
    outline: none;
    background-color: ${hexOpacity(components.button.text.tertiary, 0.1)};
  }
`;

const StartIcon = styled(InlineFlex)`
  margin-right: 4px;
  margin-left: -4px;
`;

const EndIcon = styled(InlineFlex)`
  margin-left: 8px;
  margin-right: -4px;
`;

const VARIANT_STYLE_MAP = {
  [VARIANTS.PRIMARY]: PrimaryStyle,
  [VARIANTS.SECONDARY]: SecondaryStyle,
  [VARIANTS.FAB]: FabStyle,
  [VARIANTS.QUIET]: QuietStyle,
  [VARIANTS.TERTIARY]: TertiaryStyle,
};

const Button = forwardRef(({ children, variant = 'primary', startIcon, endIcon, ...props }, ref) => {
  const variantStyle = VARIANT_STYLE_MAP[variant];
  return (
    <StyledButton
      css={variantStyle}
      ref={ref}
      {...props}
    >
      {startIcon && <StartIcon>{startIcon}</StartIcon>}
      {children}
      {endIcon && <EndIcon>{endIcon}</EndIcon>}
    </StyledButton>
  );
});

export default Button;
