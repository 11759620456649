import React from 'react';
import Select, { components } from 'react-select';
import styled from '@emotion/styled';
import { UilAngleDown as ChevronDownIcon, UilSearch as SearchIcon } from '@iconscout/react-unicons';
import { useNavigation } from 'react-navi';

import { theme } from '../../../../components/theme';
import { hexOpacity } from '../../../../lib/utils';
import { BREAKPOINTS } from '../../../../lib/constants';

const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    position: 'relative',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.silver}`,
    borderRadius: '6px',
    height: '40px',
    fontSize: theme.components.text.size.label,
    '&:hover': { borderColor: theme.palette.peacock },
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    minHeight: '44px',
    backgroundColor: state.isSelected && theme.palette.volare,
    fontSize: theme.components.text.size.label,
    color: state.isSelected && theme.palette.white,
    '&:hover': {
      backgroundColor: hexOpacity(theme.palette.volare, 0.1),
      color: state.isSelected && theme.palette.volare,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: theme.components.text.size.label,
    color: theme.palette.elephant,
  }),
  menu: (provided) => ({
    ...provided,
    position: 'absolute',
    zIndex: 1,
    overflow: 'hidden',
    boxShadow: `0px 1px 3px ${hexOpacity(theme.palette.slate, 0.25)},
      0px 2px 4px ${hexOpacity(theme.palette.slate, 0.25)},
      0px 3px 5px ${hexOpacity(theme.palette.slate, 0.25)},
      0px 4px 6px ${hexOpacity(theme.palette.slate, 0.25)},
      0px 4px 6px ${hexOpacity(theme.palette.slate, 0.25)}`,
    border: `1px solid ${theme.palette.silver}`,
    borderRadius: '6px',
    width: '100%',
    backgroundColor: theme.palette.white,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '75vh',
    padding: 0,
  }),
  groupHeading: (provided) => ({
    ...provided,
    margin: 0,
    minHeight: '32px',
    paddingTop: '8px',
    paddingBottom: '8px',
    backgroundColor: hexOpacity(theme.palette.elephant, 0.5),
    fontSize: theme.components.text.size.smaller,
    textAlign: 'center',
    textTransform: 'capitalize',
    color: theme.palette.chalkboard,
  }),
  group: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

const StyledSelect = styled(Select)`
  display: block;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: none;
  }
`;

const DropdownIndicator = ({ ...props }) => (
  <>
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <SearchIcon size={16} /> : <ChevronDownIcon size={20} />}
    </components.DropdownIndicator>
  </>
);

function PolicySectionDropdown({ sortedPolicyGroups, selectedPolicy, adoptedPolicyGroups }) {
  const { navigate } = useNavigation();
  return (
    <StyledSelect
      styles={SELECT_STYLES}
      options={sortedPolicyGroups.map((securityGroup) => {
        const policiesBySecurityGroup = adoptedPolicyGroups[securityGroup];
        const policies = policiesBySecurityGroup.map((policy) => {
          return {
            value: policy.title,
            label: policy.title,
            ...policy,
          };
        });
        return {
          value: securityGroup,
          label: securityGroup,
          options: policies,
        };
      })}
      onChange={async (e) => {
        await navigate(`/policies/${encodeURIComponent(e.shortName)}`);
      }}
      value={
        selectedPolicy
          ? {
              value: selectedPolicy.title,
              label: selectedPolicy.title,
            }
          : { placeholder: 'Policy Group list' }
      }
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
    />
  );
}

export default PolicySectionDropdown;
