import React from 'react';
import { flat, Typography } from '@kintent/glide';
import { UilMegaphone } from '@iconscout/react-unicons';
import { useTheme } from '@emotion/react';

import * as Styles from './NotificationCenterHeader.styles';
import { Flex } from '../../../../components/Flex';
import { useAuthService } from '../../../../lib/state';

function NotificationCenterHeader() {
  const { currentTeam } = useAuthService();
  const theme = useTheme();

  return (
    <Styles.NotificationCenterWrapper
      direction="column"
      gap="4px"
    >
      <Flex
        alignItems="center"
        gap="12px"
      >
        <UilMegaphone
          size="24"
          color={flat(theme.color.system.gray, '60%')}
        />
        <Flex gap="4px">
          <Typography
            level="3"
            color="gray"
            strength="100%"
          >
            Stay Informed with our
          </Typography>
          <Styles.NotificationHeader level="3">Notification Center</Styles.NotificationHeader>
        </Flex>
      </Flex>
      <Typography
        level="7"
        color="gray"
        strength="80%"
      >
        Discover the latest updates and subscribe to receive compliance notifications from {currentTeam.name}.{' '}
      </Typography>
    </Styles.NotificationCenterWrapper>
  );
}

export default NotificationCenterHeader;
