// Package modules
import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

// Local modules
import { Flex } from '../../../../components/Flex';
import { useIntersected } from '../../../../lib/hooks';

// Constants
export const PROGRAM_COPY_VARIANTS = {
  initial: {
    y: -10,
    opacity: 0,
  },
  fadeIn: {
    y: [-10, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.45,
      staggerChildren: 0.35,
    },
  },
};

export function AnimatedContentContainer({ gap = '1.25rem', threshold = 0.35, direction = 'column', ...props }) {
  const ref = useRef();
  const { visited } = useIntersected(ref, { threshold });
  const animationControls = useAnimation();

  useEffect(() => {
    if (visited) {
      animationControls.start('fadeIn');
    }
  }, [visited, animationControls]);

  return (
    <Flex
      ref={ref}
      as={motion.div}
      initial="initial"
      visible="visible"
      animate={animationControls}
      variants={PROGRAM_COPY_VARIANTS}
      direction={direction}
      gap={gap}
      {...props}
    />
  );
}
