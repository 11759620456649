// Package modules
import React from 'react';
import { useCurrentRoute } from 'react-navi';

// Local modules
import { AuthenticationCard } from '../components/AuthFlowComponents';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { ResetPasswordForm } from './ResetPasswordForm';
import { Helmet } from '../../../components/Helmet';
import { useMeasurePaint, useMeasureRender, useMeasureRoute } from '../../../lib/measurement';

export const ForgotPassword = withTrustShareNavigationBar(({ resetToken = null }) => {
  const {
    data: { span },
  } = useCurrentRoute();
  span.setAttribute('page-name', 'forgot-password');
  useMeasureRoute(span);

  const tree = (
    <>
      <Helmet pageTitle="Forgot Password" />
      <AuthenticationCard>
        {resetToken ? <ResetPasswordForm resetToken={resetToken} /> : <ForgotPasswordForm />}
      </AuthenticationCard>
    </>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});
