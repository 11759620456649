import styled from '@emotion/styled';
import { flat, rgb, Typography } from '@kintent/glide';

export const TableCardFrame = styled.div`
  background-color: ${({ theme }) => rgb(theme.color.system.white)};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  overflow: hidden;
`;

export const TableCardHeader = styled(Typography)`
  background-color: ${({ theme }) => flat(theme.color.system.gray, '10%')};
  padding: 1em;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
`;

export const CardTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const CardTableHeaderRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};

  th {
    padding-bottom: 24px;
  }
`;

export const CardTableCell = styled(Typography)`
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  border-left: none;
  padding: 24px;
  white-space: nowrap;
  vertical-align: baseline;

  &:not(.leading) {
    border-right: none;
  }

  &.stretch {
    white-space: unset;
  }
`;
