import styled from '@emotion/styled';
import { ToastViewport as GlideToastViewport } from '@kintent/glide';

const ToastViewport = styled(GlideToastViewport)`
  position: fixed;
  top: 24px;
  right: 64px;
  z-index: 10000;
`;

export const Styled = {
  ToastViewport,
};
