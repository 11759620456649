import React from 'react';
import { useDialogState } from 'reakit';
import { Button, Typography } from '@kintent/glide';

import { TS_ADMIN_BRANDING_URL } from 'lib/constants';

import { useAppState, useAuthService, useRoleList } from 'lib/state';
import { canSeeCustomizeModal } from 'lib/utils';
import { useLocalStorage } from 'lib/hooks';
import * as Styles from './CustomizeTrustshareBanner.styles';

export default function CustomizeBanner() {
  const dialog = useDialogState({ visible: true, modal: false });
  const { value: PLGFlowEnabled, removeKey } = useLocalStorage('ssob');

  const { authenticatedUser } = useAuthService();
  const { data } = useAppState(authenticatedUser?.id);
  const roles = useRoleList();

  const showCustomizeModal = PLGFlowEnabled && canSeeCustomizeModal(authenticatedUser, data, roles);

  const handleCustomize = () => {
    dialog.hide();
    removeKey('ssob');
  };

  if (!showCustomizeModal) {
    return null;
  }

  return (
    <Styles.CustomizeDialog
      aria-label="Customize Trustshare Dialog"
      hideOnClickOutside={false}
      {...dialog}
    >
      <Typography
        strength="60%"
        level="4"
      >
        Customize My TrustShare
      </Typography>
      <Typography
        strength="60%"
        level="7"
      >
        You are looking at a preview of <b>your trust portal</b>, that showcases all your security and compliance
        information.
      </Typography>
      <Typography strength="60%">
        We’ve automatically pulled in all your <b>Trust Cloud</b> content (polices, sub processors, etc.)
      </Typography>
      <Typography strength="60%">
        With <b>a couple more steps</b> you make this truly your own and send it to customers. Don’t worry, it should
        only take <b>5-10 minutes</b>.
      </Typography>
      <Styles.CustomizeDialogLink
        href={`${TS_ADMIN_BRANDING_URL}?ssob=true`}
        target="blank"
      >
        <Button onClick={handleCustomize}>Customize</Button>
      </Styles.CustomizeDialogLink>
    </Styles.CustomizeDialog>
  );
}
