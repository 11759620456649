import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { flat, Heading, rgb, Typography } from '@kintent/glide';
import { UilCheck as CheckIcon, UilArrowLeft } from '@iconscout/react-unicons';
import { useTheme } from '@emotion/react';
import { useCurrentRoute, useNavigation } from 'react-navi';

import { Flex } from '../../../components/Flex';
import CardContainer from '../../../components/CardContainer';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import {
  COMPLIANCE_STANDARD_DISPLAY_NAME,
  COMPLIANCE_STANDARD_LOGO_MAP,
  COMPLIANCE_STANDARD_SECTION_DESCRIPTOR,
  COMPLIANCE_STANDARD_SRCSET_MAP,
  COMPLIANCE_STANDARDS,
  CONTROL_STATES,
  CONTROL_TABLE_HEADERS,
  POLICY_TABLE_HEADERS,
  SOC_SUBTYPE,
  STANDARD_CONTROL_COLUMN_NAME,
  TRUSTCLOUD_WEBSITE_URL,
} from '../../../lib/constants';
import { useContentVisibility, useControlList, useFrameworkList, useFrameworkSectionList } from '../../../lib/state';
import Heartbeat from '../../../components/Heartbeat';
import { Link } from '../../../components/Link';
import CertificationDropdown from './components/CertificationDropdown';
import { CardTable, CardTableCell, CardTableHeaderRow, TableCardFrame, TableCardHeader } from './components/TableCard';
import { BrandedLink } from '../../../components/BrandedLink';

const sortByComplianceSectionID = (a, b) => a.localeCompare(b, 'en', { numeric: true });
const sortByShortName = (a, b) => a.shortName.localeCompare(b.shortName, 'en', { numeric: true });

const getMonitoringStatus = (controlsInSection, section) => {
  if (controlsInSection.length && section.programPolicyMapping.length) {
    return `${controlsInSection.length} control${controlsInSection.length > 1 ? 's' : ''} and ${
      section.programPolicyMapping.length
    } polic${section.programPolicyMapping.length > 1 ? 'ies' : 'y'} continuously
    monitored by `;
  }
  if (controlsInSection.length && !section.programPolicyMapping.length) {
    return `${controlsInSection.length} control${controlsInSection.length > 1 ? 's' : ''} continuously monitored by `;
  }
  if (!controlsInSection.length && section.programPolicyMapping.length) {
    return `${section.programPolicyMapping.length} polic${
      section.programPolicyMapping.length > 1 ? 'ies' : 'y'
    } continuously monitored by `;
  }
  return null;
};

const SectionTitleContainer = styled(Flex)`
  width: 100%;

  svg {
    height: 54px;
    width: auto;
  }
`;

const ComplianceGroupIdentifier = styled(Typography)`
  background-color: ${({ theme }) => flat(theme.color.system.gray, '20%')};
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  width: max-content;
  white-space: nowrap;
`;

const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

const CardHeaderCount = styled(Typography)`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => flat(theme.color.system.gray, '60%')};
  color: ${({ theme }) => rgb(theme.color.system.white)};
`;

const TableHeaderRowLabel = styled(Typography)`
  padding: 24px;
  white-space: nowrap;
  text-align: left;

  &:first-of-type {
    border-right: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  }
`;

const PolicyTableRow = styled.tr`
  background-color: ${({ theme }) => rgb(theme.color.system.white)};
`;

const GreenCheckIcon = styled(CheckIcon)`
  padding: 3px;
  stroke-width: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => flat(theme.color.system.positive)};
  color: ${({ theme }) => rgb(theme.color.system.white)};

  transform: translateY(4px);
`;

const EmptyStateContainer = styled(Flex)`
  margin: 24px;
  padding: 24px;
  background-color: ${({ theme }) => flat(theme.color.system.gray, '10%')};
  border-radius: 6px;
`;

const StyledStandardName = styled(Typography)`
  width: max-content;
  max-width: 180px;
  white-space: break-spaces;
`;

const StyledIdentifierName = styled(Typography)`
  min-width: 76px;
`;

const StyledFlex = styled(Flex)`
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
`;

function ControlListCard({ framework, section, controls }) {
  const controlsGroupedBySection = useMemo(
    () =>
      controls.sort(sortByShortName).reduce((groups, control) => {
        control.complianceMapping.mappings[framework.shortName].controls.forEach(
          ({ controlId, name, description, section: sectionId }) => {
            if (section.id === sectionId) {
              if (!groups[controlId]) {
                // eslint-disable-next-line no-param-reassign
                groups[controlId] = [];
              }

              groups[controlId].push({ ...control, name, description });
            }
          }
        );

        return groups;
      }, {}),
    [framework, section, controls]
  );

  const sortedSectionIDs = useMemo(
    () => Object.keys(controlsGroupedBySection).sort(sortByComplianceSectionID),
    [controlsGroupedBySection]
  );

  return (
    <TableCardFrame id="controls">
      <TableCardHeader
        as={Flex}
        justifyContent="center"
        alignItems="center"
        gap="8px"
      >
        <CardHeaderCount
          as={Flex}
          justifyContent="center"
          alignItems="center"
          level="9"
        >
          {controls.length}
        </CardHeaderCount>
        &nbsp;Controls
      </TableCardHeader>
      {controls.length === 0 ? (
        <EmptyStateContainer justifyContent="center">
          <Typography>
            This&nbsp;
            {COMPLIANCE_STANDARD_SECTION_DESCRIPTOR[framework.shortName]?.singular?.toLowerCase() ?? 'Section'}
            &nbsp;contains no controls.
          </Typography>
        </EmptyStateContainer>
      ) : (
        <CardTable>
          <thead>
            <CardTableHeaderRow>
              {[
                STANDARD_CONTROL_COLUMN_NAME[framework.shortName] ?? `${framework.name} Section`,
                ...CONTROL_TABLE_HEADERS,
              ].map((title) => (
                <TableHeaderRowLabel
                  key={title}
                  as="th"
                >
                  {title}
                </TableHeaderRowLabel>
              ))}
            </CardTableHeaderRow>
          </thead>
          <tbody>
            {sortedSectionIDs.map((controlSection) =>
              controlsGroupedBySection[controlSection].map((control, rowIdx) => (
                <tr key={`${controlSection}${control.shortName}`}>
                  {rowIdx === 0 && (
                    <CardTableCell
                      as="td"
                      level="8"
                      rowSpan={controlsGroupedBySection[controlSection].length}
                      className="leading"
                    >
                      <Flex gap="10px">
                        <StyledIdentifierName level="8">{controlSection}</StyledIdentifierName>
                        <StyledStandardName level="8">
                          {[COMPLIANCE_STANDARDS.CMMC_L1, COMPLIANCE_STANDARDS.CMMC_L2].includes(framework.shortName)
                            ? control.description
                            : control.name}
                        </StyledStandardName>
                      </Flex>
                    </CardTableCell>
                  )}
                  <CardTableCell as="td">{control.customShortName ?? control.shortName}</CardTableCell>
                  <CardTableCell
                    as="td"
                    className="stretch"
                  >
                    <BrandedLink href={`/controls/${control.customShortName ?? control.shortName}`}>
                      {control.categorization.subcategory}
                    </BrandedLink>
                  </CardTableCell>
                  <CardTableCell as="td">
                    <GreenCheckIcon size={20} />
                  </CardTableCell>
                </tr>
              ))
            )}
          </tbody>
        </CardTable>
      )}
    </TableCardFrame>
  );
}

const PolicyListCard = ({ framework, policies: unsortedPolicies }) => {
  const policies = useMemo(() => unsortedPolicies.sort(sortByShortName), [unsortedPolicies]);

  return (
    <TableCardFrame id="policies">
      <TableCardHeader
        as={Flex}
        justifyContent="center"
        alignItems="center"
        gap="8px"
      >
        <CardHeaderCount
          as={Flex}
          justifyContent="center"
          alignItems="center"
          level="9"
        >
          {policies?.length}
        </CardHeaderCount>
        &nbsp;Policies
      </TableCardHeader>
      {policies?.length === 0 ? (
        <EmptyStateContainer justifyContent="center">
          <Typography>
            This&nbsp;
            {COMPLIANCE_STANDARD_SECTION_DESCRIPTOR[framework.shortName]?.singular?.toLowerCase() ?? 'Section'}
            &nbsp;contains no policies.
          </Typography>
        </EmptyStateContainer>
      ) : (
        <CardTable>
          <thead>
            <CardTableHeaderRow>
              {POLICY_TABLE_HEADERS.map((title) => (
                <TableHeaderRowLabel
                  key={title}
                  as="th"
                  text="elephant"
                >
                  {title}
                </TableHeaderRowLabel>
              ))}
            </CardTableHeaderRow>
          </thead>
          <tbody>
            {policies?.map((policy) => (
              <PolicyTableRow key={`${policy.shortName}`}>
                <CardTableCell
                  as="td"
                  level="8"
                  className="leading"
                >
                  {policy.shortName}
                </CardTableCell>
                <CardTableCell
                  as="td"
                  className="stretch"
                >
                  <BrandedLink href={`/policies/${policy.shortName}`}>{policy.title}</BrandedLink>
                </CardTableCell>
                <CardTableCell
                  as="td"
                  className="stretch"
                >
                  {policy.description}
                </CardTableCell>
              </PolicyTableRow>
            ))}
          </tbody>
        </CardTable>
      )}
    </TableCardFrame>
  );
};

function CertificationStandardDetail({ slug, id: sectionId }) {
  const theme = useTheme();
  const { data } = useFrameworkList();
  const { navigate } = useNavigation();

  const frameworks = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.filter((framework) => framework.isCatalog);
    }
    return [];
  }, [data]);

  const { certificationAccessLevelMap, shouldHideControlDetail, shouldHidePolicyDetail } = useContentVisibility();
  const shouldHideArtifactDetails = shouldHideControlDetail && shouldHidePolicyDetail;
  const shouldHideCertificationDetail = certificationAccessLevelMap[slug];

  const route = useCurrentRoute();
  const subtype = route.url.query.subtype || '';

  const framework = frameworks.find(({ shortName }) => `${shortName}${subtype}` === slug);

  const { data: sectionList } = useFrameworkSectionList(framework?.id);

  const section = sectionList?.find(({ id }) => id === sectionId);

  const [controls] = useControlList();

  const subsections = section?.subsections;

  const subsectionReferenceIds = subsections.map(({ referenceId }) => referenceId);

  const controlsInSection = useMemo(
    () =>
      controls.filter((control) => {
        const controlMappingToThisFramework = control.complianceMapping.mappings[framework.shortName];
        return (
          controlMappingToThisFramework?.controls.find(({ controlId }) => subsectionReferenceIds.includes(controlId)) &&
          control.state.value === CONTROL_STATES.ADOPTED
        );
      }),
    [controls, framework, subsectionReferenceIds]
  );

  useEffect(() => {
    if (shouldHideCertificationDetail || shouldHideArtifactDetails) navigate('/certifications');
  }, []);

  return (
    <Flex
      direction="column"
      gap="2rem"
    >
      <StyledFlex
        as={Link}
        href={`/certifications/${slug}`}
        alignItems="center"
        gap="12px"
      >
        <UilArrowLeft
          size={20}
          color={flat(theme.color.system.gray, '60%')}
        />
        <Typography level="9">Back to Standards</Typography>
      </StyledFlex>
      <CardContainer>
        <SectionTitleContainer
          alignItems="center"
          gap="12px"
        >
          {COMPLIANCE_STANDARD_LOGO_MAP[framework.shortName] && (
            <img
              src={
                typeof COMPLIANCE_STANDARD_LOGO_MAP[framework.shortName] === 'object' // Needed to determine if the logo is on the top level or in the object
                  ? COMPLIANCE_STANDARD_LOGO_MAP[framework.shortName][SOC_SUBTYPE.GENERIC]
                  : COMPLIANCE_STANDARD_LOGO_MAP[framework.shortName]
              }
              srcSet={
                typeof COMPLIANCE_STANDARD_SRCSET_MAP[framework.shortName] === 'object' // Needed to determine if the logo is on the top level or in the object
                  ? COMPLIANCE_STANDARD_SRCSET_MAP[framework.shortName][SOC_SUBTYPE.GENERIC]
                  : COMPLIANCE_STANDARD_SRCSET_MAP[framework.shortName]
              }
              alt={`${COMPLIANCE_STANDARD_DISPLAY_NAME[framework.shortName]} logo`}
              height={80}
            />
          )}
          <Flex
            direction="column"
            gap="8px"
          >
            {(section.displayIdentifier ?? section.referenceId) &&
              ![
                COMPLIANCE_STANDARDS.CMMC_L1,
                COMPLIANCE_STANDARDS.CMMC_L2,
                COMPLIANCE_STANDARDS.HIPAA,
                COMPLIANCE_STANDARDS.NIST_CSF,
                COMPLIANCE_STANDARDS.NIST_SP_800_171,
              ].includes(framework.shortName) && (
                <ComplianceGroupIdentifier>
                  {section.displayIdentifier ?? section.referenceId}
                </ComplianceGroupIdentifier>
              )}
            <Heading
              level="6"
              as="h1"
            >
              {[COMPLIANCE_STANDARDS.CMMC_L1].includes(framework.shortName) ? section.referenceId : section.title}
            </Heading>
          </Flex>
        </SectionTitleContainer>
        <Flex
          direction="column"
          justifyContent="space-between"
          alignItems="end"
          gap="8px"
        >
          <CertificationDropdown
            framework={framework}
            currentSection={section}
          />
          {!!getMonitoringStatus(controlsInSection, section) && (
            <Flex
              alignItems="center"
              gap="8px"
            >
              <Heartbeat strokeColor={flat(theme.color.system.positive)} />
              <StyledTypography
                level="8"
                as="p"
              >
                {getMonitoringStatus(controlsInSection, section)}
                <BrandedLink
                  href={TRUSTCLOUD_WEBSITE_URL}
                  target="_blank"
                >
                  TrustCloud
                </BrandedLink>
              </StyledTypography>
            </Flex>
          )}
        </Flex>
      </CardContainer>
      {!shouldHideControlDetail && (
        <ControlListCard
          framework={framework}
          section={section}
          controls={controlsInSection}
        />
      )}
      {!shouldHidePolicyDetail && (
        <PolicyListCard
          framework={framework}
          policies={section.programPolicyMapping}
        />
      )}
    </Flex>
  );
}

export default withTrustShareNavigationBar(CertificationStandardDetail);
