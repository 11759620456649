import React, { useMemo } from 'react';
import { flat, Heading } from '@kintent/glide';
import { useTheme } from '@emotion/react';
import { Link } from 'react-navi';
import { useContainer } from 'unstated-next';

import * as Styles from './DashboardPolicySection.styles';
import DashboardPolicySectionItem from './DashboardPolicySectionItem';
import Heartbeat from '../../../../components/Heartbeat';
import { Flex } from '../../../../components/Flex';
import { useContentVisibility, useFeatureFlag, usePolicyList, useSegment } from '../../../../lib/state';
import { getPoliciesForGroup, sortByProp } from '../../../../lib/utils';
import { POLICY_SECURITY_GROUPS } from '../../../../lib/constants';
import { FEATURE_FLAG } from '../../../../lib/featureFlags';
import { SegmentProvider } from '../../../../components/SegmentProvider';

const sortByTitle = sortByProp('title');
const SECURITY_GROUPS = Object.values(POLICY_SECURITY_GROUPS);
const MAX_LIMIT = 4;

function DashboardPolicySection() {
  const theme = useTheme();
  const isPortalSegmentAware = useFeatureFlag(FEATURE_FLAG.SEGMENT_AWARE_TS_PORTAL_V1);
  const { segmentId } = useContainer(SegmentProvider);
  const { data: segment } = useSegment(segmentId);

  const selectedSegment = useMemo(() => (isPortalSegmentAware ? segment : null), [isPortalSegmentAware, segment]);

  const { shouldHidePolicyDetail: shouldHideLinkForPolicyDetails } = useContentVisibility();
  const [policiesList] = usePolicyList();

  const policies = useMemo(() => {
    if (selectedSegment) {
      return policiesList.filter(
        (policy) => selectedSegment.content.data.policies.includes(policy.id) && policy.trustShareAccessLevel !== null
      );
    }
    return policiesList.filter((policy) => policy.trustShareAccessLevel !== null);
  }, [policiesList, selectedSegment]);

  if (policies.length === 0) return null;
  return (
    <Styles.PolicySectionContainer as="section">
      <Flex
        gap="16px"
        alignItems="center"
      >
        <Styles.CountBadge variant="primary">{policies.length}</Styles.CountBadge>
        <Heading level="3">Policies</Heading>
        <Heartbeat strokeColor={flat(theme.color.system.positive)} />
      </Flex>
      <Styles.Grid>
        {SECURITY_GROUPS.map((group) => {
          const policiesPerGroup = getPoliciesForGroup(policies.sort(sortByTitle), group);
          if (policiesPerGroup.length === 0) return null;
          return (
            <Styles.Card
              key={group}
              gap="16px"
              direction="column"
            >
              <Flex
                alignItems="center"
                gap="4px"
              >
                <Heading level="5">{group}</Heading>
                <Styles.PolicyLengthLabel level="6">·</Styles.PolicyLengthLabel>
                <Styles.PolicyLengthLabel level="6">{policiesPerGroup.length}</Styles.PolicyLengthLabel>
              </Flex>
              <div>
                {policiesPerGroup.map(
                  (policy, idx) =>
                    idx < MAX_LIMIT && (
                      <DashboardPolicySectionItem
                        accessLevel={policy.trustShareAccessLevel}
                        key={policy.id}
                        policyId={policy.id}
                        shortName={policy.shortName}
                        shouldHideLinkForPolicyDetails={shouldHideLinkForPolicyDetails}
                        title={policy.title}
                        policy={policy}
                        externalPolicyUrl={policy.externalPolicyUrl}
                      />
                    )
                )}
              </div>
              {!shouldHideLinkForPolicyDetails && (
                <Styles.ViewAllLink
                  as={Link}
                  href="/policies"
                  level="7"
                >
                  View All
                </Styles.ViewAllLink>
              )}
            </Styles.Card>
          );
        })}
      </Styles.Grid>
    </Styles.PolicySectionContainer>
  );
}

export default DashboardPolicySection;
