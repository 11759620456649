import styled from '@emotion/styled';

export const AppContainer = styled.div`
  background-color: white;

  display: grid;
  grid-template-areas:
    'main'
    'footer';

  min-height: 100vh;
`;
