import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import {
  UilImport as DownloadIcon,
  UilKeySkeleton as KeyIcon,
  UilExternalLinkAlt,
  UilFileSearchAlt,
} from '@iconscout/react-unicons';
import { Heading, Toast, ToastProvider, Typography } from '@kintent/glide';
import { FEATURE_FLAG } from 'lib/featureFlags';
import { Link, useNavigation } from 'react-navi';
import { Spinner } from '../../../../components/Spinner';
import DateTime from '../../../../components/DateTime';
import { Flex } from '../../../../components/Flex';
import Heartbeat from '../../../../components/Heartbeat';
import { useAuthService, useFeatureFlag, usePolicy, useRequestAccessRedirectionUrl } from '../../../../lib/state';
import {
  canDownloadPolicy,
  downloadFileFromAPI,
  isDataRoomUserAbleToAccess,
  shouldHideRequestAccessForDataRoom,
} from '../../../../lib/utils';
import { BREAKPOINTS } from '../../../../lib/constants';
import CardContainer from '../../../../components/CardContainer';
import { GlideButton } from '../../../../components/GlideButton';
import { Styled } from './PolicyMetadata.styles';

// Styled components
const MetadataContainer = styled.div`
  & > h5 {
    padding-top: 10px;
  }

  & > p {
    padding-top: 4px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & > div {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0;
    }
  }
`;

const PolicyFieldWrapper = styled(Flex)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    flex-wrap: nowrap;
    gap: 32px;
  }
`;

const DownloadButton = styled(GlideButton)`
  & svg {
    margin-left: 8px;
  }
`;

const LinkButton = styled(GlideButton)`
  text-decoration: none;
`;

const PolicyField = ({ fieldName, fieldValue }) => (
  <Flex
    direction="column"
    gap="8px"
  >
    <Typography
      as="p"
      level="9"
    >
      {fieldName}
    </Typography>
    <Heading
      as="p"
      level="8"
    >
      {fieldValue}
    </Heading>
  </Flex>
);

function PolicyMetadata({
  policy,
  id,
  title,
  description,
  shortName,
  department = null,
  approvalDate = null,
  accessLevel,
  externalPolicyUrl,
}) {
  const theme = useTheme();
  const { navigate } = useNavigation();
  const { authenticatedUser } = useAuthService();
  const [isPolicyDownloading, setDownloadingPolicy] = useState(false);
  const [toastError, setToastError] = useState(null);
  const [showExportToast, setShowExportToast] = useState(false);
  const { downloadPolicy } = usePolicy(id);
  const hasDataRoomAccess = isDataRoomUserAbleToAccess(policy, authenticatedUser);
  const canDownload = canDownloadPolicy(accessLevel, authenticatedUser) && hasDataRoomAccess;
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);
  const isDisableContinuouslyMonitored = useFeatureFlag(FEATURE_FLAG.DISABLE_CONTINUOUSLY_MONITORED);
  const hideDataRoomRequestAccess = useFeatureFlag(FEATURE_FLAG.HIDE_DATA_ROOM_REQUEST_ACCESS);
  const enableViewOnlyDocuments = useFeatureFlag(FEATURE_FLAG.VIEW_ONLY_DOCUMENTS);
  const canViewExternalPolicyUrl = externalPolicyUrl && canDownload;

  const isPolicyInDataRoom = policy.dataRoomIds && policy.dataRoomIds.length > 0;

  const viewPolicyPageUrl = `/policies/${id}/view`;

  // TODO - This should be handled based on the access level of the policy
  const handlePolicyDownload = async () => {
    setDownloadingPolicy(true);
    try {
      await downloadFileFromAPI(() => downloadPolicy(), `${title.toLowerCase().replace(' ', '_')}.pdf}`);
    } catch (e) {
      setToastError({
        hasError: true,
        errorMessage: e.name,
      });
      setShowExportToast(!showExportToast);
    } finally {
      setDownloadingPolicy(false);
    }
  };

  const hideRequestAccessForDataRoom = shouldHideRequestAccessForDataRoom(
    hideDataRoomRequestAccess,
    isPolicyInDataRoom
  );

  // TODO - Refactor this to be more readable and reusable
  const requestAccessButtonVisibility =
    !canDownload && !canViewExternalPolicyUrl && !authenticatedUser && !isRequestAccessHidden;

  const handleRequestAccess = useCallback(() => {
    navigate(requestAccessRedirectionUrl);
  }, [navigate, requestAccessRedirectionUrl]);

  return (
    <CardContainer
      direction="column"
      gap="16px"
    >
      <MetadataContainer>
        <Flex
          gap="12px"
          direction="column-reverse"
          alignItems="flex-start"
        >
          <Heading level="5">{title}</Heading>
          <Flex
            alignItems="center"
            gap="8px"
          >
            <Heartbeat strokeColor={theme.palette.jade} />
            <Typography
              level="9"
              as="p"
            >
              {isDisableContinuouslyMonitored ? 'Assessed ' : 'Continuously assessed '} for adherence and risk
            </Typography>
          </Flex>
        </Flex>
        <Typography
          level="8"
          as="p"
        >
          {description}
        </Typography>
      </MetadataContainer>
      <PolicyFieldWrapper gap="24px">
        <PolicyField
          fieldName="Policy ID"
          fieldValue={shortName}
        />
        {department && (
          <PolicyField
            fieldName="Department"
            fieldValue={department}
          />
        )}
        {approvalDate && (
          <PolicyField
            fieldName="Last Approved"
            fieldValue={
              <DateTime
                date={new Date(approvalDate)}
                shortMonth
                includeYear
              />
            }
          />
        )}
      </PolicyFieldWrapper>

      {enableViewOnlyDocuments && canViewExternalPolicyUrl ? (
        <DownloadButton
          as={Link}
          href={externalPolicyUrl}
          target="_blank"
        >
          View <UilExternalLinkAlt size={16} />
        </DownloadButton>
      ) : (
        enableViewOnlyDocuments &&
        canDownload && (
          <LinkButton
            as={Link}
            href={viewPolicyPageUrl}
            icon={<UilFileSearchAlt size={20} />}
            variant="secondary"
            className="secondary"
          >
            View
          </LinkButton>
        )
      )}

      {!enableViewOnlyDocuments && canViewExternalPolicyUrl && (
        <DownloadButton
          as={Link}
          href={externalPolicyUrl}
          target="_blank"
        >
          View <UilExternalLinkAlt size={16} />
        </DownloadButton>
      )}

      {requestAccessButtonVisibility && !hideRequestAccessForDataRoom && (
        <DownloadButton onClick={handleRequestAccess}>
          Request access to {enableViewOnlyDocuments ? 'view' : 'download'}
          <KeyIcon size={16} />
        </DownloadButton>
      )}

      {!enableViewOnlyDocuments && (
        <>
          {canDownload && !canViewExternalPolicyUrl && (
            <DownloadButton
              onClick={handlePolicyDownload}
              disabled={isPolicyDownloading}
            >
              Download
              {isPolicyDownloading ? <Spinner size={16} /> : <DownloadIcon size={16} />}
            </DownloadButton>
          )}
        </>
      )}
      {toastError?.hasError && (
        <>
          <ToastProvider>
            <Toast
              variant="negative"
              label="Error"
              description={toastError?.errorMessage}
              open={showExportToast}
              duration={3000}
              onOpenChange={() => setShowExportToast(false)}
            />
            <Styled.StyledToastViewport />
          </ToastProvider>
        </>
      )}
    </CardContainer>
  );
}

export default PolicyMetadata;
