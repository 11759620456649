import styled from '@emotion/styled';
import { color, Typography } from '@kintent/glide';

export const Navigation = styled.nav`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  padding: 20px 32px;

  background-color: ${({ theme }) => theme.components.header.navigation.backgroundColor};
  border: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 50)};
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  box-shadow: ${({ theme }) => `0 1px 1px 0 ${color(theme.colorV2.system.gray, 50)}`};

  min-height: 82px;

  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 12rem);
`;

export const LogoContainer = styled.div`
  justify-left: start;
`;

export const TrustShareLogoContainer = styled.a`
  display: flex;

  width: 150px;
  height: 50px;
`;

export const MenuItemsContainer = styled.div`
  justify-self: center;
`;

export const NavigationItemList = styled.ul`
  display: flex;
  gap: 32px;

  list-style: none;
`;

export const NavigationItem = styled(Typography)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    color: ${({ theme }) => theme.components.header.navigation.textColor};
    text-decoration: underline;
  }
`;
