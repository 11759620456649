import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@kintent/glide';

import { Grid } from '../design-system';
import { BREAKPOINTS } from '../lib/constants';

const ColumnNameGrid = styled(Grid)`
  padding-left: 16px;
  padding-right: 16px;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  & > p {
    white-space: nowrap;
  }

  & > p:nth-of-type(2) {
    display: none;

    @media (min-width: ${BREAKPOINTS.TABLET}px) {
      display: block;
    }
  }

  & > p:last-child {
    width: 100%;
    text-align: right;
    @media (min-width: ${BREAKPOINTS.TABLET}px) {
      text-align: left;
    }
  }
`;

function MappingColumnName({ columnNames }) {
  return (
    <ColumnNameGrid container>
      {columnNames.map((columnName) => {
        return (
          <Typography
            as="p"
            key={columnName}
            item
            level="9"
          >
            {columnName}
          </Typography>
        );
      })}
    </ColumnNameGrid>
  );
}

export default MappingColumnName;
