import React, { useEffect, useState } from 'react';
import { useCurrentRoute } from 'react-navi';

import { Helmet } from '../../../components/Helmet';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { FloatingRequestAccessButton } from '../../../components/FloatingRequestAccessButton';
import SubprocessorLeftPanel from './components/SubprocessorLeftPanel';
import { useSubprocessorList } from '../../../lib/state';
import SubprocessorDetailPanel from './components/SubprocessorDetailPanel';
import SubprocessorDropdown from './components/SubprocessorDropdown';
import PageContainer from '../../../components/PageContainer';
import { useMeasurePaint, useMeasureRender, useMeasureRoute } from '../../../lib/measurement';

export const Subprocessors = withTrustShareNavigationBar(({ name }) => {
  const {
    data: { span },
  } = useCurrentRoute();
  if (name) {
    span.setAttribute('page-name', 'subprocessor-details');
  } else {
    span.setAttribute('page-name', 'subprocessors');
  }
  useMeasureRoute(span);

  const [vendors] = useSubprocessorList();

  const subprocessors = vendors.filter((subprocessor) => subprocessor.isSubprocessor);

  let subprocessorToSelect = null;

  if (name) {
    subprocessorToSelect = subprocessors.find((subprocessor) => subprocessor.name === name);
  }

  const [selectedSubprocessor, setSelectedSubprocessor] = useState(subprocessorToSelect ?? subprocessors[0]);

  useEffect(() => {
    window.history.replaceState(null, null, `/subprocessors/${(subprocessorToSelect || subprocessors[0]).name}`);
  }, []);

  const handleSubprocessorClick = (subprocessor) => {
    if (subprocessor.id !== selectedSubprocessor.id) {
      setSelectedSubprocessor(subprocessor);
    }
  };
  if (subprocessors.length === 0) return null;

  const tree = (
    <>
      <PageContainer
        gap="32px"
        direction="column"
      >
        <Helmet pageTitle="Subprocessors" />
        <SubprocessorLeftPanel
          subprocessors={subprocessors}
          selectedSubprocessor={selectedSubprocessor}
          onSubprocessorClick={handleSubprocessorClick}
        />
        <SubprocessorDropdown
          subprocessors={subprocessors}
          selectedSubprocessor={selectedSubprocessor}
        />
        <SubprocessorDetailPanel selectedSubprocessor={selectedSubprocessor} />
        <FloatingRequestAccessButton />
      </PageContainer>
    </>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});
