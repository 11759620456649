import React, { useState } from 'react';
import { Toast } from '@kintent/glide';
import { ScrollTarget } from './DocumentDetailPanel.styles';

import * as Styles from './DocumentResourceSection.styles';
import DashboardDocumentCard from '../../home/components/DashboardDocumentCard';
import { Flex } from '../../../../components/Flex';
import { useAuthService, useTeamDocumentList } from '../../../../lib/state';
import { DOCUMENT_ACCESS_LEVEL, DOCUMENT_TYPE } from '../../../../lib/constants';

function DocumentResourceSection() {
  const { currentTeam } = useAuthService();
  const [teamDocuments] = useTeamDocumentList(currentTeam.id);
  const [shouldShowDownloadErrorToast, setShouldShowDownloadErrorToast] = useState(false);

  const privatePublicDocumentList = teamDocuments
    .filter((resource) => [DOCUMENT_ACCESS_LEVEL.PRIVATE, DOCUMENT_ACCESS_LEVEL.PUBLIC].includes(resource.accessLevel))
    .map((resource) => ({
      ...resource,
      documentType: DOCUMENT_TYPE.RESOURCE,
    }));

  if (!privatePublicDocumentList.length) {
    return null;
  }

  return (
    <>
      <Flex
        direction="column"
        gap="16px"
      >
        <ScrollTarget id={DOCUMENT_TYPE.RESOURCE} />
        <Styles.DocumentTitle level="5">Other Resources</Styles.DocumentTitle>
        <Styles.Grid>
          {privatePublicDocumentList.map((document) => {
            return (
              <DashboardDocumentCard
                key={document.id}
                document={document}
                setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
              />
            );
          })}
        </Styles.Grid>
      </Flex>
      <Toast
        variant="negative"
        label="Error"
        description="We couldn't generate the download file, please contact support."
        open={shouldShowDownloadErrorToast}
        duration={1000}
        onOpenChange={() => setShouldShowDownloadErrorToast(false)}
      />
    </>
  );
}

export default DocumentResourceSection;
