import { UilExternalLinkAlt, UilPadlock } from '@iconscout/react-unicons';
import React from 'react';
import { Link } from 'react-navi';

import { FEATURE_FLAG } from 'lib/featureFlags';
import { Spinner } from '../../../../components/Spinner';
import {
  useAuthService,
  useDocumentDownload,
  useDocumentViewedActivity,
  useFeatureFlag,
  useRequestAccessRedirectionUrl,
} from '../../../../lib/state';
import { downloadFileFromAPI } from '../../../../lib/utils';
import * as Styles from './DashboardDocuments.styles';
import { GlideButton } from '../../../../components/GlideButton';

const ACTION_ICON_SIZE = 20;

function ResourceActionButton({
  canDownload,
  canView,
  documentId,
  isDownloading,
  setDownloading,
  identifier,
  setShouldShowDownloadErrorToast,
}) {
  const { currentTeam, authenticatedUser } = useAuthService();
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const shouldShowRequestAccessButton = !isRequestAccessHidden && !canDownload && !canView && !authenticatedUser;

  const { trackDocumentViewedActivity } = useDocumentViewedActivity();

  const downloadResource = useDocumentDownload(currentTeam.id, documentId);

  const handleDocumentDownload = async (resourceFilename) => {
    setDownloading(true);
    try {
      await downloadFileFromAPI(() => downloadResource(), resourceFilename);
    } catch (e) {
      setShouldShowDownloadErrorToast(true);
    }
    setDownloading(false);
  };

  const handleResourceClick = () => {
    trackDocumentViewedActivity(documentId);
    window.open(identifier);
  };

  if (canDownload) {
    return (
      <GlideButton
        variant="secondary"
        icon={isDownloading ? <Spinner /> : <Styles.DownloadIcon size={ACTION_ICON_SIZE} />}
        onClick={() => handleDocumentDownload(identifier)}
        disabled={isDownloading}
        className="secondary"
      >
        Download
      </GlideButton>
    );
  }

  if (canView && !canDownload) {
    return (
      <Styles.LinkButton
        icon={<UilExternalLinkAlt size={ACTION_ICON_SIZE} />}
        onClick={handleResourceClick}
        target="_blank"
        variant="secondary"
        className="secondary"
      >
        View
      </Styles.LinkButton>
    );
  }

  if (shouldShowRequestAccessButton) {
    return (
      <Styles.LinkButton
        as={Link}
        variant="secondary"
        icon={<UilPadlock size={ACTION_ICON_SIZE} />}
        href={requestAccessRedirectionUrl}
        className="secondary"
      >
        Request Access
      </Styles.LinkButton>
    );
  }

  return null;
}

export default ResourceActionButton;
