import styled from '@emotion/styled';
import { UilImport } from '@iconscout/react-unicons';
import { Badge, base, flat, rgb, TabList, translucent, Typography } from '@kintent/glide';

import HomePageContainer from './HomePageContainer';
import { GlideButton } from '../../../../components/GlideButton';

export const DashboardLayoutContainer = styled(HomePageContainer)`
  margin-top: 72px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  margin-top: 16px;
`;

export const GridItem = styled.div`
  min-height: 56px;
  min-width: 316px;
  padding: 16px;

  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  border-radius: 8px;

  background-color: ${({ theme }) => rgb(theme.color.system.white)};
  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '10%')};

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }
`;

export const DownloadIcon = styled(UilImport)`
  color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
`;

export const Overlay = styled.div`
  background: ${({ theme }) => rgb(theme.color.system.gray['20%'], '50%')};
  border-radius: 6px;
  padding-right: 14px;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;

  opacity: 0;

  & > button,
  a {
    transform: translateY(-8px);
    transition: transform 150ms ease-in 50ms;
  }

  &:hover {
    opacity: 1;

    & > button,
    a {
      transform: translateY(0);
    }
  }

  transition: opacity 250ms ease-in;
`;

export const LinkButton = styled(GlideButton)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const LearnMoreContent = styled(Typography)`
  min-height: 120px;
  word-break: break-word;
`;

export const DocumentTitleTypography = styled(Typography)`
  padding-right: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  overflow: hidden;
  word-break: break-word;
`;

export const CountBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
`;

export const DocumentTabList = styled(TabList)`
  & > button {
    border-left-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};

    &[data-state='active'] {
      background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
      border-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};

      & + [data-state='inactive'] {
        border-left-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
      }
    }

    &:hover {
      & + [data-state='inactive'] {
        border-left-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
      }
    }

    &:hover {
      border-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    }
  }
`;
