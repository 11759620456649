import styled from '@emotion/styled';
import { AccordionItem } from '@kintent/glide';

import { hexOpacity } from '../../../lib/utils';

export const FaqAccordionItem = styled(AccordionItem)`
  &:hover[data-state='closed'] {
    border-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};

    & > div > button {
      color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
      background-color: ${({ theme }) => hexOpacity(theme.components.header.primaryCTAButton.background, 0.1)};
    }
  }

  &:hover[data-state='open'] {
    & > div > button {
      color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
      border-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    }
  }

  &[data-state='open'] {
    border-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }
`;
