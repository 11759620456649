import React from 'react';
import styled from '@emotion/styled';
import { Heading, Typography } from '@kintent/glide';

import { SpaceAround } from '../../../design-system';
import { Flex } from '../../Flex';

// Assets
import welcomeIllustration from '../../../assets/welcomeIllustration.svg';

const ModalWelcomeImageWrapper = styled.div`
  width: 150px;
  height: 185px;

  & > img {
    object-fit: contain;
  }
`;

function WelcomeStep({ teamName, salutationMessage }) {
  const doesTeamNameEndWithS = teamName[teamName.length - 1].toLowerCase() === 's';
  return (
    <Flex
      as={SpaceAround}
      bottom="32px"
      direction="column"
      gap="32px"
      alignItems="center"
    >
      <Heading level="4">{`Welcome to ${teamName}${doesTeamNameEndWithS ? '’' : '’s'} TrustShare!`}</Heading>
      <ModalWelcomeImageWrapper>
        <img
          src={welcomeIllustration}
          alt="welcome-Illustration"
        />
      </ModalWelcomeImageWrapper>
      <Typography level="body">{salutationMessage}</Typography>
    </Flex>
  );
}

export default WelcomeStep;
