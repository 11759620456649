import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Heading, Typography } from '@kintent/glide';

import { Stack } from '../../../design-system';
import { Checkbox } from '../../Checkbox';
import { Flex } from '../../Flex';
import { BREAKPOINTS } from '../../../lib/constants';

const StyledCheckbox = styled(Checkbox)`
  min-width: 16px;
  margin-top: 9px;

  & + label {
    display: block;
  }
`;

const StyledFlex = styled(Flex)`
  margin-top: 16px;

  & + div {
    margin: 12px 0;
  }
`;

const NdaAgreementMessage = styled(Typography)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const StyledPdfWrapper = styled.div`
  overflow-y: auto;
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    height: 450px;
  }
`;

function NdaAgreementStep({ teamName, ndaDocumentUrl, checkboxState, email }) {
  const [totalPages, setTotalPages] = useState(0);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  return (
    <>
      <Stack>
        <Heading level="4">NDA Agreement</Heading>
        <NdaAgreementMessage
          as="p"
          level="body"
        >
          Please read through the following NDA agreement and agree or decline your consent below.
        </NdaAgreementMessage>
      </Stack>
      <StyledPdfWrapper>
        <Document
          file={ndaDocumentUrl}
          onLoadSuccess={handleDocumentLoadSuccess}
        >
          {Array.from({ length: totalPages }, (_, i) => {
            return (
              <Page
                pageNumber={i + 1}
                scale={1.0}
                noData={false}
                key={i}
              />
            );
          })}
        </Document>
      </StyledPdfWrapper>
      <StyledFlex gap="8px">
        <StyledCheckbox
          onClick={() => checkboxState.setState(!checkboxState.state)}
          checked={checkboxState.state}
          id="agreeCheckbox"
        />
        <Typography
          as="label"
          level="8"
          htmlFor="agreeCheckbox"
        >
          I agree to the above Non-Disclosure Agreement. I am aware that my consent will be recorded.
        </Typography>
      </StyledFlex>
      <Flex
        direction="column"
        gap="16px"
      >
        <Typography
          as="p"
          level="8"
        >
          By checking the box above, you are agreeing to the terms of this NDA with {teamName}. A copy of the agreement
          will be emailed to {email}.
        </Typography>
        <Typography
          as="p"
          level="8"
        >
          Declining this agreement will log you out but still allow you to view our public documents on our TrustShare.
        </Typography>
      </Flex>
    </>
  );
}

export default NdaAgreementStep;
