import styled from '@emotion/styled';
import { Flex } from './Flex';
import { BREAKPOINTS } from '../lib/constants';

// This component hosts the RHS content of the page on Controls, Policies and Subprocessors module.
// We can reuse this for the other pages in future having a similar design paradigm.
const PageContainer = styled(Flex)`
  width: calc(100% - 2em);

  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    max-width: 1200px;
    flex-direction: row;
  }
`;

export default PageContainer;
