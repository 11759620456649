import React from 'react';
import { Tag, Typography } from '@kintent/glide';
import { format } from 'date-fns';

import * as Styles from './NotificationItem.styles';
import { TRUSTSHARE_NOTIFICATION_TYPE_LABELS } from '../../../../../lib/constants';

function NotificationItem({ notification, notificationLength, index }) {
  const formattedDate = format(new Date(notification._metadata.createdDate), 'EEEE, MMMM d, yyyy');

  const shouldDisplaySeparator = index < notificationLength - 1;
  return (
    <Styles.ListItem
      key={notification.id}
      className="Styles.ListItemst-item"
    >
      <Styles.NotificationHeader>
        <Typography
          as="p"
          level="4"
          className="notification-title"
        >
          {notification.title}
        </Typography>
      </Styles.NotificationHeader>
      <Typography
        strength="60%"
        as="p"
        className="notification-description"
        dangerouslySetInnerHTML={{ __html: notification.description }}
      />
      <Styles.NotificationMetadata>
        <Tag>{TRUSTSHARE_NOTIFICATION_TYPE_LABELS[notification.type]}</Tag>
        <Typography
          as="p"
          strength="60%"
          level="8"
        >
          {formattedDate}
        </Typography>
      </Styles.NotificationMetadata>
      {shouldDisplaySeparator && <hr className="separator" />}
    </Styles.ListItem>
  );
}

export default NotificationItem;
