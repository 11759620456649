import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Box, Dialog, DialogBackdrop, useDialogState } from 'reakit';
import { UilTimes as XIcon } from '@iconscout/react-unicons';
import PropTypes from 'prop-types';
import { Typography } from '@kintent/glide';

import IconButton from './IconButton';
import { hexOpacity, useInitialMount } from '../lib/utils';
import Button, { VARIANTS as BUTTON_VARIANTS } from './Button';
import { Flex } from '../components/Flex';

// Constants.
const CANCEL_ICON = <XIcon />;

const ACTION_ALIGNMENTS = {
  START: 'flex-start',
  CENTER: 'center',
  END: 'flex-end',
};

const HEADER_ALIGNMENTS = {
  START: 'space-between',
  CENTER: 'center',
  END: 'flex-end',
};

const StyledModal = styled(Dialog, {
  shouldForwardProp: (prop) =>
    !['banner', 'ignoreSidebar', 'hasPrimaryVariant', 'ignoreVerticalPosition'].includes(prop),
})`
  position: absolute;
  top: ${({ ignoreVerticalPosition }) => (ignoreVerticalPosition ? '5%' : '50%')};
  left: ${({ ignoreSidebar }) => (ignoreSidebar ? '50%' : 'calc(50% + 47.5px)')};
  transform: ${({ ignoreVerticalPosition }) => (ignoreVerticalPosition ? 'translateX(-50%)' : 'translate(-50%, -50%)')};

  @media (min-width: 1290px) {
    left: ${({ theme, ignoreSidebar }) =>
      ignoreSidebar ? '50%' : `calc(50% + ${parseInt(theme.components.sidebar.expandedWidth, 10) / 2}px)`};
  }

  display: flex;
  flex-direction: column;
  width: 635px;
  border-radius: 8px;
  box-shadow: 0 0 12px 0 ${({ theme }) => hexOpacity(theme.palette.granite, 0.3)};
  background-color: ${({ theme }) => theme.palette.white};
  padding: 40px;

  ${({ theme, banner }) =>
    !!banner &&
    css`
      & > :first-child {
        margin: -38px;
        margin-bottom: 16px;
      }

      & > :nth-child(2) {
        border-bottom-width: 0;
        margin-bottom: 0;
      }

      h3 {
        color: ${theme.palette.shadow};
        font-size: 1.125rem;
        & + div {
          display: none;
        }
      }
    `}

  ${({ hasPrimaryVariant, theme }) =>
    !hasPrimaryVariant &&
    css`
      button {
        --foreground: ${theme.palette.shadow};
      }
    `}
`;

const StyledBackdrop = styled(DialogBackdrop)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: ${({ theme }) => hexOpacity(theme.palette.granite, 0.4)};
  z-index: 25000;
`;

const Header = styled(Box, {
  shouldForwardProp(props) {
    return !['justifyContent'].includes(props);
  },
})`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const Title = styled(Typography)`
  color: ${({ theme: { components } }) => components.modal.title};
`;

const StyledCloseIcon = styled(IconButton)`
  position: absolute;
  top: 14px;
  right: 14px;
  color: ${({ theme }) => theme.palette.elephant};
`;

const TertiaryButton = styled(Button)`
  font-size: ${({ theme }) => theme.components.text.size.small};
  margin-right: auto;

  &:focus {
    text-decoration: unset;
  }
`;

/**
 * Returns an array of `[isOpen, show, hide]`, where `isOpen` is a boolean to be passed to the `open` property of a Modal component,
 * and `show` and `hide` are functions used to show or hide the modal.
 */
export function useModal(initialOpen = false) {
  const [isOpen, setOpen] = useState(initialOpen);

  const show = useCallback(() => setOpen(true), []);
  const hide = useCallback(() => setOpen(false), []);

  return { isOpen, show, hide };
}

export function Modal({
  title,
  titleAlignment,
  banner,
  isOpen,
  show,
  hide,
  primaryLabel,
  secondaryLabel,
  hideSecondary,
  tertiaryLabel,
  enableSecondary,
  secondaryButtonVariant,
  enableTertiary,
  onTertiary,
  tertiaryButtonVariant,
  onPrimary,
  onSecondary,
  onCancel,
  enablePrimary,
  primaryButtonVariant,
  primaryButtonStartIcon,
  primaryButtonEndIcon,
  secondaryButtonStartIcon,
  secondaryButtonEndIcon,
  showActions,
  actionsAlignment,
  actionsGap,
  showCloseIcon,
  showHeader,
  initialFocusRef,
  icon,
  children,
  hideOnEsc,
  ignoreSidebar,
  renderWithinPortal,
  ignoreVerticalPosition,
  ...remainingProps
}) {
  const dialogState = useDialogState({ modal: renderWithinPortal });
  const secondaryButtonRef = useRef(null);
  const titleRef = useRef(null);
  const initialMount = useInitialMount();

  useEffect(() => {
    if (isOpen) {
      dialogState.show();
    } else {
      dialogState.hide();
    }
  }, [isOpen]);

  // Memoized click handlers.
  const onCancelHandler = useCallback(() => {
    const keepOpen = onCancel?.();
    if (!keepOpen) {
      hide();
    }
  }, [hide, onCancel]);

  const onPrimaryHandler = useCallback(() => {
    const keepOpen = onPrimary();
    if (!keepOpen) {
      hide();
    }
  }, [hide, onPrimary]);

  const onSecondaryHandler = useCallback(() => {
    const keepOpen = (onSecondary || onCancel)?.();
    if (!keepOpen) {
      hide();
    }
  }, [hide, onCancel, onSecondary]);

  useEffect(() => {
    if (!dialogState.visible && isOpen && !initialMount) {
      onCancelHandler();
    }
  }, [dialogState.visible]);

  return (
    <StyledBackdrop {...dialogState}>
      <StyledModal
        aria-label={title}
        ignoreSidebar={ignoreSidebar}
        hideOnClickOutside={false}
        hideOnEsc={hideOnEsc}
        banner={banner}
        unstable_initialFocusRef={initialFocusRef ?? titleRef}
        unstable_finalFocusRef={secondaryButtonRef}
        unstable_disclosureRef={initialFocusRef || secondaryButtonRef}
        hasPrimaryVariant={primaryButtonVariant !== BUTTON_VARIANTS.TERTIARY}
        ignoreVerticalPosition={ignoreVerticalPosition}
        {...dialogState}
        {...remainingProps}
      >
        {banner}
        {showHeader && (
          <Header justifyContent={titleAlignment}>
            <Title
              as="h3"
              level="4"
              tabIndex={0}
              ref={titleRef}
            >
              {title}
            </Title>
            {showCloseIcon && (
              <IconButton
                aria-label="cancel"
                size="xlarge"
                icon={icon || CANCEL_ICON}
                onClick={onCancelHandler}
              />
            )}
          </Header>
        )}
        {!showHeader && showCloseIcon && (
          <StyledCloseIcon
            aria-label="cancel"
            size="xlarge"
            icon={icon || CANCEL_ICON}
            onClick={onCancelHandler}
          />
        )}
        {children}
        {showActions && (
          <Flex
            className="modalActions"
            alignItems="center"
            gap={actionsGap}
            justifyContent={actionsAlignment}
          >
            {enableTertiary && (
              <TertiaryButton
                variant={tertiaryButtonVariant || 'action'}
                disabled={!enableTertiary}
                onClick={onTertiary}
                className="tertiary-button"
              >
                {tertiaryLabel}
              </TertiaryButton>
            )}
            {!hideSecondary && (
              <Button
                className="secondary-button"
                ref={secondaryButtonRef}
                variant={secondaryButtonVariant}
                startIcon={secondaryButtonStartIcon}
                endIcon={secondaryButtonEndIcon}
                disabled={!enableSecondary}
                onClick={onSecondaryHandler}
              >
                {secondaryLabel}
              </Button>
            )}
            <Button
              variant={primaryButtonVariant}
              startIcon={primaryButtonStartIcon}
              endIcon={primaryButtonEndIcon}
              disabled={!enablePrimary}
              onClick={onPrimaryHandler}
            >
              {primaryLabel}
            </Button>
          </Flex>
        )}
      </StyledModal>
    </StyledBackdrop>
  );
}

Modal.propTypes = {
  /** The title to display in the modal header row. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /** If set, display element after title bar */
  banner: PropTypes.element,
  /** A boolean controlling whether the modal is shown or hidden. */
  isOpen: PropTypes.bool.isRequired,
  /** The label of the Primary button. */
  primaryLabel: PropTypes.string,
  /** The label of the Secondary button. */
  secondaryLabel: PropTypes.string,
  /** The label of the Tertiary button. */
  tertiaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** A function that will be called when the Primary button is clicked. Should return `false` if the dialog can be closed, or `true` to keep it open. */
  onPrimary: PropTypes.func,
  /** A function that will be called when the Secondary button is clicked, or when the dialog is closed. */
  onSecondary: PropTypes.func,
  /** A function that will be called when the close icon is clicked. */
  onCancel: PropTypes.func,
  /** A function that will be called when the Tertiary button is clicked. */
  onTertiary: PropTypes.func,
  /** If false, disable the primary button */
  enablePrimary: PropTypes.bool,
  /** Whether the secondary button is visible */
  hideSecondary: PropTypes.bool,
  /** Whether the secondary button is enabled */
  enableSecondary: PropTypes.bool,
  /** Whether the tertiary button is enabled */
  enableTertiary: PropTypes.bool,
  /** If false, hide action buttons */
  showActions: PropTypes.bool,
  /** If false, hide close icon button */
  showCloseIcon: PropTypes.bool,
  /** If false, hide title text and cancel button */
  showHeader: PropTypes.bool,
  /** If true, hide the modal by hitting the ESC key. */
  hideOnEsc: PropTypes.bool,
  /** A reference to a React element that should gain focus when the modal is first opened. */
  // eslint-disable-next-line react/forbid-prop-types
  initialFocusRef: PropTypes.object,
  /** If set, display this icon instead of the standard 'X' icon at the corner of the dialog. */
  icon: PropTypes.element,
  /** If true, center the modal without taking into account the sidebar width */
  ignoreSidebar: PropTypes.bool,
  /** If true, render the dialog within a Portal. */
  renderWithinPortal: PropTypes.bool,
  /** Header position */
  titleAlignment: PropTypes.oneOf(Object.values(HEADER_ALIGNMENTS)),
  /** Variant of primary button */
  primaryButtonVariant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  /** Variant of secondary button */
  secondaryButtonVariant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  /** Icon that will be displayed on the left of the primary button */
  primaryButtonStartIcon: PropTypes.element,
  /** Icon that will be displayed on the right of the primary button */
  primaryButtonEndIcon: PropTypes.element,
  /** Icon that will be displayed on the left of the secondary button */
  secondaryButtonStartIcon: PropTypes.element,
  /** Icon that will be displayed on the right of the secondary button */
  secondaryButtonEndIcon: PropTypes.element,
  /** Action buttons position */
  actionsAlignment: PropTypes.oneOf(Object.values(ACTION_ALIGNMENTS)),
  /** Action buttons gap */
  actionsGap: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Modal.defaultProps = {
  banner: null,
  primaryLabel: 'Confirm',
  secondaryLabel: 'Cancel',
  tertiaryLabel: 'Back',
  onPrimary: () => false,
  onSecondary: null,
  onCancel: null,
  onTertiary: null,
  enablePrimary: true,
  hideSecondary: false,
  enableSecondary: true,
  enableTertiary: false,
  showActions: true,
  showCloseIcon: true,
  showHeader: true,
  hideOnEsc: true,
  initialFocusRef: null,
  icon: null,
  ignoreSidebar: false,
  renderWithinPortal: true,
  titleAlignment: HEADER_ALIGNMENTS.START,
  primaryButtonVariant: BUTTON_VARIANTS.TERTIARY,
  secondaryButtonVariant: BUTTON_VARIANTS.QUIET,
  primaryButtonStartIcon: null,
  primaryButtonEndIcon: null,
  secondaryButtonStartIcon: null,
  secondaryButtonEndIcon: null,
  actionsAlignment: ACTION_ALIGNMENTS.END,
  actionsGap: '6px',
};
