// Package modules
import React from 'react';
import { motion } from 'framer-motion';

// Assets
import trustCloudLogo from '../assets/trustcloud_logo_b_and_w.svg';

// Constants.
const TRUSTCLOUD_LOGO_ANIMATION_VARIANTS = {
  down: {
    y: 8,
    x: -4,
  },
  up: {
    y: -8,
    x: 4,
  },
};

const TRUSTCLOUD_LOGO_TRANSITIONS = {
  y: {
    duration: 0.8,
    delay: 0.25,
    yoyo: Infinity,
    ease: 'easeInOut',
  },
  x: {
    duration: 0.8,
    delay: 0.25,
    yoyo: Infinity,
    ease: 'easeInOut',
  },
};

// Components.
export const TrustCloudLogo = () => (
  <motion.img
    alt="TrustCloud Logo"
    animate="up"
    initial="down"
    src={trustCloudLogo}
    transition={TRUSTCLOUD_LOGO_TRANSITIONS}
    variants={TRUSTCLOUD_LOGO_ANIMATION_VARIANTS}
    width={80}
  />
);
