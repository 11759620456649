import styled from '@emotion/styled';
import { base, color, Typography } from '@kintent/glide';

export const CertificationSectionContainer = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;

  width: 1080px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CertificationSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & .certifications-section-title {
    font-weight: 600;
  }
`;

export const CertificationListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`;

export const ListCard = styled.div`
  background-color: white;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 600)};

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => color(theme.colorV2.system.gray, 100)};

  display: flex;
  flex-direction: column;
  gap: 32px;

  padding: 28px;
`;

export const LogoWrapper = styled.div`
  height: 56px;
  width: 56px;

  & > img {
    height: auto;
    width: 100%;
  }
`;

export const CertificationCardTitle = styled(Typography)`
  font-weight: 600;

  padding-bottom: 12px;
`;

export const ShowMoreContainer = styled.div`
  width: 100%;
  text-align: center;
`;
