import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';

import { Flex } from '../../../../components/Flex';
import { Spinner } from '../../../../components/Spinner';
import {
  useContentVisibility,
  useFeatureFlag,
  usePolicyComplianceMappings,
  useProgramContent,
} from '../../../../lib/state';
import PolicyComplianceStandardMapping from './PolicyComplianceStandardMapping';
import PolicyControlMapping from './PolicyControlMapping';
import PolicyMetadata from './PolicyMetadata';
import { FEATURE_FLAG } from '../../../../lib/featureFlags';

// Constants
const COMPLIANCE_SHORT_NAME_SORT_ORDER = Intl.Collator('en', { numeric: 'true', sensitivity: 'base' }).compare;
const LOADER_ANIMATION_VARIANTS = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

// Styled components
const PolicyDetailPanelContainer = styled(Flex)`
  position: relative;
  overflow: visible;
  flex: 1;
`;

const ProgressContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.palette.white};

  z-index: 2;
`;

function transformComplianceMapping(complianceMappings) {
  const transformedMappings = {};

  // This returns the total compliance mappings to be displayed
  const totalStandardMappingCount = Object.keys(complianceMappings).reduce((previousValue, nextValue) => {
    return previousValue + complianceMappings[nextValue].controls.length;
  }, 0);

  // We sort the keys within the memo to ensure child components don't need to be responsible for sorting
  const sortedKeys = Object.keys(complianceMappings).sort(COMPLIANCE_SHORT_NAME_SORT_ORDER);
  sortedKeys.forEach((key) => {
    if (!transformedMappings[key]) {
      transformedMappings[key] = { controls: [], category: complianceMappings[key].complianceStandardGroupName };
    }
    transformedMappings[key].controls = complianceMappings[key].controls;
  });

  return {
    shouldShowComplianceMappingSection: Object.keys(sortedKeys).length > 0,
    totalStandardMappingCount,
    standardMappings: transformedMappings,
  };
}

function PolicyDetailPanel({ policy }) {
  const { complianceMappings = {}, isLoading } = usePolicyComplianceMappings(policy.id);
  /**
   * This is memoized since the inner function handles sorting and aggregating properties
   * to be displayed and the data manipulation could be expensive
   */
  const { shouldShowComplianceMappingSection, totalStandardMappingCount, standardMappings } = useMemo(() =>
    transformComplianceMapping(complianceMappings)
  );

  const { shouldHideControlOverview: shouldHidePolicyControlsMapping } = useContentVisibility();

  const areSpecificDescriptionsEnabled = useFeatureFlag(FEATURE_FLAG.SPECIFIC_DESCRIPTIONS);

  const [{ publicFacingDescriptions }] = useProgramContent();
  const specificPolicyDescriptions = publicFacingDescriptions?.policies ?? {};
  const specificDescription = specificPolicyDescriptions[policy.shortName];

  return (
    <PolicyDetailPanelContainer
      direction="column"
      gap="16px"
    >
      <AnimatePresence>
        {isLoading && (
          <ProgressContainer
            as={motion.div}
            justifyContent="center"
            alignItems="center"
            variants={LOADER_ANIMATION_VARIANTS}
            animate="visible"
            exit="hidden"
            initial={false}
          >
            <Spinner />
          </ProgressContainer>
        )}
      </AnimatePresence>
      <PolicyMetadata
        id={policy.id}
        title={policy.title.replace('Policy', '').trim()}
        description={areSpecificDescriptionsEnabled ? specificDescription ?? policy.description : policy.description}
        shortName={policy.shortName}
        department={policy.group?.name}
        approvalDate={policy.lastApprovedDate}
        accessLevel={policy.trustShareAccessLevel}
        externalPolicyUrl={policy.externalPolicyUrl}
        policy={policy}
      />
      {!shouldHidePolicyControlsMapping && policy.relatedControlIds.length > 0 && (
        <PolicyControlMapping
          policy={policy}
          controlIds={policy.relatedControlIds}
        />
      )}
      {shouldShowComplianceMappingSection && (
        <PolicyComplianceStandardMapping
          policy={policy}
          standardMappings={standardMappings}
          totalStandardMappingCount={totalStandardMappingCount}
        />
      )}
    </PolicyDetailPanelContainer>
  );
}

export default PolicyDetailPanel;
