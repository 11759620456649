let globalServiceInstance = null;

export class GlobalService {
  constructor() {
    this.lastUpdatedAt = Date.now();
  }

  static getGlobalServiceInstance() {
    if (!globalServiceInstance) {
      globalServiceInstance = new GlobalService();
    }
    return globalServiceInstance;
  }

  // Instance methods
  setLastUpdatedAt(timestamp) {
    this.lastUpdatedAt = timestamp;
  }

  getLastUpdatedAt() {
    return this.lastUpdatedAt;
  }
}
