import styled from '@emotion/styled';
import { flat, rgba, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import { BREAKPOINTS } from '../../../../lib/constants';

export const DocumentGroupContainer = styled(Flex)`
  display: none;
  position: sticky;
  top: 100px;
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: flex;
    padding-top: 9px;
    padding-right: 40px;

    min-width: 240px;
  }
`;

export const DocumentSection = styled(Flex)`
  padding: 4px 8px;
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};

    border-radius: 4px;

    background-color: ${({ theme }) => rgba(theme.color.system.gray, '5%')};
  }
  text-decoration: none;

  &:hover:not(.active) {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }

  transition: color 250ms ease-in;
`;

export const IconWrapper = styled(Flex)`
  width: 20px;
  height: 20px;

  & > img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const SectionName = styled(Typography)`
  width: 100%;
  &:hover {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }
  &.active {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }
`;
