import React, { useState } from 'react';
import { Link, useCurrentRoute, useNavigation } from 'react-navi';
import { Typography } from '@kintent/glide';
import { UilArrowLeft } from '@iconscout/react-unicons';
import styled from '@emotion/styled';

import { GlideButton } from 'components/GlideButton';
import { useAuthService, usePolicyList } from 'lib/state';
import PDFViewer from 'components/PDFViewer';

import { canDownloadPolicy } from 'lib/utils';
import ContactUsDialog from 'app/trust-share/home/components/ContactUsDialog';
import PDFViewerErrorBoundary from 'components/PDFViewerErrorBoundary';
import CardContainer from '../../../../components/CardContainer';

const ICON_SIZE = 20;

const LinkButton = styled(GlideButton)`
  text-decoration: none;
`;

function PolicyView() {
  const {
    data: { id },
  } = useCurrentRoute();

  const { currentTeam } = useAuthService();
  const [policies, error] = usePolicyList();
  const policy = policies.find((p) => p.id === id);
  const navigation = useNavigation();
  const [contactDialog, setContactDialog] = useState(false);

  const hasAccessToPolicy = canDownloadPolicy(policy?.trustShareAccessLevel, currentTeam);

  if (!hasAccessToPolicy) {
    navigation.navigate('/policies');
    return null;
  }

  return (
    <CardContainer
      direction="column"
      gap="32px"
    >
      <LinkButton
        as={Link}
        href={`/policies/${policy.shortName}`}
        icon={<UilArrowLeft size={ICON_SIZE} />}
        variant="ghost"
        className="ghost"
      >
        Back to policy
      </LinkButton>
      <Typography strength="80%">
        You are now viewing the associated document for {currentTeam.name}&apos;s {policy.title} policy.
      </Typography>
      <PDFViewerErrorBoundary
        teamName={currentTeam.name}
        setContactDialog={setContactDialog}
        hasError={!!error}
      >
        <PDFViewer
          policyId={id}
          setContactDialog={setContactDialog}
        />
      </PDFViewerErrorBoundary>
      {contactDialog && (
        <ContactUsDialog
          isOpen={contactDialog}
          setIsOpen={setContactDialog}
          currentTeam={currentTeam}
        />
      )}
    </CardContainer>
  );
}

export default PolicyView;
