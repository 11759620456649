import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@kintent/glide';

const StyledContainer = styled(Typography)`
  border: 1px solid ${({ theme }) => theme.components.header.primaryCTAButton.background};
  border-radius: 4px;

  color: ${({ theme }) => theme.components.header.primaryCTAButton.background};

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 25px;

  min-width: 25px;

  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 1px;
`;

// This is used to display the count of entities of the section in context.
// We use this on Standard Mappings, Controls etc.
function CountBox({ count }) {
  return (
    <StyledContainer
      level="9"
      as="p"
    >
      {count}
    </StyledContainer>
  );
}

export default CountBox;
