import styled from '@emotion/styled';
import { flat, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';

export const Item = styled(Flex)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};

  &:is(a) {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    }
  }
`;

export const IconAction = styled('button', {
  shouldForwardProp(props) {
    return !['size'].includes(props);
  },
})`
  border: none;
  background: none;

  display: inline-flex;

  padding: 0;

  & > svg {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }

  &:hover {
    cursor: pointer;
  }
`;
