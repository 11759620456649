import React from 'react';
import styled from '@emotion/styled';
import { useCurrentRoute } from 'react-navi';

import { useContentVisibility } from 'lib/state';
import { Flex } from '../../../../components/Flex';
import CertificationMetadata from './CertificationMetadata';
import CertificationReadiness from './CertificationReadiness';

const StyledContainer = styled(Flex)`
  position: relative;
  overflow: visible;
  flex: 1;
  padding-bottom: 20px;
`;

function CertificationDetailPanel({ selectedCertification, selectedDocument = null }) {
  const { certificationAccessLevelMap } = useContentVisibility();
  const {
    data: { slug },
  } = useCurrentRoute();

  const shouldHideCertificationDetail = certificationAccessLevelMap[slug];

  return (
    <StyledContainer
      direction="column"
      gap="16px"
    >
      <CertificationMetadata
        selectedCertification={selectedCertification}
        selectedDocument={selectedDocument}
      />
      {!shouldHideCertificationDetail && <CertificationReadiness selectedCertification={selectedCertification} />}
    </StyledContainer>
  );
}

export default CertificationDetailPanel;
