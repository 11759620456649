// Package modules.
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Box } from 'reakit';

// Styles.
const Container = styled(Box, {
  shouldForwardProp(prop) {
    return !['fullWidth', 'p'].includes(prop);
  },
})`
  /* Allow padding to 'grow out' from the content width. */
  box-sizing: content-box;

  margin-left: auto;
  margin-right: auto;
  max-width: ${({ fullWidth }) => (fullWidth ? '1200px' : '1024px')};
  width: calc(100% - 2 * ${({ p, theme }) => theme.spacing[p]});
`;

Container.defaultProps = {
  fullWidth: false,
  p: 'md',
};
Container.propTypes = {
  fullWidth: PropTypes.bool,
  p: PropTypes.string,
};

// Exports.
export default Container;
