import React, { useState } from 'react';
import { Link, useNavigation } from 'react-navi';

import { FEATURE_FLAG } from 'lib/featureFlags';
import { UilFileSearchAlt } from '@iconscout/react-unicons';
import { DOCUMENT_TYPE } from '../../../../lib/constants';
import { ScrollTarget } from '../../documents/components/DocumentDetailPanel.styles';
import CertificationActionButton from './CertificationActionButton';
import * as Styles from './DashboardDocuments.styles';
import { useAuthService, useFeatureFlag, useProgramContent } from '../../../../lib/state';
import {
  canDownloadCertification,
  canDownloadPolicy,
  canDownloadQuestionnaire,
  canDownloadResource,
  canViewCertification,
  canViewResource,
  isDataRoomUserAbleToAccess,
  shouldHideItem,
} from '../../../../lib/utils';
import PolicyActionButton from './PolicyActionButton';
import QuestionnaireActionButton from './QuestionnaireActionButton';
import ResourceActionButton from './ResourceActionButton';
import ResourceActionIcon from './ResourceActionIcon';
import LearnMore from './DashboardDocumentLearnMore';
import { Flex } from '../../../../components/Flex';

function DashboardDocumentCard({ document, setShouldShowDownloadErrorToast }) {
  const { id: documentId } = document;

  const { authenticatedUser } = useAuthService();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);
  const areSpecificDescriptionsEnabled = useFeatureFlag(FEATURE_FLAG.SPECIFIC_DESCRIPTIONS);
  const enableViewOnlyDocuments = useFeatureFlag(FEATURE_FLAG.VIEW_ONLY_DOCUMENTS);

  const { navigate } = useNavigation();

  const [isDownloading, setDownloading] = useState(false);

  const canUserDownloadResource = canDownloadResource(document.type, document.accessLevel, authenticatedUser);
  const canUserViewResource = canViewResource(document.type, document.accessLevel, authenticatedUser);
  const hasDataRoomAccess = isDataRoomUserAbleToAccess(document, authenticatedUser);
  const isDocumentInDataRoom = document.dataRoomIds && document.dataRoomIds.length > 0;
  const canUserDownloadPolicy =
    canDownloadPolicy(
      document.accessLevel ? document.accessLevel : document.trustShareAccessLevel,
      authenticatedUser
    ) && hasDataRoomAccess;
  const canUserDownloadQuestionnaire = canDownloadQuestionnaire(document.accessLevel, authenticatedUser);
  const canUserDownloadCertification = canDownloadCertification(
    document.trustShareAccessLevel,
    document.filename,
    authenticatedUser
  );
  const canUserViewCertification = canViewCertification(
    document.trustShareAccessLevel,
    document.certificationUrl,
    authenticatedUser
  );
  const canViewExternalPolicyUrl = document.externalPolicyUrl && canUserDownloadPolicy;

  const shouldHideQuestionnaireOverlay = shouldHideItem(isRequestAccessHidden, [canUserDownloadQuestionnaire]);
  const shouldHidePolicyOverlay = shouldHideItem(isRequestAccessHidden, [canUserDownloadPolicy]);
  const shouldHideDocumentsOverlay = shouldHideItem(isRequestAccessHidden, [
    canUserDownloadResource,
    canUserViewResource,
  ]);
  const shouldHideCertificationOverlay = shouldHideItem(isRequestAccessHidden, [
    canUserDownloadCertification,
    canUserViewCertification,
  ]);

  const [{ publicFacingDescriptions }] = useProgramContent();
  const specificDocumentDescriptions = publicFacingDescriptions?.documents ?? {};
  const specificDocumentDescription = areSpecificDescriptionsEnabled ? specificDocumentDescriptions[documentId] : null;
  const documentDescription = specificDocumentDescription ?? document.description ?? null;

  const certificationSlug = `${document?.certification?.shortName ?? ''}${document?.subtype || ''}`;
  switch (document.documentType) {
    case DOCUMENT_TYPE.QUESTIONNAIRE:
      return (
        <Styles.GridItem>
          <Styles.DocumentTitleTypography level="8">{document.name || document.company}</Styles.DocumentTitleTypography>
          <ResourceActionIcon
            canDownload={canUserDownloadQuestionnaire}
            canView={false}
            isDownloading={isDownloading}
            user={authenticatedUser}
          />
          {!shouldHideQuestionnaireOverlay && (
            <Styles.Overlay className="overlay">
              <QuestionnaireActionButton
                documentId={documentId}
                setDownloading={setDownloading}
                isDownloading={isDownloading}
                downloadFileName={`${
                  document.name ? document.name.replace(' ', '_') : document.company.replace(' ', '_')
                }.xlsx`}
                canDownload={canUserDownloadQuestionnaire}
                setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
              />
            </Styles.Overlay>
          )}
        </Styles.GridItem>
      );
    case DOCUMENT_TYPE.POLICY:
      return (
        <Styles.GridItem>
          <Styles.DocumentTitleTypography level="8">{document.title}</Styles.DocumentTitleTypography>
          {enableViewOnlyDocuments && !document.externalPolicyUrl ? (
            <Link href={`/policies/${documentId}/view`}>
              <UilFileSearchAlt size={20} />
            </Link>
          ) : (
            <ResourceActionIcon
              canDownload={canUserDownloadPolicy && !document.externalPolicyUrl}
              canView={!!document.externalPolicyUrl}
              isDownloading={isDownloading}
              user={authenticatedUser}
              isResourceInDataRoom={isDocumentInDataRoom}
            />
          )}
          <Styles.Overlay className="overlay">
            <Flex
              alignItems="center"
              gap="12px"
            >
              <Styles.LinkButton
                variant="secondary"
                onClick={() => navigate(`/policies/${document.shortName}`)}
                className="secondary"
              >
                Learn More
              </Styles.LinkButton>
              {!shouldHidePolicyOverlay && (
                <PolicyActionButton
                  documentId={documentId}
                  setDownloading={setDownloading}
                  isDownloading={isDownloading}
                  title={document.title}
                  canDownload={canUserDownloadPolicy}
                  isDocumentInDataRoom={isDocumentInDataRoom}
                  canViewExternalPolicyUrl={canViewExternalPolicyUrl}
                  externalPolicyUrl={document.externalPolicyUrl}
                  setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
                />
              )}
            </Flex>
          </Styles.Overlay>
        </Styles.GridItem>
      );
    case DOCUMENT_TYPE.RESOURCE:
      return (
        <Styles.GridItem>
          <Styles.DocumentTitleTypography level="8">
            <ScrollTarget id={`document-${encodeURIComponent(document.displayName)}`} />
            {document.displayName}
          </Styles.DocumentTitleTypography>
          <ResourceActionIcon
            canDownload={canUserDownloadResource}
            canView={canUserViewResource}
            isDownloading={isDownloading}
            user={authenticatedUser}
          />
          {(!shouldHideDocumentsOverlay || documentDescription) && (
            <Styles.Overlay className="overlay">
              <Flex
                alignItems="center"
                gap="12px"
              >
                {documentDescription && (
                  <LearnMore
                    documentId={documentId}
                    setDownloading={setDownloading}
                    isDownloading={isDownloading}
                    identifier={document.identifier}
                    canView={canUserViewResource}
                    canDownload={canUserDownloadResource}
                    name={document.displayName}
                    description={documentDescription}
                    setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
                  />
                )}
                <ResourceActionButton
                  documentId={documentId}
                  setDownloading={setDownloading}
                  isDownloading={isDownloading}
                  identifier={document.identifier}
                  canView={canUserViewResource}
                  canDownload={canUserDownloadResource}
                  setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
                />
              </Flex>
            </Styles.Overlay>
          )}
        </Styles.GridItem>
      );
    case DOCUMENT_TYPE.CERTIFICATION:
      return (
        <Styles.GridItem>
          <Styles.DocumentTitleTypography level="8">{document.certification.name}</Styles.DocumentTitleTypography>
          <ResourceActionIcon
            canDownload={canUserDownloadCertification}
            canView={canUserViewCertification}
            isDownloading={isDownloading}
            user={authenticatedUser}
          />
          <Styles.Overlay className="overlay">
            <Flex
              alignItems="center"
              gap="12px"
            >
              <Styles.LinkButton
                variant="secondary"
                onClick={() => navigate(`/certifications/${certificationSlug}`)}
                className="secondary"
              >
                Learn More
              </Styles.LinkButton>
              {!shouldHideCertificationOverlay && (
                <CertificationActionButton
                  documentId={documentId}
                  setDownloading={setDownloading}
                  isDownloading={isDownloading}
                  canView={canUserViewCertification}
                  canDownload={canUserDownloadCertification}
                  fileName={document.filename}
                  link={document.certificationUrl}
                  setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
                />
              )}
            </Flex>
          </Styles.Overlay>
        </Styles.GridItem>
      );
    default:
      return null;
  }
}

export default DashboardDocumentCard;
