import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { base, flat, Heading, rgb, translucent, Typography } from '@kintent/glide';

import { TRUST_SHARE_ACCESS_LEVELS } from 'lib/constants';
import HomePageContainer from './HomePageContainer';
import { Search } from './Search';
import { Flex } from '../../../../components/Flex';
import { useAuthService, useFeatureFlag, useProgramContent } from '../../../../lib/state';
import { NEWLINE_REGEX } from '../../../../lib/utils';
import { FEATURE_FLAG } from '../../../../lib/featureFlags';

// Styled components
const Container = styled.div`
  position: relative;
`;

const DefaultGradientOverlay = styled.div`
  background-image: linear-gradient(
    to top,
    ${({ theme }) => flat(theme.color.brand.lighter)},
    ${({ theme }) => rgb(theme.color.system.white)}
  );
  height: 60%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const GradientOverlay = styled('div', {
  shouldForwardProp(props) {
    return !['backgroundGradientColor'].includes(props);
  },
})`
  height: 60%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  background-image:  linear-gradient(
    to top,
    ${({ backgroundGradientColor }) => backgroundGradientColor}, 
    ${({ theme }) => rgb(theme.color.system.white)}
  )};
`;

const WhiteOverlay = styled.div`
  background-color: transparent;
  height: 40%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const HeroContentContainer = styled.div`
  background-color: ${({ theme }) => rgb(theme.color.system.white)};

  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  border-radius: 8px;

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '10%')};

  padding: 36px;
  position: relative;
  z-index: 1;
`;

const BrandedHeading = styled(Heading)`
  color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  display: inline-block;
`;

const CONTINUOUSLY_MONITORED_REGEX = /continuously\s+(monitor|monitored|monitoring)/gi;

const regExPattern = (matchTeamName) =>
  new RegExp(
    `(${matchTeamName}'s|${matchTeamName}‘s|${matchTeamName}’s|${matchTeamName}'s|${matchTeamName}\`s|${matchTeamName}'|${matchTeamName}‘|${matchTeamName}’|${matchTeamName}\`|${matchTeamName})`,
    'gi'
  );

function HeroTitle({ heroTitle, teamName }) {
  const heroTitleParts = heroTitle.split(regExPattern(teamName));
  return (
    <Heading level="1">
      {heroTitleParts.map((part) => {
        if (regExPattern(teamName).test(part)) {
          return (
            <BrandedHeading
              level="1"
              as="span"
              key={part}
            >
              {part}
            </BrandedHeading>
          );
        }
        return <React.Fragment key={part}>{part}</React.Fragment>;
      })}
    </Heading>
  );
}

function Hero() {
  const { currentTeam, authenticatedUser } = useAuthService();
  const [{ heroTitle, heroDescription, headerStyleConfig }] = useProgramContent();
  const isDisableContinuouslyMonitored = useFeatureFlag(FEATURE_FLAG.DISABLE_CONTINUOUSLY_MONITORED);

  const heroDescriptionOnBasisOfIsDisabledContinuouslyMonitored = useMemo(() => {
    if (isDisableContinuouslyMonitored) {
      const resultString = heroDescription.replace(CONTINUOUSLY_MONITORED_REGEX, (match, capture) => {
        switch (capture.toLowerCase()) {
          case 'monitor':
            return 'monitor';
          case 'monitored':
            return 'monitored';
          case 'monitoring':
            return 'monitoring';
          default:
            return match;
        }
      });
      return resultString;
    }
    return heroDescription;
  }, [heroDescription, isDisableContinuouslyMonitored]);

  // Search should only be visible when a TS is transparent or a user has authenticated
  const shouldDisplaySearch =
    currentTeam.trustShareAccessLevel === TRUST_SHARE_ACCESS_LEVELS.TRANSPARENT || authenticatedUser != null;

  return (
    <Container>
      <HomePageContainer>
        <HeroContentContainer>
          <Flex
            direction="column"
            gap="36px"
          >
            <Flex
              direction="column"
              gap="24px"
            >
              <HeroTitle
                heroTitle={heroTitle}
                teamName={currentTeam?.name}
              />
              <Typography
                dangerouslySetInnerHTML={{
                  __html: heroDescriptionOnBasisOfIsDisabledContinuouslyMonitored?.replace(NEWLINE_REGEX, ''),
                }}
              />
            </Flex>
            {shouldDisplaySearch ? <Search /> : null}
          </Flex>
        </HeroContentContainer>
      </HomePageContainer>
      {headerStyleConfig.gradientBackground ? (
        <GradientOverlay backgroundGradientColor={headerStyleConfig.gradientBackground?.backgroundColor} />
      ) : (
        <DefaultGradientOverlay />
      )}
      <WhiteOverlay />
    </Container>
  );
}

export default Hero;
