import React from 'react';
import { Link, Typography } from '@kintent/glide';
import { UilFileAlt } from '@iconscout/react-unicons';

import * as Styles from './PrimarySection.styles';
import CustomTag from '../../../../../components/CustomTag/CustomTag';
import { useAuthService, usePolicyList, useProgramContent, useTeamDocumentList } from '../../../../../lib/state';
import { DOCUMENT_TYPE } from '../../../../../lib/constants';

function ListItem({ itemId, type }) {
  const { currentTeam } = useAuthService();
  const [polices] = usePolicyList();
  const [documents] = useTeamDocumentList(currentTeam?.id);

  switch (type) {
    case DOCUMENT_TYPE.POLICY: {
      const policy = polices.find((item) => item.id === itemId);
      return (
        <li data-testid="primary-section-list-item">
          <UilFileAlt size="20" />
          <Typography
            as={Link}
            className="primary-section-item-title"
            href={`/documents/${policy.id}?type=${DOCUMENT_TYPE.POLICY}`}
            level="8"
            strength="80%"
          >
            {policy?.title}
          </Typography>
        </li>
      );
    }
    case DOCUMENT_TYPE.RESOURCE: {
      const document = documents.find((item) => item.id === itemId);
      return (
        <li data-testid="primary-section-list-item">
          <UilFileAlt size="20" />
          <Typography
            as={Link}
            className="primary-section-item-title"
            href={`/documents/${document.id}?type=${DOCUMENT_TYPE.RESOURCE}`}
            level="8"
            strength="80%"
          >
            {document?.displayName}
          </Typography>
        </li>
      );
    }
    default:
      return null;
  }
}

function PrimarySection() {
  const [{ primarySectionTitle, primarySectionContent }] = useProgramContent();

  const primarySectionContentItems = primarySectionContent?.items;

  const shouldRenderPrimarySectionContent =
    Array.isArray(primarySectionContentItems) && primarySectionContentItems.length > 0;

  return (
    <Styles.PrimaryGridItem data-testid="primary-section">
      <Styles.GridItemHeading>
        <Typography
          as="h5"
          level="5"
          className="primary-section-title"
        >
          {primarySectionTitle}
        </Typography>
        {shouldRenderPrimarySectionContent && <CustomTag>{primarySectionContentItems.length}</CustomTag>}
      </Styles.GridItemHeading>
      <Styles.Card>
        <Styles.UnstyledList data-testid="primary-section-item-container">
          {shouldRenderPrimarySectionContent &&
            primarySectionContentItems.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  type={item.type}
                  itemId={item.id}
                />
              );
            })}
        </Styles.UnstyledList>
      </Styles.Card>
    </Styles.PrimaryGridItem>
  );
}

export default PrimarySection;
