import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'reakit';
import { Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import { BREAKPOINTS } from '../../../../lib/constants';
import { hexOpacity } from '../../../../lib/utils';

const MappedControlContainer = styled(Flex)`
  position: relative;

  margin-top: 0px;

  align-items: flex-start;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    overflow: hidden;

    width: calc(100% + 50px);
  }
`;

const ComplianceStandardControlIDButton = styled(Button)`
  align-items: center;

  box-shadow: none;
  border: none;
  border-radius: 5px;
  max-width: 180px;
  min-height: 30px;

  background-color: ${({ theme }) => hexOpacity(theme.palette.volare, 0.1)};

  &.selected {
    background-color: ${({ theme }) => theme.palette.volare};

    color: ${({ theme }) => theme.palette.white};

    & > small {
      z-index: 2;

      color: ${({ theme }) => theme.palette.white};
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    
    &:not(.no-description) {
      cursor: pointer;
    }

    margin-left: 58px;

    transition: background-color 250ms ease-in, color 250ms ease-in;

    &:before {
      content: '';

      position: absolute;
      left: 0;
      bottom: 15px;

      margin: auto;
      border-top: 1px dashed ${({ theme }) => theme.palette.elephant};
      width: 58px;
    }

    &.selected:after {
      content: '';
      position: absolute;
      left: 58px;
      top: 15px;
      margin: auto;
      width: 100%;
      height: 0;
      border-top: 1px dashed ${({ theme }) => theme.palette.volare};
      z-index: 1;
    }

    &.selected {
      background-color: ${({ theme }) => theme.palette.volare};

      color: ${({ theme }) => theme.palette.white};

      & > small {
        position: relative;
        z-index: 2;
      }
    }

    &:hover:not(.no-description) {
      background-color: ${({ theme }) => theme.palette.volare};

      color: ${({ theme }) => theme.palette.white};
    }
`;

function ComplianceStandardControlItem({
  controlId,
  title,
  displayIdentifier,
  selectedStandardControl,
  onControlClick,
}) {
  return (
    <MappedControlContainer>
      <ComplianceStandardControlIDButton
        className={`
          ${selectedStandardControl?.id === controlId && displayIdentifier ? 'selected' : ''}
          ${!displayIdentifier ? 'no-description' : ''}
        `}
        onClick={onControlClick}
        variant="floating"
      >
        <Typography
          as="small"
          level={selectedStandardControl?.id === controlId && displayIdentifier ? '8' : '9'}
        >
          {displayIdentifier ?? title}
        </Typography>
      </ComplianceStandardControlIDButton>
    </MappedControlContainer>
  );
}

export default ComplianceStandardControlItem;
