// Package modules
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@kintent/glide';
import propTypes from 'prop-types';

// Styled components
const AvatarBox = styled('span', {
  shouldForwardProp(props) {
    return !['as', 'size'].includes(props);
  },
})`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
`;

const UserInitialsLabel = styled(Typography)`
  letter-spacing: 1px;
  text-transform: uppercase;
`;

function getInitials(name) {
  const lastSpaceIdx = name.lastIndexOf(' ');
  let initials = name.charAt(0);
  if (lastSpaceIdx > 0 && name.length > lastSpaceIdx + 1) {
    initials += name.charAt(lastSpaceIdx + 1);
  }
  return initials;
}

export const Avatar = forwardRef(({ name, src, ...remainingProps }, ref) => {
  if (src === null) {
    return (
      <AvatarBox
        ref={ref}
        {...remainingProps}
      >
        <UserInitialsLabel level="8">{name ? getInitials(name) : 'K'}</UserInitialsLabel>
      </AvatarBox>
    );
  }

  if (typeof src !== 'string') {
    return (
      <AvatarBox
        ref={ref}
        {...remainingProps}
      >
        <UserInitialsLabel level="8">{src}</UserInitialsLabel>
      </AvatarBox>
    );
  }

  return (
    <AvatarBox
      as="img"
      ref={ref}
      src={src}
      alt={name ? getInitials(name) : 'K'}
      {...remainingProps}
    />
  );
});

Avatar.propTypes = {
  /** The name of the person represented by this avatar. */
  name: propTypes.string.isRequired,
  /** A URL to a picture of the person represented by this avatar, or an icon element. */
  src: propTypes.oneOfType([propTypes.element, propTypes.string]),
  /** Size of the picture. */
  size: propTypes.number,
};

Avatar.defaultProps = {
  src: null,
  size: 32,
};
