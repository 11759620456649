import styled from '@emotion/styled';
import { color } from '@kintent/glide';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 32px;
`;

export const ListItem = styled.div`
  background-color: white;
  border-radius: 8px;

  border: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 50)};

  box-shadow: ${({ theme }) => `${theme.boxShadow.drop} ${color(theme.colorV2.system.gray, 100)}`};

  cursor: pointer;

  padding: 12px;
`;
