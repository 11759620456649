import styled from '@emotion/styled';
import { color } from '@kintent/glide';

export const MainGrid = styled.main`
  grid-area: main;

  background-color: ${({ theme }) => color(theme.colorV2.system.gray, 100)};

  min-height: 100vh;

  padding: 12rem 4rem 4rem 4rem;
`;

export const NotificationDetailsContainer = styled.section`
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  background-color: white;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 600)};

  box-shadow: ${({ theme }) => `${theme.boxShadow.drop} ${color(theme.colorV2.system.gray, 100)}`};

  padding: 48px;

  display: grid;
  grid-template-rows: auto 1fr;
  gap: 36px;

  & p.notification-detail-description {
    a {
      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => theme.components.header.navigation.textColor};
      }
    }
  }
`;

export const NotificationDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .notification-detail-title {
    font-weight: 600;
  }
`;

export const NotificationDetailsMetadata = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
