import styled from '@emotion/styled';
import { Badge, flat, Typography } from '@kintent/glide';

import HomePageContainer from './HomePageContainer';

export const SubprocessorsSectionContainer = styled(HomePageContainer)`
  margin-top: 40px;
`;

export const DescriptionLabel = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
`;

export const CountBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
`;
