import React, { Fragment, useMemo } from 'react';
import { UilPlus } from '@iconscout/react-unicons';
import { Link } from 'react-navi';

import * as Styles from './DashboardNotificationCenterSection.styles';
import HomePageContainer from './HomePageContainer';
import NotificationCenterHeader from './NotificationCenterHeader';
import NotificationCard from './NotificationCard';
import { useAuthService, useTrustShareNotifications } from '../../../../lib/state';

const NOTIFICATIONS_TO_SHOW = 3;

const NOTIFICATION_CARD = {
  id: 'notification-card',
  accessorFn: (data) => data.id,
  cell: ({ row }) => (
    <NotificationCard
      type={row.original.type}
      title={row.original.title}
      notificationType={row.original.type}
      dateTime={row.original._metadata.createdDate}
      description={row.original.description?.split('\n').map((p) => (
        <Fragment key={p}>
          {p}
          <br />
        </Fragment>
      ))}
      links={row.original.links}
    />
  ),
};

function DashboardNotificationCenterSection() {
  const { currentTeam } = useAuthService();
  const { data: trustshareNotifications } = useTrustShareNotifications();

  const sortedTrustshareNotifications = useMemo(() => {
    return [...trustshareNotifications]
      .sort((a, b) => {
        return new Date(b._metadata.createdDate) - new Date(a._metadata.createdDate);
      })
      .slice(0, NOTIFICATIONS_TO_SHOW);
  }, [trustshareNotifications]);

  const DEFAULT_PROPS = {
    columns: [NOTIFICATION_CARD],
    data: sortedTrustshareNotifications,
    getRowId: (row) => row.id,
    pagination: false,
    selection: false,
    sorting: true,
    search: false,
  };

  if (!sortedTrustshareNotifications.length) {
    return null;
  }

  return (
    <HomePageContainer>
      <Styles.NotificationCenterContainer>
        <NotificationCenterHeader />
        <Styles.NotificationTable
          {...DEFAULT_PROPS}
          variant="primary"
        />
        {trustshareNotifications.length > NOTIFICATIONS_TO_SHOW && (
          <Styles.NotificationCenterFooterWrapper>
            <Styles.SeeMoreNotificationLink
              href="/notifications"
              as={Link}
              gap="8px"
              alignItems="center"
            >
              <UilPlus
                size={18}
                color="white"
              />
              See more {trustshareNotifications.length - NOTIFICATIONS_TO_SHOW} more notifications in {currentTeam.name}
              ’s Notification Center
            </Styles.SeeMoreNotificationLink>
          </Styles.NotificationCenterFooterWrapper>
        )}
      </Styles.NotificationCenterContainer>
    </HomePageContainer>
  );
}

export default DashboardNotificationCenterSection;
