import React from 'react';
import { UilExternalLinkAlt, UilFileSearchAlt, UilPadlock } from '@iconscout/react-unicons';
import { Link } from 'react-navi';

import { FEATURE_FLAG } from 'lib/featureFlags';
import { Spinner } from '../../../../components/Spinner';
import { useAuthService, useFeatureFlag, usePolicy, useRequestAccessRedirectionUrl } from '../../../../lib/state';
import { downloadFileFromAPI, shouldHideRequestAccessForDataRoom } from '../../../../lib/utils';
import * as Styles from './DashboardDocuments.styles';
import { GlideButton } from '../../../../components/GlideButton';

const ACTION_ICON_SIZE = 20;

function PolicyActionButton({
  canDownload,
  title,
  documentId,
  isDownloading,
  setDownloading,
  isDocumentInDataRoom = false,
  canViewExternalPolicyUrl,
  externalPolicyUrl,
  setShouldShowDownloadErrorToast,
}) {
  const { authenticatedUser } = useAuthService();
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);
  const hideDataRoomRequestAccess = useFeatureFlag(FEATURE_FLAG.HIDE_DATA_ROOM_REQUEST_ACCESS);
  const enableViewOnlyDocuments = useFeatureFlag(FEATURE_FLAG.VIEW_ONLY_DOCUMENTS);

  const hideRequestAccessForDataRoom = shouldHideRequestAccessForDataRoom(
    hideDataRoomRequestAccess,
    isDocumentInDataRoom
  );

  const shouldShowRequestAccessButton = !isRequestAccessHidden && !canDownload && !authenticatedUser;

  const { downloadPolicy } = usePolicy(documentId);

  const handlePolicyDownload = async () => {
    setDownloading(true);
    try {
      await downloadFileFromAPI(() => downloadPolicy(), `${title.toLowerCase().replace(' ', '_')}.pdf}`);
    } catch (e) {
      setShouldShowDownloadErrorToast(true);
    } finally {
      setDownloading(false);
    }
  };

  if (canViewExternalPolicyUrl) {
    return (
      <GlideButton
        variant="secondary"
        as={Link}
        href={externalPolicyUrl}
        target="_blank"
      >
        <UilExternalLinkAlt size={ACTION_ICON_SIZE} />
        View
      </GlideButton>
    );
  }

  if (enableViewOnlyDocuments) {
    if (canDownload && !externalPolicyUrl) {
      return (
        <GlideButton
          variant="secondary"
          as={Link}
          href={`/policies/${documentId}/view`}
          target="_blank"
        >
          <UilFileSearchAlt size={ACTION_ICON_SIZE} />
          View
        </GlideButton>
      );
    }

    return null;
  }

  if (!enableViewOnlyDocuments) {
    if (canDownload) {
      return (
        <GlideButton
          variant="secondary"
          icon={isDownloading ? <Spinner /> : <Styles.DownloadIcon size={ACTION_ICON_SIZE} />}
          onClick={() => handlePolicyDownload()}
          disabled={isDownloading}
          className="secondary"
        >
          Download
        </GlideButton>
      );
    }

    if (shouldShowRequestAccessButton && !hideRequestAccessForDataRoom) {
      return (
        <Styles.LinkButton
          as={Link}
          variant="secondary"
          icon={<UilPadlock size={ACTION_ICON_SIZE} />}
          href={requestAccessRedirectionUrl}
          className="secondary"
        >
          Request Access
        </Styles.LinkButton>
      );
    }

    return null;
  }
}

export default PolicyActionButton;
