import React, { useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Typography } from '@kintent/glide';

import { Grid } from '../../../../design-system';
import { Flex } from '../../../../components/Flex';
import { Link } from '../../../../components/Link';
import { hexOpacity } from '../../../../lib/utils';
import PulsatingDot, { PULSATING_DOT_VARIANTS } from '../../../../components/PulsatingDot';
import Heartbeat from '../../../../components/Heartbeat';
import { BREAKPOINTS } from '../../../../lib/constants';
import { useDeviceResolution } from '../../../../lib/hooks';

const ControlCard = styled(Flex)`
  border-radius: 8px;
  box-shadow: 0 4px 12px ${({ theme }) => hexOpacity(theme.palette.elephant, 0.2)};
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.white};

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const AnimationContainer = styled(motion(Flex))`
  width: 22px;
  height: 22px;
`;

const CategoryStyledTypography = styled(Grid)`
  display: none;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: block;
  }
`;

const StyledTypography = styled(Typography)`
  &:hover {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }
`;

function SubprocessorControlCard({ controlName, controlDetailUrl, controlCategory, shouldHideLinkForControlDetails }) {
  const { isDesktop } = useDeviceResolution();
  const theme = useTheme();
  const [isHover, setHover] = useState(false);

  const toggleHoverState = useCallback(() => {
    setHover((hover) => !hover);
  }, [setHover]);

  const controlProps = {};
  if (!shouldHideLinkForControlDetails) {
    controlProps.as = Link;
    controlProps.href = controlDetailUrl;
  }

  return (
    <ControlCard
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        item
        as={motion.div}
        onHoverStart={toggleHoverState}
        onHoverEnd={toggleHoverState}
      >
        <StyledTypography
          level="9"
          {...controlProps}
        >
          {controlName}
        </StyledTypography>
      </Grid>
      <CategoryStyledTypography
        item
        as={Typography}
        level="9"
      >
        {controlCategory}
      </CategoryStyledTypography>
      <Grid item>
        <AnimationContainer
          alignItems="center"
          justifyContent="center"
          onHoverStart={toggleHoverState}
          onHoverEnd={toggleHoverState}
        >
          <AnimatePresence exitBeforeEnter>
            {isDesktop ? (
              <AnimatePresence exitBeforeEnter>
                {!isHover ? (
                  <PulsatingDot
                    key="pulse-animation"
                    exit="exit"
                    variants={PULSATING_DOT_VARIANTS}
                  />
                ) : (
                  <Heartbeat
                    key="heartbeat-animation"
                    strokeColor={theme.components.header.primaryCTAButton.background}
                  />
                )}
              </AnimatePresence>
            ) : (
              <Heartbeat
                key="heartbeat-animation"
                strokeColor={theme.components.header.primaryCTAButton.background}
              />
            )}
          </AnimatePresence>
        </AnimationContainer>
      </Grid>
    </ControlCard>
  );
}

export default SubprocessorControlCard;
