import styled from '@emotion/styled';
import { Typography } from '@kintent/glide';

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 48px;
`;

export const Heading = styled(Typography)`
  margin-top: 24px;
  font-weight: 600;
  text-align: center;
`;

export const Description = styled(Typography)`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 40px;
`;
