import React from 'react';
import styled from '@emotion/styled';

import { Flex } from '../../../../components/Flex';
import { useContentVisibility } from '../../../../lib/state';
import SubprocessorMetadata from './SubprocessorMetadata';
import SubprocessorControlMappingSection from './SubprocessorControlMappingSection';
import SubprocessorPolicySection from './SubprocessorPolicySection';

const StyledContainer = styled(Flex)`
  position: relative;

  overflow: visible;

  flex: 1;

  padding-bottom: 20px;
`;

function SubprocessorDetailPanel({ selectedSubprocessor }) {
  const { shouldHideControlOverview: shouldHideControlsSection, shouldHidePolicyOverview: shouldHidePoliciesSection } =
    useContentVisibility();

  return (
    <StyledContainer
      direction="column"
      gap="16px"
    >
      <SubprocessorMetadata selectedSubprocessor={selectedSubprocessor} />
      {!shouldHideControlsSection && <SubprocessorControlMappingSection selectedSubprocessor={selectedSubprocessor} />}
      {!shouldHidePoliciesSection && <SubprocessorPolicySection selectedSubprocessor={selectedSubprocessor} />}
    </StyledContainer>
  );
}

export default SubprocessorDetailPanel;
