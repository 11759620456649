import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Box } from 'reakit';
import { css } from '@emotion/react';

// Component enabling adding top or bottom margins to an element.
const SpaceAround = styled(Box, {
  shouldForwardProp(prop) {
    return !['top', 'bottom', 'left', 'right'].includes(prop);
  },
})`
  ${({ top }) =>
    top &&
    css`
      margin-top: ${top};
    `};

  ${({ bottom }) =>
    bottom &&
    css`
      margin-bottom: ${bottom};
    `};

  ${({ left }) =>
    left &&
    css`
      margin-left: ${left};
    `};

  ${({ right }) =>
    right &&
    css`
      margin-right: ${right};
    `};
`;

SpaceAround.defaultProps = {
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
};

SpaceAround.propTypes = {
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
};

export default SpaceAround;
