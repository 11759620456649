// Package modules.
import React from 'react';
import { format as dateFormat, formatISO as dateFormatISO } from 'date-fns';
import PropTypes from 'prop-types';

// Constants.
const CURRENT_YEAR = new Date().getFullYear();

// Components.
const DateTime = ({ children, date, format: providedFormat, includeTime, shortMonth, includeYear, ...props }) => {
  // Add year if year is not current.
  let format = providedFormat;
  if (format === null) {
    format = shortMonth ? 'MMM d' : 'MMMM d';
    if (date.getFullYear() !== CURRENT_YEAR || includeYear) {
      format += ', yyyy';
    }
  }
  if (includeTime) {
    format += ', p';
  }

  return (
    <time
      dateTime={dateFormatISO(date, { representation: includeTime ? 'complete' : 'date' })}
      {...props}
    >
      {children ?? dateFormat(date, format)}
    </time>
  );
};

DateTime.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  format: PropTypes.string,
  includeTime: PropTypes.bool,
  includeYear: PropTypes.bool,
  shortMonth: PropTypes.bool,
};

DateTime.defaultProps = {
  format: null,
  includeTime: false,
  includeYear: false,
  shortMonth: false,
};

// Exports.
export default DateTime;
