// Package modules
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Checkbox as BaseFormCheckbox } from 'reakit';

// Package modules
import { hexOpacity } from '../lib/utils';

const StyledFormCheckbox = styled(BaseFormCheckbox)`
  appearance: none;
  border: 1px solid ${({ theme }) => hexOpacity(theme.palette.ariel, 0.4)};
  border-radius: 4px;

  outline: none;
  cursor: pointer;
  color: currentColor;

  width: 16px;
  height: 16px;

  &::after {
    content: '';

    display: block;
    transform: rotate(45deg);

    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    width: 6px;
    height: 12px;
  }

  &:checked {
    border-color: ${({ theme }) => hexOpacity(theme.palette.navy, 0.2)};
    background-color: ${({ theme }) => theme.palette.navy};

    &::after {
      border-color: currentColor;
      color: ${({ theme }) => theme.palette.white};
    }
  }
`;

export const Checkbox = forwardRef((props, ref) => (
  <StyledFormCheckbox
    ref={ref}
    {...props}
  />
));
