// Package modules
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { UilCheck as CheckIcon } from '@iconscout/react-unicons';
import { useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
import { Typography } from '@kintent/glide';

// Local modules
import { SpaceAround } from '../../../design-system';
import { ErrorDescriptionLabel } from '../../../components/PageElements';
import { api } from '../../../lib/api';
import { BREAKPOINTS } from '../../../lib/constants';
import { ACCOUNT_SETUP_INCOMPLETE } from '../../../lib/errors/ApiError';
import { useAuthService } from '../../../lib/state';
import {
  AnimatedContentContainer,
  PROGRAM_COPY_VARIANTS,
} from '../../trust-share/home/components/AnimatedContentContainer';
import { Flex } from '../../../components/Flex';
import { hexOpacity } from '../../../lib/utils';

import { ReactComponent as ErrorPopsicleIllustration } from '../../../assets/error-popsicle.svg';
import { GlideButton } from '../../../components/GlideButton';

// Constants
const EMAIL_RESEND_STATUS = {
  NOT_ATTEMPTED: 'not-attempted',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
};

const StyledSuccessDescription = styled(Typography)`
  padding: 1rem 1rem;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding: 2rem 3rem;
  }
`;

const CheckIconWrapper = styled(Flex)`
  position: relative;
  z-index: 0;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.jade};

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    z-index: -1;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: ${({ theme }) => hexOpacity(theme.palette.jade, 0.2)};
  }
`;

export function AccountSetupIncompleteError({ email, teamName, handleBackToSignIn, error }) {
  const { currentTeam } = useAuthService();
  const theme = useTheme();
  const [emailResendStatus, setEmailResendStatus] = useState(EMAIL_RESEND_STATUS.NOT_ATTEMPTED);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const handleResendVerificationEmail = async () => {
    setEmailResendStatus(EMAIL_RESEND_STATUS.PENDING);
    setIsSendingEmail(true);
    try {
      await api.trustshare.resend(email, currentTeam.id);
      setEmailResendStatus(EMAIL_RESEND_STATUS.SUCCESS);
      setIsSendingEmail(false);
    } catch (e) {
      setEmailResendStatus(EMAIL_RESEND_STATUS.ERROR);
      setIsSendingEmail(false);
    }
  };

  if (emailResendStatus === EMAIL_RESEND_STATUS.SUCCESS) {
    return (
      <SpaceAround
        top="1rem"
        bottom="1rem"
        as={motion.div}
        variants={PROGRAM_COPY_VARIANTS}
      >
        <AnimatedContentContainer alignItems="center">
          <CheckIconWrapper
            alignItems="center"
            justifyContent="center"
          >
            <CheckIcon
              size={44}
              color={theme.palette.white}
            />
          </CheckIconWrapper>
          <Typography level="4">Email resent!</Typography>
          <StyledSuccessDescription
            element="p"
            level="8"
          >
            You will receive an email from our team asking you to verify your email address and complete your account
            setup.
          </StyledSuccessDescription>
          <GlideButton onClick={handleBackToSignIn}>Back to Sign In</GlideButton>
        </AnimatedContentContainer>
      </SpaceAround>
    );
  }

  // This condition is to show error message if the email resend fails. Second part makes sure that we don't show default case in case the email resend fails, and the resend status is pending
  if (emailResendStatus === EMAIL_RESEND_STATUS.ERROR) {
    return (
      <SpaceAround
        top="1rem"
        bottom="1rem"
      >
        <AnimatedContentContainer alignItems="center">
          <ErrorPopsicleIllustration
            width={68}
            height={68}
          />
          <Typography level="4">Oops! Something went wrong</Typography>
          <ErrorDescriptionLabel>
            Looks like we could not send an email. Would you like to retry getting a new email?
          </ErrorDescriptionLabel>
          <Flex gap="2rem">
            <GlideButton
              onClick={handleBackToSignIn}
              variant="secondary"
              className="secondary"
            >
              Back to Sign In
            </GlideButton>
            <GlideButton
              disabled={isSendingEmail}
              onClick={handleResendVerificationEmail}
            >
              Retry
            </GlideButton>
          </Flex>
        </AnimatedContentContainer>
      </SpaceAround>
    );
  }

  const title = error === ACCOUNT_SETUP_INCOMPLETE ? 'Account Setup Incomplete' : 'Email Verification Pending';
  const errorMessage =
    error === ACCOUNT_SETUP_INCOMPLETE
      ? `Oops! It looks like the account setup is incomplete. To resume account setup on ${teamName}’s TrustShare,
            please continue with the email setup link`
      : `Oops! It looks like the email verification is pending. To resume account setup on ${teamName}’s TrustShare,
            please continue with the email setup link`;

  return (
    <SpaceAround
      top="1rem"
      bottom="1rem"
    >
      <AnimatedContentContainer alignItems="center">
        <ErrorPopsicleIllustration
          width={68}
          height={68}
        />
        <Typography level="4">{title}</Typography>
        <ErrorDescriptionLabel>{errorMessage}</ErrorDescriptionLabel>
        <Flex gap="2rem">
          <GlideButton
            onClick={handleBackToSignIn}
            variant="secondary"
            className="secondary"
          >
            Back to Sign In
          </GlideButton>
          <GlideButton
            disabled={isSendingEmail}
            onClick={handleResendVerificationEmail}
          >
            Send Email
          </GlideButton>
        </Flex>
      </AnimatedContentContainer>
    </SpaceAround>
  );
}
