import React, { useState } from 'react';
import { Disclosure, DisclosureContent } from 'reakit';
import styled from '@emotion/styled';
import { UilAngleDown as ChevronDownIcon } from '@iconscout/react-unicons';
import { AnimatePresence, motion } from 'framer-motion';
import { Heading, Typography } from '@kintent/glide';

import { Grid, Stack } from '../../../../design-system';
import { Flex, InlineFlex } from '../../../../components/Flex';
import { hexOpacity } from '../../../../lib/utils';
import {
  BREAKPOINTS,
  COMPLIANCE_MAPPING_COLUMN_NAMES,
  COMPLIANCE_STANDARD_DISPLAY_NAME,
  COMPLIANCE_STANDARD_GROUP_NAME,
  COMPLIANCE_STANDARD_LOGO_MAP,
  COMPLIANCE_STANDARD_SRCSET_MAP,
  COMPLIANCE_STANDARDS,
  SOC_SUBTYPE,
} from '../../../../lib/constants';
import MappingColumnName from '../../../../components/MappingColumnName';
import ComplianceStandardControlItem from './ComplianceStandardControlItem';
import catEmptyState from '../../../../assets/cat-empty-state.gif';
import catEmptyState2x from '../../../../assets/cat-empty-state@2x.gif';
import CardContainer from '../../../../components/CardContainer';
import CountBox from '../../../../components/CountBox';

// Constants
const POLICY_SUFFIX_REGEX = new RegExp(/\sPolicy/, 'gmi');
const PADDING_RIGHT = '50px';
const CTRL_ID_HEIGHT = 30;
const HEIGHT_VAL = CTRL_ID_HEIGHT / 2;

// Styled components
const StandardMappingColumnStack = styled.div`
  & > * + * {
    margin-top: 8px;
  }
`;

const MappingItemCard = styled.div`
  margin-top: 8px;
  padding: 16px;

  border-radius: 8px;
  box-shadow: 0 4px 12px ${({ theme }) => hexOpacity(theme.palette.elephant, 0.2)};

  background-color: ${({ theme }) => theme.palette.white};
`;

const StyledDisclosure = styled(Disclosure)`
  width: fit-content;
  padding: 0;

  border-radius: 0;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }

  &:focus {
    &::before {
      border: 0;
    }
  }

  &.open > svg {
    transform: rotate(180deg);
    transition: transform 250ms ease-in;
  }

  &.closed > svg {
    transform: rotate(0deg);
    transition: transform 250ms ease-out;
  }
`;

const ComplianceStandardLogo = styled.div`
  width: 24px;
  height: 24px;

  & > img {
    max-width: 100%;
    height: auto;
  }
`;

// This function lower cases the policy suffix on the title
function formatPolicyTitleSuffixForDisplay(policyTitle) {
  return policyTitle.replace(POLICY_SUFFIX_REGEX, ' policy');
}

function ComplianceStandardBadge({ mapping }) {
  return (
    <Flex
      gap="12px"
      alignItems="center"
    >
      <ComplianceStandardLogo>
        <img
          src={
            mapping === COMPLIANCE_STANDARDS.SOC2
              ? COMPLIANCE_STANDARD_LOGO_MAP[COMPLIANCE_STANDARDS.SOC2][SOC_SUBTYPE.GENERIC]
              : COMPLIANCE_STANDARD_LOGO_MAP[mapping]
          }
          srcSet={
            mapping === COMPLIANCE_STANDARDS.SOC2
              ? COMPLIANCE_STANDARD_SRCSET_MAP[mapping][SOC_SUBTYPE.GENERIC]
              : COMPLIANCE_STANDARD_SRCSET_MAP[mapping]
          }
          alt={mapping}
        />
      </ComplianceStandardLogo>
      <Heading
        as="small"
        level="9"
      >
        {mapping === COMPLIANCE_STANDARDS.SOC2
          ? COMPLIANCE_STANDARD_DISPLAY_NAME[COMPLIANCE_STANDARDS.SOC2][SOC_SUBTYPE.GENERIC]
          : COMPLIANCE_STANDARD_DISPLAY_NAME[mapping]}
      </Heading>
    </Flex>
  );
}

const COLLAPSE_VARIANTS = {
  collapsed: {
    height: 0,
    opacity: 0,
  },
  expanded: {
    height: 'auto',
    opacity: 1,
  },
};

const ComplianceMappingContainer = styled(motion.div)`
  overflow: hidden;

  & > div {
    padding-top: 20px;
  }
`;

const VerticalDivider = styled.hr`
  display: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
    border-color: ${({ theme }) => hexOpacity(theme.palette.elephant, 0.5)};
    border-width: 0;
    border-right-width: 1px;

    width: 0;
    height: 30px;
  }
`;

const ComplianceStandardControlList = styled(Stack)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    position: relative;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding-top: 40px;

    &:before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      border: 0 dashed ${({ theme }) => theme.palette.elephant};
      border-left-width: 1px;
      width: 0;
      height: calc(100% - ${HEIGHT_VAL}px);
    }

    &.empty {
      justify-content: flex-start;

      padding-top: 0;

      &:before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        border: 0 dashed ${({ theme }) => theme.palette.elephant};
        border-right-width: 1px;

        width: 0;
        height: 59px;
      }

      &:after {
        content: '';

        position: absolute;
        top: 59px;
        left: 0;

        border: 0 dashed ${({ theme }) => theme.palette.elephant};
        border-top-width: 1px;
        width: 59px;
        height: 0;
      }
    }
  }
`;

const LogoContainer = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
    flex-shrink: 0;

    margin-top: 16px;
    width: 85px;
  }
`;

const StyledStack = styled(Stack)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-right: ${PADDING_RIGHT};
  }
`;

const ControlBox = styled(InlineFlex)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    padding: 6px 14px;

    background-color: ${({ theme }) => theme.palette.silver};

    & > p:first-of-type {
      width: 72px;

      word-break: break-all;
    }

    & > p:nth-of-type(2) {
      text-transform: capitalize;
      white-space: nowrap;
    }
  }
`;

const ComplianceStandardWrapper = styled(Flex)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-left: 5px;
    margin-top: 0;
  }
`;

const EmptyStateWrapper = styled.div`
  align-items: center;

  margin-left: 59px;
`;

const StyledTypography = styled(Typography)`
  border-radius: 5px;
  padding: 7px 10px;

  background-color: ${({ theme }) => hexOpacity(theme.palette.silver, 0.6)};
`;

const EmptyComplianceStandardControls = ({ controlName, selectedStandard }) => (
  <EmptyStateWrapper vcenter>
    <img
      alt="No Compliance Standard Controls"
      src={catEmptyState}
      srcSet={`${catEmptyState2x} 2x`}
      width={174}
      height={176}
    />
    <StyledTypography
      level="8"
      as="p"
    >
      {`No ${COMPLIANCE_STANDARD_DISPLAY_NAME[selectedStandard]} controls map to ${controlName}`}
    </StyledTypography>
  </EmptyStateWrapper>
);

const DescriptionContainer = styled(Stack)`
  display: grid;

  align-self: stretch;
`;

const DescriptionWrapper = styled.div`
  position: relative;

  grid-area: 1/1/2/2;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  margin-top: 24px;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: 0px;
  }
`;

const CustomDivider = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
    position: absolute;
    height: 100%;
    border: 0 dashed ${({ theme }) => theme.palette.volare};
    border-right-width: 1px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 26px;
      height: 0;
      border: 0 dashed ${({ theme }) => theme.palette.volare};
      border-top-width: 1px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 26px;
      height: 0;
      border: 0 dashed ${({ theme }) => theme.palette.volare};
      border-top-width: 1px;
    }
  }
`;

const Description = styled(Stack)`
  border: 1px solid ${({ theme }) => hexOpacity(theme.palette.volare, 0.4)};
  border-radius: 12px;
  height: 100%;
  padding: 15px;

  background-color: ${({ theme }) => hexOpacity(theme.palette.volare, 0.05)};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: 43px;
  }
`;

const StyledFlex = styled(Flex)`
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
  }
`;

const StyledGrid = styled(Grid)`
  cursor: pointer;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledCategoryNameGrid = styled(Grid)`
  display: none;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: block;
  }
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  display: none;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: block;
  }
`;

function MappingItem({
  policy,
  mapping,
  category,
  totalComplianceMappingCount,
  handleMappingItemClick,
  visible,
  complianceControls,
}) {
  const [selectedStandardControl, setSelectedStandardControl] = useState(complianceControls[0] ?? null);
  return (
    <>
      <StyledGrid
        container
        alignItems="center"
        onClick={() => handleMappingItemClick(mapping)}
      >
        <Grid item>
          <ComplianceStandardBadge mapping={mapping} />
        </Grid>
        <StyledCategoryNameGrid item>
          <Typography
            as="small"
            level="9"
          >
            {COMPLIANCE_STANDARD_GROUP_NAME[category]}
          </Typography>
        </StyledCategoryNameGrid>
        <Grid
          fullWidth
          item
        >
          <Flex
            gap="8px"
            alignItems="center"
            justifyContent="flex-end"
          >
            <CountBox count={totalComplianceMappingCount} />
            <StyledDisclosure
              as={Flex}
              alignItems="center"
              className={visible ? 'open' : 'closed'}
            >
              <StyledChevronDownIcon size={20} />
            </StyledDisclosure>
          </Flex>
        </Grid>
      </StyledGrid>
      <AnimatePresence exitBeforeEnter>
        {visible && (
          <DisclosureContent
            as={ComplianceMappingContainer}
            layout
            variants={COLLAPSE_VARIANTS}
            initial="collapsed"
            animate="expanded"
            exit="collapsed"
            transition="tween"
          >
            <div>
              <StyledFlex
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <StyledStack>
                  <ControlBox
                    alignItems="flex-start"
                    gap="8px"
                    direction="column-reverse"
                  >
                    <Typography
                      as="p"
                      level="8"
                    >
                      {policy.shortName}
                    </Typography>
                    <VerticalDivider />
                    <Heading
                      as="p"
                      level="8"
                    >
                      {policy.title}
                    </Heading>
                  </ControlBox>
                  <ComplianceStandardWrapper
                    alignItems="flex-start"
                    gap="9px"
                    justifyContent="flex-start"
                  >
                    <LogoContainer>
                      <ComplianceStandardLogo />
                    </LogoContainer>
                    <ComplianceStandardControlList
                      as={Flex}
                      justifyContent="flex-start"
                      gap="16px"
                      wrap
                      space="lg"
                    >
                      {complianceControls.length > 0 ? (
                        complianceControls.map((complianceControl) => (
                          <ComplianceStandardControlItem
                            key={complianceControl.id}
                            controlId={complianceControl.id}
                            title={complianceControl.title}
                            referenceId={complianceControl.referenceId}
                            displayIdentifier={complianceControl.displayIdentifier}
                            selectedStandardControl={selectedStandardControl}
                            onControlClick={() => {
                              if (complianceControl.displayIdentifier) {
                                setSelectedStandardControl(complianceControl);
                              }
                            }}
                          />
                        ))
                      ) : (
                        <EmptyComplianceStandardControls
                          controlName={policy.shortName}
                          selectedStandard={mapping}
                        />
                      )}
                    </ComplianceStandardControlList>
                  </ComplianceStandardWrapper>
                </StyledStack>
                <DescriptionContainer>
                  {complianceControls.length &&
                    complianceControls.map((controlItem) => {
                      return (
                        <React.Fragment key={controlItem.id}>
                          {controlItem.displayIdentifier && (
                            <DescriptionWrapper isVisible={selectedStandardControl?.id === controlItem.id}>
                              <CustomDivider aria-orientation="vertical" />
                              <Description space="sm">
                                <Heading
                                  as="p"
                                  level="7"
                                >
                                  {controlItem.displayIdentifier}
                                </Heading>
                                <Heading
                                  as="p"
                                  level="8"
                                >
                                  {controlItem.type && `(${controlItem.type})`}
                                </Heading>
                                <Typography
                                  as="p"
                                  level="8"
                                >
                                  {controlItem.title}
                                </Typography>
                              </Description>
                            </DescriptionWrapper>
                          )}
                        </React.Fragment>
                      );
                    })}
                </DescriptionContainer>
              </StyledFlex>
            </div>
          </DisclosureContent>
        )}
      </AnimatePresence>
    </>
  );
}

function PolicyComplianceStandardMapping({ policy, standardMappings, totalStandardMappingCount }) {
  const [activeMapping, setActiveMapping] = useState(null);

  const memoizedHandleMappingItemClick = React.useCallback(
    (mapping) => {
      setActiveMapping(mapping === activeMapping ? null : mapping);
    },
    [setActiveMapping, activeMapping]
  );

  return (
    <CardContainer>
      <Flex
        direction="column"
        gap="16px"
      >
        <Flex
          alignItems="center"
          gap="8px"
        >
          <CountBox count={totalStandardMappingCount} />
          <Heading level="5">Standard Mappings</Heading>
        </Flex>
        <Typography
          as="p"
          level="8"
        >
          The {formatPolicyTitleSuffixForDisplay(policy.title)} maps to the requirements of the following security,
          privacy, and compliance standards:
        </Typography>
        <StandardMappingColumnStack>
          <MappingColumnName columnNames={[...Object.values(COMPLIANCE_MAPPING_COLUMN_NAMES)]} />
          {Object.keys(standardMappings).map((mapping) => {
            return (
              <MappingItemCard key={mapping}>
                <MappingItem
                  policy={policy}
                  mapping={mapping}
                  category={standardMappings[mapping].category}
                  totalComplianceMappingCount={standardMappings[mapping].controls.length}
                  complianceControls={standardMappings[mapping].controls}
                  handleMappingItemClick={memoizedHandleMappingItemClick}
                  visible={activeMapping === mapping}
                />
              </MappingItemCard>
            );
          })}
        </StandardMappingColumnStack>
      </Flex>
    </CardContainer>
  );
}

export default PolicyComplianceStandardMapping;
