import React, { useCallback } from 'react';
import { useNavigation } from 'react-navi';
import { UilPadlock } from '@iconscout/react-unicons';

import { FEATURE_FLAG } from 'lib/featureFlags';
import * as Styles from './DocumentHeader.styles';
import { Search } from '../../home/components/Search';
import { Flex } from '../../../../components/Flex';
import { SpaceAround } from '../../../../design-system';
import { useAuthService, useFeatureFlag, useRequestAccessRedirectionUrl } from '../../../../lib/state';
import { TRUST_SHARE_ACCESS_LEVELS } from '../../../../lib/constants';

function DocumentHeader() {
  const { navigate } = useNavigation();
  const { currentTeam, authenticatedUser } = useAuthService();
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const shouldDisplaySearch =
    currentTeam.trustShareAccessLevel === TRUST_SHARE_ACCESS_LEVELS.TRANSPARENT || authenticatedUser !== null;

  const handleRequestAccess = useCallback(() => {
    navigate(requestAccessRedirectionUrl);
  }, [navigate, requestAccessRedirectionUrl]);

  return (
    <Styles.HeaderContainer
      direction="column"
      gap="16px"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
      >
        <Styles.HeadingTitle level="5">Trust, Security, & Compliance Documents</Styles.HeadingTitle>
        {!isRequestAccessHidden && !authenticatedUser && (
          <Styles.RequestAccessButton
            onClick={handleRequestAccess}
            icon={<UilPadlock />}
          >
            Request Access to All Documents
          </Styles.RequestAccessButton>
        )}
      </Flex>
      <Styles.HeadingSubtitle>
        To help you effortlessly understand and review our security, privacy and corporate trust posture, we have
        consolidated documentation into a single, easily-accessible location.
      </Styles.HeadingSubtitle>
      {shouldDisplaySearch && (
        <SpaceAround top="8px">
          <Search
            shouldHideControlsAndSubprocessors
            placeholder="Search for..."
          />
        </SpaceAround>
      )}
    </Styles.HeaderContainer>
  );
}

export default DocumentHeader;
