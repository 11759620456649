import React, { useMemo } from 'react';
import { Typography } from '@kintent/glide';

import * as Styles from './FooterV2.styles';
import { useProgramContent } from '../../lib/state';

function createMapFromFooterLinks(footerLinks) {
  return (
    footerLinks?.items.reduce((acc, footerItem) => {
      const { label, url = null } = footerItem;
      return acc.set(label, url);
    }, new Map()) ?? new Map()
  );
}

function FooterLinks({ footerMap }) {
  if (footerMap.size === 0) return null;

  return Array.from(footerMap).map(([label, url]) => {
    const dataTestId = `footer-section-link-${label?.replace(/\s/g, '-').toLowerCase()}`;
    return (
      <li key={label}>
        <Typography
          as={url ? 'a' : 'p'}
          data-testid={dataTestId}
          strength="60%"
          level="8"
          {...(url && { href: url, target: '_blank' })}
        >
          {label}
        </Typography>
      </li>
    );
  });
}

function FooterV2() {
  const [{ footerCopy, footerLinks = null, footerStyle }] = useProgramContent();

  // Extract footer styles if they exist
  const footerCopyStyle = footerStyle?.footerCopy ?? {};

  // Create a map from footer links
  const footerMap = useMemo(() => createMapFromFooterLinks(footerLinks), [footerLinks]);

  return (
    <Styles.Footer data-testid="footer-section">
      <Styles.FooterContainer>
        {footerCopy && (
          <Typography
            as="p"
            strength="60%"
            level="8"
            style={footerCopyStyle}
            className="footer-section-copy"
          >
            {footerCopy}
          </Typography>
        )}
        <Styles.UnstyledList>
          <FooterLinks footerMap={footerMap} />
        </Styles.UnstyledList>
      </Styles.FooterContainer>
    </Styles.Footer>
  );
}

export default FooterV2;
