import styled from '@emotion/styled';
import { color } from '@kintent/glide';

export const MainGrid = styled.main`
  grid-area: main;

  background-color: ${({ theme }) => color(theme.colorV2.system.gray, 100)};

  min-height: 100vh;

  padding: 12rem 4rem 4rem 4rem;
`;

export const DocumentDetailContainer = styled.section`
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  gap: 36px;

  background-color: white;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 600)};

  box-shadow: ${({ theme }) => `${theme.boxShadow.drop} ${color(theme.colorV2.system.gray, 100)}`};

  padding: 48px;

  & .document-detail-title {
    font-weight: 600;
  }
`;

export const PdfWrapper = styled.div`
  position: relative;
  min-height: 718px;

  border: 10px solid ${({ theme }) => color(theme.colorV2.system.gray, 200)};
  border-radius: 8px;
`;
