import React, { useState } from 'react';
import { useCurrentRoute } from 'react-navi';
import { Typography } from '@kintent/glide';
import { Document, Page, pdfjs } from 'react-pdf';

import { DOCUMENT_TYPE } from 'lib/constants';
import * as Styles from './DocumentDetail.styles';
import { Helmet } from '../../../../../components/Helmet';
import { useArtifactForPDFViewer } from '../../../../../lib/state';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/scripts/pdf.worker.min.js`;

// Constants
const DEFAULT_PAGE_HEIGHT = 700;
const DEFAULT_PAGE_WIDTH = 900;

function DocumentDetail() {
  const { data, url } = useCurrentRoute();

  const [totalPages, setTotalPages] = useState(0);

  const { blob, artifact } = useArtifactForPDFViewer(url.query.type, data.slug);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  return (
    <>
      <Helmet pageTitle="Documents" />
      <Styles.MainGrid data-testid="document-detail-grid">
        <Styles.DocumentDetailContainer>
          <Typography
            as="h1"
            level="1"
            className="document-detail-title"
          >
            {url.query.type === DOCUMENT_TYPE.POLICY ? artifact.title : artifact.displayName}
          </Typography>
          <Styles.PdfWrapper>
            <Document
              file={blob}
              loading=""
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              {Array.from({ length: totalPages }, (_, index) => (
                <Page
                  key={`page_${index + 1}`}
                  height={DEFAULT_PAGE_HEIGHT}
                  width={DEFAULT_PAGE_WIDTH}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  className="page"
                />
              ))}
            </Document>
          </Styles.PdfWrapper>
        </Styles.DocumentDetailContainer>
      </Styles.MainGrid>
    </>
  );
}

export default DocumentDetail;
