import React, { useEffect, useState } from 'react';
import { useCurrentRoute, useNavigation } from 'react-navi';
import { useContainer } from 'unstated-next';

// Local modules
import { MediaQueriesContext } from '../components/MediaQueriesProvider';
import { ON_BOARDING_FLOW_LOCAL_STORAGE_KEY } from './constants';
import { useAuthService } from './state';
import { isPublishedUrl } from './utils';

/**
 * This hook monitors the appearance of the page element in the screen scope.
 * @param trackedNode
 * @return {{item: null, visited: boolean, isIntersection: boolean}}
 */
export const useIntersected = (trackedNode, options) => {
  const [intersected, setIntersected] = useState({
    isIntersection: true,
    visited: false,
    item: null,
  });

  const observer = new IntersectionObserver(([item]) => {
    if (item.isIntersecting && !intersected.isIntersection) {
      setIntersected((prevState) => ({ ...prevState, isIntersection: true, visited: true, item }));
    } else if (!item.isIntersecting && intersected.isIntersection) {
      setIntersected((prevState) => ({ ...prevState, isIntersection: false, item }));
    } else if (item.isIntersecting && intersected.isIntersection && !intersected.visited) {
      setIntersected((prevState) => ({ ...prevState, isIntersection: true, visited: true, item }));
    }
  }, options && { ...options });

  useEffect(() => {
    const { current = null } = trackedNode;
    if (!current) return null;
    observer.observe(current);

    return () => observer.disconnect();
  });

  return intersected;
};

/**
 * This hook returns an object with three elements that represent the current screen resolution.
 * @return {{isDesktop, isTablet, isMobile}}
 */
export function useDeviceResolution() {
  const { isMobile, isTablet, isDesktop } = useContainer(MediaQueriesContext);
  return {
    isMobile,
    isTablet,
    isDesktop,
  };
}

export function useLocalStorage(key) {
  const value = (() => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch {
      return localStorage.getItem(key);
    }
  })();
  const setValue = (newValue) => localStorage.setItem(key, newValue);
  const removeKey = () => localStorage.removeItem(key);

  return {
    value,
    setValue,
    removeKey,
  };
}

// Checks the local storage and query param to determine if the user is on the onboarding flow.
// Returns true if the user is on the onboarding flow.
export function useIsOnBoardingFlow() {
  const [isOnBoarding, setIsOnBoarding] = useState(false);

  const { currentTeam } = useAuthService();
  const isPreviewEnvironment = !isPublishedUrl(currentTeam?.trustShareUrl);

  const {
    url: {
      query: { onboarding = null },
    },
  } = useCurrentRoute();
  const { navigate } = useNavigation();
  const { value: isOnBoardingFlowEnabledInLocalStorage, setValue: setOnBoardingFlowEnabledInLocalStorage } =
    useLocalStorage(ON_BOARDING_FLOW_LOCAL_STORAGE_KEY);

  useEffect(() => {
    if (!isPreviewEnvironment) {
      return;
    }
    if (isOnBoardingFlowEnabledInLocalStorage) {
      setIsOnBoarding(true);
    }
    if (onboarding) {
      setOnBoardingFlowEnabledInLocalStorage(true);
      setIsOnBoarding(true);
      const url = new URL(window.location.href);
      url.searchParams.delete('onboarding');
      navigate(`${url.pathname}${url.search}`, { replace: true });
    }
  }, [
    onboarding,
    isPreviewEnvironment,
    navigate,
    isOnBoardingFlowEnabledInLocalStorage,
    setOnBoardingFlowEnabledInLocalStorage,
  ]);

  return isOnBoarding;
}

// Get invitationToken from the query
export function useInvitationToken() {
  const {
    url: {
      query: { invitation = null },
    },
  } = useCurrentRoute();

  return invitation;
}
