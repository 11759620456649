import React from 'react';
import styled from '@emotion/styled';
import { UilFileAlt as ResourceIcon } from '@iconscout/react-unicons';
import { Link } from 'react-navi';
import { useTheme } from '@emotion/react';
import { Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import { useAuthService, useContentVisibility, usePolicyList } from '../../../../lib/state';
import ControlPageSection from './ControlPageSection';
import { pluralize } from '../../../../lib/utils';
import CardContainer from '../../../../components/CardContainer';

const StyledPolicyCard = styled(Flex)`
  border-radius: 100px;
  width: fit-content;
  padding: 4px 6px 4px 4px;

  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  text-decoration: none;
`;

const PolicyTitleLabel = styled(Typography)`
  color: white;

  text-transform: uppercase;
`;

function PolicyMappings({ policyIds }) {
  const theme = useTheme();
  const { currentTeam } = useAuthService();
  const [policies] = usePolicyList();
  const { shouldHidePolicyDetail: shouldHideLinkForPolicyDetails } = useContentVisibility();

  const associatedPolicies = policies.filter((policy) => policyIds.includes(policy.id));

  if (associatedPolicies.length === 0) return null;

  return (
    <CardContainer>
      <ControlPageSection
        title={pluralize('Policy Mapping', associatedPolicies.length)}
        count={associatedPolicies.length}
        description={`This control maps to the following ${
          associatedPolicies.length > 1 ? 'policies' : 'policy'
        } in the ${currentTeam.name} compliance program:`}
      >
        <Flex
          gap="8px"
          wrap
        >
          {associatedPolicies.map((policy) => {
            let policyProps = {};
            if (!shouldHideLinkForPolicyDetails) {
              policyProps = {
                href: `/policies/${encodeURIComponent(policy.shortName)}`,
                as: Link,
              };
            }
            return (
              <StyledPolicyCard
                alignItems="center"
                justifyContent="start"
                key={policy.id}
                {...policyProps}
              >
                <ResourceIcon
                  height={15}
                  color={theme.palette.white}
                />
                <PolicyTitleLabel level="9">{policy.title}</PolicyTitleLabel>
              </StyledPolicyCard>
            );
          })}
        </Flex>
      </ControlPageSection>
    </CardContainer>
  );
}

export default PolicyMappings;
