import React from 'react';
import { useCurrentRoute } from 'react-navi';
import { Accordion, Typography } from '@kintent/glide';
import Markdown from 'react-markdown';

import { Helmet } from '../../../components/Helmet';
import { FloatingRequestAccessButton } from '../../../components/FloatingRequestAccessButton';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { useMeasurePaint, useMeasureRender } from '../../../lib/measurement';
import { Flex } from '../../../components/Flex';
import { useProgramContent } from '../../../lib/state';
import HomePageContainer from '../home/components/HomePageContainer';
import * as Styles from './Faq.styles';

export const Faq = withTrustShareNavigationBar(() => {
  const route = useCurrentRoute();
  const {
    data: { span },
  } = route;
  const [{ faq }] = useProgramContent();

  const tree = (
    <>
      <Helmet pageTitle="FAQ" />
      <Flex
        as={HomePageContainer}
        direction="column"
        justifyContent="center"
        gap="64px"
      >
        <Flex
          direction="column"
          gap="12px"
          alignItems="center"
        >
          <Typography level="1">Frequently Asked Questions</Typography>
          <Typography
            level="6"
            color="black"
            strength="100%"
          >
            You&lsquo;ve got questions, we&lsquo;ve got answers
          </Typography>
        </Flex>
        <Flex
          direction="column"
          gap="20px"
        >
          {Object.keys(faq)?.map((key) => (
            <Accordion
              type="single"
              key={key}
            >
              <Styles.FaqAccordionItem
                value={key}
                heading={faq[key].question}
              >
                <Typography
                  as="p"
                  level="8"
                >
                  <Markdown>{faq[key].answer}</Markdown>
                </Typography>
              </Styles.FaqAccordionItem>
            </Accordion>
          ))}
        </Flex>
      </Flex>
      <FloatingRequestAccessButton />
    </>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});
