import React from 'react';
import { Link, useCurrentRoute } from 'react-navi';

import * as Styles from './DocumentsLeftPanel.styles';

function DocumentsLeftPanel({ selectedDocumentSection, documentSections, onDocumentSectionClick }) {
  const {
    data: { slug },
  } = useCurrentRoute();

  return (
    <Styles.DocumentGroupContainer
      direction="column"
      gap="16px"
    >
      {documentSections.map((documentSection) => {
        const isDocumentSectionSelected =
          (slug || selectedDocumentSection.documentType) === documentSection.documentType;
        return (
          <Styles.DocumentSection
            gap="12px"
            key={documentSection.documentType}
            onClick={(e) => {
              e.preventDefault();
              window.history.replaceState(null, null, `/documents#${documentSection.documentType}`);
              document.getElementById(documentSection.documentType).scrollIntoView({
                behavior: 'smooth',
              });
              return onDocumentSectionClick(documentSection);
            }}
            className={isDocumentSectionSelected ? 'active' : null}
            as={Link}
            href={`/documents#${encodeURIComponent(documentSection.documentType)}`}
            alignItems="center"
          >
            <Styles.IconWrapper alignItems="center">
              <documentSection.icon />
            </Styles.IconWrapper>
            <Styles.SectionName
              level="7"
              className={isDocumentSectionSelected ? 'active' : null}
            >
              {documentSection.displayName}
            </Styles.SectionName>
          </Styles.DocumentSection>
        );
      })}
    </Styles.DocumentGroupContainer>
  );
}

export default DocumentsLeftPanel;
