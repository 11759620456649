import styled from '@emotion/styled';
import { color } from '@kintent/glide';

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .notification-title {
    font-weight: 600;
  }

  & .separator {
    border: none;
    border-top: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 50)};
    margin: 16px 0;
  }

  & .notification-description {
    width: 80%;

    a {
      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => theme.components.header.navigation.textColor};
      }
    }
  }
`;

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NotificationMetadata = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
