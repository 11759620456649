import React from 'react';
import { Typography } from '@kintent/glide';

import * as Styles from './Documents.styles';
import DocumentList from './components/document-list/DocumentList';
import { Search } from '../home/components/Search';
import { MediaQueriesProvider } from '../../../components/MediaQueriesProvider';

// Constants
const DOCUMENT_COPY = 'Trust, Security, & Compliance Documents';

function Documents() {
  return (
    <MediaQueriesProvider>
      <Styles.MainGrid>
        <Styles.DocumentsContainer>
          <Styles.DocumentContainerHeading>
            <Typography
              as="h1"
              level="1"
            >
              {DOCUMENT_COPY}
            </Typography>
            <Typography
              as="p"
              strength="80%"
            >
              To help you effortlessly understand and review our security, privacy and corporate trust posture, we have
              consolidated documentation into a single, easily-accessible location.
            </Typography>
          </Styles.DocumentContainerHeading>
          <Styles.DocumentSearchContainer>
            <Typography
              as="p"
              strength="80%"
            >
              Need more information?
            </Typography>
            <Search
              placeholder="Ask us a question about our trust program"
              shouldHideControlsAndSubprocessors
              shouldUseEnterpriseGradeURLs
            />
          </Styles.DocumentSearchContainer>
        </Styles.DocumentsContainer>
        <Styles.DocumentListContainer>
          <Typography
            as="h4"
            level="4"
          >
            {DOCUMENT_COPY}
          </Typography>
          <DocumentList />
        </Styles.DocumentListContainer>
      </Styles.MainGrid>
    </MediaQueriesProvider>
  );
}

export default Documents;
