import React, { useEffect, useState } from 'react';
import { useCurrentRoute, useNavigation } from 'react-navi';

import { withTrustShareNavigationBar } from '../../components/NavigationBar';
import { useAuthService, useTeamSettings } from '../../lib/state';
import CompleteAccountSetup from './CompleteAccountSetup';
import CreateAccountWithUserDetails from './CreateAccountWithUserDetails';
import CreateAccountWithSSO from './CreateAccountWithSSO';
import { Helmet } from '../../components/Helmet';

// Constants
const CREATE_ACCOUNT_OPTIONS = {
  USER_DETAILS: 'user-details',
  SSO: 'sso',
};

const CreateAccount = withTrustShareNavigationBar(() => {
  const { url } = useCurrentRoute();
  const [authenticationMethod, setAuthenticationMethod] = useState(CREATE_ACCOUNT_OPTIONS.SSO);

  const { currentTeam } = useAuthService();
  const [teamSettings] = useTeamSettings(currentTeam.id);
  const { navigate } = useNavigation();

  // If either of the conditions are false, the page should not be shown
  const isCreateAccountEnabled = teamSettings.trustShare?.nda?.isAutoApproveAccessRequestEnabled;

  useEffect(() => {
    if (!isCreateAccountEnabled) {
      navigate('/request-access', { replace: true });
    }
  }, []);

  if ('verificationToken' in url.query || 'token' in url.query) {
    return (
      <CompleteAccountSetup
        verificationToken={url.query.verificationToken || null}
        ssoToken={url.query.token || null}
      />
    );
  }

  const handleCreateAccountOption = () => {
    setAuthenticationMethod(CREATE_ACCOUNT_OPTIONS.USER_DETAILS);
  };

  const handleBack = () => {
    setAuthenticationMethod(CREATE_ACCOUNT_OPTIONS.SSO);
  };

  return (
    <>
      <Helmet pageTitle="Create Account" />
      {authenticationMethod === CREATE_ACCOUNT_OPTIONS.USER_DETAILS ? (
        <CreateAccountWithUserDetails handleBack={handleBack} />
      ) : (
        <CreateAccountWithSSO handleCreateAccountOption={handleCreateAccountOption} />
      )}
    </>
  );
});

export default CreateAccount;
