// Package modules
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-navi';
import { motion } from 'framer-motion';
import { UilKeySkeletonAlt as KeyIcon } from '@iconscout/react-unicons';
import { Typography } from '@kintent/glide';

// Local modules
import { FEATURE_FLAG } from 'lib/featureFlags';
import { InlineFlex } from './Flex';
import { isPublishedUrl } from '../lib/utils';
import { BREAKPOINTS } from '../lib/constants';
import { useAuthService, useFeatureFlag, useTeamSettings } from '../lib/state';
import { GlideButton } from './GlideButton';

// Constants
const FLOATING_BUTTON_ANIMATION_VARIANTS = {
  initial: { scale: 1 },
  hovered: { scale: 1.03 },
};

const FLOATING_BUTTON_ICON_ANIMATION_VARIANTS = {
  initial: {
    rotateZ: 45,
    transition: {
      duration: 1,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  hovered: {
    rotateZ: [45, -45, -45, -45, -45, 45],
    transition: {
      duration: 2.5,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

// Styled components
const FloatingRequestAccessButtonWrapper = styled(motion(Typography))`
  position: fixed;
  z-index: 100;
  bottom: 4.2rem;
  right: 1rem;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      right: 1.5rem;
      bottom: 5.6rem;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      right: 3rem;
    }
  }
`;

const StyledButton = styled(GlideButton)`
  text-decoration: none;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  & > div {
    margin-right: 6px;
  }
`;

// Exports
export function FloatingRequestAccessButton(props) {
  const { currentTeam, authenticatedUser } = useAuthService();
  const [teamSettings] = useTeamSettings(currentTeam.id);
  const isPublishedEnvironment = isPublishedUrl(currentTeam.trustShareUrl);
  const { artifact = null } = props;
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  // Do not display the FAB button if the user is logged in or if the TrustShare is not published
  if (authenticatedUser || !isPublishedEnvironment) {
    return null;
  }

  // If either of the conditions are false, the Request Access button navigates the user to the legacy Request Access Flow
  const shouldRedirectToCreateAccountFlow = teamSettings.trustShare?.nda?.isAutoApproveAccessRequestEnabled;

  const urlToNavigateTo = () => {
    if (shouldRedirectToCreateAccountFlow) {
      return '/create-account';
    }
    return artifact ? `/request-access?artifact=${encodeURIComponent(artifact)}` : '/request-access';
  };

  return (
    !isRequestAccessHidden && (
      <FloatingRequestAccessButtonWrapper
        initial="initial"
        animate="animate"
        whileHover="hovered"
        as="div"
      >
        <StyledButton
          as={motion(Link)}
          href={urlToNavigateTo()}
          variant="primary"
          variants={FLOATING_BUTTON_ANIMATION_VARIANTS}
          icon={
            <InlineFlex
              as={motion.span}
              variants={FLOATING_BUTTON_ICON_ANIMATION_VARIANTS}
            >
              <KeyIcon size={20} />
            </InlineFlex>
          }
          {...props}
        >
          Request Access
        </StyledButton>
      </FloatingRequestAccessButtonWrapper>
    )
  );
}
