import React from 'react';
import { FEATURE_FLAG } from '../../../lib/featureFlags';
import { useFeatureFlag } from '../../../lib/state';
import { CertificationList } from './CertificationList';
import CertificationsV2 from './CertificationsV2';

function CertificationsFeatureSwitcher() {
  const isNewCertificationDetailEnabled = useFeatureFlag(FEATURE_FLAG.CERTIFICATION_DETAIL);

  if (isNewCertificationDetailEnabled) {
    return <CertificationsV2 />;
  }
  return <CertificationList />;
}

export default CertificationsFeatureSwitcher;
