import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useCurrentRoute } from 'react-navi';

import { getTeamCertificationsWithChildren, isDataRoomUserAbleToAccess } from 'lib/utils';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import PageContainer from '../../../components/PageContainer';
import { FloatingRequestAccessButton } from '../../../components/FloatingRequestAccessButton';
import CertificationLeftPanelV2 from './components/CertificationsLeftPanelV2';
import { useAuthService, useProgramContent, useTeamCertificationList } from '../../../lib/state';
import { useMeasureRoute } from '../../../lib/measurement';
import CertificationDetailPanel from './components/CertificationDetailPanel';

function CertificationsV2() {
  const {
    data: { span, slug },
  } = useCurrentRoute();
  const [programContent] = useProgramContent();

  // redirect to the home page if programContent.isCertificationVisible is false
  if (programContent && !programContent.isCertificationVisible) {
    window.location.href = '/';
    return null;
  }

  // pull documentId from query string if it exists
  const urlParams = new URLSearchParams(window.location.search);
  const documentId = urlParams.get('documentId');

  if (slug) {
    span.setAttribute('page-name', 'certification-details');
  } else {
    span.setAttribute('page-name', 'certifications');
  }
  useMeasureRoute(span);

  const { currentTeam, authenticatedUser } = useAuthService();
  const [unsortedTeamCertifications] = useTeamCertificationList(currentTeam.id);

  const rawTeamCertifications = unsortedTeamCertifications.sort((a, b) => {
    if (a.certification.shortName < b.certification.shortName) return -1;
    if (a.certification.shortName > b.certification.shortName) return 1;
    return 0;
  });
  const teamCertifications = getTeamCertificationsWithChildren(rawTeamCertifications, authenticatedUser)?.filter(
    ({ children }) => children.length > 0
  );

  let certificationToSelect = null;

  if (slug) {
    certificationToSelect = teamCertifications.find(({ certification, subtype }) => {
      return `${certification.shortName}${subtype || ''}` === slug;
    });
  }

  const [selectedCertification, setSelectedCertification] = useState(certificationToSelect || teamCertifications[0]);

  useEffect(() => {
    if (!isDataRoomUserAbleToAccess(selectedCertification, authenticatedUser)) {
      window.location.href = '/home';
      return true;
    }

    return true;
  }, []);

  const handleCertificationToggle = (certification) => {
    if (certification.id !== selectedCertification?.certification.id) {
      setSelectedCertification(certification);
    }
  };

  useEffect(() => {
    if (documentId) {
      const certificationByDocumentId = teamCertifications.find(({ children }) => {
        return children.find(({ id }) => id === documentId);
      });
      setSelectedCertification(certificationByDocumentId);
    }
  }, [documentId]);

  if (teamCertifications.length === 0) return null;

  return (
    <div>
      <Helmet pageTitle="Certifications" />
      <PageContainer
        alignItems="flex-start"
        gap="32px"
        direction="column"
      >
        <CertificationLeftPanelV2
          teamCertifications={teamCertifications}
          selectedCertification={selectedCertification}
          onCertificationClick={handleCertificationToggle}
        />
        <CertificationDetailPanel
          selectedCertification={selectedCertification}
          selectedDocument={
            documentId && selectedCertification && selectedCertification.children.find(({ id }) => id === documentId)
          }
        />
      </PageContainer>
      <FloatingRequestAccessButton />
    </div>
  );
}

export default withTrustShareNavigationBar(CertificationsV2);
