import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@kintent/glide';

import { useContentVisibility, useControlList } from '../../../../lib/state';
import { Flex } from '../../../../components/Flex';
import SubprocessorPageSection from './SubprocessorPageSection';
import SubprocessorControlCard from './SubprocessorControlCard';
import { BREAKPOINTS } from '../../../../lib/constants';
import CardContainer from '../../../../components/CardContainer';

const RelatedControlColumnGrid = styled(Flex)`
  padding-left: 16px;
  padding-right: 16px;

  white-space: nowrap;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const RelatedControlColumnStack = styled.div`
  & > * + * {
    margin-top: 8px;
  }
`;

const StyledTypography = styled(Typography)`
  display: none;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: block;
  }
`;

const getControlMappingsForSubprocessor = ({ selectedSubprocessor, controls }) => {
  const controlCategoryMap = {};

  controls.forEach((control) => {
    controlCategoryMap[control.shortName] = control.categorization.category;
  });

  const matchedControls = [];

  selectedSubprocessor.controls.forEach((selectedControl) => {
    matchedControls.push({
      shortName: selectedControl.shortName,
      controlName: selectedControl.controlName,
      category: controlCategoryMap[selectedControl.shortName],
    });
  });

  return matchedControls;
};

function SubprocessorControlMappingSection({ selectedSubprocessor }) {
  const [controls] = useControlList();
  const { shouldHideControlDetail: shouldHideLinkForControlDetails } = useContentVisibility();

  const matchedControls = useMemo(
    () => getControlMappingsForSubprocessor({ selectedSubprocessor, controls }),
    [selectedSubprocessor, controls]
  );

  if (matchedControls.length === 0) return null;

  return (
    <CardContainer
      direction="column"
      gap="16px"
    >
      <SubprocessorPageSection
        numberOfControls={matchedControls.length}
        subProcessorName={selectedSubprocessor.name}
      />
      <RelatedControlColumnStack>
        <RelatedControlColumnGrid justifyContent="space-between">
          <Typography
            as="p"
            level="9"
          >
            Control Name
          </Typography>
          <StyledTypography
            as="p"
            level="9"
          >
            Category
          </StyledTypography>
          <Typography
            as="p"
            level="9"
          >
            Monitoring Status
          </Typography>
        </RelatedControlColumnGrid>
        {matchedControls.map((control) => {
          return (
            <SubprocessorControlCard
              key={control.shortName}
              controlCategory={control.category}
              controlDetailUrl={`/controls/${encodeURIComponent(control.customShortName ?? control.shortName)}`}
              controlName={control.controlName}
              shouldHideLinkForControlDetails={shouldHideLinkForControlDetails}
            />
          );
        })}
      </RelatedControlColumnStack>
    </CardContainer>
  );
}

export default SubprocessorControlMappingSection;
