// Package modules.
import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'reakit';

// Helpers.
/**
 * This feature detection function checks if a given browser
 * has support for gap property within the context of a flexbox
 *
 * Example - This is mainly relevant for Safari since gap is supported within a grid layout, but not within
 * a flex layout
 */
const isFlexGapSupported = () => {
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  // create two, elements inside it
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
  flex.parentNode.removeChild(flex);

  return isSupported;
};

function shouldForwardProp(prop) {
  return !['alignItems', 'direction', 'gap', 'inline', 'justifyContent', 'wrap'].includes(prop);
}

// Constants.
const SUPPORTS_FLEX_GAP = isFlexGapSupported();

// Styles.
const FlexWrapper = styled.div`
  overflow: hidden;
`;

const StyledFlex = styled(Box, { shouldForwardProp })`
  ${({ gap }) =>
    gap &&
    css`
      --gapX: ${(typeof gap === 'object' ? gap.x : gap) || '0rem'};
      --gapY: ${(typeof gap === 'object' ? gap.y : gap) || '0rem'};

      gap: var(--gapY) var(--gapX);
    `}

  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${({ wrap }) => (typeof wrap === 'boolean' && wrap ? 'wrap' : wrap)};

  & > .flex-grow {
    flex-grow: 1;
  }
`;

const FlexContainer = styled(StyledFlex)`
  margin: calc(var(--gapY) / -2) calc(var(--gapX) / -2); // Negate halved value at edges.
  & > * {
    margin: calc(var(--gapY) / 2) calc(var(--gapX) / 2); // Apply half value because of "doubling up".
  }
`;

// Components.
const LegacyFlex = forwardRef(({ className, ...props }, ref) => (
  <FlexWrapper className={className}>
    <FlexContainer
      ref={ref}
      {...props}
    />
  </FlexWrapper>
));

export const Flex = forwardRef((props, ref) => {
  const Component = SUPPORTS_FLEX_GAP || !props.gap ? StyledFlex : LegacyFlex;
  return (
    <Component
      ref={ref}
      {...props}
    />
  );
});

export const InlineFlex = forwardRef((props, ref) => (
  <Flex
    ref={ref}
    inline
    {...props}
  />
));
