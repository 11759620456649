import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, Button, Disclosure } from 'reakit';
import { UilAngleDown } from '@iconscout/react-unicons';
import { Heading, Typography } from '@kintent/glide';

import { useFrameworkList } from 'lib/state';
import { Grid, Stack } from '../../../../design-system';
import { hexOpacity } from '../../../../lib/utils';
import { Flex, InlineFlex } from '../../../../components/Flex';

import {
  BREAKPOINTS,
  COMPLIANCE_STANDARD_DISPLAY_NAME,
  COMPLIANCE_STANDARD_GROUP_NAME,
  COMPLIANCE_STANDARD_LOGO_MAP,
  COMPLIANCE_STANDARD_SRCSET_MAP,
  SOC_SUBTYPE,
  UUID_REGEX,
} from '../../../../lib/constants';
import catEmptyState from '../../../../assets/cat-empty-state.gif';
import catEmptyState2x from '../../../../assets/cat-empty-state@2x.gif';
import CountBox from '../../../../components/CountBox';

import { ReactComponent as CustomFrameworkBadge } from '../../../../assets/CustomBadge.svg';

// Constants.
const COLLAPSE_VARIANTS = {
  collapsed: {
    height: 0,
    opacity: 0,
  },
  expanded: {
    height: 'auto',
    opacity: 1,
  },
};

const PADDING_RIGHT = '50px'; // applied in two places that need to synchronize on this value.
const CTRL_ID_HEIGHT = 30;
const HEIGHT_VAL = CTRL_ID_HEIGHT / 2;

const StyledAccordion = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  cursor: pointer;
`;

const StyledLogo = styled.div`
  width: 24px;
  height: 24px;

  & > img {
    max-width: 100%;
    height: auto;
  }
`;

const StyledDisclosure = styled(Disclosure)`
  width: fit-content;
  padding: 0;

  border-radius: 0;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }

  &:focus {
    &::before {
      border: 0;
    }
  }

  &.open > svg {
    transform: rotate(180deg);
    transition: transform 250ms ease-in;
  }

  &.closed > svg {
    transform: rotate(0deg);
    transition: transform 250ms ease-out;
  }
`;

const StyledChevronDown = styled(UilAngleDown)`
  display: none;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: block;
  }
`;

const StandardWrapper = styled(Flex)`
  width: 100%;
`;

const StyledItems = styled(motion.div)`
  overflow: hidden;

  & > div {
    margin-top: 20px;
  }
`;

const ControlBox = styled(InlineFlex)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    padding: 6px 14px;

    background-color: ${({ theme }) => theme.palette.silver};

    & > p:first-of-type {
      width: 72px;

      word-break: break-all;
    }

    & > p:nth-of-type(2) {
      text-transform: capitalize;
      white-space: nowrap;
    }
  }
`;

const VerticalDivider = styled.hr`
  display: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
    border-color: ${({ theme }) => hexOpacity(theme.palette.elephant, 0.5)};
    border-width: 0;
    border-right-width: 1px;

    width: 0;
    height: 30px;
  }
`;

const ComplianceStandardWrapper = styled(Flex)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-left: 5px;
    margin-top: 0;
  }
`;

const LogoContainer = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
    flex-shrink: 0;

    margin-top: 16px;
    width: 85px;
  }
`;

const ComplianceStandardLogo = styled.img`
  max-width: 100%;
  height: auto;
`;

const ComplianceStandardControlList = styled(Stack)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    position: relative;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding-top: 40px;

    &:before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      border: 0 dashed ${({ theme }) => theme.palette.elephant};
      border-left-width: 1px;
      width: 0;
      height: calc(100% - ${HEIGHT_VAL}px);
    }

    &.empty {
      justify-content: flex-start;

      padding-top: 0;

      &:before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        border: 0 dashed ${({ theme }) => theme.palette.elephant};
        border-right-width: 1px;

        width: 0;
        height: 59px;
      }

      &:after {
        content: '';

        position: absolute;
        top: 59px;
        left: 0;

        border: 0 dashed ${({ theme }) => theme.palette.elephant};
        border-top-width: 1px;
        width: 59px;
        height: 0;
      }
    }
  }
`;

const DescriptionContainer = styled(Stack)`
  display: grid;

  align-self: stretch;
`;

const DescriptionWrapper = styled.div`
  position: relative;

  grid-area: 1/1/2/2;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  margin-top: 24px;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: 0px;
  }
`;

const Description = styled(Stack)`
  border: 1px solid ${({ theme }) => hexOpacity(theme.palette.volare, 0.4)};
  border-radius: 12px;
  height: 100%;
  padding: 15px;

  background-color: ${({ theme }) => hexOpacity(theme.palette.volare, 0.05)};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: 43px;
  }
`;

const MappedControlContainer = styled(Flex)`
  position: relative;

  margin-top: 0px;

  align-items: flex-start;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    overflow: hidden;

    width: calc(100% + ${PADDING_RIGHT});
  }
`;

const ComplianceStandardControlIDButton = styled(Button, {
  shouldForwardProp(props) {
    return !['height'].includes(props);
  },
})`
  align-items: center;

  box-shadow: none;
  border: none;
  border-radius: 5px;
  max-width: 180px;
  height: auto;
  min-height: ${CTRL_ID_HEIGHT}px;
  white-space: normal; 
  word-wrap: break-word;

  background-color: ${({ theme }) => hexOpacity(theme.palette.volare, 0.1)};

  &.selected {
    background-color: ${({ theme }) => theme.palette.volare};

    color: ${({ theme }) => theme.palette.white};

    & > small {
      z-index: 2;

      color: ${({ theme }) => theme.palette.white};
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    cursor: pointer;

    margin-left: 58px;
    
  transition: background-color 250ms ease-in, color 250ms ease-in;

    &:before {
      content: '';

      position: absolute;
      left: 0;
      top: ${({ height }) => `calc(${height}px / 2)`}; /* Set top value based on the dynamic height */

      margin: auto;
      border-top: 1px dashed ${({ theme }) => theme.palette.elephant};
      width: 58px;
    }

  &.selected:after {
    content: '';
    position: absolute;
    left: 58px;
    top: ${({ height }) => `calc(${height}px / 2)`}; /* Set top value based on the dynamic height */
    margin: auto;
    width: 100%;
    height: 0;
    border-top: 1px dashed ${({ theme }) => theme.palette.volare};
    z-index: 1;
  }

  &.selected {
    background-color: ${({ theme }) => theme.palette.volare};

    color: ${({ theme }) => theme.palette.white};

    & > small {
      position: relative;
      z-index: 2;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.volare};

    & > small {
      color: ${({ theme }) => theme.palette.white};
    }
  }
`;

const EmptyStateWrapper = styled(Box)`
  align-items: center;

  margin-left: 59px;
`;

const StyledTypography = styled(Typography)`
  border-radius: 5px;
  padding: 7px 10px;

  background-color: ${({ theme }) => hexOpacity(theme.palette.silver, 0.6)};
`;

const CategoryStyledTypography = styled(Typography)`
  display: none;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: block;
  }
`;

const StyledFlex = styled(Flex)`
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
  }
`;

const CustomDivider = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
    position: absolute;
    height: 100%;
    border: 0 dashed ${({ theme }) => theme.palette.volare};
    border-right-width: 1px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 26px;
      height: 0;
      border: 0 dashed ${({ theme }) => theme.palette.volare};
      border-top-width: 1px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 26px;
      height: 0;
      border: 0 dashed ${({ theme }) => theme.palette.volare};
      border-top-width: 1px;
    }
  }
`;

const StyledStack = styled(Stack)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-right: ${PADDING_RIGHT};
  }
`;

const getLogoMap = (mapping) =>
  typeof COMPLIANCE_STANDARD_LOGO_MAP[mapping] === 'object' // Needed to determine if the logo is on the top level or in the object
    ? COMPLIANCE_STANDARD_LOGO_MAP[mapping][SOC_SUBTYPE.GENERIC]
    : COMPLIANCE_STANDARD_LOGO_MAP[mapping];

const getSrcSetMap = (mapping) =>
  typeof COMPLIANCE_STANDARD_SRCSET_MAP[mapping] === 'object' // Needed to determine if the logo is on the top level or in the object
    ? COMPLIANCE_STANDARD_SRCSET_MAP[mapping][SOC_SUBTYPE.GENERIC]
    : COMPLIANCE_STANDARD_SRCSET_MAP[mapping];

const getStandardNameMap = (mapping) =>
  typeof COMPLIANCE_STANDARD_DISPLAY_NAME[mapping] === 'object'
    ? COMPLIANCE_STANDARD_DISPLAY_NAME[mapping][SOC_SUBTYPE.GENERIC]
    : COMPLIANCE_STANDARD_DISPLAY_NAME[mapping];

const EmptyComplianceStandardControls = ({ controlName, selectedStandard }) => (
  <EmptyStateWrapper vcenter>
    <img
      alt="No Compliance Standard Controls"
      src={catEmptyState}
      srcSet={`${catEmptyState2x} 2x`}
      width={174}
      height={176}
    />
    <StyledTypography
      as="p"
      level="9"
    >
      {`No ${COMPLIANCE_STANDARD_DISPLAY_NAME[selectedStandard]} controls map to ${controlName}`}
    </StyledTypography>
  </EmptyStateWrapper>
);

const ComplianceStandardControl = ({ controlId, selectedStandardControl, onControlClick }) => {
  const heightRef = useRef();

  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const { height: buttonHeight } = heightRef.current.getBoundingClientRect();

    setHeight(buttonHeight);
  }, [heightRef?.current?.clientHeight]);

  return (
    <MappedControlContainer>
      <ComplianceStandardControlIDButton
        className={selectedStandardControl?.controlId === controlId ? 'selected' : ''}
        onClick={onControlClick}
        variant="floating"
        height={height}
        ref={heightRef}
      >
        <Typography
          as="small"
          align="center"
          level={selectedStandardControl?.controlId === controlId ? '8' : '9'}
        >
          {controlId}
        </Typography>
      </ComplianceStandardControlIDButton>
    </MappedControlContainer>
  );
};

const getFrameworkNameWithLogo = (mapping, frameworks) => {
  // a uuid is returned for custom frameworks
  const isCustomFramework = UUID_REGEX.test(mapping);

  if (isCustomFramework) {
    const { name } = frameworks.find((framework) => framework.id === mapping) || {};
    return (
      <>
        <CustomFrameworkBadge
          height={24}
          width={24}
        />
        <Heading
          as="small"
          level="9"
        >
          {name}
        </Heading>
      </>
    );
  }
  return (
    <>
      <StyledLogo>
        <img
          src={getLogoMap(mapping)}
          srcSet={getSrcSetMap(mapping)}
          alt={mapping}
        />
      </StyledLogo>
      <Heading
        as="small"
        level="9"
      >
        {getStandardNameMap(mapping)}
      </Heading>
    </>
  );
};

function StandardMappingAccordion({
  mapping,
  mappingDetails,
  control,
  standardMappingOpen,
  handleStandardMappingClick,
}) {
  const [selectedStandardControl, setSelectedStandardControl] = useState(mappingDetails?.controls[0]);
  const { data } = useFrameworkList();
  const collapsed = standardMappingOpen !== mapping;

  const frameworks = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.filter((framework) => !framework.isCatalog);
    }
    return [];
  }, [data]);

  return (
    <>
      <StyledAccordion
        container
        alignItems="center"
        onClick={handleStandardMappingClick}
      >
        <StandardWrapper
          gap="12px"
          alignItems="center"
        >
          {getFrameworkNameWithLogo(mapping, frameworks)}
        </StandardWrapper>
        <CategoryStyledTypography level="9">
          {COMPLIANCE_STANDARD_GROUP_NAME[mappingDetails.groupType]}
        </CategoryStyledTypography>
        <StandardWrapper
          gap="10px"
          alignItems="center"
          justifyContent="flex-end"
        >
          <CountBox count={mappingDetails.controls.length} />
          <StyledDisclosure
            as={Flex}
            alignItems="center"
            className={!collapsed ? 'open' : 'closed'}
          >
            <StyledChevronDown size={20} />
          </StyledDisclosure>
        </StandardWrapper>
      </StyledAccordion>
      <AnimatePresence>
        {!collapsed && (
          <StyledItems
            layout
            variants={COLLAPSE_VARIANTS}
            initial="collapsed"
            animate="expanded"
            exit="collapsed"
            collapsed={collapsed}
            transition={{ type: 'tween' }}
          >
            <StyledStack space="lg">
              <StyledFlex
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <StyledStack>
                  <ControlBox
                    alignItems="flex-start"
                    gap="8px"
                    direction="column-reverse"
                  >
                    <Typography
                      as="p"
                      level="8"
                      data-testid="mapped-control-id"
                    >
                      {control.customShortName ?? control.shortName}
                    </Typography>
                    <VerticalDivider />
                    <Heading
                      as="p"
                      level="8"
                    >
                      {control.subcategory}
                    </Heading>
                  </ControlBox>
                  <ComplianceStandardWrapper
                    alignItems="flex-start"
                    gap="9px"
                    justifyContent="flex-start"
                  >
                    <LogoContainer>
                      <ComplianceStandardLogo />
                    </LogoContainer>
                    <ComplianceStandardControlList
                      as={Flex}
                      justifyContent="flex-start"
                      gap="16px"
                      wrap
                      space="lg"
                      alignItems="center"
                    >
                      {mappingDetails.controls.length ? (
                        mappingDetails.controls.map((controlItem) => (
                          <ComplianceStandardControl
                            controlId={controlItem.controlId}
                            key={controlItem.controlId}
                            selectedStandardControl={selectedStandardControl}
                            onControlClick={() => setSelectedStandardControl(controlItem)}
                          />
                        ))
                      ) : (
                        <EmptyComplianceStandardControls
                          controlName={control.customShortName ?? control.shortName}
                          selectedStandard={mapping}
                        />
                      )}
                    </ComplianceStandardControlList>
                  </ComplianceStandardWrapper>
                </StyledStack>
                <DescriptionContainer>
                  {mappingDetails.controls.length &&
                    mappingDetails.controls.map((controlItem) => {
                      return (
                        <DescriptionWrapper
                          key={controlItem.controlId}
                          isVisible={selectedStandardControl?.controlId === controlItem.controlId}
                        >
                          <CustomDivider aria-orientation="vertical" />
                          <Description space="sm">
                            <Heading
                              level="7"
                              as="p"
                            >
                              {controlItem.controlId}
                            </Heading>
                            <Heading
                              as="p"
                              level="8"
                            >
                              {controlItem.name}
                              {controlItem.type && `(${controlItem.type})`}
                            </Heading>
                            <Typography
                              as="p"
                              level="8"
                            >
                              {controlItem.description}
                            </Typography>
                          </Description>
                        </DescriptionWrapper>
                      );
                    })}
                </DescriptionContainer>
              </StyledFlex>
            </StyledStack>
          </StyledItems>
        )}
      </AnimatePresence>
    </>
  );
}

export default StandardMappingAccordion;
