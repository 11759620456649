import React from 'react';
import styled from '@emotion/styled';

import { Stack } from '../../../../design-system';
import ControlCategoryDisclosure from './ControlCategoryDisclosure';
import { BREAKPOINTS } from '../../../../lib/constants';

const StyledContainer = styled(Stack)`
  display: none;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: block;
    padding-top: 9px;
    padding-right: 40px;

    width: 240px;
  }
`;

function ControlCategoryPanel({
  sortedControlCategory,
  adoptedControlsByCategory,
  selectedControlCategory,
  onControlCategoryClick,
  selectedControl,
}) {
  return (
    <StyledContainer>
      {sortedControlCategory.map((category) => {
        const controlsByCategory = adoptedControlsByCategory[category];
        if (controlsByCategory.length === 0) return null;
        return (
          <ControlCategoryDisclosure
            key={category}
            category={category}
            controlsByCategory={controlsByCategory}
            selectedControlCategory={selectedControlCategory}
            onControlCategoryClick={onControlCategoryClick}
            selectedControl={selectedControl}
          />
        );
      })}
    </StyledContainer>
  );
}

export default ControlCategoryPanel;
