import React, { useState } from 'react';
import { Toast } from '@kintent/glide';

import { getDocumentsChildren } from 'lib/utils';
import DashboardDocumentCard from './DashboardDocumentCard';
import DashboardDocumentCardViewMore from './DashboardDocumentCardViewMore';
import * as Styles from './DashboardDocuments.styles';

const MAX_DOCUMENTS = 8;

function DashboardDocumentsGrid({ documents }) {
  const [shouldShowDownloadErrorToast, setShouldShowDownloadErrorToast] = useState(false);

  const childrenDocuments = getDocumentsChildren(documents);

  const allDocuments = [...documents, ...childrenDocuments];

  return (
    <>
      <Styles.Grid>
        {allDocuments.map((document, index) => {
          if (index === MAX_DOCUMENTS)
            return (
              <DashboardDocumentCardViewMore
                count={allDocuments.length - MAX_DOCUMENTS}
                key={document.id}
              />
            );
          if (index > MAX_DOCUMENTS) return null;
          return (
            <DashboardDocumentCard
              key={document.id}
              document={document}
              setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
            />
          );
        })}
      </Styles.Grid>
      <Toast
        variant="negative"
        label="Error"
        description="We couldn't generate the download file, please contact support."
        open={shouldShowDownloadErrorToast}
        duration={1000}
        onOpenChange={() => setShouldShowDownloadErrorToast(false)}
      />
    </>
  );
}

export default DashboardDocumentsGrid;
