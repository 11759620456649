import styled from '@emotion/styled';
import { base, color } from '@kintent/glide';

export const PrimaryGridItem = styled.article`
  grid-area: primary;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const GridItemHeading = styled.div`
  display: flex;
  justify-content: space-between;

  & .primary-section-title {
    font-weight: 600;
  }
`;

export const Card = styled.div`
  background-color: white;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 600)};

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => color(theme.colorV2.system.gray, 100)};

  padding: 28px;
`;

export const UnstyledList = styled.ul`
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 16px;

  & > li {
    display: flex;
    align-items: center;
    gap: 12px;

    & svg {
      color: ${({ theme }) => color(theme.colorV2.system.gray, 600)};
    }

    & .primary-section-item-title {
      text-decoration: underline;
    }
  }
`;
