import React, { useState } from 'react';
import styled from '@emotion/styled';

import ControlPageSection from './ControlPageSection';
import StandardMappingAccordion from './StandardMappingAccordion';
import { hexOpacity, pluralize } from '../../../../lib/utils';
import { COMPLIANCE_MAPPING_COLUMN_NAMES } from '../../../../lib/constants';
import CardContainer from '../../../../components/CardContainer';
import MappingColumnName from '../../../../components/MappingColumnName';

const StyledWrapper = styled.div`
  margin-top: 8px;
  box-shadow: 0 4px 12px ${({ theme }) => hexOpacity(theme.palette.elephant, 0.2)};
  border-radius: 8px;
  padding: 16px;
`;

const getTotalMappingCount = (standardsMappings) => {
  let count = 0;
  Object.keys(standardsMappings.mappings)?.forEach((mapping) => {
    if (standardsMappings.mappings[mapping]?.controls) {
      count += standardsMappings.mappings[mapping].controls.length;
    }
  });
  return count;
};

function StandardsMappingsSection({ control }) {
  const [standardMappingOpen, setStandardMappingOpen] = useState(''); // Stores currently opened standard mapping
  const standardsMappings = control?.complianceMapping;
  const totalCount = getTotalMappingCount(standardsMappings);

  if (!totalCount || !standardsMappings?.mappings) {
    return null;
  }

  return (
    <CardContainer>
      <ControlPageSection
        title={pluralize('Standards Mapping', totalCount)}
        count={totalCount}
        description={`This control maps to the following security, privacy, and other compliance ${pluralize(
          'standard',
          totalCount
        )}:`}
      >
        <MappingColumnName columnNames={[...Object.values(COMPLIANCE_MAPPING_COLUMN_NAMES)]} />
        {Object.keys(standardsMappings.mappings)?.map((mapping) => {
          return (
            <StyledWrapper key={mapping}>
              <StandardMappingAccordion
                mapping={mapping}
                mappingDetails={standardsMappings.mappings[mapping]}
                control={{
                  customShortName: control.customShortName,
                  shortName: control.shortName,
                  subcategory: control.categorization.subcategory,
                }}
                standardMappingOpen={standardMappingOpen}
                handleStandardMappingClick={() =>
                  setStandardMappingOpen(mapping === standardMappingOpen ? '' : mapping)
                }
              />
            </StyledWrapper>
          );
        })}
      </ControlPageSection>
    </CardContainer>
  );
}

export default StandardsMappingsSection;
