// Package modules
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useCurrentRoute } from 'react-navi';
import { motion } from 'framer-motion';
import { useTheme } from '@emotion/react';
import { UilCheck as CheckIcon, UilQuestionCircle as HelpIcon } from '@iconscout/react-unicons';
import {
  unstable_Form as BaseForm,
  TooltipReference,
  unstable_useFormState as useFormState,
  useTooltipState,
} from 'reakit';
import { Typography } from '@kintent/glide';

// Local modules
import { FormContext, FormInput, FormMessage, FormTextArea, SpaceAround, Stack, Tooltip } from '../design-system';
import { SectionDescription, SectionTitle } from './PageElements';
import {
  AnimatedContentContainer,
  PROGRAM_COPY_VARIANTS,
} from '../app/trust-share/home/components/AnimatedContentContainer';
import { Flex } from './Flex';
import { useAuthService, useProgramContent } from '../lib/state';
import { useDeviceResolution } from '../lib/hooks';
import { hexOpacity, mailtoHandler } from '../lib/utils';
import { api } from '../lib/api';
import { BREAKPOINTS, CONTACT_US_REQUEST_TYPES, EMAIL_REGEX } from '../lib/constants';
import {
  ACCOUNT_EXPIRED,
  CONTACT_REQUEST_ALREADY_EXISTS,
  USER_ALREADY_EXISTS,
  USER_ALREADY_INVITED,
} from '../lib/errors/ApiError';

// Assets
import { ReactComponent as FailedAccessRequestIllustration } from '../assets/error-popsicle.svg';
import { GlideButton } from './GlideButton';

const INVALID_EMAIL_ERROR_MESSAGE = `
  We'd like to earn your trust by being transparent about our compliance and security posture.
  In return, we also ask than you use your business email, so we can establish the business purpose of your request.
  Knowing your business purpose helps us approve your request faster.
`;

// Styled components
const StyledTooltip = styled(Tooltip)`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.navy};
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.navy};

  width: 225px;

  padding: 1rem;

  &:after {
    display: none;
  }
`;

const SuccessMessageLabel = styled(SectionDescription)`
  padding-bottom: 1.5rem;
  max-width: 420px;
`;

const StyledEmailHelperLabel = styled(Typography)`
  margin-top: 0.75rem;
`;

const FormWrapper = styled.div`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding-top: 1rem;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      padding: 2rem 3rem 0.625rem 3rem;
    }
  }
`;

const StyledFlex = styled(Flex)`
  & > div {
    flex: 1;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      gap: 0 1rem;
      flex-direction: row;
    }
  }
`;

const CheckIconWrapper = styled(Flex)`
  position: relative;
  z-index: 0;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.jade};

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    z-index: -1;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: ${({ theme }) => hexOpacity(theme.palette.jade, 0.2)};
  }
`;

const SubmitButton = styled(GlideButton)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      width: auto;
    }
  }
`;

const ContactSupport = styled(Typography)`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  text-align: center;
`;

export function RequestAccessFailedInfo({ errorMessage, userName, onReattempt }) {
  const { currentTeam } = useAuthService();

  const handleContactSupport = () => {
    mailtoHandler(currentTeam.name, userName);
  };

  return (
    <SpaceAround
      as={AnimatedContentContainer}
      top="2rem"
      direction="column"
      alignItems="center"
      gap="2.5rem"
    >
      <FailedAccessRequestIllustration />
      <Stack>
        {errorMessage ? (
          <SectionDescription align="center">{errorMessage}</SectionDescription>
        ) : (
          <>
            <StyledSectionTitle level="5">Error</StyledSectionTitle>
            <SectionDescription align="center">
              There was an error requesting access to&nbsp;
              {currentTeam.name}
              ’s TrustShare.
              <br />
              Please try again.
            </SectionDescription>
          </>
        )}
      </Stack>
      <Flex
        as={motion.div}
        direction="column"
        alignItems="center"
        gap="3rem"
        variants={PROGRAM_COPY_VARIANTS}
      >
        {errorMessage ? (
          <Typography
            as={Link}
            href="/home"
            level="8"
          >
            Return to Home
          </Typography>
        ) : (
          <GlideButton onClick={onReattempt}>Try Again</GlideButton>
        )}
        <Typography
          as="p"
          level="8"
        >
          Need help?&nbsp;&nbsp;
          <ContactSupport
            as="span"
            onClick={handleContactSupport}
          >
            Contact support
          </ContactSupport>
        </Typography>
      </Flex>
    </SpaceAround>
  );
}

const DefaultRequestAccessDescription = () => (
  <>
    <SectionDescription>
      We are happy to share our compliance metrics and goals with you. To access this information, please fill out the
      form below.
    </SectionDescription>
    <SectionDescription>
      Once your request is approved, you will receive an invitation to create a TrustCloud account (or log in with an
      existing one), which will allow you to view our TrustShare.
    </SectionDescription>
  </>
);

const getRequestAccessDescription = (programContent) => {
  const publicFacingDescription = programContent?.publicFacingDescriptions?.requestAccess;

  if (publicFacingDescription) {
    const descriptionsByNewLine = publicFacingDescription.split('\n');
    return descriptionsByNewLine.map((description) => (
      <SectionDescription
        key={description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    ));
  }

  return <DefaultRequestAccessDescription />;
};

export function RequestAccessForm({ onRequestSent, onRequestFailed, onRequestReset }) {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailedMessage, setShowFailedMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const tooltip = useTooltipState({ placement: 'right-start' });
  const { currentTeam } = useAuthService();
  const theme = useTheme();
  const { isDesktop } = useDeviceResolution();
  const [programContent] = useProgramContent();

  const router = useCurrentRoute();
  const {
    url: {
      query: { artifact: requestedDocument = null, type: requestedDocumentType = '' },
    },
  } = router;

  const requestAccessForm = useFormState({
    onSubmit: async ({ firstName, lastName, companyName, workEmail, companyUrl, businessPurpose }) => {
      try {
        await api.team.contactUs({
          type: CONTACT_US_REQUEST_TYPES.CONTACT_US,
          teamId: currentTeam.id,
          firstName,
          lastName,
          company: companyName,
          email: workEmail.trim(),
          companyUrl,
          businessPurpose,
        });
        setShowSuccessMessage(true);
        onRequestSent();
      } catch (e) {
        switch (e.sourceError?.error) {
          case CONTACT_REQUEST_ALREADY_EXISTS:
            setErrorMessage(
              <>
                We see that you’ve already requested access to {currentTeam.name} ’s TrustShare. Your request needs
                approval before you can login. Please reach out to {currentTeam.name}
                {currentTeam.contactUsEmail ? (
                  <>
                    {' at '}
                    <ContactSupport
                      as="span"
                      onClick={() => {
                        mailtoHandler(currentTeam.name, `${firstName} ${lastName}`);
                      }}
                    >
                      {currentTeam.contactUsEmail}
                    </ContactSupport>
                  </>
                ) : (
                  ''
                )}{' '}
                to get your request approved.
              </>
            );
            break;
          case USER_ALREADY_INVITED:
            setErrorMessage(e.sourceError.debug);
            break;
          case USER_ALREADY_EXISTS:
            setErrorMessage(
              <>
                You seem to have an account to access {currentTeam.name} ’s TrustShare. Have you tried to{' '}
                <Typography
                  as={Link}
                  href="/login"
                >
                  login
                </Typography>{' '}
                or <a href="/reset-password">reset your password</a>?
              </>
            );
            break;
          case ACCOUNT_EXPIRED:
            setErrorMessage(
              <>
                Your access to {currentTeam.name}
                ’s TrustShare has expired. You will need to get in touch with {currentTeam.name}{' '}
                {currentTeam.contactUsEmail ? (
                  <Typography as="span">
                    at{' '}
                    <ContactSupport
                      as="span"
                      onClick={() => {
                        mailtoHandler(currentTeam.name, `${firstName} ${lastName}`);
                      }}
                    >
                      {currentTeam.contactUsEmail}
                    </ContactSupport>
                  </Typography>
                ) : (
                  ''
                )}{' '}
                to provide extended access to their TrustShare.
              </>
            );
            break;
          default:
            break;
        }
        setShowFailedMessage(true);
        onRequestFailed?.();
      }
    },
    onValidate: ({ firstName, lastName, companyName, companyUrl, businessPurpose, workEmail }) => {
      const errors = {};

      if (firstName.trim().length === 0) {
        errors.firstName = 'Please enter your first name';
      }

      if (lastName.trim().length === 0) {
        errors.lastName = 'Please enter your last name';
      }

      if (companyName.trim().length === 0) {
        errors.companyName = 'Please enter your company name';
      }

      if (companyUrl.trim().length === 0) {
        errors.companyUrl = 'Please enter your company’s website';
      }

      if (businessPurpose.trim().length === 0) {
        errors.businessPurpose = `Please enter a reason for requesting access to ${currentTeam.name}’s TrustShare`;
      }

      if (!EMAIL_REGEX.test(workEmail)) {
        errors.workEmail = 'Please enter a valid email address';
      }

      // Throw if errors, return otherwise.
      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    validateOnBlur: true,
    values: {
      firstName: '',
      lastName: '',
      companyName: '',
      workEmail: '',
      companyUrl: '',
      businessPurpose: requestedDocument
        ? `I would like to view ${currentTeam.name}‘s ${requestedDocument} ${requestedDocumentType}`
        : '',
    },
  });

  // Change tooltip placement on mobile and tablets devices.
  useEffect(() => {
    tooltip.place(isDesktop ? 'right-start' : 'top-end');
  }, [tooltip, isDesktop]);

  const handleReattempt = () => {
    setShowFailedMessage(false);
    setErrorMessage(null);
    onRequestReset?.();
    requestAccessForm.reset();
  };

  if (showSuccessMessage) {
    return (
      <SpaceAround top="32px">
        <AnimatedContentContainer alignItems="center">
          <motion.div variants={PROGRAM_COPY_VARIANTS}>
            <CheckIconWrapper
              alignItems="center"
              justifyContent="center"
            >
              <CheckIcon
                size={44}
                color={theme.palette.white}
              />
            </CheckIconWrapper>
          </motion.div>
          <SectionTitle>Access Requested</SectionTitle>
          <SuccessMessageLabel
            as="p"
            level="8"
          >
            We’re excited to share our compliance journey with you! You will receive an email from our team shortly with
            more information on next steps.
          </SuccessMessageLabel>
          <motion.div variants={PROGRAM_COPY_VARIANTS}>
            <GlideButton
              as={Link}
              href="/home"
            >
              Return To Home
            </GlideButton>
          </motion.div>
        </AnimatedContentContainer>
      </SpaceAround>
    );
  }

  if (showFailedMessage) {
    const userName = `${requestAccessForm.values.firstName} ${requestAccessForm.values.lastName}`;
    return (
      <RequestAccessFailedInfo
        userName={userName}
        onReattempt={handleReattempt}
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <FormWrapper>
      <FormContext.Provider value={requestAccessForm}>
        <BaseForm {...requestAccessForm}>
          <Stack space="xl">
            <Stack>{getRequestAccessDescription(programContent)}</Stack>
            <StyledFlex
              gap={{ y: '1.875rem' }}
              direction="column"
            >
              <div>
                <FormInput
                  name="firstName"
                  placeholder="First name*"
                  required
                />
                <FormMessage name="firstName" />
              </div>
              <div>
                <FormInput
                  name="lastName"
                  placeholder="Last name*"
                  required
                />
                <FormMessage name="lastName" />
              </div>
            </StyledFlex>
            <div>
              <Flex
                gap="12px"
                alignItems="center"
              >
                <FormInput
                  name="workEmail"
                  placeholder="name@your-company.com*"
                  required
                />
                <TooltipReference
                  as={Flex}
                  {...tooltip}
                >
                  <HelpIcon
                    size={18}
                    color={theme.palette.navy}
                  />
                </TooltipReference>
              </Flex>
              <FormMessage name="workEmail" />
              <StyledEmailHelperLabel
                as="p"
                level="9"
              >
                {`*Only requests from legitimate businesses will be considered.
                  Please ensure that you explain your interest in accessing ${currentTeam.name}’s TrustShare.`}
              </StyledEmailHelperLabel>
            </div>
            <FormInput
              name="companyName"
              placeholder="Company name*"
              required
            />
            <FormMessage name="companyName" />
            <FormInput
              type="url"
              name="companyUrl"
              placeholder="Company website*"
              required
            />
            <FormMessage name="companyUrl" />
            <FormTextArea
              name="businessPurpose"
              placeholder="State your business purpose and the list of documents you need"
              required
            />
            <FormMessage name="businessPurpose" />
            <Flex justifyContent="flex-end">
              <SubmitButton
                variant="primary"
                onClick={requestAccessForm.submit}
                disabled={!requestAccessForm.valid || requestAccessForm.submitting}
              >
                Submit
              </SubmitButton>
            </Flex>
            <StyledTooltip {...tooltip}>{INVALID_EMAIL_ERROR_MESSAGE}</StyledTooltip>
          </Stack>
        </BaseForm>
      </FormContext.Provider>
    </FormWrapper>
  );
}
