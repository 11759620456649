import React, { useState } from 'react';
import { UilCheck as CheckIcon } from '@iconscout/react-unicons';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Box } from 'reakit';
import { Link, useCurrentRoute, useNavigation } from 'react-navi';
import { Typography } from '@kintent/glide';

import { withTrustShareNavigationBar } from '../../components/NavigationBar';
import { SectionDescription } from '../../components/PageElements';
import { useAuthService } from '../../lib/state';
import { AnimatedContentContainer } from '../trust-share/home/components/AnimatedContentContainer';
import { SpaceAround } from '../../design-system';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import { Flex } from '../../components/Flex';
import { BREAKPOINTS } from '../../lib/constants';
import { hexOpacity } from '../../lib/utils';
import { api } from '../../lib/api';
import { GlideButton } from '../../components/GlideButton';

const ResponsiveFlex = styled(Flex)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      gap: 3rem;
    }
  }
`;

const StyledBox = styled(Box)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    box-shadow: -15px -15px 25px 0 ${({ theme }) => hexOpacity(theme.palette.white, 0.9)},
      10px 10px 15px 4px ${({ theme }) => hexOpacity(theme.palette.shadow, 0.1)};
    border-radius: ${({ theme }) => theme.radius.xl};

    padding: 1rem 1.5rem;

    & {
      padding: 2rem 2rem;
    }
  }
`;

const CheckIconWrapper = styled(Flex)`
  position: relative;
  z-index: 0;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.jade};

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    z-index: -1;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: ${({ theme }) => hexOpacity(theme.palette.jade, 0.2)};
  }
`;

const TypographyLink = styled(Typography)`
  margin-top: 0.5rem;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const StyledSectionDescription = styled(SectionDescription)`
  text-align: center;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
`;

const getTeamNameWithApostrophe = (teamName) => {
  const lastCharacter = teamName[teamName.length - 1];
  if (lastCharacter.toLowerCase() === 's') {
    return `${teamName}‘`;
  }
  return `${teamName}‘s`;
};

const ConnectTrustshare = withTrustShareNavigationBar(() => {
  const theme = useTheme();
  const { url } = useCurrentRoute();
  const { currentTeam, updateAuth } = useAuthService();
  const [isJoiningTeam, setIsJoiningTeam] = useState(false);
  const { navigate } = useNavigation();
  const { token: temporaryJwtForJoiningNewTeam } = url.query;

  if (!temporaryJwtForJoiningNewTeam) {
    navigate('/home');
  }

  const handleJoinTeam = async () => {
    try {
      const { user, token } = await api.trustshare.joinTrustShareTeam(temporaryJwtForJoiningNewTeam);
      updateAuth(token, user);
      navigate('/home');
    } catch (e) {
      setIsJoiningTeam(false);
    }
  };

  return (
    <ResponsiveContainer
      as={ResponsiveFlex}
      direction="column"
      gap="1rem"
    >
      <StyledBox>
        <AnimatedContentContainer alignItems="center">
          <CheckIconWrapper
            alignItems="center"
            justifyContent="center"
          >
            <CheckIcon
              size={44}
              color={theme.palette.white}
            />
          </CheckIconWrapper>
          <StyledTypography level="4">
            Connect to {getTeamNameWithApostrophe(currentTeam.name)} TrustShare
          </StyledTypography>
          <StyledSectionDescription
            as={SpaceAround}
            top="19px"
            bottom="21px"
          >
            We already have an account associated with this email.
            <br />
            Would you like to connect {currentTeam.name} with this account?
          </StyledSectionDescription>
          <GlideButton
            onClick={handleJoinTeam}
            disabled={isJoiningTeam}
          >
            Connect Account
          </GlideButton>
          <TypographyLink
            as={Link}
            href="/"
            level="8"
          >
            Take me back home
          </TypographyLink>
        </AnimatedContentContainer>
      </StyledBox>
    </ResponsiveContainer>
  );
});

export default ConnectTrustshare;
