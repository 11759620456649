// Package modules
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useCurrentRoute, useNavigation } from 'react-navi';
import { Box } from 'reakit';

// Local modules
import { FEATURE_FLAG } from 'lib/featureFlags';
import { Stack } from '../../../design-system';
import { Helmet } from '../../../components/Helmet';
import { Flex } from '../../../components/Flex';
import { ResponsiveContainer } from '../../../components/ResponsiveContainer';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { RequestAccessForm } from '../../../components/RequestAccessForm';
import { ErrorDescriptionLabel, ErrorTitleLabel, PageTitle } from '../../../components/PageElements';
import { useDeviceResolution } from '../../../lib/hooks';
import { BREAKPOINTS } from '../../../lib/constants';
import { useAuthService, useFeatureFlag, useTeamSettings } from '../../../lib/state';
import { hexOpacity } from '../../../lib/utils';
import { useMeasurePaint, useMeasureRender, useMeasureRoute } from '../../../lib/measurement';
import { GlideButton } from '../../../components/GlideButton';

// Styled components
const StyledFlex = styled(Flex)`
  padding-bottom: 2rem;
`;

const StyledBox = styled(Box)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    box-shadow: -15px -15px 25px 0px ${({ theme }) => hexOpacity(theme.palette.white, 0.9)},
      10px 10px 15px 4px ${({ theme }) => hexOpacity(theme.palette.shadow, 0.1)};
    border-radius: ${({ theme }) => theme.radius.xl};

    padding: 1rem 1.5rem;
  }
`;

const RequestFormPaddedContainer = styled(Box)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding: 2rem 2rem;
      padding-top: 0;
    }
  }
`;

const ResponsiveFlex = styled(Flex)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      gap: 3rem;
    }
  }
`;

function RequestFormContainer() {
  // State
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [hasRequestFailed, setRequestFailed] = useState(false);
  const { isTablet } = useDeviceResolution();
  const showPageTitle = (!hasRequestFailed && !isRequestSent) || isTablet;

  const { currentTeam } = useAuthService();
  const [teamSettings] = useTeamSettings(currentTeam.id);
  const { navigate } = useNavigation();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  // If either of the conditions are false, the Request Access button navigates the user to the legacy Request Access Flow
  const shouldRedirectToCreateAccountFlow = teamSettings.trustShare?.nda?.isAutoApproveAccessRequestEnabled;

  useEffect(() => {
    if (isRequestAccessHidden) {
      navigate('/home', { replace: true });
    } else if (shouldRedirectToCreateAccountFlow) {
      navigate('/create-account', { replace: true });
    }
  }, []);

  return (
    <>
      {showPageTitle && <PageTitle>Request Access</PageTitle>}
      <StyledBox>
        <Stack space="lg">
          <RequestFormPaddedContainer>
            <RequestAccessForm
              onRequestSent={() => setIsRequestSent(true)}
              onRequestFailed={() => setRequestFailed(true)}
              onRequestReset={() => {
                setIsRequestSent(false);
                setRequestFailed(false);
              }}
            />
          </RequestFormPaddedContainer>
        </Stack>
      </StyledBox>
    </>
  );
}

function SSOError() {
  return (
    <>
      <Helmet pageTitle="Request Access" />
      <StyledBox>
        <ErrorTitleLabel>Sign in with SSO failed</ErrorTitleLabel>
        <Stack space="xl">
          <ErrorDescriptionLabel>
            Oops! This email is not associated with a TrustShare account. You may not have created an account, or your
            invitation or account may have expired. To request an invitation, please fill out the Access Request form.
          </ErrorDescriptionLabel>
          <StyledFlex justifyContent="flex-end">
            <GlideButton
              as={Link}
              href="/request-access"
              variant="primary"
            >
              Request Access
            </GlideButton>
          </StyledFlex>
        </Stack>
      </StyledBox>
    </>
  );
}

export const RequestAccess = withTrustShareNavigationBar(({ showError = false }) => {
  const {
    data: { span },
  } = useCurrentRoute();
  span.setAttribute('page-name', 'request-access');
  useMeasureRoute(span);

  if (showError) {
    return <SSOError />;
  }

  const tree = (
    <ResponsiveContainer
      as={ResponsiveFlex}
      direction="column"
      gap="1rem"
    >
      <Helmet pageTitle="Request Access" />
      <RequestFormContainer />
    </ResponsiveContainer>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});
