import React from 'react';
import { View } from 'react-navi';
import { ThemeProvider } from '@emotion/react';

import * as Styles from './EnterpriseAppLayout.styles';
import NavigationBarV2 from '../NavigationBarV2/NavigationBarV2';
import FooterV2 from '../FooterV2/FooterV2';
import { ErrorBoundary } from '../ErrorBoundary';
import { useCustomerTheme, useProgramContent } from '../../lib/state';

function EnterpriseAppLayout() {
  const [{ headerStyleConfig }] = useProgramContent();
  const customerTheme = useCustomerTheme(headerStyleConfig);
  return (
    <ThemeProvider theme={customerTheme}>
      <Styles.AppContainer data-testid="enterprise-app-container">
        <ErrorBoundary>
          <NavigationBarV2 />
          <View />
          <FooterV2 />
        </ErrorBoundary>
      </Styles.AppContainer>
    </ThemeProvider>
  );
}

export default EnterpriseAppLayout;
