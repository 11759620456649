import styled from '@emotion/styled';

export const StatementContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  /* Ensure the content fits within 1080px */
  width: 1080px;

  padding-left: 6rem;
  padding-right: 6rem;

  margin: 0 auto;

  text-align: center;

  & .statement-primary {
    font-weight: 800;
  }
`;
