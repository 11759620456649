import React from 'react';
import { Heading } from '@kintent/glide';

import { DashboardTrustLeaderItem } from './DashboardTrustLeaderItem';
import * as Styles from './DashboardTrustLeadersSection.styles';
import { SpaceAround } from '../../../../design-system';
import { Flex } from '../../../../components/Flex';
import { useAuthService, useFeatureFlag, useProgramContent, useTeamLeaderList } from '../../../../lib/state';
import { FEATURE_FLAG } from '../../../../lib/featureFlags';

function DashboardTrustLeadersSection() {
  const { currentTeam } = useAuthService();
  const [trustLeaders] = useTeamLeaderList(currentTeam.id);
  const [{ isLeaderVisible, leaderDescription }] = useProgramContent();

  const shouldAutoAlignTrustLeaders = useFeatureFlag(FEATURE_FLAG.AUTO_ALIGN_TRUST_LEADERS);

  if (!isLeaderVisible || !trustLeaders.length) {
    return null;
  }

  /**
   * reformarts the trustLeaders array in such a way that
   * - 5 or less items align in a single row
   * - 5 to 10 will be divided in 2 rows (3+3, 3+4, 4+4, 4+5, 5+5)
   * - more than 10 will follow the same pattern
   * Formula used to calculate the number of rows:
   * R = Math.ceil(n / 5) where n is the number of items
   * Formula used to calculate the number of items in a row:
   * rowLength = Math.floor(n / R) + (n % R > 0 ? 1 : 0) where n is the number of items and R is the number of rows
   * @returns {Array} - an array of arrays where each inner array represents a row
   */
  function formatTrustLeaders() {
    const n = trustLeaders.length;
    const R = Math.ceil(n / 5);

    const formattedArray = [];

    for (let i = 0; i < R; i++) {
      const rowLength = Math.floor(n / R) + (n % R > 0 ? 1 : 0);
      const rowItems = trustLeaders.slice(i * rowLength, (i + 1) * rowLength);
      formattedArray.push(rowItems);
    }

    return formattedArray;
  }

  const getCardWidth = (rowLength) => {
    if (rowLength === 1) return '282px';
    if (rowLength === 2) return '588px';
    if (rowLength === 3) return '384px';
    if (rowLength === 4) return '282px';
    if (rowLength === 5) return '220.8px';
    return '282px';
  };

  return (
    <>
      <Styles.TrustLeadersSectionContainer as="section">
        <Flex
          direction="column"
          gap="16px"
        >
          <Flex
            gap="16px"
            alignItems="center"
          >
            <Styles.CountBadge variant="primary">{trustLeaders.length}</Styles.CountBadge>
            <Heading level="3">Trust Leadership</Heading>
          </Flex>
          <SpaceAround bottom="8px">
            <Styles.DescriptionLabel level="8">{leaderDescription}</Styles.DescriptionLabel>
          </SpaceAround>
          {shouldAutoAlignTrustLeaders ? (
            formatTrustLeaders().map((row) => (
              <Styles.FlexContainerAutoAlign
                key={row[0].id}
                gap="24px"
                wrap
              >
                {row.map((trustLeader) => (
                  <DashboardTrustLeaderItem
                    key={trustLeader.id}
                    name={`${trustLeader.firstName} ${trustLeader.lastName}`}
                    trustLeader={trustLeader}
                    cardWidth={getCardWidth(row.length)}
                  />
                ))}
              </Styles.FlexContainerAutoAlign>
            ))
          ) : (
            <Styles.FlexContainer
              gap="24px"
              wrap
            >
              {trustLeaders.map((trustLeader) => (
                <DashboardTrustLeaderItem
                  key={trustLeader.id}
                  name={`${trustLeader.firstName} ${trustLeader.lastName}`}
                  trustLeader={trustLeader}
                />
              ))}
            </Styles.FlexContainer>
          )}
        </Flex>
      </Styles.TrustLeadersSectionContainer>
    </>
  );
}

export default DashboardTrustLeadersSection;
