import React from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';

import { hexOpacity } from '../lib/utils';

// Constants
export const PULSATING_DOT_VARIANTS = {
  exit: {
    opacity: 0,
    scale: [0.9, 0.1, 0],
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

// Styled components
const PulsatingDot = styled(motion.div)`
  height: 12px;
  width: 12px;

  position: relative;
  z-index: 1;

  border-radius: 100%;
  background: ${({ theme }) => `linear-gradient(130deg, ${theme.palette.jade} 15%, ${theme.palette.forest} 85%)`};

  cursor: pointer;

  &:after {
    position: absolute;
    z-index: -1;

    content: '';

    display: block;
    width: 100%;
    height: 100%;

    transform-origin: center;

    border-radius: 100%;

    animation-duration: 2560ms;
    animation-iteration-count: infinite;
    animation-name: pulsate;
    animation-timing-function: linear;

    box-shadow: 0 0 0 8px ${({ theme }) => hexOpacity(theme.palette.jade, 0.3)};
  }

  @keyframes pulsate {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    66% {
      opacity: 1;
      transform: scale(0.8);
    }

    99% {
      opacity: 0;
      transform: scale(0.9);
    }

    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
`;

export default PulsatingDot;
