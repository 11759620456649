import styled from '@emotion/styled';
import { base, flat, rgb, rgba, translucent } from '@kintent/glide';

import { GlideButton } from '../../../../components/GlideButton';

export const Card = styled.div`
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  border-radius: 8px;

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '10%')};

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 20px 24px 44px 24px;
  position: relative;
  background-color: white;

  /* Modify the height of the tag with a classname instead of creating a styled component */
  & .status-tag {
    height: fit-content;
    color: ${({ theme }) => flat(theme.color.system.positive)};
    border-color: ${({ theme }) => flat(theme.color.system.positive)};

    & > span {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    & .status-badge {
      height: 8px;
      width: 8px;

      border-radius: 50%;
      background-color: ${({ theme }) => flat(theme.color.system.positive)};
    }
  }

  transition: border-color 300ms ease-in;
`;

export const LogoWrapper = styled.div`
  height: 56px;
  width: 56px;

  & > img {
    height: auto;
    width: 100%;
  }
`;

export const Overlay = styled.div`
  background: ${({ theme }) => rgb(theme.color.system.gray['20%'], '50%')};
  border-radius: 6px;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 8px;

  width: 100%;
  height: 100%;

  opacity: 0;

  & > button,
  a {
    transform: translateY(-8px);
    transition: transform 150ms ease-in 50ms;
    text-decoration: none;
  }

  &:hover {
    opacity: 1;
    background-color: ${({ theme }) => rgba(theme.color.system.gray, '5%')};

    & > button,
    a {
      transform: translateY(0);

      &:hover {
        text-decoration: underline;
      }
    }
  }
  border: 1px solid ${({ theme }) => theme.components.header.primaryCTAButton.background};
  transition: opacity 250ms ease-in;
`;

// This style is to ensure that we make the button appear as a link. We're not using an anchor tag because we invoke an API when the button is clicked
export const LinkButton = styled(GlideButton)`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
