import React from 'react';
import styled from '@emotion/styled';
import { base, flat, translucent } from '@kintent/glide';

import { Flex } from './Flex';
import { BREAKPOINTS } from '../lib/constants';

const CardContainer = styled(Flex)`
  padding: 16px;

  background-color: ${({ theme }) => theme.palette.white};

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '20%')};
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};

  border-radius: 8px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    padding: 32px;
  }

  & .additional-soc-criteria-readiness {
    padding-top: 40px;
  }
`;

export default CardContainer;
