import styled from '@emotion/styled';
import { color } from '@kintent/glide';

export const ContentContainer = styled.section`
  background-color: ${({ theme }) => color(theme.colorV2.system.gray, 100)};

  grid-area: content;

  padding: 4rem 4rem 2rem 4rem;
`;

export const MasonryGrid = styled.div`
  display: grid;
  grid-template-areas:
    'primary notifications'
    'documents documents';
  grid-template-columns: 1fr 1fr;
  gap: 64px;

  width: 1080px;
  margin-left: auto;
  margin-right: auto;

  padding-top: 6rem;
`;
