import styled from '@emotion/styled';
import { base, Button, flat, rgb, translucent, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import { hexOpacity } from '../../../../lib/utils';

export const FlexItem = styled.div`
  height: 56px;
  padding: 16px;

  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  border-radius: 8px;

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '10%')};
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }
`;

export const Overlay = styled.div`
  background: ${({ theme }) => rgb(theme.color.system.gray['20%'], '50%')};
  border-radius: 6px;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;

  opacity: 0;

  & > button,
  a {
    transform: translateY(-4px);
    transition: transform 100ms ease-in 50ms;
  }

  &:hover {
    opacity: 1;

    & > button,
    a {
      transform: translateY(0);
    }
  }

  transition: opacity 250ms ease-in;
`;

export const FallbackLabel = styled(Typography)`
  width: 20px;
  height: 20px;

  background-color: ${({ theme }) => flat(theme.color.category.forest, '5%')};
  border: 1px solid ${({ theme }) => flat(theme.color.system.positive, '40%')};
  border-radius: 8px;
  padding-bottom: 20px;
  text-decoration: none;
  text-align: center;
`;

export const LogoWrapper = styled(Flex)`
  width: 20px;
  height: 20px;

  & > img {
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
  }
`;

export const LinkButton = styled(Button)`
  color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    border-color: ${({ theme }) => hexOpacity(theme.components.header.primaryCTAButton.background, 0.2)};
  }
`;

export const NameTypography = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray)};
`;
