import React from 'react';
import { useCurrentRoute } from 'react-navi';
import { Tag, Typography } from '@kintent/glide';
import { format } from 'date-fns';

import * as Styles from './NotificationDetails.styles';
import { Helmet } from '../../../components/Helmet';
import { useTrustShareNotifications } from '../../../lib/state';
import { TRUSTSHARE_NOTIFICATION_TYPE_LABELS } from '../../../lib/constants';

function NotificationDetails() {
  const {
    data: { notificationId },
  } = useCurrentRoute();
  const { data: allNotifications } = useTrustShareNotifications();

  const notification = allNotifications?.find((item) => item.id === notificationId);
  if (notification == null) return null;

  const formattedDate = format(new Date(notification._metadata.createdDate), 'EEEE, MMMM d, yyyy');

  return (
    <>
      <Helmet pageTitle="Notification Details" />
      <Styles.MainGrid data-testid="enterprise-main-notification-detail-layout">
        <Styles.NotificationDetailsContainer>
          <Styles.NotificationDetailHeader>
            <Typography
              className="notification-detail-title"
              as="h4"
              level="4"
            >
              {notification.title}
            </Typography>
            <Styles.NotificationDetailsMetadata>
              <Tag>{TRUSTSHARE_NOTIFICATION_TYPE_LABELS[notification.type]}</Tag>
              <Typography
                as="p"
                strength="60%"
                level="8"
              >
                {formattedDate}
              </Typography>
            </Styles.NotificationDetailsMetadata>
          </Styles.NotificationDetailHeader>
          <Typography
            as="p"
            strength="80%"
            level="8"
            dangerouslySetInnerHTML={{ __html: notification.description }}
            className="notification-detail-description"
          />
        </Styles.NotificationDetailsContainer>
      </Styles.MainGrid>
    </>
  );
}

export default NotificationDetails;
