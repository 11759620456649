import styled from '@emotion/styled';
import { Link } from 'react-navi';

export const BrandedLink = styled(Link)`
  text-decoration: underline;

  & > svg,
  &:hover {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }
`;
