import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import {
  unstable_Form as BaseForm,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_useFormState as useFormState,
} from 'reakit';
import { Flex } from 'components/Flex';
import { useCurrentRoute } from 'react-navi';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '@kintent/glide';

import { NONCE_STORAGE_KEY } from '../../components/Google';
import { Helmet } from '../../components/Helmet';
import { CardTitleLabel } from '../../components/PageElements';
import { FormContext, FormInput, FormMessage, FormTextArea } from '../../design-system';
import { api } from '../../lib/api';
import { SSO_PROVIDER, USER_TYPE } from '../../lib/constants';
import { CREATE_ACCOUNT_MESSAGES } from '../../lib/errors/ApiError';
import { useAuthService, useSelfServiceProspectUser } from '../../lib/state';
import { parseJwt } from '../../lib/utils';
import { AuthenticationCard } from '../user/components/AuthFlowComponents';
import CreateAccountMessage from './CreateAccountMessage';
import { GlideButton } from '../../components/GlideButton';

// Styled components
const AccountDetailFormContainer = styled.div`
  margin-top: 44px;
`;

const FormStack = styled.div`
  display: grid;
  gap: 24px;

  padding-left: 68px;
  padding-right: 68px;
  padding-bottom: 24px;
`;

const handleShouldDisableSubmitButton = (companyName, companyWebsite, businessPurpose) =>
  companyName === '' || companyWebsite === '' || businessPurpose === '';

const isInvalidTokenError = (ssoToken, isSsoProviderMicrosoft, jwt, url, ssoCreateAccountError) =>
  (ssoToken && !isSsoProviderMicrosoft && !jwt) || (ssoToken && !url.query.provider) || ssoCreateAccountError;

const CompleteAccountSetup = (props) => {
  const { verificationToken, ssoToken } = props;
  const { url } = useCurrentRoute();
  const companyWebSiteRef = useRef();
  const { currentTeam, publicTeamId, updateAuth, selectTeam } = useAuthService();

  const [isLinkVerified, setLinkVerified] = useState(false);
  const { data: verificationData, error } = useSelfServiceProspectUser(
    isLinkVerified ? null : verificationToken || null
  );

  const [ssoCreateAccountError, setSsoCreateAccountError] = useState(null);
  const ssoProvider = url.query.provider || null;
  const isSsoProviderMicrosoft = ssoProvider === SSO_PROVIDER.MICROSOFT;
  const jwt = isSsoProviderMicrosoft ? null : parseJwt(verificationToken || ssoToken);

  useEffect(() => {
    if (verificationData) {
      setLinkVerified(true);
    }
  }, [verificationData]);

  const accountDetailForm = useFormState({
    onSubmit: async (values) => {
      if (ssoToken) {
        const nonce = isSsoProviderMicrosoft ? uuidv4() : sessionStorage.getItem(NONCE_STORAGE_KEY);
        try {
          const response = await api.user.create({
            token: ssoToken,
            provider: url.query.provider,
            company: values.companyName,
            businessPurpose: values.businessPurpose,
            companyWebsite: values.companyWebsite,
            teamId: currentTeam.id,
            nonce,
            userType: USER_TYPE.TEMPORARY_GUEST,
            isSSOUser: true,
          });
          // user already exits
          const { user, token } = await api.auth.loginWithSSO(url.query.provider, ssoToken, null, response.userType);
          await selectTeam(publicTeamId, token);
          updateAuth(token, user);
          window.location.href = '/home';
        } catch (e) {
          if (Object.values(CREATE_ACCOUNT_MESSAGES).includes(e?.sourceError?.error)) {
            setSsoCreateAccountError(e?.sourceError?.error);
          } else {
            setSsoCreateAccountError(CREATE_ACCOUNT_MESSAGES.SOMETHING_WENT_WRONG);
          }
        }
      } else {
        const { user, sessionToken } = await api.trustshare.updateProspectUserCompany({
          ...values,
          verificationToken,
        });
        await selectTeam(publicTeamId, sessionToken);
        // Attempt to login via email/password was successful and update the authService as needed
        updateAuth(sessionToken, user);
        window.location.href = '/home';
      }
    },
    onValidate: ({ companyName, businessPurpose }) => {
      const errors = {};

      if (companyName.trim().length === 0) {
        errors.companyName = 'Please enter your company name';
      }

      if (businessPurpose.trim().length === 0) {
        errors.businessPurpose = `Please enter a reason for requesting access to ${currentTeam.name}’s TrustShare`;
      }

      if (companyWebSiteRef.current.checkValidity() === false) {
        errors.companyWebsite = 'Please enter a valid website';
      }

      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    values: {
      companyName: '',
      companyWebsite: '',
      businessPurpose: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  // This is needed as form.valid is not reliable
  const { companyName, companyWebsite, businessPurpose } = accountDetailForm.values;
  const shouldDisableSubmitButton = handleShouldDisableSubmitButton(companyName, companyWebsite, businessPurpose);

  if (!ssoToken && error) {
    // The message could be success or failure.
    return (
      <CreateAccountMessage
        email={jwt?.email}
        message={error?.sourceError?.error}
      />
    );
  }

  if (isInvalidTokenError(ssoToken, isSsoProviderMicrosoft, jwt, url, ssoCreateAccountError)) {
    // Invalid token being passed in
    return (
      <CreateAccountMessage
        email={null}
        message={ssoCreateAccountError || CREATE_ACCOUNT_MESSAGES.INVALID_TOKEN}
      />
    );
  }

  return (
    <>
      <Helmet pageTitle="Complete Account Setup" />
      <AuthenticationCard>
        <CardTitleLabel>Complete Account Setup</CardTitleLabel>
        <AccountDetailFormContainer>
          <FormContext.Provider value={accountDetailForm}>
            <BaseForm {...accountDetailForm}>
              <FormStack>
                <div>
                  <FormInput
                    name="companyName"
                    placeholder="Company name*"
                    required
                  />
                  <FormMessage name="companyName" />
                </div>
                <div>
                  <FormInput
                    name="companyWebsite"
                    type="url"
                    placeholder="Company website*"
                    required
                    ref={companyWebSiteRef}
                  />
                  <FormMessage name="companyWebsite" />
                </div>
                <div>
                  <FormTextArea
                    name="businessPurpose"
                    placeholder="Business purpose*"
                    required
                  />
                  <Flex
                    direction="column"
                    gap={2}
                  >
                    <FormMessage name="businessPurpose" />
                    <Typography level="9">Example: Performing security due diligence</Typography>
                  </Flex>
                </div>
                <Flex justifyContent="end">
                  <FormSubmitButton
                    as={GlideButton}
                    variant="primary"
                    disabled={shouldDisableSubmitButton || !accountDetailForm.valid || accountDetailForm.submitting}
                  >
                    Submit
                  </FormSubmitButton>
                </Flex>
              </FormStack>
            </BaseForm>
          </FormContext.Provider>
        </AccountDetailFormContainer>
      </AuthenticationCard>
    </>
  );
};

export default CompleteAccountSetup;
