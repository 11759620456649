import { useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';

function useSegmentProvider() {
  const [segmentId, setSegmentId] = useState(null);

  const result = useMemo(
    () => ({
      segmentId,
      setSegmentId,
    }),
    [segmentId, setSegmentId]
  );

  return result;
}

export const SegmentProvider = createContainer(useSegmentProvider);
