import styled from '@emotion/styled';
import { base, color } from '@kintent/glide';

export const MainGrid = styled.main`
  grid-area: main;

  background-color: ${({ theme }) => color(theme.colorV2.system.gray, 100)};

  min-height: 100vh;

  padding: 12rem 4rem 4rem 4rem;
`;

export const NotificationContainer = styled.section`
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  background-color: white;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 600)};

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => color(theme.colorV2.system.gray, 100)};

  padding: 48px;
`;

export const UnstyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;

  list-style: none;
`;
