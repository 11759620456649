import styled from '@emotion/styled';
import { flat, rgb } from '@kintent/glide';
import { Link } from 'react-navi';

import { Flex } from '../../../../components/Flex';

export const NotificationCardWrapper = styled(Flex)`
  background-color: ${({ theme }) => rgb(theme.color.system.white)};
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const NotificationType = styled(Flex)`
  padding: 4px 8px;
  border-radius: 4px;

  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '80%')};
`;

export const NotificationLink = styled(Link)`
  color: ${({ theme }) => flat(theme.color.brand.primary)};
  cursor: pointer;
  text-underline-offset: 2px;

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
