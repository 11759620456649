import React, { useMemo } from 'react';
import { Link } from 'react-navi';
import { UilFileAlt as ResourceIcon } from '@iconscout/react-unicons';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Heading, Typography } from '@kintent/glide';

import { useAuthService, useContentVisibility, useControlList, usePolicyList } from '../../../../lib/state';
import { Flex } from '../../../../components/Flex';
import CardContainer from '../../../../components/CardContainer';
import CountBox from '../../../../components/CountBox';

const StyledPolicyCard = styled(Flex)`
  border-radius: 100px;
  width: fit-content;
  padding: 4px 6px 4px 4px;

  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  text-decoration: none;
`;

const ControlMappingSectionDescription = styled(Typography)`
  line-height: 1.7;
`;

const StyledTypography = styled(Typography)`
  color: #fff;

  text-transform: uppercase;
`;

const getPoliciesForSubprocessor = ({ selectedSubprocessor, controls, policies }) => {
  const controlsForSubprocessor = selectedSubprocessor.controls.map((control) => control.shortName);

  const matchedControls = controls.filter((control) => controlsForSubprocessor.includes(control.shortName));

  const allPolicyIds = matchedControls.map((control) => control.relatedPolicyIds).flat();

  const uniquePolicyIds = new Set(allPolicyIds);

  return policies.filter((policy) => uniquePolicyIds.has(policy.id));
};

function SubprocessorPolicySection({ selectedSubprocessor }) {
  const theme = useTheme();
  const { currentTeam } = useAuthService();
  const [controls] = useControlList();
  const [policies] = usePolicyList();

  const associatedPolicies = useMemo(
    () => getPoliciesForSubprocessor({ selectedSubprocessor, controls, policies }),
    [selectedSubprocessor, controls, policies]
  );

  const { shouldHidePolicyDetail: shouldHideLinkForPolicyDetails } = useContentVisibility();

  if (associatedPolicies.length === 0) return null;

  return (
    <CardContainer
      direction="column"
      gap="16px"
    >
      <Flex
        gap="8px"
        alignItems="center"
      >
        <CountBox count={associatedPolicies.length} />
        <Heading level="5">Policy Mappings</Heading>
      </Flex>
      <ControlMappingSectionDescription
        as="p"
        level="8"
      >
        This subprocessor maps to the following policies in the {currentTeam.name} compliance program:
      </ControlMappingSectionDescription>
      <Flex
        gap="8px"
        wrap
      >
        {associatedPolicies.map((policy) => {
          let policyProps = {};
          if (!shouldHideLinkForPolicyDetails) {
            policyProps = {
              href: `/policies/${encodeURIComponent(policy.shortName)}`,
              as: Link,
            };
          }
          return (
            <StyledPolicyCard
              alignItems="center"
              justifyContent="start"
              key={policy.id}
              {...policyProps}
            >
              <ResourceIcon
                height={15}
                color={theme.palette.white}
              />
              <StyledTypography level="9">{policy.title}</StyledTypography>
            </StyledPolicyCard>
          );
        })}
      </Flex>
    </CardContainer>
  );
}

export default SubprocessorPolicySection;
