// Package modules
import React from 'react';
import styled from '@emotion/styled';
import { useCurrentRoute } from 'react-navi';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '@kintent/glide';

// Local modules
import { Button } from '../design-system';
import { GOOGLE_SSO_CLIENT_ID, GOOGLE_SSO_ENDPOINT, HOST, SSO_PROVIDER } from '../lib/constants';

// Assets
import googleBgImage from '../assets/sso/btn_google_signin_dark_normal_web@2x.png';

// Constants.
export const NONCE_STORAGE_KEY = 'sso.google.nonce';

// Styled components
const GoogleSigninLink = styled(Button)`
  outline-offset: 1px;

  border-width: 0;
  border-radius: 0;
  width: 205px;
  height: 45px;

  background-image: url(${googleBgImage});
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    box-shadow: 0 2px 3.5px 0 rgba(29, 94, 83, 0.29), 0 2.5px 11.5px 0 rgba(49, 81, 76, 0.2),
      0 0.3px 0.8px 0.1px rgba(8, 29, 26, 0.26);
  }

  &:active,
  &:focus {
    box-shadow: none;
    outline: 2px solid ${({ theme }) => theme.palette.navy};
  }
`;

const StyledTypography = styled(Typography)`
  position: absolute;

  clip: rect(0, 0, 0, 0);
  overflow: hidden;

  border-width: 0;
  height: 1px;
  margin: -1px;
  padding: 0;
  width: 1px;

  white-space: nowrap;
`;

export function GoogleSso(props) {
  const { url } = useCurrentRoute();

  const googleOAuthLogin = async () => {
    // Generate and store nonce.
    const nonce = uuidv4();
    sessionStorage.setItem(NONCE_STORAGE_KEY, nonce);

    const pathname = url.pathname.replace(/\/$/, '');
    const state = {
      provider: SSO_PROVIDER.GOOGLE,
      trustShareProgramUrl: `${window.location.protocol}//${window.location.host}${pathname}`,
    };

    // Pass invitation token as part of the state.
    if ('invitation' in url.query) {
      state.invitation = url.query.invitation;
    }

    const qs = new URLSearchParams({
      client_id: GOOGLE_SSO_CLIENT_ID,
      redirect_uri: `${HOST}/auth/trustshare-oauth`,
      response_type: 'code',
      scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ].join(' '),
      state: btoa(JSON.stringify(state)),
      nonce,
    });

    window.location.href = `${GOOGLE_SSO_ENDPOINT}${qs}`;
  };

  return (
    <GoogleSigninLink
      variant="quiet"
      onClick={googleOAuthLogin}
      {...props}
    >
      <StyledTypography>Sign in with Google</StyledTypography>
    </GoogleSigninLink>
  );
}
