import React, { useMemo, useState } from 'react';
import { Typography } from '@kintent/glide';

import * as Styles from './CertificationSection.styles';
import CustomTag from '../../../../../components/CustomTag/CustomTag';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import { useAuthService, useProgramContent, useTeamCertificationList } from '../../../../../lib/state';
import {
  DOCUMENT_ACCESS_LEVEL,
  getCertificationCardTitle,
  getCertificationLogo,
  getCertificationLogoSrcSet,
} from '../../../../../lib/constants';
import { getDefaultCertificationDescription, sortByProp } from '../../../../../lib/utils';

// Display certifications that are public and sort them based on the sort order prescribed
function getPublicCertifications(allCertifications) {
  return allCertifications
    .filter((certification) => certification.trustShareAccessLevel === DOCUMENT_ACCESS_LEVEL.PUBLIC)
    .sort(sortByProp('sortOrder'));
}

function CertificationSection() {
  const { currentTeam } = useAuthService();
  const [allCertifications] = useTeamCertificationList(currentTeam.id);
  const [{ maxCertificationLimit }] = useProgramContent();
  const [showAll, setShowAll] = useState(false);

  const publicCertifications = useMemo(() => getPublicCertifications(allCertifications), [allCertifications]);

  const handleToggleCertifications = () => {
    setShowAll((prevVal) => !prevVal);
  };

  if (publicCertifications.length === 0) return null;

  const publicCertificationsToDisplay = showAll
    ? publicCertifications
    : publicCertifications.slice(0, maxCertificationLimit);
  const remainingCertificationCount = publicCertifications.length - maxCertificationLimit;

  return (
    <Styles.CertificationSectionContainer>
      <Styles.CertificationSectionHeader>
        <Typography
          as="h5"
          level="5"
          className="certifications-section-title"
        >
          Certifications
        </Typography>
        <CustomTag>{publicCertifications.length}</CustomTag>
      </Styles.CertificationSectionHeader>
      <Styles.CertificationListGrid>
        {publicCertificationsToDisplay.map((listItem) => {
          return (
            <Styles.ListCard key={listItem.id}>
              <Styles.LogoWrapper>
                <img
                  alt="Certification Logo"
                  src={getCertificationLogo(listItem.certification.shortName, listItem.subtype)}
                  srcSet={getCertificationLogoSrcSet(listItem.certification.shortName)}
                />
              </Styles.LogoWrapper>
              <div>
                <Styles.CertificationCardTitle as="p">
                  {getCertificationCardTitle(listItem.certification.shortName, listItem.subtype)}
                </Styles.CertificationCardTitle>
                <Typography
                  level="8"
                  strength="60%"
                >
                  {listItem.description ??
                    getDefaultCertificationDescription(
                      currentTeam?.name,
                      listItem.certification.shortName,
                      listItem.status,
                      listItem.subtype
                    )}
                </Typography>
              </div>
            </Styles.ListCard>
          );
        })}
      </Styles.CertificationListGrid>
      {remainingCertificationCount > 0 && (
        <Styles.ShowMoreContainer>
          <CustomButton onClick={handleToggleCertifications}>
            {showAll ? 'Show Less' : `Show ${remainingCertificationCount} More`}
          </CustomButton>
        </Styles.ShowMoreContainer>
      )}
    </Styles.CertificationSectionContainer>
  );
}

export default CertificationSection;
