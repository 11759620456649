// Package modules
import React, { useState } from 'react';

import { useNavigation } from 'react-navi';
import { Heading } from '@kintent/glide';
import { UilEnvelopeAlt, UilKeySkeleton } from '@iconscout/react-unicons';

// Local modules
import { FEATURE_FLAG } from 'lib/featureFlags';
import { useAuthService, useFeatureFlag, useProgramContent } from 'lib/state';
import * as Styles from './ContactUsSection.styles';
import ContactUsDialog from './ContactUsDialog';

function ContactUsSection() {
  const [contactDialog, setContactDialog] = useState(false);
  const { currentTeam, authenticatedUser } = useAuthService();
  const [{ headerStyleConfig }] = useProgramContent();
  const navigation = useNavigation();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const handleClick = () => {
    if (authenticatedUser != null) {
      setContactDialog(true);
    } else {
      navigation.navigate('/request-access');
    }
  };

  return (
    <Styles.Container backgroundGradientColor={headerStyleConfig.gradientBackground?.backgroundColor}>
      <Styles.StyledSection as="section">
        <Styles.StyledFlex
          direction="column"
          gap="16px"
        >
          <Heading level="3">Interested in Learning More?</Heading>
          {isRequestAccessHidden ? (
            <Styles.DescriptionLabel level="8">
              Contact us and we can share more information about our trust and compliance program.
            </Styles.DescriptionLabel>
          ) : (
            <Styles.DescriptionLabel level="8">
              {authenticatedUser == null ? 'Request access' : 'Contact us'} and we can share more information about our
              trust and compliance program.
            </Styles.DescriptionLabel>
          )}
          {!isRequestAccessHidden && currentTeam?.contactUsEmail && (
            <Styles.StyledActionButton
              iconPosition="start"
              icon={authenticatedUser != null ? <UilEnvelopeAlt /> : <UilKeySkeleton />}
              onClick={handleClick}
            >
              {authenticatedUser != null ? 'Contact Us' : 'Request Access'}
            </Styles.StyledActionButton>
          )}
        </Styles.StyledFlex>
      </Styles.StyledSection>
      {contactDialog && (
        <ContactUsDialog
          isOpen={contactDialog}
          setIsOpen={setContactDialog}
          currentTeam={currentTeam}
        />
      )}
    </Styles.Container>
  );
}

export default ContactUsSection;
