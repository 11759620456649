import React, { useEffect } from 'react';

import { useLocalStorage } from 'lib/hooks';
import { useCurrentRoute } from 'react-navi';
import DashboardDocuments from './components/DashboardDocuments';
import Hero from './components/Hero';
import DashboardCertificationSection from './components/DashboardCertificationSection';
import DashboardControlsSection from './components/ControlsSection';
import DashboardPolicySection from './components/DashboardPolicySection';
import DashboardSubprocessorsSection from './components/DashboardSubprocessorsSection';
import DashboardTrustLeadersSection from './components/DashboardTrustLeadersSection';
import ContactUsSection from './components/ContactUsSection';
import { Helmet } from '../../../components/Helmet';
import { FloatingRequestAccessButton } from '../../../components/FloatingRequestAccessButton';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { useAuthService, useContentVisibility } from '../../../lib/state';
import { TRUST_SHARE_ACCESS_LEVELS } from '../../../lib/constants';
import CustomizeBanner from './components/CustomizeTrustshareBanner';
import DashboardNotificationCenterSection from './components/DashboardNotificationCenterSection';

function Home() {
  const { setValue } = useLocalStorage('ssob');
  const { authenticatedUser, currentTeam } = useAuthService();
  const isTransparentAccessLevel = currentTeam.trustShareAccessLevel === TRUST_SHARE_ACCESS_LEVELS.TRANSPARENT;
  const { shouldHideControlOverview: shouldHideControls } = useContentVisibility();
  const { shouldHidePolicyOverview: shouldHidePolicies } = useContentVisibility();
  const { url } = useCurrentRoute();

  useEffect(() => {
    if (url.query.ssob) {
      setValue(true);
    }
  }, []);

  return (
    <>
      <Helmet pageTitle="Home" />
      <Hero />
      <DashboardCertificationSection />
      <DashboardDocuments />
      {(isTransparentAccessLevel || authenticatedUser) && <>{!shouldHidePolicies && <DashboardPolicySection />}</>}
      {(isTransparentAccessLevel || authenticatedUser) && <>{!shouldHideControls && <DashboardControlsSection />}</>}
      <DashboardSubprocessorsSection />
      <DashboardTrustLeadersSection />
      <DashboardNotificationCenterSection />
      <FloatingRequestAccessButton />
      <ContactUsSection />
      {authenticatedUser?.id && <CustomizeBanner />}
    </>
  );
}

export default withTrustShareNavigationBar(Home);
