import React, { createContext, forwardRef, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import {
  Button as BaseButton,
  unstable_FormCheckbox as BaseFormCheckbox,
  unstable_FormInput as BaseFormInput,
  unstable_FormLabel as BaseFormLabel,
  unstable_FormMessage as BaseFormMessage,
  Box,
  VisuallyHidden,
} from 'reakit';
import { Typography } from '@kintent/glide';
import { UilEyeSlash as HidePasswordIcon, UilEye as ShowPasswordIcon } from '@iconscout/react-unicons';

// Local modules
import { Checkbox } from '../components/Checkbox';
import { hexOpacity } from '../lib/utils';
import { BREAKPOINTS } from '../lib/constants';

const FormInputWrapper = styled(Box)`
  position: relative;
  width: 100%;

  &:focus-within {
    &:before {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      border-radius: ${({ theme }) => theme.radius.lg};
      border: 1px solid ${({ theme }) => theme.palette.navy};
    }
  }

  & > button {
    all: unset;

    position: absolute;
    top: 0;
    right: 14px;
    bottom: 0;

    color: ${({ theme }) => theme.palette.elephant};
    line-height: 100%;

    cursor: pointer;
  }
`;

const StyledFormInput = styled(BaseFormInput)`
  width: 100%; // Fill parent
  font-size: 0.875rem;

  color: ${({ theme }) => theme.palette.navy};
  border: 1px solid ${({ theme }) => theme.palette.arctic};
  border-radius: ${({ theme }) => theme.radius.lg};

  padding: 0.75rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.granite};
  }

  &[aria-invalid='true'] {
    border-color: ${({ theme }) => theme.palette.pimento};
  }

  &[aria-disabled='true'] {
    background-color: ${({ theme }) => hexOpacity(theme.palette.shadow, 0.1)};
  }
`;

const StyledFormMessage = styled(BaseFormMessage)`
  margin-top: 4px;

  color: ${({ theme }) => theme.palette.pimento};
`;

const StyledFormTextArea = styled(StyledFormInput)`
  display: block;
  resize: none;
`;

const StyledHidePasswordIcon = styled(HidePasswordIcon)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      width: 18px;
      height: 18px;
    }
  }
`;

const StyledShowPasswordIcon = styled(ShowPasswordIcon)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      width: 18px;
      height: 18px;
    }
  }
`;

const RequiredIndicator = styled.span`
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.pimento};
`;

export const StyledFormLabel = styled(BaseFormLabel)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export function FormHelperLabel(props) {
  return (
    <Typography
      as="p"
      level="8"
      {...props}
    />
  );
}

export const FormContext = createContext();

export function FormMessage(props) {
  const form = useContext(FormContext);
  return (
    <StyledFormMessage
      as={Typography}
      level="9"
      {...props}
      {...form}
    />
  );
}

export const FormInput = forwardRef(({ value, clearable, ...props }, ref) => {
  const { name, type } = props;
  const theme = useTheme();

  const [inputType, setInputType] = useState(type);
  const form = useContext(FormContext);

  function toggleShowPassword() {
    if (!inputType) return false;
    const { current } = ref;
    const newInputType = inputType === 'password' ? 'text' : 'password';
    setInputType(newInputType);
    current.type = newInputType;
    return newInputType;
  }

  // Support value changes.
  useEffect(() => {
    if (value) {
      form.update(name, value);
    }
  }, [name, value]);

  // Remove aria-invalid attribute until form submit.
  const attrs = {};
  if (form.submitFailed + form.submitSucceed === 0) {
    attrs['aria-invalid'] = null;
  }

  return (
    <FormInputWrapper>
      <StyledFormInput
        ref={ref}
        {...attrs}
        {...form}
        {...props}
      />
      {type === 'password' && (
        <BaseButton
          type="button"
          onClick={toggleShowPassword}
        >
          {inputType === 'password' ? (
            <StyledHidePasswordIcon
              size={14}
              color={theme.palette.navy}
            />
          ) : (
            <StyledShowPasswordIcon
              size={14}
              color={theme.palette.navy}
            />
          )}
          <VisuallyHidden>Show/Hide password</VisuallyHidden>
        </BaseButton>
      )}
    </FormInputWrapper>
  );
});

export function FormTextArea(props) {
  const form = useContext(FormContext);
  return (
    <FormInputWrapper>
      <StyledFormTextArea
        as="textarea"
        rows={6}
        {...props}
        {...form}
      />
    </FormInputWrapper>
  );
}

export function FormCheckbox(props) {
  const form = useContext(FormContext);
  return (
    <BaseFormCheckbox
      as={Checkbox}
      {...props}
      {...form}
    />
  );
}

export const FormLabel = ({ children, required, ...props }) => {
  const form = useContext(FormContext);
  return (
    <Typography
      aria-required={required}
      as={StyledFormLabel}
      level="9"
      {...form}
      {...props}
    >
      {children}
      &nbsp;
      {required && <RequiredIndicator />}
    </Typography>
  );
};
