import React, { useEffect, useState } from 'react';

import { useCurrentRoute, useNavigation } from 'react-navi';
import { FloatingRequestAccessButton } from '../../../components/FloatingRequestAccessButton';
import { Helmet } from '../../../components/Helmet';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { useContentVisibility, usePolicyList } from '../../../lib/state';
import PolicyNavigationPanel from './components/PolicyNavigationPanel';
import PolicyDetailPanel from './components/PolicyDetailPanel';
import PolicySectionDropdown from './components/PolicySectionDropdown';
import PageContainer from '../../../components/PageContainer';
import { useMeasurePaint, useMeasureRender, useMeasureRoute } from '../../../lib/measurement';
import { useInitialMount } from '../../../lib/utils';

export function transformPolicyGroupBy(policies) {
  const transformedPolicyGroups = [];
  policies.forEach((policy) => {
    if (!policy.securityGroup) {
      transformedPolicyGroups[0] = [...transformedPolicyGroups[0], policy];
    } else if (transformedPolicyGroups[policy.securityGroup]) {
      transformedPolicyGroups[policy.securityGroup] = [...transformedPolicyGroups[policy.securityGroup], policy];
    } else {
      transformedPolicyGroups[policy.securityGroup] = [policy];
    }
  });
  return transformedPolicyGroups;
}

const PolicyDetail = withTrustShareNavigationBar(({ shortName }) => {
  const {
    url: { pathname },
    data: { span },
  } = useCurrentRoute();
  if (shortName) {
    span.setAttribute('page-name', 'policy-details');
  } else {
    span.setAttribute('page-name', 'policies');
  }
  useMeasureRoute(span);

  const [policiesList] = usePolicyList();
  const { navigate } = useNavigation();
  const initialMount = useInitialMount();

  const { shouldHidePolicyDetail: shouldHidePoliciesPage } = useContentVisibility();

  const policies = policiesList.filter((policy) => policy.trustShareAccessLevel !== null);

  const isPolicyListEmpty = policies.length === 0;

  const adoptedPolicyGroups = transformPolicyGroupBy(policies);
  const sortedPolicyGroups = Object.keys(adoptedPolicyGroups).sort();

  let policyToDisplay = null;
  if (shortName) {
    policyToDisplay = policies.find((policy) => policy.shortName === shortName) || null;
  }

  const [selectedPolicyGroup, setSelectedPolicyGroup] = useState(
    policyToDisplay ? policyToDisplay.securityGroup : sortedPolicyGroups[0]
  );
  const firstPolicyGroup = sortedPolicyGroups[0];
  const firstPolicy = adoptedPolicyGroups[firstPolicyGroup]?.sort((a, b) => (a.title > b.title ? 1 : -1))[0];

  useEffect(() => {
    if (pathname.startsWith('/policies') && shouldHidePoliciesPage) {
      navigate('/home');
    } else if (isPolicyListEmpty) {
      navigate('/');
    } else if ((policyToDisplay || firstPolicy)?.shortName && initialMount) {
      window.history.replaceState(
        null,
        null,
        `/policies/${encodeURIComponent((policyToDisplay || firstPolicy).shortName)}`
      );
    }
  }, [pathname, shouldHidePoliciesPage, policies]);

  if (isPolicyListEmpty) {
    return null;
  }

  const handlePolicyGroupToggle = (toggledGroup) => {
    if (toggledGroup === selectedPolicyGroup) {
      setSelectedPolicyGroup(null);
    } else {
      setSelectedPolicyGroup(toggledGroup);
    }
  };

  const tree = (
    <>
      <Helmet pageTitle="Policies" />
      <PageContainer
        alignItems="flex-start"
        gap="32px"
        direction="column"
      >
        <PolicyNavigationPanel
          sortedPolicyGroups={sortedPolicyGroups}
          selectedPolicy={policyToDisplay || firstPolicy}
          onPolicyGroupClick={handlePolicyGroupToggle}
          selectedPolicyGroup={selectedPolicyGroup}
          adoptedPolicyGroups={adoptedPolicyGroups}
        />
        <PolicySectionDropdown
          sortedPolicyGroups={sortedPolicyGroups}
          selectedPolicy={policyToDisplay || firstPolicy}
          adoptedPolicyGroups={adoptedPolicyGroups}
        />
        <PolicyDetailPanel policy={policyToDisplay || firstPolicy} />
      </PageContainer>
      <FloatingRequestAccessButton />
    </>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});

export default PolicyDetail;
