import styled from '@emotion/styled';
import { flat, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';

export const NotificationCenterWrapper = styled(Flex)`
  background-color: ${({ theme }) => flat(theme.color.system.gray, '20%')};
  padding: 24px;
`;

export const NotificationHeader = styled(Typography)`
  color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
`;
