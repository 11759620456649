// Package modules
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Box } from 'reakit';

const DEFAULT_SPACING_IN_PIXELS = 22;
const DEFAULT_SEGMENT_COUNT = 12;
const DIRECTIONS = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};
const FLEX_ALIGN_OPTIONS = ['start', 'end', 'center', 'stretch'];

const containerStyle = (props) => css`
  display: grid;
  grid-template-columns: repeat(${props.columnCount}, 1fr);
  grid-template-rows: repeat(${props.rowCount || 1}, 1fr);

  column-gap: ${props.columnSpacing * DEFAULT_SPACING_IN_PIXELS}px;
  row-gap: ${props.rowSpacing * DEFAULT_SPACING_IN_PIXELS}px;

  justify-items: ${props.justifyItems};
  align-items: ${props.alignItems};
`;

const itemStyle = (props) => css`
  grid-column: ${props.colStart} / span ${props.colSpan};
  grid-row: ${props.rowStart} / span ${props.rowSpan};
`;

const StyledGrid = styled(Box, {
  shouldForwardProp(prop) {
    return ![
      'container',
      'item',
      'segments',
      'direction',
      'spacing',
      'rowSpacing',
      'columnSpacing',
      'justifyItems',
      'alignItems',
      'rowCount',
      'columnCount',
      'colSpan',
      'colStart',
      'rowSpan',
      'rowStart',
      'fullWidth',
      'fullHeight',
    ].includes(prop);
  },
})`
  ${(props) => props.container && containerStyle}
  ${(props) => props.item && itemStyle}
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `}
`;

const Grid = React.forwardRef(
  (
    {
      children,
      container = false,
      item = false,
      segments,
      direction,
      spacing,
      rowSpacing,
      columnSpacing,
      justifyItems = 'start',
      alignItems = 'start',
      colStart = 1,
      colSpan = 1,
      rowStart = 1,
      rowSpan = 1,
      fullWidth = false,
      fullHeight = false,
      ...remainingProps
    },
    ref
  ) => {
    const gridProps = {
      container,
      item,
      columnSpacing: columnSpacing ?? spacing ?? 1,
      rowSpacing: rowSpacing ?? spacing ?? 1,
      justifyItems,
      alignItems,
      colStart,
      colSpan,
      rowStart,
      rowSpan,
      fullWidth,
      fullHeight,
    };

    if (direction === DIRECTIONS.VERTICAL) {
      gridProps.columnCount = 1;
      gridProps.rowCount = segments || DEFAULT_SEGMENT_COUNT;
    } else {
      gridProps.columnCount = segments || DEFAULT_SEGMENT_COUNT;
    }

    return (
      <StyledGrid
        ref={ref}
        {...gridProps}
        {...remainingProps}
      >
        {children}
      </StyledGrid>
    );
  }
);

Grid.propTypes = {
  /** If true, this element will be a grid, and accept properties labeled with (container). */
  container: PropTypes.bool,
  /** If true, this element will be a grid item, and accept properties labeled with (item). */
  item: PropTypes.bool,
  /** (container) Number of grid segments (columns or rows, depending on direction). */
  segments: PropTypes.number,
  /** (container) Determines whether elements are laid out horizontally or vertically */
  direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
  /** (container) Spacing between rows and columns, as a multiplier of 8px. */
  spacing: PropTypes.number,
  /** (container) Spacing between rows, as a multiplier of 8px. Overrides `spacing` property. */
  rowSpacing: PropTypes.number,
  /** (container) Spacing between rows, as a multiplier of 8px. Overrides `spacing` property. */
  columnSpacing: PropTypes.number,
  /** (container) Set the justify-items property. */
  justifyItems: PropTypes.oneOf(FLEX_ALIGN_OPTIONS),
  /** (container) Set the align-items property. */
  alignItems: PropTypes.oneOf(FLEX_ALIGN_OPTIONS),
  /** (item) The number of columns spanned by this grid item. */
  colSpan: PropTypes.number,
  /** (item) The start column index. */
  colStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** (item) The number of rows spanned by this grid item. */
  rowSpan: PropTypes.number,
  /** (item) The start row index.  */
  rowStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** If true, this element will have its width set to 100%. */
  fullWidth: PropTypes.bool,
  /** If true, this element will have its height set to 100%. */
  fullHeight: PropTypes.bool,
};

Grid.defaultProps = {
  container: false,
  item: false,
  segments: 12,
  direction: DIRECTIONS.HORIZONTAL,
  spacing: null,
  rowSpacing: null,
  columnSpacing: null,
  justifyItems: 'start',
  alignItems: 'start',
  colSpan: 1,
  colStart: 'auto',
  rowSpan: 1,
  rowStart: 'auto',
  fullWidth: false,
  fullHeight: false,
};

export default Grid;
