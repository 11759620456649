import React, { useState } from 'react';
import { createContainer } from 'unstated-next';

function useError() {
  const [error, setError] = useState(null);

  return {
    error,
    setError,
  };
}

export const ErrorProvider = createContainer(useError);

// Provide Higher Order Component.
export const withErrorProvider = (Component) => (props) =>
  (
    <ErrorProvider.Provider>
      <Component {...props} />
    </ErrorProvider.Provider>
  );
