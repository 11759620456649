import styled from '@emotion/styled';
import { base, flat, Heading, rgb, translucent, Typography } from '@kintent/glide';
import { Flex } from '../../../../components/Flex';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`;

export const DocumentTitle = styled(Heading)`
  color: ${({ theme }) => flat(theme.color.system.gray)};
`;

export const Card = styled(Flex)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '10%')};
  background-color: ${({ theme }) => rgb(theme.color.system.white)};
  padding: 20px 24px 44px 24px;
`;

export const PolicyLengthLabel = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '40%')};
`;

export const ViewAllLink = styled(Typography)`
  color: ${({ theme }) => theme.components.header.primaryCTAButton.background};

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
