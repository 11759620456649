import React, { useState } from 'react';
import { Toast } from '@kintent/glide';

import { getDocumentsChildren } from 'lib/utils';
import { Flex } from '../../../../components/Flex';
import { DOCUMENT_TYPE } from '../../../../lib/constants';
import { ScrollTarget } from './DocumentDetailPanel.styles';
import * as Styles from './DocumentSharedWithSection.styles';
import DashboardDocumentCard from '../../home/components/DashboardDocumentCard';

function DocumentSharedWithSection({ documentsSharedWithUser }) {
  const [shouldShowDownloadErrorToast, setShouldShowDownloadErrorToast] = useState(false);
  if (!documentsSharedWithUser.length) {
    return null;
  }

  const childrenDocuments = getDocumentsChildren(documentsSharedWithUser);

  const allDocuments = [...documentsSharedWithUser, ...childrenDocuments];

  return (
    <>
      <Flex
        direction="column"
        gap="16px"
      >
        <ScrollTarget id={DOCUMENT_TYPE.SHARED_WITH_YOU} />
        <Styles.DocumentTitle level="5">Shared with only you</Styles.DocumentTitle>
        <Styles.Grid>
          {allDocuments.map((document) => (
            <DashboardDocumentCard
              key={document.id}
              document={document}
              setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
            />
          ))}
        </Styles.Grid>
      </Flex>
      <Toast
        variant="negative"
        label="Error"
        description="We couldn't generate the download file, please contact support."
        open={shouldShowDownloadErrorToast}
        duration={1000}
        onOpenChange={() => setShouldShowDownloadErrorToast(false)}
      />
    </>
  );
}

export default DocumentSharedWithSection;
