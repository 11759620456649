// Package modules
import React from 'react';
import { Helmet as BaseHelmet } from 'react-helmet';

// Package modules
import { useAuthService, useTeamIcon } from '../lib/state';

/**
 * The `link` element is modified to have an id and data-react-helmet attribute because overriding
 * the favicon is documented to be an issue here - https://github.com/nfl/react-helmet/issues/430.
 * The same attributes have been added to the index.html to resolve this issue
 */
export function Helmet({ pageTitle }) {
  const { currentTeam } = useAuthService();

  /**
   * Do not destructure here since `teamIconData` is null if a program has not provided one
   */
  const [teamIconData] = useTeamIcon(currentTeam.id);

  return (
    <BaseHelmet>
      <meta
        property="og:title"
        content={`${currentTeam.name} TrustShare`}
      />
      <title>{`${currentTeam.name} TrustShare | ${pageTitle}`}</title>
      {
        /*
         * Render the teamIcon as the favicon if it exists.
         * Default favicon is TrustCloud's favicon as using the logo makes it look visually distorted
         */
        teamIconData && (
          <link
            id="favicon"
            data-react-helmet="true"
            rel="icon"
            type={teamIconData.contentType}
            href={teamIconData.teamIcon}
            sizes="16x16"
          />
        )
      }
    </BaseHelmet>
  );
}
