// Package modules.
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { UilSpinnerAlt as LoaderIcon } from '@iconscout/react-unicons';

// Styles.
const spin = css`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = styled(LoaderIcon)`
  animation: spin 3s linear infinite;
  ${css`
    ${spin}
  `}
`;

export const SpinnerRing = styled.span`
  animation: spin 1.5s linear infinite;
  ${css`
    ${spin}
  `}

  border: 3px solid ${({ theme }) => theme.palette.silver};
  border-top-color: ${({ theme }) => theme.palette.ceu};
  border-right-color: ${({ theme }) => theme.palette.ceu};
  border-radius: 9999px;

  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;
