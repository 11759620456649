import React, { useEffect, useState } from 'react';
import { useCurrentRoute } from 'react-navi';
import { UilFileAlt, UilFileLockAlt, UilShield, UilUsersAlt } from '@iconscout/react-unicons';
import { Helmet } from '../../../components/Helmet';
import { FloatingRequestAccessButton } from '../../../components/FloatingRequestAccessButton';

import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import PageContainer from '../../../components/PageContainer';
import { DOCUMENT_ACCESS_LEVEL, DOCUMENT_TYPE } from '../../../lib/constants';
import { useMeasurePaint, useMeasureRender } from '../../../lib/measurement';
import {
  useAuthService,
  useContentVisibility,
  usePolicyList,
  useRoleList,
  useSharedQuestionnaireList,
  useTeamCertificationList,
  useTeamDocumentList,
} from '../../../lib/state';
import { isElementInViewport, modifyDataRoomDocumentsWithTypeAnnotation } from '../../../lib/utils';
import DocumentDetailPanel from './components/DocumentDetailPanel';
import DocumentsLeftPanel from './components/DocumentsLeftPanel';

const DOCUMENT_LEFT_PANEL_ITEMS = [
  {
    displayName: 'Shared with only you',
    documentType: DOCUMENT_TYPE.SHARED_WITH_YOU,
    icon: UilUsersAlt,
  },
  {
    displayName: 'Certifications',
    documentType: DOCUMENT_TYPE.CERTIFICATION,
    icon: UilShield,
  },
  {
    displayName: 'Policies',
    documentType: DOCUMENT_TYPE.POLICY,
    icon: UilFileAlt,
  },
  {
    displayName: 'Other Resources',
    documentType: DOCUMENT_TYPE.RESOURCE,
    icon: UilFileLockAlt,
  },
];
export const DocumentListV2 = withTrustShareNavigationBar(() => {
  const route = useCurrentRoute();
  const {
    data: { span, title },
  } = route;

  const slug = route.url.hash.substring(1);

  const { authenticatedUser, currentTeam } = useAuthService();
  const [documentList] = useTeamDocumentList(currentTeam.id);
  const [certificationList] = useTeamCertificationList(currentTeam.id);
  const [policies] = usePolicyList();
  const questionnaires = useSharedQuestionnaireList(currentTeam.id);
  const { shouldHidePolicyDetail, shouldHidePolicyOverview } = useContentVisibility();
  const roles = useRoleList();

  const documentsSharedWithUser = modifyDataRoomDocumentsWithTypeAnnotation(
    documentList,
    certificationList,
    shouldHidePolicyOverview ? [] : policies,
    questionnaires,
    roles,
    authenticatedUser
  );
  const privatePublicDocumentList = documentList
    .filter((resource) => [DOCUMENT_ACCESS_LEVEL.PRIVATE, DOCUMENT_ACCESS_LEVEL.PUBLIC].includes(resource.accessLevel))
    .map((resource) => ({
      ...resource,
      documentType: DOCUMENT_TYPE.RESOURCE,
    }));

  const visibleCertifications = certificationList.filter((item) =>
    [DOCUMENT_ACCESS_LEVEL.PUBLIC, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(item.trustShareAccessLevel)
  );

  const visiblePolicies = policies.filter((policy) =>
    [DOCUMENT_ACCESS_LEVEL.PUBLIC, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(policy.trustShareAccessLevel)
  );

  const shouldDisplayDocumentsForYouSection = Boolean(authenticatedUser && documentsSharedWithUser.length > 0);
  const shouldDisplayOtherResources = Boolean(privatePublicDocumentList.length > 0);
  const shouldDisplayCertifications = Boolean(visibleCertifications.length > 0);
  const shouldDisplayPolicies = Boolean(visiblePolicies.length > 0);

  const documentSections = DOCUMENT_LEFT_PANEL_ITEMS.filter((section) => {
    if (section.documentType === DOCUMENT_TYPE.RESOURCE) {
      return shouldDisplayOtherResources;
    }

    if (section.documentType === DOCUMENT_TYPE.SHARED_WITH_YOU) {
      return shouldDisplayDocumentsForYouSection;
    }

    if (section.documentType === DOCUMENT_TYPE.CERTIFICATION) {
      return shouldDisplayCertifications;
    }

    if (section.documentType === DOCUMENT_TYPE.POLICY) {
      if (shouldHidePolicyDetail && shouldHidePolicyOverview) {
        return false;
      }
      return shouldDisplayPolicies;
    }

    return true;
  });

  let documentSectionToSelect = null;

  if (slug) {
    documentSectionToSelect = documentSections.find(({ documentType }) => {
      return documentType === slug;
    });
  }

  const [selectedDocumentSection, setSelectedDocumentSection] = useState(
    documentSectionToSelect ?? documentSections[0]
  );

  useEffect(() => {
    const selectedSection = (documentSectionToSelect || documentSections[0])?.documentType;
    if (selectedSection) {
      window.history.replaceState(null, null, `/documents#${selectedSection}`);
    }
  }, []);

  const handleDocumentSectionToggle = (documentSection) => {
    if (documentSection.documentType !== selectedDocumentSection?.documentType) {
      setSelectedDocumentSection(documentSection);
    }
  };

  const handleScroll = () => {
    if (window.scrollY < 100) {
      handleDocumentSectionToggle({
        documentType: shouldDisplayDocumentsForYouSection ? DOCUMENT_TYPE.SHARED_WITH_YOU : DOCUMENT_TYPE.CERTIFICATION,
      });
      return;
    }

    Object.values(DOCUMENT_TYPE).forEach((documentType) => {
      const isDocumentType = isElementInViewport(documentType);
      if (isDocumentType && documentType !== selectedDocumentSection) {
        handleDocumentSectionToggle({ documentType });
      }
    });
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [selectedDocumentSection]);

  const tree = (
    <>
      <Helmet pageTitle={title} />
      <PageContainer
        alignItems="flex-start"
        gap="32px"
        direction="column"
      >
        <DocumentsLeftPanel
          documentSections={documentSections}
          selectedDocumentSection={selectedDocumentSection}
          onDocumentSectionClick={handleDocumentSectionToggle}
        />
        <DocumentDetailPanel
          isAuthenticated={!!authenticatedUser}
          documentsSharedWithUser={documentsSharedWithUser}
        />
      </PageContainer>
      <FloatingRequestAccessButton />
    </>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});
