import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { UilTimes as CloseIcon } from '@iconscout/react-unicons';
import { Typography } from '@kintent/glide';

import { SpaceAround, Stack } from '../design-system';
import { BREAKPOINTS, ON_BOARDING_FLOW_LOCAL_STORAGE_KEY, TS_ADMIN_BRANDING_URL } from '../lib/constants';
import { hexOpacity } from '../lib/utils';
import { useIsOnBoardingFlow, useLocalStorage } from '../lib/hooks';
import { Flex } from './Flex';
import { GlideButton } from './GlideButton';

export const StyledContainer = styled(motion('div'), {
  shouldForwardProp(prop) {
    return !['isScrolling'].includes(prop);
  },
})`
  position: fixed;
  bottom: 4.3rem;
  right: 1rem;
  z-index: 1000;

  opacity: ${({ isScrolling }) => (isScrolling ? 0.8 : 1)};

  transition: opacity 0.3s ease-in-out;

  width: 350px;
  padding: 24px;
  box-shadow: 0 0 6px ${({ theme }) => hexOpacity(theme.palette.chalkboard, 0.35)};
  border-radius: 16px;

  background-color: ${({ theme }) => theme.palette.white};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      right: 2rem;
    }
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

const StyledCollapsedButton = styled(GlideButton)`
  position: fixed;
  bottom: 5.9rem;
  right: 2.5rem;
  z-index: 1001;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      right: 3.5rem;
    }
  }
`;

function CustomizePopup({ onClose }) {
  const [isVisible, setIsVisible] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);

  const { removeKey: removeOnBoardingFlowLocalStorageFlag } = useLocalStorage(ON_BOARDING_FLOW_LOCAL_STORAGE_KEY);

  useEffect(() => {
    let timeout;
    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleCustomize = () => {
    removeOnBoardingFlowLocalStorageFlag();
    setIsVisible(false);
    window.open(TS_ADMIN_BRANDING_URL, '_blank').focus();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <StyledContainer
      isScrolling={isScrolling}
      initial={{ scale: 0, originX: 0.8, originY: 0.9 }}
      animate={{ scale: 1, originX: 0.8, originY: 0.9 }}
      exit={{ scale: 0, originX: 0.8, originY: 0.9 }}
    >
      <StyledCloseIcon onClick={handleClose} />
      <Stack space="lg">
        <Typography level="6">Customize My TrustShare</Typography>
        <Typography>
          You are looking at a preview of <Typography as="strong">your trust portal</Typography>, that showcases all
          your security and compliance information.
        </Typography>
        <Typography>
          We’ve automatically pulled in all your <Typography as="strong">TrustCloud</Typography> content (polices, sub
          processors, etc.)
        </Typography>
        <Typography>
          With a{' '}
          <Typography
            level="labelstronger"
            element="strong"
          >
            couple more steps
          </Typography>{' '}
          you can make this truly your own and send it to customers. Don’t worry, it should only take{' '}
          <Typography
            level="labelstronger"
            as="strong"
          >
            5- 10 minutes.
          </Typography>
        </Typography>
        <Flex
          justifyContent="flex-end"
          as={SpaceAround}
          top="40px"
        >
          <GlideButton
            variant="primary"
            onClick={handleCustomize}
          >
            Customize
          </GlideButton>
        </Flex>
      </Stack>
    </StyledContainer>
  );
}

function CollapsedButton({ onClick }) {
  return (
    <StyledCollapsedButton
      variant="primary"
      onClick={onClick}
    >
      Customize
    </StyledCollapsedButton>
  );
}

function OnBoardingJourney({ children }) {
  const isOnBoarding = useIsOnBoardingFlow();
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      {isOnBoarding && (
        <AnimatePresence>
          {isCollapsed ? (
            <CollapsedButton onClick={() => setIsCollapsed(false)} />
          ) : (
            <CustomizePopup
              onClose={() => setIsCollapsed(true)}
              key="popup"
            />
          )}
        </AnimatePresence>
      )}
      {children}
    </>
  );
}

export default OnBoardingJourney;
