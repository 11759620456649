import React from 'react';
import { Typography } from '@kintent/glide';

import * as Styles from './Statement.styles';
import { useProgramContent } from '../../../../../lib/state';

function Statement() {
  const [{ statementPrimaryText = null, statementReferrerText = null, statementStyle }] = useProgramContent();

  const statementPrimaryTextStyle = statementStyle?.primary ?? {};
  const statementPrimaryReferrerTextStyle = statementStyle?.primaryReferrerTextStyle ?? {};

  return (
    <Styles.StatementContainer data-testid="statement-section">
      {statementPrimaryText && (
        <Typography
          as="h4"
          level="4"
          data-testid="statement-section-primary"
          className="statement-primary"
          style={statementPrimaryTextStyle}
        >
          {statementPrimaryText}
        </Typography>
      )}
      {statementReferrerText && (
        <Typography
          as="p"
          strength="60%"
          style={statementPrimaryReferrerTextStyle}
          dangerouslySetInnerHTML={{ __html: statementReferrerText }}
        />
      )}
    </Styles.StatementContainer>
  );
}

export default Statement;
