import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { AnimatePresence, motion } from 'framer-motion';
import { UilAngleDown } from '@iconscout/react-unicons';
import { Link, useCurrentRoute } from 'react-navi';
import { Heading, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import { hexOpacity } from '../../../../lib/utils';

const COLLAPSE_VARIANTS = {
  collapsed: {
    height: 0,
    opacity: 0,
  },
  expanded: {
    height: 'auto',
    opacity: 1,
  },
};

const StyledContainer = styled.div`
  & > div {
    cursor: pointer;
  }
`;

const StyledDisclosure = styled(Disclosure)`
  & > svg.open {
    transform: rotate(180deg);
    transition: transform 250ms ease-in;
  }

  & > svg.closed {
    transform: rotate(0deg);
    transition: transform 250ms ease-out;
  }
`;

const StyledDisclosureContent = styled(motion(DisclosureContent))`
  overflow: hidden;
  padding-left: 23px;

  & a {
    display: block;
    margin-top: 12px;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    text-decoration: none;

    &.active {
      background-color: ${({ theme }) => hexOpacity(theme.palette.volare, 0.1)};
    }

    &:hover:not(.active) {
      color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    }

    transition: color 0.25s ease-in;
  }
`;

function ControlCategoryDisclosure({
  category,
  controlsByCategory,
  selectedControlCategory,
  onControlCategoryClick,
  selectedControl,
}) {
  const {
    data: { shortName },
  } = useCurrentRoute();

  const sortedControls = useMemo(
    () => controlsByCategory.sort((a, b) => (a.categorization.subcategory > b.categorization.subcategory ? 1 : -1)),
    [controlsByCategory]
  );

  const [initiateAnimation, setInitiateAnimation] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitiateAnimation(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);
  const isCurrentCategorySelected = category === selectedControlCategory;
  const disclosure = useDisclosureState({ visible: category === selectedControlCategory });
  const memoizedHandleControlCategoryClick = useCallback(
    (controlCategory) => onControlCategoryClick(controlCategory),
    [onControlCategoryClick]
  );

  return (
    <StyledContainer className="policy-group-items">
      <StyledDisclosure
        as={Flex}
        alignItems="center"
        justifyContent="space-between"
        {...disclosure}
        toggle={() => memoizedHandleControlCategoryClick(category)}
      >
        <Heading level="7">{category}</Heading>
        <UilAngleDown
          size={20}
          className={isCurrentCategorySelected ? 'open' : 'closed'}
        />
      </StyledDisclosure>
      <AnimatePresence>
        {isCurrentCategorySelected && (
          <StyledDisclosureContent
            layout
            variants={COLLAPSE_VARIANTS}
            initial={initiateAnimation ? 'collapsed' : false}
            animate="expanded"
            exit="collapsed"
            transition="tween"
            {...disclosure}
          >
            {sortedControls.map((control) => {
              const isControlSelected =
                shortName === control.shortName || control.shortName === selectedControl.shortName;
              return (
                <Typography
                  key={control.id}
                  level="8"
                  className={isControlSelected ? 'active' : null}
                  as={Link}
                  href={`/controls/${encodeURIComponent(control.customShortName ?? control.shortName)}`}
                >
                  {control.categorization.subcategory}
                </Typography>
              );
            })}
          </StyledDisclosureContent>
        )}
      </AnimatePresence>
    </StyledContainer>
  );
}

export default ControlCategoryDisclosure;
