import React from 'react';
import { Button, Modal, useModal } from '@kintent/glide';
import { useNavigation } from 'react-navi';

import * as Styles from './DashboardDocuments.styles';
import { Flex } from '../../../../components/Flex';
import { FEATURE_FLAG } from '../../../../lib/featureFlags';
import {
  useAuthService,
  useDocumentDownload,
  useDocumentViewedActivity,
  useFeatureFlag,
  useRequestAccessRedirectionUrl,
} from '../../../../lib/state';
import { downloadFileFromAPI } from '../../../../lib/utils';
import { CloseButton, GlideButton, RequestButton } from '../../../../components/GlideButton';

function getActionButtons({
  canDownload,
  canView,
  shouldShowRequestAccessButton,
  isDownloading,
  handleDocumentDownload,
  learnMoreModal,
  handleDocumentLinkClick,
  handleRequestAccess,
}) {
  if (canDownload) {
    return (
      <Flex
        alignItems="center"
        gap="8px"
      >
        <CloseButton
          variant="secondary"
          onClick={learnMoreModal.hide}
        >
          Close
        </CloseButton>
        <GlideButton
          variant="primary"
          onClick={handleDocumentDownload}
          disabled={isDownloading}
        >
          Download
        </GlideButton>
      </Flex>
    );
  }

  if (!canDownload && canView) {
    return (
      <Flex
        alignItems="center"
        gap="8px"
      >
        <CloseButton
          variant="secondary"
          onClick={learnMoreModal.hide}
        >
          Close
        </CloseButton>
        <GlideButton
          variant="primary"
          onClick={handleDocumentLinkClick}
        >
          View
        </GlideButton>
      </Flex>
    );
  }

  if (shouldShowRequestAccessButton) {
    return (
      <Flex
        alignItems="center"
        gap="8px"
      >
        <CloseButton
          variant="secondary"
          onClick={learnMoreModal.hide}
        >
          Close
        </CloseButton>
        <RequestButton
          variant="primary"
          onClick={handleRequestAccess}
        >
          Request Access
        </RequestButton>
      </Flex>
    );
  }

  return (
    <Button
      variant="secondary"
      onClick={learnMoreModal.hide}
    >
      Close
    </Button>
  );
}

function LearnMoreModal({
  canDownload,
  canView,
  documentId,
  learnMoreModal,
  name,
  shouldShowRequestAccessButton,
  identifier,
  isDownloading,
  setDownloading,
  currentTeam,
  description,
  setShouldShowDownloadErrorToast,
}) {
  const { navigate } = useNavigation();
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const { trackDocumentViewedActivity } = useDocumentViewedActivity();

  const handleRequestAccess = () => {
    navigate(requestAccessRedirectionUrl);
    learnMoreModal.hide();
  };

  const handleDocumentLinkClick = () => {
    trackDocumentViewedActivity(documentId);
    window.open(identifier);
    learnMoreModal.hide();
  };

  const downloadResource = useDocumentDownload(currentTeam.id, documentId);

  const handleDocumentDownload = async () => {
    setDownloading(true);
    try {
      await downloadFileFromAPI(() => downloadResource(), identifier);
    } catch (e) {
      setShouldShowDownloadErrorToast(true);
    }
    setDownloading(false);
    learnMoreModal.hide();
  };

  const actions = getActionButtons({
    canDownload,
    canView,
    shouldShowRequestAccessButton,
    isDownloading,
    handleDocumentDownload,
    learnMoreModal,
    handleDocumentLinkClick,
    handleRequestAccess,
  });

  const title = name.length > 50 ? name.substring(0, 47).concat('...') : name;

  return (
    <Modal
      title={title}
      actions={actions}
      dialog={learnMoreModal}
    >
      <Styles.LearnMoreContent>{description}</Styles.LearnMoreContent>
    </Modal>
  );
}

function LearnMore({
  canDownload,
  canView,
  description,
  documentId,
  name,
  identifier,
  isDownloading,
  setDownloading,
  setShouldShowDownloadErrorToast,
}) {
  const learnMoreModal = useModal();

  const { currentTeam, authenticatedUser } = useAuthService();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const shouldShowRequestAccessButton = !isRequestAccessHidden && !canDownload && !canView && !authenticatedUser;

  return (
    <>
      <GlideButton
        variant="secondary"
        onClick={learnMoreModal.show}
        className="secondary"
      >
        Learn More
      </GlideButton>
      <LearnMoreModal
        canDownload={canDownload}
        canView={canView}
        documentId={documentId}
        learnMoreModal={learnMoreModal}
        name={name}
        shouldShowRequestAccessButton={shouldShowRequestAccessButton}
        identifier={identifier}
        isDownloading={isDownloading}
        setDownloading={setDownloading}
        currentTeam={currentTeam}
        description={description}
        setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
      />
    </>
  );
}

export default LearnMore;
