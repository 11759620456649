import React, { useState } from 'react';
import { Link } from 'react-navi';
import styled from '@emotion/styled';
import { Box } from 'reakit';
import { UilCheck as CheckIcon } from '@iconscout/react-unicons';
import { motion } from 'framer-motion';
import { useTheme } from '@emotion/react';
import { Heading, Typography } from '@kintent/glide';

import { PageTitle, SectionDescription } from '../../components/PageElements';
import { BREAKPOINTS } from '../../lib/constants';
import { CREATE_ACCOUNT_MESSAGES } from '../../lib/errors/ApiError';
import { hexOpacity, mailtoHandler } from '../../lib/utils';
import {
  AnimatedContentContainer,
  PROGRAM_COPY_VARIANTS,
} from '../trust-share/home/components/AnimatedContentContainer';
import { Flex } from '../../components/Flex';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import { useDeviceResolution } from '../../lib/hooks';
import { useAuthService } from '../../lib/state';
import { api } from '../../lib/api';

import { ReactComponent as ErrorPopsicleIllustration } from '../../assets/error-popsicle.svg';
import { GlideButton } from '../../components/GlideButton';

const StyledBox = styled(Box)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    box-shadow: -15px -15px 25px 0 ${({ theme }) => hexOpacity(theme.palette.white, 0.9)},
      10px 10px 15px 4px ${({ theme }) => hexOpacity(theme.palette.shadow, 0.1)};
    border-radius: ${({ theme }) => theme.radius.xl};

    padding: 1rem 1.5rem;

    & {
      padding: 2rem 2rem;
    }
  }
`;

const CheckIconWrapper = styled(Flex)`
  position: relative;
  z-index: 0;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.jade};

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    z-index: -1;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: ${({ theme }) => hexOpacity(theme.palette.jade, 0.2)};
  }
`;

const SuccessMessageLabel = styled(SectionDescription)`
  padding-bottom: 1.5rem;
  text-align: center;
`;

const ResponsiveFlex = styled(Flex)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      gap: 3rem;
    }
  }
`;

const getEmailResentTitleAndMessage = (message) => {
  return {
    title: `${
      message === CREATE_ACCOUNT_MESSAGES.ACCOUNT_SETUP_INCOMPLETE ? 'Account Setup' : 'Verification'
    }  Email Resent!`,
    message: (
      <>
        You will receive an email from our team asking you to
        <br />
        {message === CREATE_ACCOUNT_MESSAGES.ACCOUNT_SETUP_INCOMPLETE
          ? 'complete your account setup.'
          : 'verify your email address and complete your account setup.'}
      </>
    ),
  };
};

const ContactSupport = styled(Typography)`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const CreateAccountMessageContent = ({ title, message, action, isSuccess = false }) => {
  const theme = useTheme();
  const { isTablet } = useDeviceResolution();
  return (
    <ResponsiveContainer
      as={ResponsiveFlex}
      direction="column"
      gap="1rem"
    >
      {isTablet && <PageTitle>Create Account</PageTitle>}
      <StyledBox>
        <AnimatedContentContainer alignItems="center">
          <motion.div variants={PROGRAM_COPY_VARIANTS}>
            {isSuccess ? (
              <CheckIconWrapper
                alignItems="center"
                justifyContent="center"
              >
                <CheckIcon
                  size={44}
                  color={theme.palette.white}
                />
              </CheckIconWrapper>
            ) : (
              <ErrorPopsicleIllustration
                width={68}
                height={68}
              />
            )}
          </motion.div>
          <Heading level="4">{title}</Heading>
          <SuccessMessageLabel>{message}</SuccessMessageLabel>
          <motion.div variants={PROGRAM_COPY_VARIANTS}>{action}</motion.div>
        </AnimatedContentContainer>
      </StyledBox>
    </ResponsiveContainer>
  );
};

// eslint-disable-next-line complexity
function CreateAccountMessage({ message, email, username = '' }) {
  const { currentTeam } = useAuthService();

  const [createAccountMessage, setCreateAccountMessage] = useState(message);
  const [isResendDisabled, setResendDisabled] = useState(false);
  const displayName = username.trim().length > 0 ? username : email;

  const handleResendVerificationEmail = async () => {
    setResendDisabled(true);
    try {
      await api.trustshare.resend(email, currentTeam.id);
      setResendDisabled(false);
      setCreateAccountMessage(CREATE_ACCOUNT_MESSAGES.EMAIL_RESENT);
    } catch (e) {
      setResendDisabled(false);
      setCreateAccountMessage(CREATE_ACCOUNT_MESSAGES.EMAIL_RESENDING_FAILED);
    }
  };

  switch (createAccountMessage) {
    case CREATE_ACCOUNT_MESSAGES.ACCOUNT_CREATED:
      return (
        <CreateAccountMessageContent
          title="Check your Inbox"
          message={
            <>
              We’re excited to share our compliance journey with you!
              <br />
              You will receive an email from our team shortly asking you to
              <br />
              verify your email address and complete your account setup.
            </>
          }
          action={
            <GlideButton
              as={Link}
              href="/"
            >
              Return To Home
            </GlideButton>
          }
          isSuccess
        />
      );
    case CREATE_ACCOUNT_MESSAGES.USER_ALREADY_EXISTS_FOR_TRUSTSHARE:
    case CREATE_ACCOUNT_MESSAGES.USER_ALREADY_EXISTS:
    case CREATE_ACCOUNT_MESSAGES.USER_ALREADY_COMPLETED_ACCOUNT_SETUP:
    case CREATE_ACCOUNT_MESSAGES.USER_ALREADY_VERIFIED:
      return (
        <CreateAccountMessageContent
          title="Account Already Exists"
          message={
            <>
              Looks like an account associated with this email already exists.
              <br />
              Sign in with your existing account to access additional documents.
            </>
          }
          action={
            <GlideButton
              as={Link}
              href="/login"
            >
              Sign In
            </GlideButton>
          }
        />
      );
    case CREATE_ACCOUNT_MESSAGES.USER_VERIFICATION_PENDING:
      return (
        <CreateAccountMessageContent
          title="Please Verify Your Email"
          message={
            <>
              Looks like an email verification link has already been sent.
              <br />
              The email verification link is valid for 3 days. Would you like the link to be resent?
            </>
          }
          action={
            <GlideButton
              onClick={handleResendVerificationEmail}
              disabled={isResendDisabled}
            >
              Yes, resend it
            </GlideButton>
          }
        />
      );
    case CREATE_ACCOUNT_MESSAGES.ACCOUNT_SETUP_INCOMPLETE:
      return (
        <CreateAccountMessageContent
          title="Account Setup Pending"
          message={
            <>
              Looks like your account setup is pending.
              <br />
              Would you like the link for account setup to be resent?
            </>
          }
          action={
            <GlideButton
              onClick={handleResendVerificationEmail}
              disabled={isResendDisabled}
            >
              Yes, resend it
            </GlideButton>
          }
        />
      );
    case CREATE_ACCOUNT_MESSAGES.TOKEN_EXPIRED:
      return (
        <CreateAccountMessageContent
          title="Oops! Email link expired!"
          message={
            <>
              Looks like the email verification link has expired.
              <br />
              The email verification link is valid for 3 days. Would you like to get a new link?
            </>
          }
          action={
            <GlideButton
              onClick={handleResendVerificationEmail}
              disabled={isResendDisabled}
            >
              Yes, resend it
            </GlideButton>
          }
        />
      );
    case CREATE_ACCOUNT_MESSAGES.EMAIL_RESENDING_FAILED:
      return (
        <CreateAccountMessageContent
          title="Oops! Something went wrong"
          message={
            <>
              Looks like we could not send an email
              <br />
              Would you like to retry getting a new link?
            </>
          }
          action={
            <GlideButton
              onClick={handleResendVerificationEmail}
              disabled={isResendDisabled}
            >
              Yes, resend it
            </GlideButton>
          }
        />
      );
    case CREATE_ACCOUNT_MESSAGES.INVALID_TOKEN:
    case CREATE_ACCOUNT_MESSAGES.ORIGIN_TEAM_CHANGED:
    case CREATE_ACCOUNT_MESSAGES.JSON_WEB_TOKEN_ERROR:
    case CREATE_ACCOUNT_MESSAGES.INVALID_VERIFICATION_LINK:
      return (
        <CreateAccountMessageContent
          title="Oops! The link is invalid!"
          message={
            <>
              We’re sorry, but the URL you entered is incorrect. Please double-check the URL and try again.
              <br />
              If you are trying to access the website from an email you received, please ensure that you are using the
              correct URL provided in the email.
            </>
          }
          action={
            <GlideButton
              as={Link}
              href="/"
            >
              Return To Home
            </GlideButton>
          }
        />
      );
    case CREATE_ACCOUNT_MESSAGES.EMAIL_RESENT:
      return (
        <CreateAccountMessageContent
          {...getEmailResentTitleAndMessage(message)}
          action={
            <GlideButton
              as={Link}
              href="/"
            >
              Return To Home
            </GlideButton>
          }
          isSuccess
        />
      );
    case CREATE_ACCOUNT_MESSAGES.ACCOUNT_EXPIRED:
      return (
        <CreateAccountMessageContent
          title="Oops! Your existing account has expired!"
          message={
            <>
              We see that your account exists, but has expired.
              <br />
              Please reach out to {currentTeam.name}
              {currentTeam.contactUsEmail ? (
                <>
                  {' at '}
                  <ContactSupport
                    element="span"
                    onClick={() => {
                      mailtoHandler(currentTeam.name, displayName);
                    }}
                  >
                    {currentTeam.contactUsEmail}
                  </ContactSupport>
                </>
              ) : null}{' '}
              to get your account reactivated.
            </>
          }
          action={
            <GlideButton
              as={Link}
              href="/"
            >
              Return To Home
            </GlideButton>
          }
        />
      );
    case CREATE_ACCOUNT_MESSAGES.SOMETHING_WENT_WRONG:
    case CREATE_ACCOUNT_MESSAGES.UNAUTHORIZED:
    default:
      return (
        <CreateAccountMessageContent
          title="Something went wrong!"
          message={
            <>
              Something went wrong!
              <br />
              Return home to view publicly accessible documents.
            </>
          }
          action={
            <GlideButton
              as={Link}
              href="/"
            >
              Return To Home
            </GlideButton>
          }
        />
      );
  }
}

export default CreateAccountMessage;
