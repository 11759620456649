// Package modules.
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import debounce from 'debounce-promise';
import styled from '@emotion/styled';
import { useNavigation } from 'react-navi';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  UilArrowDown as ArrowDownIcon,
  UilArrowUp as ArrowUpIcon,
  UilTimes as ClearIcon,
  UilEnter as EnterIcon,
  UilFileAlt as FileAltIcon,
  UilSearch as SearchIcon,
  UilSlidersV as SlidersVIcon,
} from '@iconscout/react-unicons';
import { flat, Heading, Typography } from '@kintent/glide';

// Local modules.
import { useDebounce } from 'lib/utils';
import { api } from 'lib/api';
import { Flex } from '../../../../components/Flex';
import { MediaQueriesContext } from '../../../../components/MediaQueriesProvider';
import { Spinner } from '../../../../components/Spinner';
import { IconButton, Stack } from '../../../../design-system';
import {
  BREAKPOINTS,
  DOCUMENT_TYPE,
  getCertificationCardTitle,
  getCertificationLogo,
  getCertificationLogoSrcSet,
} from '../../../../lib/constants';
import { useAuthService, useContentVisibility, useSearch } from '../../../../lib/state';

// Constants.
const DEFAULT_OPTION_ICON = <FileAltIcon />;

const REACT_SELECT_COMPONENT_STYLES = {
  control: (css, state) => ({
    ...css,
    borderColor: state.isFocused ? 'var(--selectedButtonColor)' : 'var(--muted)',
    boxShadow: 'none',
    color: state.isFocused ? 'var(--highlight)' : 'currentColor',
    minHeight: '50px',
    [`@media (min-width: ${BREAKPOINTS.DESKTOP}px)`]: { minHeight: 'unset' },
    paddingLeft: '12px',
    paddingRight: '8px',
    '&:hover': { borderColor: state.isFocused ? 'var(--selectedButtonColor)' : 'currentColor' },
  }),
  group: (css) => ({ ...css, padding: 'unset' }),
  groupHeading: (css) => ({ ...css, paddingLeft: '24px', paddingRight: '24px' }),
  input: (css) => ({
    ...css,
    color: 'var(--selectedButtonColor)',
    fontWeight: 500,
    height: '32px',
    overflow: 'hidden',
  }),
  loadingMessage: (css) => ({ ...css, fontWeight: 500, paddingLeft: '24px', paddingRight: '24px' }),
  menu: (css) => ({ ...css, borderRadius: 'none', boxShadow: 'none', margin: 'unset' }),
  noOptionsMessage: (css) => ({
    ...css,
    paddingLeft: '24px',
    paddingRight: '24px',
    textAlign: 'unset',
    wordBreak: 'break-all',
  }),
  option: (css, state) => ({
    ...css,
    backgroundColor: state.isFocused ? 'var(--highlightColor)' : 'none',
    color: 'currentColor', // Required to reset previously selected options' color.
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    pointerEvents: 'auto',
    fontWeight: 500,
    marginLeft: '12px',
    marginRight: '12px',
    paddingLeft: '12px',
    paddingRight: '12px',
    width: 'unset',
  }),
  placeholder: (css, state) => ({
    ...css,
    color: 'unset',
    fontWeight: 500,
    display: state.isFocused ? 'none' : 'block',
  }),
  valueContainer: (css) => ({ ...css, paddingLeft: '10px', paddingRight: '10px' }),
};

const SEARCH_RESULT_GROUPS = {
  CERTIFICATIONS: 'certifications',
  CONTROLS: 'controls',
  DOCUMENTS: 'documents',
  POLICIES: 'policies',
  SUBPROCESSORS: 'subprocessors',
};

// Styles.
const ControlWrapper = styled.div`
  --highlight: ${({ theme }) => flat(theme.color.brand.primary)};
  --muted: ${({ theme }) => theme.palette.silver};
  --selectedButtonColor: ${({ theme }) => theme.components.header.primaryCTAButton.background};

  border: 1px solid transparent;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 12px;

  color: ${({ theme }) => theme.palette.granite};

  &.open {
    border-color: ${({ theme }) => theme.palette.silver};
    background-color: ${({ theme }) => theme.palette.white};
  }

  & + * {
    /* menu */
    border: 1px solid ${({ theme }) => theme.palette.silver};
    border-top: none;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-top: 0;

    /* allow option highlight */
    --highlightColor: ${({ theme }) => flat(theme.color.brand.primary, '10%')};
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.palette.granite};
`;

const StyledTypography = styled(Typography)`
  padding-left: 24px;
  padding-right: 24px;

  kbd {
    color: ${({ theme }) => theme.palette.chalkboard};
  }

  svg {
    vertical-align: -0.5em;
  }
`;

// Helpers.
function formatGroupHeading(group) {
  return (
    <Heading
      level="7"
      as="span"
      data-testid="search-group-heading"
    >
      {group.label}
    </Heading>
  );
}

function formatOptionLabel(data) {
  return (
    <Flex
      alignItems="center"
      as={Typography}
      gap="8px"
      data-testid="search-option-value"
    >
      {data.icon ?? DEFAULT_OPTION_ICON}
      {data.label}
    </Flex>
  );
}

/*
  Content Visibility:
  - If overview access level itself is to hide, then we remove the links - don't show in the search results.
  - If the overview access level allows to show, but the details page is absent, then we show the result as paragraph tag instead of anchor tag and disable click.
*/
function formatSearchResults(
  { count, ...groups },
  contentVisibility,
  shouldHideControlsAndSubprocessors,
  shouldUseEnterpriseGradeURLs
) {
  if (count === 0) {
    return [];
  }
  const {
    shouldHidePolicyOverview: hidePolicyResults,
    shouldHidePolicyDetail: hidePolicyLinks,
    shouldHideControlOverview: hideControlResults,
    shouldHideControlDetail: hideControlLinks,
  } = contentVisibility;
  return Object.keys(groups).map((group) => ({
    label: group,
    options: groups[group]
      .map((item) => {
        const result = {
          id: item.id,
          href: `/${encodeURIComponent(group)}`,
          label: item.displayName ?? item.title ?? item.name,
        };

        if (group === SEARCH_RESULT_GROUPS.CERTIFICATIONS) {
          const { certification } = item;
          return {
            ...result,
            icon: (
              <img
                alt="Certification Logo"
                src={getCertificationLogo(certification.shortName, item.subtype)}
                srcSet={getCertificationLogoSrcSet(certification.shortName, item.subtype)}
                width={24}
                height={24}
              />
            ),
            label: getCertificationCardTitle(certification.shortName, item.subtype),
          };
        }

        if (group === SEARCH_RESULT_GROUPS.CONTROLS) {
          if (hideControlResults || shouldHideControlsAndSubprocessors) {
            return null;
          }
          if (hideControlLinks) {
            return {
              ...result,
              icon: <SlidersVIcon />,
              label: item.categorization.subcategory,
              isDisabled: true,
            };
          }
          return {
            ...result,
            icon: <SlidersVIcon />,
            href: `/controls/${encodeURIComponent(item.customShortName ?? item.shortName)}`,
            label: item.categorization.subcategory,
          };
        }

        if (group === SEARCH_RESULT_GROUPS.POLICIES) {
          if (hidePolicyResults) {
            return null;
          }
          if (hidePolicyLinks) {
            return {
              ...result,
              isDisabled: true,
            };
          }
          return {
            ...result,
            href: shouldUseEnterpriseGradeURLs
              ? `/documents/${item.id}?type=${DOCUMENT_TYPE.POLICY}`
              : `/policies/${item.shortName}`,
          };
        }

        if (group === SEARCH_RESULT_GROUPS.SUBPROCESSORS) {
          if (shouldHideControlsAndSubprocessors) {
            return null;
          }
          return {
            ...result,
            icon: item.logoUrl ? (
              <img
                alt={item.name}
                src={item.logoUrl}
                width={24}
                height={24}
              />
            ) : null,
            href: `/subprocessors/${encodeURIComponent(item.name)}`,
          };
        }

        if (group === SEARCH_RESULT_GROUPS.DOCUMENTS) {
          return {
            ...result,
            href: shouldUseEnterpriseGradeURLs
              ? `/documents/${item.id}?type=${DOCUMENT_TYPE.RESOURCE}`
              : `/documents/#document-${encodeURIComponent(item.displayName)}`,
          };
        }

        return result;
      })
      .filter((item) => item !== null),
  }));
}

function getOptionLabel(data) {
  return data.label;
}

function getOptionValue(data) {
  return data.id;
}

function getPlaceholder(name, { isTablet, isDesktop }) {
  if (isDesktop) {
    return `Looking for something specific? Search across ${name}’s security, trust, and compliance program`;
  }
  if (isTablet) {
    return `Search across ${name}’s security program`;
  }
  return `Search ${name}’s program`;
}

// Components.
function AccessibilityHints() {
  return (
    <StyledTypography>
      Use{' '}
      <kbd>
        <ArrowUpIcon />
      </kbd>
      <kbd>
        <ArrowDownIcon />
      </kbd>{' '}
      to navigate, press{' '}
      <kbd>
        <EnterIcon />
      </kbd>{' '}
      (<kbd>enter</kbd>) to select, <kbd>esc</kbd> to dismiss.
    </StyledTypography>
  );
}

function Control({ children, ...remainingProps }) {
  const { clearValue, menuIsOpen, selectProps } = remainingProps;
  return (
    <ControlWrapper className={menuIsOpen ? 'open' : 'closed'}>
      <components.Control {...remainingProps}>
        <StyledSearchIcon />
        {children}
        {selectProps.inputValue.length > 0 ? (
          <IconButton
            aria-label="Clear query"
            icon={<ClearIcon />}
            size="large"
            onClick={clearValue}
          />
        ) : null}
      </components.Control>
    </ControlWrapper>
  );
}

function LoadingMessage(props) {
  return (
    <components.LoadingMessage {...props}>
      <Flex
        alignItems="center"
        as={Typography}
        gap="1ch"
      >
        Loading results
        <Spinner />
      </Flex>
    </components.LoadingMessage>
  );
}

function MenuList({ children, isLoading, ...remainingProps }) {
  return (
    <components.MenuList
      isLoading={isLoading}
      data-testid="search-menu-list"
      {...remainingProps}
    >
      <Stack>
        {isLoading ? (
          <LoadingMessage {...remainingProps} />
        ) : (
          <>
            <AccessibilityHints />
            {children}
          </>
        )}
      </Stack>
    </components.MenuList>
  );
}

function NoOptionsMessage(props) {
  const { selectProps } = props;
  return (
    <components.NoOptionsMessage {...props}>
      <Typography
        as="p"
        level="8"
      >
        No results for <q>{selectProps.inputValue}</q>, try another search query.
      </Typography>
    </components.NoOptionsMessage>
  );
}

function VoidComponent() {
  return null;
}

// Configure react-select overrides.
const REACT_SELECT_COMPONENT_OVERRIDES = {
  Control,
  IndicatorsContainer: VoidComponent,
  LoadingMessage,
  MenuList,
  NoOptionsMessage,
};

export function Search({
  shouldHideControlsAndSubprocessors = false,
  placeholder = '',
  shouldUseEnterpriseGradeURLs = false,
}) {
  const [searchValue, setSearchValue] = useState('');

  const { navigate } = useNavigation();
  const { currentTeam } = useAuthService();
  const contentVisibility = useContentVisibility();
  const debouncedSearchValue = useDebounce(searchValue, 1000); // Debounce for 1000ms

  const { isTablet, isDesktop } = MediaQueriesContext.useContainer();

  // Use reducer to sync input value with search options.
  const [{ value, options }, dispatch] = useReducer(
    (state, action) => {
      if (action.type === 'set-value') {
        return { value: action.payload, options: [] };
      }

      // Update options if value is still up-to-date (set-options is triggered async).
      if (action.type === 'set-options' && action.payload.value === state.value) {
        return action.payload;
      }
      return state;
    },
    { value: '', options: [] }
  );

  // Retain input value and options after losing focus.
  const trackInputValue = useCallback(
    // eslint-disable-next-line consistent-return
    (newValue, { action }) => {
      // tracks the search input value
      setSearchValue(newValue);

      // Navigate to option on select.
      if (action === 'select-option') {
        if (newValue?.href) {
          navigate(newValue?.href);
        }
      } else if (action !== 'input-blur' && action !== 'menu-close') {
        const result = newValue ?? ''; // Clear action sets value to null.
        dispatch({ type: 'set-value', payload: result });
        return result;
      }

      // Do not return a value as this will trigger a search (and React error) when selecting an option.
    },
    [dispatch, navigate]
  );

  // Search handler.
  const search = useSearch();
  const debouncedSearch = useMemo(
    () =>
      debounce(async (query) => {
        const data = await search(query);
        const result = formatSearchResults(
          data,
          contentVisibility,
          shouldHideControlsAndSubprocessors,
          shouldUseEnterpriseGradeURLs
        );
        dispatch({ type: 'set-options', payload: { value: query, options: result } });
        return result;
      }, 250),
    [dispatch, search]
  );

  useEffect(() => {
    if (debouncedSearchValue !== '') {
      api.track.insertActivity({ keywordSearch: debouncedSearchValue });
    }

    return () => setSearchValue('');
  }, [debouncedSearchValue]);

  const hasValue = value.length > 0;
  return (
    <AsyncSelect
      aria-labelledby="search-label"
      components={REACT_SELECT_COMPONENT_OVERRIDES}
      defaultOptions={options}
      formatGroupLabel={formatGroupHeading}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      inputId="search"
      inputValue={value}
      loadOptions={debouncedSearch}
      onChange={trackInputValue}
      onInputChange={trackInputValue}
      openMenuOnClick={hasValue}
      openMenuOnFocus={hasValue}
      placeholder={
        shouldHideControlsAndSubprocessors ? placeholder : getPlaceholder(currentTeam.name, { isTablet, isDesktop })
      }
      styles={REACT_SELECT_COMPONENT_STYLES}
      tabSelectsValue={false}
    />
  );
}
