/** @jsxImportSource @emotion/react */
// Package modules.
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from 'reakit';

const SIZES = {
  XLARGE: 'xlarge',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const VARIANTS = { PRIMARY: 'primary' };

const StyledButton = styled(Button, {
  shouldForwardProp(prop) {
    return !['iconPixelSize'].includes(prop);
  },
})`
  display: inline-flex;
  width: ${(props) => (props.iconPixelSize <= 24 ? 24 : 40)}px;
  height: ${(props) => (props.iconPixelSize <= 24 ? 24 : 40)}px;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  svg {
    width: ${(props) => props.iconPixelSize}px;
    height: ${(props) => props.iconPixelSize}px;
  }

  &[aria-disabled='true'] {
    opacity: 0.5;

    cursor: not-allowed;
  }

  &:active,
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.components.iconbutton.highlight};
    border-radius: 50%;
  }
`;

const IconButton = forwardRef(({ variant, size, icon, ...buttonProps }, ref) => {
  let iconPixelSize;
  if (size === SIZES.XLARGE) {
    iconPixelSize = 32;
  } else if (size === SIZES.LARGE) {
    iconPixelSize = 24;
  } else if (size === SIZES.SMALL) {
    iconPixelSize = 12;
  } else {
    iconPixelSize = 16;
  }

  return (
    <StyledButton
      as="div"
      iconPixelSize={iconPixelSize}
      ref={ref}
      {...buttonProps}
    >
      {icon}
    </StyledButton>
  );
});

IconButton.propTypes = {
  /** Controls the overall style of this icon button. */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /** The size of the icon within the button. The size of the button itself is fixed at 24px. */
  size: PropTypes.oneOf(Object.values(SIZES)),
  /** An icon, which will be displayed centered within the button.  */
  icon: PropTypes.element.isRequired,
};

IconButton.defaultProps = {
  variant: VARIANTS.PRIMARY,
  size: SIZES.MEDIUM,
};

export default IconButton;
