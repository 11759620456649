import React from 'react';
import { Typography } from '@kintent/glide';
import { Link } from 'react-navi';

import * as Styles from './NavigationBarV2.styles';
import { NoLogoLabel, StyledTrustShareLogo } from '../NavigationBar';
import { useAuthService, useCustomerLogo, useTeamLogo } from '../../lib/state';

// Constants
const NAVIGATION_ITEMS = [
  {
    title: 'Home',
    path: '/home',
    exact: true,
  },
  {
    title: 'Documents',
    path: '/documents',
    exact: false,
  },
  {
    title: 'Notifications',
    path: '/notifications',
    exact: false,
  },
];

function Logo() {
  const { currentTeam } = useAuthService();
  const [teamLogo] = useTeamLogo(currentTeam?.id);
  const [trustShareLogo] = useCustomerLogo();

  const logoToDisplay = trustShareLogo ?? teamLogo ?? null;

  return (
    <div>
      {logoToDisplay ? (
        <Styles.TrustShareLogoContainer
          href={currentTeam?.websiteUrl}
          target="_blank"
        >
          <StyledTrustShareLogo
            src={logoToDisplay}
            alt={`${currentTeam?.name} TrustShare logo`}
          />
        </Styles.TrustShareLogoContainer>
      ) : (
        <NoLogoLabel>
          <Typography level="8">{currentTeam?.name}</Typography>
        </NoLogoLabel>
      )}
    </div>
  );
}

function NavigationBarV2() {
  return (
    <Styles.Navigation data-testid="enterprise-navigation-bar">
      <Styles.LogoContainer data-test-id="navigation-bar-logo-container">
        <Logo />
      </Styles.LogoContainer>
      <Styles.MenuItemsContainer data-test-id="navigation-bar-items-container">
        <Styles.NavigationItemList>
          {NAVIGATION_ITEMS.map((item) => {
            return (
              <li key={item.title}>
                <Styles.NavigationItem
                  as={Link}
                  strength="60%"
                  activeClassName="active"
                  href={item.path}
                >
                  {item.title}
                </Styles.NavigationItem>
              </li>
            );
          })}
        </Styles.NavigationItemList>
      </Styles.MenuItemsContainer>
    </Styles.Navigation>
  );
}

export default NavigationBarV2;
