import React, { useEffect, useState } from 'react';
import { useCurrentRoute, useNavigation } from 'react-navi';

import { FloatingRequestAccessButton } from '../../../components/FloatingRequestAccessButton';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { Helmet } from '../../../components/Helmet';
import { useContentVisibility, useControlList, useTestCountForControls } from '../../../lib/state';
import { filterForAdoptedControls, transformControlsByCategory } from '../../../lib/utils';
import ControlDetailPanel from './components/ControlDetailPanel';
import ControlCategoryPanel from './components/ControlCategoryPanel';
import ControlSectionDropdown from './components/ControlSectionDropdown';
import PageContainer from '../../../components/PageContainer';
import { useMeasurePaint, useMeasureRender, useMeasureRoute } from '../../../lib/measurement';

const ControlDetail = withTrustShareNavigationBar(({ shortName }) => {
  const {
    url: { pathname },
    data: { span },
  } = useCurrentRoute();
  const { navigate } = useNavigation();

  if (shortName) {
    span.setAttribute('page-name', 'control-details');
  } else {
    span.setAttribute('page-name', 'controls');
  }
  useMeasureRoute(span);

  const [controls] = useControlList();
  const { testCount } = useTestCountForControls();

  const adoptedControls = filterForAdoptedControls(controls);
  const adoptedControlsByCategory = transformControlsByCategory(adoptedControls);
  const sortedControlCategory = Object.keys(adoptedControlsByCategory).sort();
  const { shouldHideControlDetail } = useContentVisibility();

  let controlToSelect = null;

  if (shortName) {
    controlToSelect = controls.find(
      (control) => control.shortName === shortName || control.customShortName === shortName
    );
  }

  const [selectedControlCategory, setSelectedControlCategory] = useState(
    controlToSelect ? controlToSelect.categorization.category : sortedControlCategory[0]
  );

  const firstCategory = sortedControlCategory[0];
  const firstControl = adoptedControlsByCategory[firstCategory].sort((a, b) =>
    a.categorization.subcategory > b.categorization.subcategory ? 1 : -1
  )[0];

  useEffect(() => {
    const selectedControl = controlToSelect || firstControl;
    window.history.replaceState(
      null,
      null,
      `/controls/${encodeURIComponent(selectedControl.customShortName ?? selectedControl.shortName)}`
    );
  }, []);

  useEffect(() => {
    if (pathname.startsWith('/controls') && shouldHideControlDetail) {
      navigate('/home');
    }
  }, [pathname, shouldHideControlDetail]);

  const handleControlCategoryClick = (controlCategory) => {
    if (controlCategory === selectedControlCategory) {
      setSelectedControlCategory(null);
    } else {
      setSelectedControlCategory(controlCategory);
    }
  };

  const tree = (
    <>
      <Helmet pageTitle="Controls" />
      <PageContainer
        alignItems="flex-start"
        gap="32px"
        direction="column"
      >
        <ControlCategoryPanel
          sortedControlCategory={sortedControlCategory}
          adoptedControlsByCategory={adoptedControlsByCategory}
          selectedControlCategory={selectedControlCategory}
          onControlCategoryClick={handleControlCategoryClick}
          selectedControl={controlToSelect || firstControl}
        />
        <ControlSectionDropdown
          sortedControlCategory={sortedControlCategory}
          adoptedControlsByCategory={adoptedControlsByCategory}
          selectedControl={controlToSelect || firstControl}
        />
        <ControlDetailPanel
          selectedControl={controlToSelect || firstControl}
          testCount={testCount.get(controlToSelect?.id ?? firstControl?.id)}
        />
      </PageContainer>
      <FloatingRequestAccessButton />
    </>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});

export default ControlDetail;
