// Package modules
import React from 'react';
import { Link } from 'react-navi';
import { Typography } from '@kintent/glide';

export function TermsAndPrivacyPolicy() {
  return (
    <Typography level="8">
      I accept the TrustCloud&nbsp;
      <Link
        href="https://www.trustcloud.ai/legal/tos/"
        target="_blank"
      >
        Terms of Service
      </Link>
      &nbsp;and&nbsp;
      <Link
        href="https://www.trustcloud.ai/legal/privacy/"
        target="_blank"
      >
        Privacy Policy
      </Link>
    </Typography>
  );
}
