import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Tooltip as BaseTooltip } from 'reakit';
import { hexOpacity } from '../lib/utils';

const VARIANTS = { PRIMARY: 'primary', ERROR: 'error' };

const getBorderColor = (variant, tooltipTheme) =>
  tooltipTheme.border && tooltipTheme.border[variant] ? `1.5px solid ${tooltipTheme.border[variant]}` : 'none';

const StyledTooltip = styled(BaseTooltip, { shouldForwardProp: (prop) => prop !== 'variant' })`
  z-index: 100;
  background-color: ${({
    variant,
    theme: {
      components: { tooltip },
    },
  }) => hexOpacity(tooltip.background[variant], 0.9)};
  border: ${({
    variant,
    theme: {
      components: { tooltip },
    },
  }) => getBorderColor(variant, tooltip)};
  color: ${({
    variant,
    theme: {
      components: { tooltip },
    },
  }) => tooltip.text[variant]};
  padding: 6px 10px;
  border-radius: ${({ theme }) => theme.radius.md};

  font-size: 0.75rem;

  display: flex;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    margin-left: -5px;

    ${({
      placement = 'bottom',
      variant,
      theme: {
        components: { tooltip },
      },
    }) => {
      if (placement === 'bottom') {
        return css`
          bottom: 100%;
          border-top: 0;
          border-bottom-color: ${hexOpacity(tooltip.background[variant], 0.9)};
        `;
      }
      if (placement === 'top-end') {
        return css`
          left: unset;
          right: 3%;
          top: 100%;
          border-bottom: 0;
          border-top-color: ${hexOpacity(tooltip.background[variant], 0.9)};
        `;
      }
      if (placement === 'right') {
        return css`
          margin-left: 0px; // This causes inconsistencies when the placement is set to right
          left: -10px;
          right: unset;
          border-bottom-color: ${hexOpacity(tooltip.background[variant], 0.9)};
          transform: rotate(270deg);
        `;
      }
      return css`
        top: 100%;
        border-bottom: 0;
        border-top-color: ${hexOpacity(tooltip.background[variant], 0.9)};
      `;
    }}
  }
`;

function Tooltip({ variant, ...props }) {
  return (
    <StyledTooltip
      variant={variant}
      {...props}
    />
  );
}

export default Tooltip;

Tooltip.propTypes = {
  /** The semantic type the tooltip represents. */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

Tooltip.defaultProps = { variant: VARIANTS.PRIMARY };
