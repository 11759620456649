import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import { Stack } from '../../../../design-system';
import { hexOpacity, pluralize } from '../../../../lib/utils';
import { useAuthService, useSubprocessorList } from '../../../../lib/state';
import ControlPageSection from './ControlPageSection';
import CardContainer from '../../../../components/CardContainer';

const StyledScopeCard = styled(Flex)`
  padding: 16px;

  height: 67px;

  max-width: 250px;

  border-radius: 8px;

  box-shadow: 0 1px 4px 0 ${({ theme }) => hexOpacity(theme.palette.slate, 0.25)},
    0 2px 6px 0 ${({ theme }) => hexOpacity(theme.palette.slate, 0.25)},
    0 3px 7px 0 ${({ theme }) => hexOpacity(theme.palette.slate, 0.25)},
    0 4px 8px 0 ${({ theme }) => hexOpacity(theme.palette.slate, 0.25)};

  position: relative;

  cursor: pointer;

  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;

    border-radius: 10px;

    width: 100%;
    height: 100%;

    opacity: 0;

    transition: all 0.15s ease-in;
  }

  &:hover {
    transform: scale(1.05, 1.05);
    box-shadow: 0 4px 16px 0 ${({ theme }) => theme.palette.slate};
  }

  &:hover::after {
    opacity: 1;
  }
`;

const StyledLogo = styled(Flex)`
  width: 20px;
  height: 20px;

  > img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const StyledLogoWrapper = styled(Typography)`
  width: 20px;
  height: 20px;

  border: 2px solid ${({ theme }) => theme.palette.silver};
  border-radius: 50%;
`;

function ControlScope({ control }) {
  const { currentTeam } = useAuthService();
  const [vendors] = useSubprocessorList();

  const matchedSubProcessors = vendors.filter(
    (subProcessor) =>
      subProcessor.isSubprocessor && control.stackComponentIds.some((id) => subProcessor.stackComponentIds.includes(id))
  );
  if (matchedSubProcessors.length === 0) return null;

  return (
    <CardContainer>
      <ControlPageSection
        title="Control Scope"
        count={matchedSubProcessors.length}
        description={`This control applies to ${matchedSubProcessors.length > 1 ? 'these' : 'this'} ${pluralize(
          'subprocessor',
          matchedSubProcessors.length
        )} 
        used at ${currentTeam.name}:`}
      >
        <Flex
          wrap
          gap="16px"
        >
          {matchedSubProcessors.map((subProcessor) => {
            return (
              <StyledScopeCard
                key={subProcessor.id}
                alignItems="center"
                onClick={() => {
                  window.open(`/subprocessors/${encodeURIComponent(subProcessor.name)}`, '_self');
                }}
              >
                <Stack>
                  <Flex
                    alignItems="center"
                    gap="8px"
                  >
                    {subProcessor.logoUrl ? (
                      <StyledLogo alignItems="center">
                        <img
                          src={subProcessor.logoUrl}
                          alt={subProcessor.name}
                        />
                      </StyledLogo>
                    ) : (
                      <StyledLogoWrapper
                        as={Flex}
                        alignItems="center"
                        justifyContent="center"
                        level="9"
                      >
                        {subProcessor.name[0]}
                      </StyledLogoWrapper>
                    )}
                    <Flex direction="column">
                      <Typography
                        as="p"
                        level="8"
                      >
                        {subProcessor.name}
                      </Typography>
                    </Flex>
                  </Flex>
                </Stack>
              </StyledScopeCard>
            );
          })}
        </Flex>
      </ControlPageSection>
    </CardContainer>
  );
}

export default ControlScope;
