import styled from '@emotion/styled';
import { color } from '@kintent/glide';

export const MainGrid = styled.main`
  grid-area: main;

  background-color: ${({ theme }) => color(theme.colorV2.system.gray, 100)};

  min-height: 100vh;

  padding: 12rem 4rem 4rem 4rem;

  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const DocumentsContainer = styled.section`
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  background-color: white;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 600)};

  box-shadow: ${({ theme }) => `${theme.boxShadow.drop} ${color(theme.colorV2.system.gray, 100)}`};

  padding: 48px;
`;

export const DocumentContainerHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-bottom: 36px;
  padding-left: 12px;

  & p {
    width: 80%;
  }
`;

export const DocumentListContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 36px;

  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
`;

export const DocumentSearchContainer = styled.div`
  & p {
    padding-left: 12px;
  }
`;
