// Package modules
import React from 'react';
import styled from '@emotion/styled';

// Local modules
import { Flex } from '../../../components/Flex';
import { BREAKPOINTS } from '../../../lib/constants';

// Styled components
const StyledFlex = styled(Flex)`
  padding-bottom: 2rem;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      gap: 2rem;
      justify-content: flex-end;
      flex-direction: row;
    }
  }
`;

// Exports
export const SignInAndRequestAccessButtonsWrapper = (props) => (
  <StyledFlex
    gap="1rem"
    justifyContent="center"
    direction="column"
    {...props}
  />
);
