import React from 'react';
import { motion } from 'framer-motion';

// Constants
export const HEARTBEAT_PATH_VARIANTS = {
  hidden: {
    pathLength: 0,
    opacity: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      opacity: { duration: 0.1, ease: 'easeIn' },
      pathLength: { duration: 2, ease: 'easeOut', repeat: Infinity },
    },
  },
};

const SVG_CONTAINER_VARIANTS = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.1 } },
  exit: {
    opacity: 0,
    scale: [0.9, 0.1, 0],
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

function Heartbeat({ strokeColor }) {
  return (
    <motion.svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      initial="hidden"
      animate="visible"
      variants={SVG_CONTAINER_VARIANTS}
      xmlns="http://w3.org/2000/svg"
    >
      <motion.path
        d="M 1,10 L 6,10 L 8,5 L 10,10 L 12,15 L 14,10 L 16,10 L 19,10"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth={1.8}
        strokeLinecap="round"
        variants={HEARTBEAT_PATH_VARIANTS}
      />
    </motion.svg>
  );
}

export default Heartbeat;
