// Package modules
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import {
  Box,
  unstable_Form as Form,
  TooltipReference,
  useCheckboxState,
  unstable_useFormState as useFormState,
  useTooltipState,
} from 'reakit';
import { Link, useCurrentRoute, useNavigation } from 'react-navi';
import { UilArrowLeft as ArrowLeftIcon, UilEnvelope } from '@iconscout/react-unicons';
import { motion, useAnimation } from 'framer-motion';

// Local modules
import { FormCheckbox, FormContext, FormInput, FormMessage, SpaceAround, Stack, Tooltip } from '../../../design-system';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { Helmet } from '../../../components/Helmet';
import { AuthenticationCard, GoToSignInButton } from '../components/AuthFlowComponents';
import { Checkbox as StyledCheckbox } from '../../../components/Checkbox';
import { CardTitleLabel, ErrorDescriptionLabel, ErrorTitleLabel } from '../../../components/PageElements';
import { TypographyDivider } from '../../../components/TypographyDivider';
import { Flex } from '../../../components/Flex';
import { GoogleSso } from '../../../components/Google';
import { MicrosoftSso } from '../../../components/Microsoft';
import { InvitationNotFoundError } from './InvitationNotFoundError';
import { SignInAndRequestAccessButtonsWrapper } from '../components/SignInAndRequesAccessButtonsWrapper';
import { TermsAndPrivacyPolicy } from '../../../components/TermsAndPrivacyPolicy';
import { MediaQueryContainer } from '../../../components/ResponsiveContainer';
import { useAuthService } from '../../../lib/state';
import { useDeviceResolution } from '../../../lib/hooks';
import { hasPasswordBeenBreached } from '../../../lib/utils';
import { api } from '../../../lib/api';
import { BREAKPOINTS, DEVICE_TYPES, USER_TYPE } from '../../../lib/constants';
import { useMeasurePaint, useMeasureRender, useMeasureRoute } from '../../../lib/measurement';
import { GlideButton } from '../../../components/GlideButton';

// Constants
const COMMON_TRANSITIONS = {
  visible: { ease: 'easeInOut' },
  hidden: { ease: 'easeInOut' },
};

const SSO_CONTAINER_ANIMATION_VARIANTS = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.35 },
    transitionEnd: { display: 'flex' },
  },
  middle: { transitionEnd: { display: 'flex' } },
  hidden: {
    opacity: 0,
    x: -200,
    transition: { duration: 0.65 },
    transitionEnd: { display: 'none' },
  },
};

const INPUT_CONTAINER_ANIMATION_VARIANTS = {
  hidden: {
    x: 200,
    opacity: 0,
    transition: { duration: 0.65 },
    transitionEnd: { display: 'none' },
  },
  middle: { transitionEnd: { display: 'block' } },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.65 },
    transitionEnd: { display: 'block' },
  },
};

const DIVIDER_ANIMATION_CONTROLS = {
  visible: {
    y: 0,
    opacity: 1,
    pointerEvents: 'all',
  },
  hidden: {
    y: 40,
    opacity: 0,
    transitionEnd: { pointerEvents: 'none' },
  },
};

// Styled Components
const JoinCard = styled(AuthenticationCard, {
  shouldForwardProp(props) {
    return !['minHeight'].includes(props);
  },
})`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 'initial')};
    }
  }
`;

const JoinContentContainer = styled(Box)`
  width: 100%;
  padding-bottom: 3rem;

  & > * + *:not(.ignore-margin) {
    margin-top: 2rem;
  }
`;

const JoinOptionsContainer = styled(Flex)`
  position: relative;
  overflow: hidden;
`;

const EmailPasswordAnimationContainer = styled(motion.div)`
  width: 100%;
  height: 160px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      height: 260px;
    }
  }
`;

const SSOStack = styled(Stack)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 160px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      height: 260px;
    }
  }
`;

const JoinForm = styled(Form)`
  width: 100%;

  // Add space for the 'outline' of input fields on mobile devices
  padding-left: 4px;
  padding-right: 4px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      width: 60%;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  background-color: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.ariel};
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.navy};

  width: 225px;

  padding: 1rem;

  &:after {
    display: none;
  }
`;

const StyledTypographyDivider = styled(TypographyDivider)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      margin-bottom: 0;
    }
  }
`;

function EmailContainer({ handleBackButtonClick, invitation, token }) {
  const { login } = useAuthService();
  const theme = useTheme();
  const { isDesktop } = useDeviceResolution();
  const tooltip = useTooltipState({ placement: 'right-start' });

  // Refs
  const passwordInputFieldRef = useRef(null);

  const createAccountForm = useFormState({
    onSubmit: async ({ name, email, password }) => {
      try {
        const user = await api.user.create({
          name,
          email,
          password,
          company: invitation.company,
          invitationToken: token,
        });
        await login(user.email, password, token, USER_TYPE.TEMPORARY_GUEST);
      } catch (e) {
        // TODO
      }
    },
    onValidate: async ({ name, password, termsAccepted }) => {
      const errors = {};

      if (name.trim().length === 0) {
        errors.name = 'Name cannot be empty';
      }

      if (passwordInputFieldRef.current.checkValidity() === false || password.length < 8) {
        errors.password = 'Please enter at least 8 characters';
      } else {
        const breached = await hasPasswordBeenBreached(password);
        if (breached) {
          errors.password = (
            <>
              This password has previously been exposed in a data breach. Please choose a different password.&nbsp;
              <Link href="https://haveibeenpwned.com/About">Learn More</Link>
            </>
          );
        }
      }

      if (termsAccepted === false) {
        errors.termsAccepted = 'You must accept our terms of use and privacy policy';
      }

      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    validateOnBlur: true,
    values: {
      name: invitation.name,
      email: invitation.email,
      password: '',
      termsAccepted: false,
    },
  });

  // Change tooltip placement on mobile and tablets devices.
  useEffect(() => {
    tooltip.place(isDesktop ? 'right-start' : 'top-end');
  }, [tooltip, isDesktop]);

  return (
    <Flex justifyContent="center">
      <FormContext.Provider value={createAccountForm}>
        <JoinForm {...createAccountForm}>
          <Stack space="lg">
            <FormInput
              name="name"
              placeholder="Name"
              required
            />
            <FormMessage name="name" />
            <FormInput
              name="email"
              type="email"
              disabled
              required
            />
            <FormMessage name="email" />
            <TooltipReference {...tooltip}>
              <FormInput
                minLength={8}
                name="password"
                placeholder="Password*"
                ref={passwordInputFieldRef}
                type="password"
                required
              />
            </TooltipReference>
            <FormMessage name="password" />
            <Flex
              alignItems="center"
              as="label"
              justifyContent="center"
              gap="1rem"
            >
              <FormCheckbox
                as={StyledCheckbox}
                name="termsAccepted"
                required
              />
              <TermsAndPrivacyPolicy />
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              gap={{ x: '1rem' }}
            >
              <GlideButton
                variant="ghost"
                icon={
                  <ArrowLeftIcon
                    color={theme.components.header.primaryCTAButton.background}
                    size={16}
                  />
                }
                onClick={handleBackButtonClick}
                className="ghost"
              >
                Back
              </GlideButton>
              <GlideButton
                variant="submit"
                type="submit"
                disabled={!createAccountForm.valid}
              >
                Create Account
              </GlideButton>
            </Flex>
            <StyledTooltip {...tooltip}>
              Passwords should be at least 8 characters, and include uppercase letters as well as numbers
            </StyledTooltip>
          </Stack>
        </JoinForm>
      </FormContext.Provider>
    </Flex>
  );
}

function SSOError({ setHasSSOError }) {
  const { navigate } = useNavigation();
  return (
    <JoinCard>
      <ErrorTitleLabel>SSO Failed</ErrorTitleLabel>
      <Stack space="xl">
        <ErrorDescriptionLabel align="justify">
          Oops! This email is not associated with a TrustShare account. You may not have created an account, or your
          invitation or account may have expired. To request an invitation, please fill out the Access Request form.
        </ErrorDescriptionLabel>
        <SignInAndRequestAccessButtonsWrapper>
          <GlideButton
            onClick={() => {
              setHasSSOError(false);
              navigate('/login');
            }}
            variant="secondary"
            className="secondary"
          >
            Back to Sign In
          </GlideButton>
          <GlideButton
            onClick={() => {
              setHasSSOError(false);
              navigate('/request-access');
            }}
          >
            Request Access
          </GlideButton>
        </SignInAndRequestAccessButtonsWrapper>
      </Stack>
    </JoinCard>
  );
}

function JoinOptionsWrapper({ invitation, token }) {
  const { currentTeam } = useAuthService();
  const checkbox = useCheckboxState({ state: false });

  // Animation controls
  const ssoBoxControls = useAnimation();
  const emailPasswordBoxControls = useAnimation();
  const dividerBoxControls = useAnimation();

  const showEmailLogin = async () => {
    await dividerBoxControls.start('hidden');
    await ssoBoxControls.start('hidden');
    await emailPasswordBoxControls.start('middle');
    await emailPasswordBoxControls.start('visible');
  };

  const showSSOLogin = async () => {
    await emailPasswordBoxControls.start('hidden');
    await ssoBoxControls.start('middle');
    await dividerBoxControls.start('visible');
    await ssoBoxControls.start('visible');
  };

  return (
    <JoinCard minHeight={640}>
      <JoinOptionsContainer>
        <JoinContentContainer>
          <MediaQueryContainer
            as={CardTitleLabel}
            hideFrom={DEVICE_TYPES.MOBILE}
            showFor={DEVICE_TYPES.TABLET}
          >
            {`Access ${currentTeam.name}’s TrustShare`}
          </MediaQueryContainer>
          <MediaQueryContainer
            className="ignore-margin"
            as={CardTitleLabel}
            hideFrom={DEVICE_TYPES.TABLET}
          >
            {`Access ${currentTeam.name}’s`}
            <br />
            TrustShare
          </MediaQueryContainer>
          <SSOStack
            as={motion.div}
            space="xl"
            transition={COMMON_TRANSITIONS}
            initial="visible"
            variants={SSO_CONTAINER_ANIMATION_VARIANTS}
            animate={ssoBoxControls}
          >
            <GoogleSso disabled={!checkbox.state} />
            <MicrosoftSso disabled={!checkbox.state} />
          </SSOStack>
          <EmailPasswordAnimationContainer
            as={motion.div}
            transition={COMMON_TRANSITIONS}
            initial="hidden"
            variants={INPUT_CONTAINER_ANIMATION_VARIANTS}
            animate={emailPasswordBoxControls}
          >
            <EmailContainer
              invitation={invitation}
              token={token}
              handleBackButtonClick={showSSOLogin}
            />
          </EmailPasswordAnimationContainer>
          <Stack
            space="lg"
            as={motion.div}
            initial="visible"
            transition={COMMON_TRANSITIONS}
            variants={DIVIDER_ANIMATION_CONTROLS}
            animate={dividerBoxControls}
          >
            <SpaceAround
              as={StyledTypographyDivider}
              bottom="48px"
            />
            <Flex
              justifyContent="center"
              alignItems="center"
              as="label"
              gap="1rem"
            >
              <StyledCheckbox {...checkbox} />
              <TermsAndPrivacyPolicy />
            </Flex>
            <Flex justifyContent="center">
              <GoToSignInButton
                startIcon={<UilEnvelope size={20} />}
                onClick={showEmailLogin}
              >
                Sign up with email
              </GoToSignInButton>
            </Flex>
          </Stack>
        </JoinContentContainer>
      </JoinOptionsContainer>
    </JoinCard>
  );
}

export const Join = withTrustShareNavigationBar(({ showError = false }) => {
  const { data } = useCurrentRoute();
  const { invitation, token, invitationFetchError, span } = data;
  span.setAttribute('page-name', 'join');
  useMeasureRoute(span);

  // State
  const [hasSSOError, setHasSSOError] = useState(showError);

  const tree = (
    <>
      <Helmet pageTitle="Join" />
      {!invitationFetchError && !showError && (
        <JoinOptionsWrapper
          invitation={invitation}
          token={token}
        />
      )}
      {hasSSOError && <SSOError setHasSSOError={setHasSSOError} />}
      {invitationFetchError && (
        <JoinCard>
          <InvitationNotFoundError />
        </JoinCard>
      )}
    </>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});
