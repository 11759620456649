// Package modules
import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Heading, Typography } from '@kintent/glide';

// Local modules
import { Flex } from './Flex';
import { formatDate } from '../lib/utils';
import { useGlobalService } from '../lib/state';
import { BREAKPOINTS } from '../lib/constants';
import { PROGRAM_COPY_VARIANTS } from '../app/trust-share/home/components/AnimatedContentContainer';

// Styled Components
const StyledPageTitle = styled(Heading)`
  text-transform: uppercase;
  letter-spacing: 5px;
`;

const InnerTimestampLabel = styled(Typography)`
  letter-spacing: 2px;
  color: ${({ theme }) => theme.components.header.trustShareLabel.textColor};
  text-align: right;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    letter-spacing: 3px;
  }
`;

const InnerDescriptionLabel = styled(Typography)`
  text-align: center;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      text-align: left;
    }
  }
`;

const InnerErrorDescriptionLabel = styled(InnerDescriptionLabel)`
  color: ${({ theme }) => theme.palette.pimento};

  padding: 1rem 1rem;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding: 3rem 4rem;
    }
  }
`;

const InnerTitleLabel = styled(Heading)`
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      text-align: left;
    }
  }
`;

const InnerErrorTitleLabel = styled(InnerTitleLabel)`
  padding-bottom: 2rem;
`;

const ContentTitle = styled(motion(Heading))`
  text-transform: uppercase;
  letter-spacing: 4px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      letter-spacing: 5px;
    }
  }
`;

const ContentDescription = styled(motion(Typography))`
  letter-spacing: 0.4px;
`;

const InnerErrorLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.pimento};

  padding: 1rem 1rem;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding: 3rem 4rem;
    }
  }
`;

const StyledHeading = styled(Heading)`
  text-transform: uppercase;

  letter-spacing: 2px;
`;
export const PageTitle = (props) => (
  <StyledPageTitle
    as="h1"
    level="4"
    {...props}
  />
);

export function PageSubtitle(props) {
  return (
    <Typography
      as="p"
      {...props}
    />
  );
}

export function TimestampLabel(props) {
  const { lastUpdatedAt } = useGlobalService();
  const { time } = formatDate(lastUpdatedAt);
  return (
    <Flex
      as={InnerTimestampLabel}
      level="9"
      {...props}
    >
      <span>Updated</span>
      <span>
        &nbsp;
        {time}
      </span>
    </Flex>
  );
}

export function ErrorDescriptionLabel(props) {
  return (
    <InnerErrorDescriptionLabel
      as="p"
      level="8"
      {...props}
    />
  );
}

export function ErrorTitleLabel(props) {
  return (
    <InnerErrorTitleLabel
      level="6"
      {...props}
    />
  );
}

export function CardDescriptionLabel(props) {
  return (
    <InnerDescriptionLabel
      as="p"
      level="8"
      {...props}
    />
  );
}

export function CardTitleLabel(props) {
  return (
    <InnerTitleLabel
      level="6"
      {...props}
    />
  );
}

export function SectionTitle(props) {
  return (
    <ContentTitle
      as="p"
      variants={PROGRAM_COPY_VARIANTS}
      {...props}
    />
  );
}

export function SectionHeader(props) {
  return <ContentTitle {...props} />;
}

export function SectionDescription(props) {
  return (
    <ContentDescription
      as="p"
      variants={PROGRAM_COPY_VARIANTS}
      {...props}
    />
  );
}

export function ErrorLabel(props) {
  return (
    <InnerErrorLabel
      as="p"
      level="8"
      {...props}
    />
  );
}

export function CardTitle(props) {
  return (
    <StyledHeading
      level="4"
      {...props}
    />
  );
}
