import React, { useMemo } from 'react';
import { Link, Typography } from '@kintent/glide';
import { format } from 'date-fns';

import * as Styles from './NotificationSection.styles';
import CustomTag from '../../../../../components/CustomTag/CustomTag';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import { useProgramContent, useTrustShareNotifications } from '../../../../../lib/state';
import { getPublicNotifications } from '../../../../../lib/utils';

// Constants
const MAX_NOTIFICATIONS = 3;

function NotificationListItem({ notification }) {
  const formattedDate = format(new Date(notification._metadata.createdDate), 'MMMM d, yyyy');
  return (
    <li className="notification-list-item">
      <Typography
        as={Link}
        level="8"
        strength="80%"
        className="notification-item-title"
        href={`/notifications/${notification.id}`}
      >
        {notification.title}
      </Typography>
      <Typography
        as="p"
        level="9"
        strength="60%"
      >
        {formattedDate}
      </Typography>
    </li>
  );
}

function NotificationSection() {
  const [{ secondarySectionTitle }] = useProgramContent();
  const { data: allNotifications } = useTrustShareNotifications();
  const publicNotifications = useMemo(() => getPublicNotifications(allNotifications), [allNotifications]);
  const topThreeNotifications = publicNotifications.slice(0, MAX_NOTIFICATIONS);
  const remainingNotificationCount = publicNotifications.length - MAX_NOTIFICATIONS;

  return (
    <Styles.NotificationGridItem data-testid="notification-section">
      <Styles.GridItemHeading>
        <Typography
          as="h5"
          level="5"
          className="notification-section-title"
        >
          {secondarySectionTitle}
        </Typography>
        <CustomTag>{publicNotifications.length}</CustomTag>
      </Styles.GridItemHeading>
      <Styles.Card>
        <Styles.List>
          {topThreeNotifications.map((notification) => {
            return (
              <NotificationListItem
                key={notification.id}
                notification={notification}
              />
            );
          })}
        </Styles.List>
        {publicNotifications.length > MAX_NOTIFICATIONS && (
          <Styles.ShowMoreNotificationsContainer>
            <CustomButton asChild>
              <Link href="/notifications">Show {remainingNotificationCount} More</Link>
            </CustomButton>
          </Styles.ShowMoreNotificationsContainer>
        )}
      </Styles.Card>
    </Styles.NotificationGridItem>
  );
}

export default NotificationSection;
