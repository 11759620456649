// Package modules
import styled from '@emotion/styled';
import React from 'react';
import { motion } from 'framer-motion';
import { useNavigation } from 'react-navi';
import { Typography } from '@kintent/glide';

// Local modules
import { Stack } from '../../../design-system';
import { CardTitle, ErrorLabel } from '../../../components/PageElements';
import { useAuthService, useTeamSettings } from '../../../lib/state';
import { mailtoHandler } from '../../../lib/utils';
import { SignInAndRequestAccessButtonsWrapper } from '../components/SignInAndRequesAccessButtonsWrapper';
import { GlideButton } from '../../../components/GlideButton';

// Constants
const ERROR_MESSAGE_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, x: 40 },
  visible: { opacity: 1, x: 0 },
};

const ContactSupport = styled(Typography)`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const ErrorMessageForAutoApproveDisabled = ({ teamName, handleBackToSignIn, handleRequestAccess }) => {
  return (
    <>
      <ErrorLabel>
        {`Oops! It looks like this account has expired. To regain access to ${teamName}’s TrustShare,
            please fill out the Access Request form.`}
      </ErrorLabel>
      <SignInAndRequestAccessButtonsWrapper>
        <GlideButton
          onClick={handleBackToSignIn}
          variant="secondary"
          className="secondary"
        >
          Back to Sign In
        </GlideButton>
        <GlideButton onClick={handleRequestAccess}>Request Access</GlideButton>
      </SignInAndRequestAccessButtonsWrapper>
    </>
  );
};

const ErrorMessageForAutoApproveEnabled = ({ email, currentTeam }) => {
  const { navigate } = useNavigation();
  return (
    <>
      <ErrorLabel>
        <>
          We see that your account exists, but has expired. Please reach out to {currentTeam.name}
          {currentTeam.contactUsEmail ? (
            <>
              {' at '}
              <ContactSupport
                element="span"
                onClick={() => {
                  mailtoHandler(currentTeam.name, email);
                }}
              >
                {currentTeam.contactUsEmail}
              </ContactSupport>
            </>
          ) : null}{' '}
          to get your account reactivated.
        </>
      </ErrorLabel>
      <SignInAndRequestAccessButtonsWrapper>
        <GlideButton
          onClick={() => {
            navigate('/home');
          }}
        >
          Back to Home
        </GlideButton>
      </SignInAndRequestAccessButtonsWrapper>
    </>
  );
};

export function AccountExpiredError({ email, handleBackToSignIn, handleRequestAccess }) {
  const { currentTeam } = useAuthService();
  const [teamSettings] = useTeamSettings(currentTeam.id);
  const isCreateAccountEnabled = teamSettings.trustShare?.nda?.isAutoApproveAccessRequestEnabled;

  return (
    <>
      <CardTitle>Account Expired</CardTitle>
      <Stack
        as={motion.div}
        initial="hidden"
        animate="visible"
        variants={ERROR_MESSAGE_ANIMATION_VARIANTS}
        space="xl"
      >
        {isCreateAccountEnabled ? (
          <ErrorMessageForAutoApproveEnabled
            email={email}
            currentTeam={currentTeam}
          />
        ) : (
          <ErrorMessageForAutoApproveDisabled
            teamName={currentTeam.name}
            handleBackToSignIn={handleBackToSignIn}
            handleRequestAccess={handleRequestAccess}
          />
        )}
      </Stack>
    </>
  );
}
