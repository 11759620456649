// Package modules
import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@kintent/glide';

// Local modules
import { BREAKPOINTS } from '../lib/constants';
import { hexOpacity } from '../lib/utils';

const StyledDivider = styled(Typography)`
  display: flex;
  align-items: center;
  min-height: 26px;

  &::before {
    margin-right: 1rem;
  }

  &::after {
    margin-left: 1rem;
  }

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    border-bottom: 1px solid ${({ theme }) => hexOpacity(theme.palette.ariel, 0.4)};
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      min-height: 76px;
    }

    & span {
      background-color: ${({ theme }) => theme.palette.white};
      box-shadow: inset 1px 1px 4px 1px ${({ theme }) => hexOpacity(theme.palette.ariel, 0.2)};
      padding: 1rem 2rem;
    }
  }
`;

export const TypographyDivider = ({ ...props }) => (
  <StyledDivider {...props}>
    <Typography
      element="span"
      level="8"
    >
      OR
    </Typography>
  </StyledDivider>
);
