import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'reakit';
import { BREAKPOINTS } from '../../../../lib/constants';

// Styled components
const HomePageContainer = styled(Box)`
  width: calc(100% - 8em);
  max-width: 1200px;

  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: calc(100% - 2em);
  }
`;

export default HomePageContainer;
