// Package modules
import React, { useEffect, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';

// Local modules
import { BREAKPOINTS } from '../lib/constants';

/**
 * This helper hook checks if the media query matches the current screen width.
 * @param query
 * @return {boolean}
 */
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return useMemo(() => matches, [matches]);
}

// Exports
export const MediaQueriesContext = createContainer(() => {
  const isMobile = useMediaQuery(`(min-width: ${BREAKPOINTS.MOBILE}px)`);
  const isTablet = useMediaQuery(`(min-width: ${BREAKPOINTS.TABLET}px)`);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.DESKTOP}px)`);

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
});

export const MediaQueriesProvider = ({ children }) => (
  <MediaQueriesContext.Provider>{children}</MediaQueriesContext.Provider>
);
