// Package modules
import React from 'react';
import { Typography } from '@kintent/glide';

// Local modules
import { Container } from '../design-system';

export function NotFoundPage() {
  return (
    <Container>
      <br />
      <br />
      <br />
      <Typography level="1">Error encountered</Typography>
      <br />
      <br />
      <Typography level="6">Something went wrong — sorry about that.</Typography>
      <Typography>If the problem persists, please contact support.</Typography>
      <br />
    </Container>
  );
}
