/* eslint-disable import/no-cycle */
import UniversalCookie from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

import { api } from './api';
import { USER_TRACKING_COOKIE_MAX_AGE, USER_TRACKING_COOKIE_NAME } from './constants';
import { authCache, memoizePromiseFn } from './utils';

// Constants
const TRUST_SHARE_PUBLIC_AUTH_TOKEN_KEY = 'trustSharePublicAuthToken';
const AUTH_TOKEN = 'trustShareAuthToken';

let authServiceInstance = null;
let cachedGetSelfAndNotifyOnUserUpdate = null;

export class AuthService {
  constructor() {
    this.cookies = new UniversalCookie();
    this.publicAuthToken = this.cookies.get(TRUST_SHARE_PUBLIC_AUTH_TOKEN_KEY) || null;
    this.token = this.cookies.get(AUTH_TOKEN) || null;
    this.publicTeamId = null;
    this.authenticatedUser = null;
    this.instanciateTrackingCookie();
  }

  // Static methods
  static getAuthServiceInstance() {
    if (!authServiceInstance) {
      authServiceInstance = new AuthService();
    }
    return authServiceInstance;
  }

  // Instance methods
  getPublicTeamId() {
    return this.publicTeamId;
  }

  getAuthenticatedUser() {
    return this.authenticatedUser;
  }

  setAuthenticatedUser(user) {
    this.authenticatedUser = user;
  }

  setPublicAuthToken(token) {
    this.publicAuthToken = token;
  }

  getPublicAuthToken() {
    return this.publicAuthToken;
  }

  getAuthToken() {
    return this.token;
  }

  setQueryClient(newQueryClient) {
    this.queryClient = newQueryClient;
  }

  setUserTrackingCookie() {
    this.cookies.set(USER_TRACKING_COOKIE_NAME, uuidv4(), { path: '/', maxAge: USER_TRACKING_COOKIE_MAX_AGE }); // max-age is in seconds (400 days)
  }

  instanciateTrackingCookie() {
    if (!this.cookies.get(USER_TRACKING_COOKIE_NAME)) {
      this.setUserTrackingCookie();
    }
  }

  // Email/Password login capability similar to TrustCloud
  async login(email, password, invitationToken, userType) {
    const { token, user } = await api.auth.login({ email, password, invitationToken, userType });
    this.updateAuth(token, user);
  }

  // Public login capability specific to TrustShare
  async publicLogin() {
    const { token: publicAuthToken, teamId: publicTeamId } = await api.auth.publicLogin();
    this._updatePublicAuth(publicAuthToken, publicTeamId);
  }

  async joinAndSwitchTeam(userId, invitationToken, authToken) {
    const invitation = await api.team.getInvitation(invitationToken, authToken);
    // If the user doesn't exist, we can exit this function
    if (!invitation?.userExists) return;
    await api.user.joinTeam(userId, invitationToken, authToken);
    const { token, user } = await api.auth.selectTeam(invitation.teamId, authToken);
    this.updateAuth(token, user);
  }

  async selectTeam(publicTeamId, authToken) {
    const { token, user } = await api.auth.selectTeam(publicTeamId, authToken);
    this.updateAuth(token, user);
  }

  updateAuth(token, user) {
    this.cookies.set(AUTH_TOKEN, token, { path: '/' });
    this.token = token;
    this.authenticatedUser = user;

    if (this.notifyOnUserUpdate) {
      this.notifyOnUserUpdate(this.authenticatedUser);
    }

    // Invalidate all queries once the user logs in
    this.queryClient?.invalidateQueries();
  }

  logout() {
    this.queryClient?.clear();
    this.cookies.remove(AUTH_TOKEN, { path: '/' });
    this.token = null;
    this.authenticatedUser = null;
    this.notifyOnUserUpdate(null);
    authCache.clear();
  }

  async isLoggedIn() {
    // Update the token with the latest cookie token.
    const latestToken = this.cookies.get(AUTH_TOKEN) || null;
    if (this.token !== latestToken) {
      this.token = latestToken;
      this.authenticatedUser = null; // Refetched below.
    }

    if (this.token === null) {
      return false;
    }

    if (!this.authenticatedUser) {
      try {
        const { authenticatedUser } = await cachedGetSelfAndNotifyOnUserUpdate(this.token, this.notifyOnUserUpdate);
        this.authenticatedUser = authenticatedUser;
      } catch (e) {
        this.token = null;
      }
    }

    return !!this.authenticatedUser;
  }

  // The callback will be called when the authenticated team is updated.
  subscribeToPublicAuthentication(callback) {
    this.notifyOnTeamUpdate = callback;
  }

  subscribeToAuthenticatedUser(callback) {
    this.notifyOnUserUpdate = callback;
  }

  _updatePublicAuth(token, teamId) {
    this.cookies.set(TRUST_SHARE_PUBLIC_AUTH_TOKEN_KEY, token, { path: '/' });
    this.publicAuthToken = token;
    this.publicTeamId = teamId;

    if (this.notifyOnTeamUpdate) {
      this.notifyOnTeamUpdate(this.publicTeamId);
    }
  }

  withAuthHeaders(headers = {}) {
    return {
      'X-Kintent-Auth': `Bearer ${this.token ? this.token : this.publicAuthToken}`,
      ...headers,
    };
  }
}

cachedGetSelfAndNotifyOnUserUpdate = memoizePromiseFn(async (token, notifyOnUserUpdate) => {
  const authenticatedUser = await api.user.getSelf(token);

  if (notifyOnUserUpdate) {
    notifyOnUserUpdate(authenticatedUser);
  }

  return {
    authenticatedUser,
  };
});
