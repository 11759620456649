// Package modules.
import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { DisclosureContent } from 'reakit';

// Styles.
const Wrapper = styled(DisclosureContent)`
  display: contents;
`;

// Components.
const Step = ({ children, className, currentId, register, step, unregister }) => {
  const ref = useRef(null);

  useEffect(() => {
    register(step, ref);
    return () => unregister(step);
  }, [register, step, unregister]);

  const isActive = step === currentId;
  return (
    <Wrapper
      className={className}
      ref={ref}
      visible={isActive}
    >
      {isActive && children}
    </Wrapper>
  );
};

// Exports.
export default Step;
