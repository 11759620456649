import styled from '@emotion/styled';
import { UilCheck } from '@iconscout/react-unicons';
import { base, flat, rgb, translucent, Typography } from '@kintent/glide';
import React, { useMemo } from 'react';
import { Link } from 'react-navi';

import CountBox from '../../../../components/CountBox';
import { Flex } from '../../../../components/Flex';
import { CONTROL_STATES } from '../../../../lib/constants';
import { useContentVisibility, useControlList } from '../../../../lib/state';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 325px 1fr 1fr auto;
  border-radius: 4px;
  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '20%')};

  padding: 24px;

  background-color: ${({ theme }) => rgb(theme.color.system.white)};

  cursor: pointer;
  transition: 0.3s ease-out;
  border: ${({ theme }) => `1px solid ${flat(theme.color.system.gray, '20%')}`};
  text-decoration: none;

  &:hover {
    border-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }
`;

const StyledIndicator = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 4px;

  border: 1px solid ${({ theme }) => flat(theme.color.system.positive)};
  border-radius: 4px;

  width: fit-content;
  color: ${({ theme }) => flat(theme.color.system.positive)};

  padding: 2px 8px;
`;

const ComplianceIdentifier = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
  background-color: ${({ theme }) => flat(theme.color.system.gray, '20%')};

  border-radius: 4px;
  padding: 4px 8px;

  min-width: 50px;
  max-width: 64px;

  text-align: center;
`;

const CriteriaTitle = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
  text-transform: uppercase;
  max-width: 230px;
`;

const LinkWrapper = ({ active, href, children }) => {
  if (active) {
    return (
      <Grid
        as={Link}
        href={href}
      >
        {children}
      </Grid>
    );
  }
  return <Grid>{children}</Grid>;
};

function CertificationSectionRow({
  title,
  policiesCount,
  displayIdentifier,
  framework,
  sectionId,
  subsections,
  subtype,
  shortName,
}) {
  const [controls] = useControlList();

  const { shouldHideControlDetail, shouldHidePolicyDetail } = useContentVisibility();

  const canSeeDetails = !shouldHideControlDetail || !shouldHidePolicyDetail;

  const subsectionReferenceIds = subsections.map(({ referenceId }) => referenceId);

  const controlsInSection = useMemo(
    () =>
      controls.filter((control) => {
        const controlMappingToThisFramework = control.complianceMapping.mappings[framework.shortName];
        return (
          controlMappingToThisFramework?.controls.find(({ controlId }) => subsectionReferenceIds.includes(controlId)) &&
          control.state.value === CONTROL_STATES.ADOPTED
        );
      }),
    [controls, subsectionReferenceIds, framework.shortName]
  );

  return (
    <LinkWrapper
      href={`/certifications/${shortName}${subtype}/${sectionId}${subtype ? `?subtype=${subtype}` : ''}`}
      active={canSeeDetails}
    >
      <Flex
        alignItems="center"
        gap="8px"
      >
        {displayIdentifier && (
          <ComplianceIdentifier
            level="8"
            as="p"
          >
            {displayIdentifier}
          </ComplianceIdentifier>
        )}
        <CriteriaTitle
          level="8"
          as="p"
        >
          {title}
        </CriteriaTitle>
      </Flex>
      <Typography
        as="div"
        level="8"
      >
        <CountBox count={controlsInSection.length} />
      </Typography>
      <Typography
        as="div"
        level="8"
      >
        <CountBox count={policiesCount} />
      </Typography>
      <div>
        <StyledIndicator level={8}>
          <UilCheck size={16} />
          Fully mapped
        </StyledIndicator>
      </div>
    </LinkWrapper>
  );
}

export default CertificationSectionRow;
