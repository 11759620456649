// Package modules
import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Dialog, DialogBackdrop, DialogDisclosure } from 'reakit';

// Local modules
import { InlineFlex } from './Flex';
import { hexOpacity } from '../lib/utils';

// Constants
const TRANSITION_DURATION = 0.5;

const NAVIGATION_ANIMATION_VARIANTS = {
  hide: { x: -266 },
  show: { x: 0 },
};

const NAVIGATION_ANIMATION_TRANSITION = {
  type: 'tween',
  duration: TRANSITION_DURATION,
};

// Styled components
const StyledDialogBackdrop = styled(DialogBackdrop)`
  position: fixed;
  inset: 0;
  z-index: 1001;

  background-color: ${({ theme }) => hexOpacity(theme.palette.shadow, 0.4)};
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.1s ease;

  &[data-enter] {
    opacity: 1;
  }

  &[data-leave] {
    transition: opacity 0.1s ease ${TRANSITION_DURATION}s;
    opacity: 0;
  }
`;

const StyledDialog = styled(motion(Dialog))`
  width: 266px;
  height: 100%;
  background-color: ${({ theme }) => theme.components.header.navigation.backgroundColor};
`;

const StyledDialogDisclosure = styled(DialogDisclosure)`
  outline: none;
`;

// Exports
export function MobileNavigationDialog({ mobileNavigationDialogState, children }) {
  return (
    <StyledDialogBackdrop {...mobileNavigationDialogState}>
      <StyledDialog
        animate={mobileNavigationDialogState.visible ? 'show' : 'hide'}
        variants={NAVIGATION_ANIMATION_VARIANTS}
        transition={NAVIGATION_ANIMATION_TRANSITION}
        {...mobileNavigationDialogState}
      >
        {children}
      </StyledDialog>
    </StyledDialogBackdrop>
  );
}

export function MobileNavigationDisclosure({ mobileNavigationDialogState, children }) {
  return (
    <StyledDialogDisclosure
      as={InlineFlex}
      {...mobileNavigationDialogState}
    >
      {children}
    </StyledDialogDisclosure>
  );
}
