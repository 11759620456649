import React from 'react';
import styled from '@emotion/styled';
import { Heading, Typography } from '@kintent/glide';

import { Flex } from '../../Flex';

const StyledHeading = styled(Heading)`
  text-align: center;

  & + p {
    margin-top: 20px;
    margin-bottom: 28px;
  }
`;

function NdaRejectionStep() {
  return (
    <Flex direction="column">
      <StyledHeading level="2">Thank you for your response</StyledHeading>
      <Typography
        as="p"
        level="7"
      >
        That’s a bummer, but we understand. Feel free to view the public documents on our TrustShare.
      </Typography>
    </Flex>
  );
}

export default NdaRejectionStep;
