import React from 'react';
import styled from '@emotion/styled';
import { Heading, Typography } from '@kintent/glide';

import { Modal } from '../../../design-system';
import { Flex } from '../../../components/Flex';
import changePassSuccess from '../../../assets/change-pass-success.svg';

const StyledModal = styled(Modal)`
  width: calc(100% - 2em);
  max-width: 680px;
`;
export const ChangePasswordSuccessModal = ({ modal }) => {
  if (!modal.isOpen) {
    return null;
  }

  return (
    <StyledModal
      as={Flex}
      direction="column"
      alignItems="center"
      gap="24px"
      showHeader={false}
      showActions={false}
      showCloseIcon={false}
      ignoreVerticalPosition={false}
      hideOnEsc
      ignoreSidebar
      {...modal}
    >
      <Heading level="6">Your password was changed. You will need to login again.</Heading>
      <img
        alt="Change password success"
        src={changePassSuccess}
        width={95}
        height={95}
      />
      <Typography level="body">You can use the password you just created to authenticate back in</Typography>
    </StyledModal>
  );
};
