import styled from '@emotion/styled';
import { flat, Heading } from '@kintent/glide';

export const DocumentTitle = styled(Heading)`
  color: ${({ theme }) => flat(theme.color.system.gray)};
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`;
