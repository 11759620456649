import React, { useState } from 'react';
import { Link, useNavigation } from 'react-navi';
import { useTheme } from '@emotion/react';
import { flat, Toast, ToastProvider } from '@kintent/glide';
import { UilExternalLinkAlt, UilFileAlt, UilFileSearchAlt, UilImport, UilPadlock } from '@iconscout/react-unicons';
import * as Sentry from '@sentry/react';

import { FEATURE_FLAG } from 'lib/featureFlags';
import * as Styles from './DashboardPolicySectionItem.styles';
import { Flex } from '../../../../components/Flex';
import { Spinner } from '../../../../components/Spinner';
import { useAuthService, useFeatureFlag, usePolicy, useRequestAccessRedirectionUrl } from '../../../../lib/state';
import {
  canDownloadPolicy,
  downloadFileFromAPI,
  isDataRoomUserAbleToAccess,
  shouldHideItem,
  shouldHideRequestAccessForDataRoom,
} from '../../../../lib/utils';
import { Styled } from '../../policies/components/PolicyMetadata.styles';

const ICON_SIZE = 20;

function ActionIcon({
  canDownload = false,
  policyId,
  title,
  canViewExternalPolicyUrl,
  externalPolicyUrl,
  isAuthenticated,
  hasDataRoomAccess,
  setToastError,
  setShowExportToast,
  showExportToast,
}) {
  const theme = useTheme();
  const { navigate } = useNavigation();
  const { downloadPolicy } = usePolicy(policyId);
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);
  const hideDataRoomRequestAccess = useFeatureFlag(FEATURE_FLAG.HIDE_DATA_ROOM_REQUEST_ACCESS);
  const enableViewOnlyDocuments = useFeatureFlag(FEATURE_FLAG.VIEW_ONLY_DOCUMENTS);

  const hideRequestAccessForDataRoom = shouldHideRequestAccessForDataRoom(hideDataRoomRequestAccess, hasDataRoomAccess);

  const [isDownloadingPolicy, setDownloadingPolicy] = useState(false);

  const shouldHideRequestAccess = shouldHideItem(isRequestAccessHidden, []);

  const handlePolicyDownload = async () => {
    setDownloadingPolicy(true);
    try {
      await downloadFileFromAPI(() => downloadPolicy(), `${title.toLowerCase().replace(' ', '_')}.pdf`);
    } catch (e) {
      setToastError({
        hasError: true,
        errorMessage: e.name,
      });
      setShowExportToast(!showExportToast);
      Sentry.captureException(e);
    }
    setDownloadingPolicy(false);
  };

  if (canViewExternalPolicyUrl) {
    return (
      <Link
        href={externalPolicyUrl}
        target="_blank"
      >
        <UilExternalLinkAlt size={20} />
      </Link>
    );
  }

  if (enableViewOnlyDocuments) {
    if (canDownload) {
      return (
        <Link href={`/policies/${policyId}/view`}>
          <UilFileSearchAlt size={ICON_SIZE} />
        </Link>
      );
    }

    return null;
  }

  if (!enableViewOnlyDocuments) {
    if (canDownload) {
      return isDownloadingPolicy ? (
        <Spinner
          size={ICON_SIZE}
          fill={theme.components.header.primaryCTAButton.background}
        />
      ) : (
        <Styles.IconAction onClick={handlePolicyDownload}>
          <UilImport
            size={ICON_SIZE}
            fill={theme.components.header.primaryCTAButton.background}
          />
        </Styles.IconAction>
      );
    }
  }

  if (!hasDataRoomAccess && isAuthenticated) {
    return <></>;
  }

  if (!hideRequestAccessForDataRoom) {
    return <></>;
  }

  return (
    <>
      {!shouldHideRequestAccess && (
        <Styles.IconAction onClick={() => navigate(requestAccessRedirectionUrl)}>
          <UilPadlock
            size={ICON_SIZE}
            fill={theme.components.header.primaryCTAButton.background}
          />
        </Styles.IconAction>
      )}
    </>
  );
}

function DashboardPolicySectionItem({
  policy,
  accessLevel,
  policyId,
  externalPolicyUrl,
  shortName,
  shouldHideLinkForPolicyDetails,
  title,
}) {
  const theme = useTheme();
  const { authenticatedUser } = useAuthService();
  const [toastError, setToastError] = useState(null);
  const [showExportToast, setShowExportToast] = useState(false);
  const hasDataRoomAccess = isDataRoomUserAbleToAccess(policy, authenticatedUser);
  const canDownload = canDownloadPolicy(accessLevel, authenticatedUser) && hasDataRoomAccess;
  const canViewExternalPolicyUrl = externalPolicyUrl && canDownload;

  const policyProps = {
    href: shouldHideLinkForPolicyDetails ? null : `/policies/${encodeURIComponent(shortName)}`,
    as: shouldHideLinkForPolicyDetails ? 'p' : Link,
  };
  return (
    <Styles.Item
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex
        alignItems="center"
        gap="12px"
      >
        <div>
          <UilFileAlt
            size={ICON_SIZE}
            fill={flat(theme.color.system.gray, '40%')}
          />
        </div>
        <Styles.Title
          level="8"
          {...policyProps}
        >
          {title}
        </Styles.Title>
      </Flex>
      {toastError?.hasError && (
        <>
          <ToastProvider>
            <Toast
              variant="negative"
              label="Error"
              description={toastError?.errorMessage}
              open={showExportToast}
              duration={3000}
              onOpenChange={() => setShowExportToast(null)}
            />
            <Styled.StyledToastViewport />
          </ToastProvider>
        </>
      )}

      <ActionIcon
        canDownload={canDownload}
        policyId={policyId}
        title={title}
        hasDataRoomAccess={hasDataRoomAccess}
        isAuthenticated={!!authenticatedUser}
        canViewExternalPolicyUrl={canViewExternalPolicyUrl}
        externalPolicyUrl={externalPolicyUrl}
        setShowExportToast={setShowExportToast}
        showExportToast={showExportToast}
        toastError={toastError}
        setToastError={setToastError}
      />
    </Styles.Item>
  );
}

export default DashboardPolicySectionItem;
