import styled from '@emotion/styled';
import React from 'react';

import { Flex } from '../../../../components/Flex';
import { useContentVisibility, useFeatureFlag, useProgramContent } from '../../../../lib/state';
import { FEATURE_FLAG } from '../../../../lib/featureFlags';

import ControlMetadata from './ControlMetadata';
import ControlScope from './ControlScope';
import PolicyMappings from './PolicyMappings';
import StandardsMappingsSection from './StandardsMappingsSection';

const StyledContainer = styled(Flex)`
  position: relative;

  overflow: visible;

  flex: 1;

  padding-bottom: 20px;
`;

function ControlDetailPanel({ selectedControl, testCount }) {
  const { shouldHidePolicyOverview: shouldHideControlPoliciesMapping } = useContentVisibility();

  const areSpecificDescriptionsEnabled = useFeatureFlag(FEATURE_FLAG.SPECIFIC_DESCRIPTIONS);

  const [{ publicFacingDescriptions }] = useProgramContent();
  const specificControlDescriptions = publicFacingDescriptions?.controls ?? {};
  const specificDescription = specificControlDescriptions[selectedControl.shortName];

  return (
    <StyledContainer
      direction="column"
      gap="16px"
    >
      <ControlMetadata
        control={selectedControl}
        testCount={testCount}
        specificDescription={areSpecificDescriptionsEnabled ? specificDescription : null}
      />
      <ControlScope control={selectedControl} />
      <StandardsMappingsSection control={selectedControl} />
      {!shouldHideControlPoliciesMapping && <PolicyMappings policyIds={selectedControl.relatedPolicyIds} />}
    </StyledContainer>
  );
}

export default ControlDetailPanel;
