import React, { useMemo } from 'react';
import { base, flat, Heading, translucent, Typography } from '@kintent/glide';
import styled from '@emotion/styled';

import CardContainer from '../../../../components/CardContainer';
import DateTime from '../../../../components/DateTime';
import { Stack } from '../../../../design-system';
import { sortSections } from '../../../../lib/utils';
import {
  BREAKPOINTS,
  COMPLIANCE_STANDARDS,
  EXCLUDED_STANDARDS_FROM_COMPLIANCE_MAPPING,
  getCertificationCardTitle,
  STANDARDS_WITHOUT_DISPLAY_IDENTIFIER,
} from '../../../../lib/constants';
import { useFrameworkList, useFrameworkSectionList } from '../../../../lib/state';
import CertificationSectionRow from './CertificationSectionRow';
import { Flex } from '../../../../components/Flex';

const SOC2_ADDITIONAL_CRITERIA_ID = ['A1', 'C1', 'PI1'];

const Grid = styled.div`
  display: grid;
  grid-template-columns: 325px 1fr 1fr 116px;

  border-radius: 8px;
  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '20%')};

  padding: 24px;

  background-color: ${({ theme }) => flat(theme.color.system.gray, '5%')};
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    grid-template-columns: 325px 1fr 1fr 116px;
  }
`;

const GridTitle = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
`;

const MappingText = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
`;

function CertificationReadiness({ selectedCertification }) {
  const { data } = useFrameworkList();

  const frameworkList = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.filter((framework) => framework.isCatalog);
    }
    return [];
  }, [data]);

  const selectedCertificationId = selectedCertification?.certification?.id;
  const framework = frameworkList.find(({ id }) => id === selectedCertificationId);

  const { data: frameworkSectionList } = useFrameworkSectionList(framework?.id);

  // return if the framework is in the EXCLUDED_STANDARDS_FROM_COMPLIANCE_MAPPING list
  if (EXCLUDED_STANDARDS_FROM_COMPLIANCE_MAPPING.includes(framework?.shortName)) return null;

  // exit if selected certification is null
  if (selectedCertification == null) return null;
  // exit if framework is null
  if (framework == null) return null;
  // exit if frameworkSectionList is not an array (covering edge cases where the data is not yet available)
  if (frameworkSectionList == null || !Array.isArray(frameworkSectionList)) return null;

  const sortedFrameworkSectionList = (frameworkSectionList ?? [])?.sort((a, b) => sortSections(a, b, framework));
  if (sortedFrameworkSectionList.length === 0) return null;

  return (
    <>
      <CardContainer direction="column">
        <Flex
          direction="column"
          gap="24px"
        >
          <Flex
            direction="column"
            gap="4px"
          >
            <Heading level="5">
              {getCertificationCardTitle(
                selectedCertification?.certification?.shortName,
                selectedCertification?.subtype
              )}{' '}
              Criteria Readiness
            </Heading>
            <MappingText
              level="7"
              top="4px"
              bottom="24px"
            >
              We have made the mappings available below for your convenience.
            </MappingText>
            <Typography level="8">
              Generated by TrustCloud on&nbsp;
              <DateTime
                date={new Date()}
                shortMonth
                includeYear
              />
            </Typography>
          </Flex>
          <Flex
            direction="column"
            gap="8px"
          >
            <Grid>
              <GridTitle
                as="p"
                level="8"
              >
                {getCertificationCardTitle(
                  selectedCertification?.certification?.shortName,
                  selectedCertification?.subtype
                )}{' '}
                Criteria
              </GridTitle>
              <GridTitle
                as="p"
                level="8"
              >
                Controls
              </GridTitle>
              <GridTitle
                as="p"
                level="8"
              >
                Policies
              </GridTitle>
              <GridTitle
                as="p"
                level="8"
              >
                Status
              </GridTitle>
            </Grid>
            {sortedFrameworkSectionList
              .filter(
                (section) =>
                  framework.shortName !== COMPLIANCE_STANDARDS.SOC2 ||
                  !SOC2_ADDITIONAL_CRITERIA_ID.includes(section.referenceId)
              )
              .map((section) => (
                <CertificationSectionRow
                  key={section.referenceId}
                  subtype={selectedCertification?.subtype || ''}
                  shortName={selectedCertification?.certification?.shortName}
                  subsections={section.subsections}
                  sectionId={section.id}
                  framework={framework}
                  title={
                    [COMPLIANCE_STANDARDS.CMMC_L1].includes(framework.shortName) ? section.referenceId : section.title
                  }
                  displayIdentifier={
                    STANDARDS_WITHOUT_DISPLAY_IDENTIFIER.includes(framework.shortName)
                      ? null
                      : section.displayIdentifier ?? section.referenceId
                  }
                  policiesCount={section.programPolicyMapping?.length}
                />
              ))}
          </Flex>
        </Flex>
        {framework.shortName === COMPLIANCE_STANDARDS.SOC2 && (
          <Flex
            className="additional-soc-criteria-readiness"
            top="1rem"
            direction="column"
          >
            <Flex
              direction="column"
              gap="24px"
            >
              <Flex
                direction="column"
                gap="4px"
              >
                <Heading level="5">
                  Additional{' '}
                  {getCertificationCardTitle(
                    selectedCertification?.certification?.shortName,
                    selectedCertification?.subtype
                  )}{' '}
                  Criteria Readiness
                </Heading>
                <MappingText
                  level="8"
                  top="4px"
                  bottom="24px"
                >
                  We have made the mappings available below for your convenience.
                </MappingText>
                <Typography
                  level="7"
                  top="4px"
                  bottom="24px"
                >
                  Generated by TrustCloud on&nbsp;
                  <DateTime
                    date={new Date()}
                    shortMonth
                    includeYear
                  />
                </Typography>
              </Flex>
              <Stack space="md">
                {sortedFrameworkSectionList
                  .filter((section) => SOC2_ADDITIONAL_CRITERIA_ID.includes(section.referenceId))
                  .map((section) => (
                    <CertificationSectionRow
                      key={section.referenceId}
                      subsections={section.subsections}
                      subtype={selectedCertification?.subtype || ''}
                      shortName={selectedCertification?.certification?.shortName}
                      sectionId={section.id}
                      framework={framework}
                      title={section.title}
                      displayIdentifier={section.displayIdentifier}
                      policiesCount={section.programPolicyMapping?.length}
                    />
                  ))}
              </Stack>
            </Flex>
          </Flex>
        )}
      </CardContainer>
    </>
  );
}

export default CertificationReadiness;
