// Package modules
import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Link, useCurrentRoute, useNavigation } from 'react-navi';
import {
  Box,
  unstable_Form as Form,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_useFormState as useFormState,
} from 'reakit';
import { UilArrowLeft as ArrowLeftIcon } from '@iconscout/react-unicons';
import { AnimatePresence, AnimateSharedLayout, motion, useAnimation } from 'framer-motion';
import { Typography } from '@kintent/glide';

// Local modules
import { FEATURE_FLAG } from 'lib/featureFlags';
import { FormContext, FormInput, FormMessage, SpaceAround, Stack } from '../../../design-system';
import { AuthenticationCard } from '../components/AuthFlowComponents';
import { CardTitle } from '../../../components/PageElements';
import { MediaQueryContainer } from '../../../components/ResponsiveContainer';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { Helmet } from '../../../components/Helmet';
import { Flex } from '../../../components/Flex';
import { TypographyDivider } from '../../../components/TypographyDivider';
import { GoogleSso } from '../../../components/Google';
import { MicrosoftSso } from '../../../components/Microsoft';
import { useAuthService, useFeatureFlag, useRequestAccessRedirectionUrl } from '../../../lib/state';
import { isPublishedUrl, parseJwt } from '../../../lib/utils';
import { BREAKPOINTS, DEVICE_TYPES, LOGIN_METHOD, USER_TYPE } from '../../../lib/constants';
import ApiError, {
  ACCOUNT_EXPIRED,
  ACCOUNT_SETUP_INCOMPLETE,
  ENTITY_NOT_FOUND,
  INVALID_CREDENTIALS,
  UNAUTHORIZED,
  USER_VERIFICATION_PENDING,
  VALIDATION_ERRORS,
} from '../../../lib/errors/ApiError';
import { api } from '../../../lib/api';
import { AccountExpiredError } from './AccountExpiredError';
import { AccountSetupIncompleteError } from './AccountSetupIncompleteError';
import { UnauthorizedAccessError } from './UnauthorizedAccessError';
import { useMeasurePaint, useMeasureRender, useMeasureRoute } from '../../../lib/measurement';
import { GlideButton } from '../../../components/GlideButton';
import { useInvitationToken } from '../../../lib/hooks';
import EntityNotFoundError from './EntityNotFoundError';

// Constants
const AUTHENTICATION_ERROR_TYPES = [INVALID_CREDENTIALS, VALIDATION_ERRORS]; // Account Expired is handled differently as per the mocks

const SSO_CONTAINER_ANIMATION_VARIANTS = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.65,
      ease: 'easeInOut',
    },
    transitionEnd: { display: 'flex' },
  },
  middle: { transitionEnd: { display: 'flex' } },
  hidden: {
    opacity: 0,
    x: -200,
    transition: { duration: 0.65 },
    transitionEnd: {
      display: 'none',
      ease: 'easeInOut',
    },
  },
};

const INPUT_CONTAINER_ANIMATION_VARIANTS = {
  hidden: {
    x: 200,
    opacity: 0,
    transition: { duration: 0.65 },
    transitionEnd: {
      display: 'none',
      ease: 'easeInOut',
    },
  },
  middle: { transitionEnd: { display: 'block' } },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.65 },
    transitionEnd: {
      display: 'block',
      ease: 'easeInOut',
    },
  },
};

const SIGN_IN_WITH_EMAIL_ANIMATION_VARIANTS = {
  initial: {
    opacity: 1,
    transition: { delay: 0.25 },
  },
  exit: {
    opacity: 0,
    transition: { delay: 0.25 },
  },
};

const BACK_TO_LOGIN_PAGE_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, x: -40 },
  visible: { opacity: 1, x: 0 },
};

// Styled Components
const LoginStack = styled.div`
  width: 100%;

  & > * + * {
    margin-top: 2rem;
  }

  // Typography from Glide has no margin which is why we need to override it here
  & > p {
    margin-top: 2rem;
  }
`;

const LoginOptionsContainer = styled(Flex)`
  position: relative;
  overflow: hidden;
`;

const SSOStack = styled(Stack)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 180px;
`;

const EmailPasswordAnimationContainer = styled(motion.div)`
  width: 100%;
  height: 180px;
`;

const ButtonStack = styled(Stack)`
  min-height: 60px;
  padding-bottom: 3rem;
`;

const ProgramIntroductionLabel = styled(Typography)`
  padding-left: 3rem;
  padding-right: 3rem;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      text-align: justify;
    }
  }
`;

const EmailPasswordContainer = styled(Box)`
  width: 100%;

  // Accommodate the borders when an input field is focused on mobile devices
  padding-left: 4px;
  padding-right: 4px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }
`;

const FixedWidthButton = styled(GlideButton)`
  // This is a one-time override for visual consistency
  min-width: 180px;
`;

const TypographyLink = styled(Typography)`
  display: inline-block;
  margin-top: 0.5rem;
  text-decoration: underline;

  color: ${({ theme }) => theme.palette.volare};

  &:hover {
    text-decoration: none;
  }
`;

const ForgotPasswordLink = styled(TypographyLink)`
  display: block;
  width: fit-content;
`;

const SignInButton = styled(FormSubmitButton)`
  border-radius: 30px;
`;

function EmailPassword({ setLoginError, handleBackButtonClick, onEmailUpdate }) {
  const route = useCurrentRoute();
  const theme = useTheme();
  const { navigate } = useNavigation();
  const { publicTeamId, updateAuth, selectTeam, joinAndSwitchTeam, currentTeam } = useAuthService();
  const isPublishedEnvironment = isPublishedUrl(currentTeam.trustShareUrl);
  const invitationToken = useInvitationToken();

  // Refs
  const emailInputFieldRef = useRef(null);
  const passwordInputFieldRef = useRef(null);

  const loginForm = useFormState({
    onSubmit: async ({ email, password }) => {
      try {
        const { user, selectedTeamId, token } = await api.auth.login({
          email,
          password,
          invitationToken: route.url.query.invitation,
          userType: USER_TYPE.TEMPORARY_GUEST,
        });

        const parsedToken = parseJwt(token);
        // If teamId is absent in the token, the user is logging into a TS for which the user has not connected before.
        if (!parsedToken.teamId) {
          navigate(`/connect?token=${token}`);
          return;
        }

        if (route.url?.query?.invitation) {
          await joinAndSwitchTeam(user.id, route.url.query.invitation, token);
          return;
        }

        /**
         * Due to multi-team support, the client needs to check if the user is logging into their last selectedTeamId.
         * If a user is not logging into a TS that matches their last selectedTeamId, the client invokes the select team API
         * endpoint to verify if they have access to this TS.
         */
        if (selectedTeamId !== publicTeamId) {
          await selectTeam(publicTeamId, token);
        } else {
          // Attempt to login via email/password was successful and update the authService as needed
          updateAuth(token, user);
        }
      } catch (e) {
        const errors = {};
        if (e instanceof ApiError && AUTHENTICATION_ERROR_TYPES.includes(e.sourceError.error)) {
          errors.email = 'Invalid email address and/or password';
        }
        if (
          e instanceof ApiError &&
          [ACCOUNT_EXPIRED, UNAUTHORIZED, ACCOUNT_SETUP_INCOMPLETE, USER_VERIFICATION_PENDING].includes(
            e.sourceError.error
          )
        ) {
          onEmailUpdate(email);
          setLoginError(e);
        }
        throw errors;
      }
    },
    onValidate: ({ email, password }) => {
      const errors = {};

      // Validate email
      if (email.trim().length === 0) {
        errors.email = 'Email address cannot be empty';
      } else if (emailInputFieldRef.current.checkValidity() === false) {
        errors.email = 'Please enter a valid email address';
      }

      // Validating password
      if (password.trim().length === 0) {
        errors.password = 'Password cannot be empty';
      }

      // Throw errors
      if (Object.keys(errors).length > 0) throw errors;
    },
    validateOnBlur: false,
    validateOnChange: false,
    values: {
      email: '',
      password: '',
    },
  });

  return (
    <EmailPasswordContainer>
      <FormContext.Provider value={loginForm}>
        <Form {...loginForm}>
          <Stack space="lg">
            <FormInput
              type="email"
              name="email"
              placeholder="Email"
              ref={emailInputFieldRef}
              {...loginForm}
            />
            <FormMessage
              name="email"
              {...loginForm}
            />
            <FormInput
              type="password"
              name="password"
              placeholder="Password"
              ref={passwordInputFieldRef}
              {...loginForm}
            />
            <FormMessage
              name="password"
              {...loginForm}
            />
            {isPublishedEnvironment && (
              <ForgotPasswordLink
                as={Link}
                level="8"
                href={`/password-reset${invitationToken ? `?invitation=${invitationToken}` : ''}`}
              >
                Forgot Password?
              </ForgotPasswordLink>
            )}
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              gap={{ x: '1.5rem' }}
            >
              <GlideButton
                variant="ghost"
                icon={
                  <ArrowLeftIcon
                    color={theme.components.header.primaryCTAButton.background}
                    size={16}
                  />
                }
                onClick={handleBackButtonClick}
                className="ghost"
              >
                Back
              </GlideButton>
              <SignInButton
                as={GlideButton}
                variant="primary"
                {...loginForm}
              >
                Sign in
              </SignInButton>
            </Flex>
          </Stack>
        </Form>
      </FormContext.Provider>
    </EmailPasswordContainer>
  );
}

function LoginErrors({ loginMethod, setLoginMethod, loginError, setLoginError, email }) {
  const { currentTeam } = useAuthService();
  const { navigate } = useNavigation();
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();

  const onHandleRequestAccess = () => {
    navigate(requestAccessRedirectionUrl);
    setLoginError(null); // Reset error state
  };

  const handleBackToSignInWithEmailSsoOptions = () => {
    // This is needed to reset the component state so the Sign in with email button is displayed
    setLoginMethod(LOGIN_METHOD.SSO);
    setLoginError(null);
    navigate('/login');
  };

  return (
    <AuthenticationCard>
      {loginError.sourceError.error === ACCOUNT_EXPIRED && (
        <AccountExpiredError
          email={email}
          handleBackToSignIn={() => {
            // This is needed to reset the component state so the Sign in with email button is displayed
            setLoginMethod(LOGIN_METHOD.SSO);
            setLoginError(null);
          }}
          handleRequestAccess={onHandleRequestAccess}
        />
      )}
      {loginError.sourceError.error === UNAUTHORIZED && (
        <UnauthorizedAccessError
          loginMethod={loginMethod}
          handleBackToSignIn={handleBackToSignInWithEmailSsoOptions}
          handleRequestAccess={onHandleRequestAccess}
        />
      )}
      {(loginError.sourceError.error === ACCOUNT_SETUP_INCOMPLETE ||
        loginError.sourceError.error === USER_VERIFICATION_PENDING) && (
        <AccountSetupIncompleteError
          email={email}
          teamName={currentTeam.name}
          handleBackToSignIn={handleBackToSignInWithEmailSsoOptions}
          error={loginError.sourceError.error}
        />
      )}
      {loginError.sourceError.error === ENTITY_NOT_FOUND && (
        <EntityNotFoundError handleBackToSignIn={handleBackToSignInWithEmailSsoOptions} />
      )}
    </AuthenticationCard>
  );
}

function LoginOptionsWrapper({ loginMethod, setLoginMethod, setLoginError, onEmailUpdate }) {
  const { currentTeam } = useAuthService();
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const ssoBoxControls = useAnimation();
  const emailPasswordBoxControls = useAnimation();

  const isPreviewEnvironment = !isPublishedUrl(currentTeam?.trustShareUrl);
  const showEmailLogin = async () => {
    setLoginMethod(LOGIN_METHOD.EMAIL);
    await ssoBoxControls.start('hidden');
    await emailPasswordBoxControls.start('middle');
    await emailPasswordBoxControls.start('visible');
  };

  const showSSOLogin = async () => {
    setLoginMethod(LOGIN_METHOD.SSO);
    await emailPasswordBoxControls.start('hidden');
    await ssoBoxControls.start('middle');
    await ssoBoxControls.start('visible');
  };

  return (
    <AuthenticationCard
      as={motion.div}
      initial="hidden"
      animate="visible"
      variants={BACK_TO_LOGIN_PAGE_ANIMATION_VARIANTS}
    >
      <LoginOptionsContainer>
        <LoginStack>
          <MediaQueryContainer
            hideFrom={DEVICE_TYPES.MOBILE}
            showFor={DEVICE_TYPES.TABLET}
          >
            <LoginStack>
              <CardTitle>{`Sign in to ${currentTeam.name}’s TrustShare`}</CardTitle>
              <ProgramIntroductionLabel
                as="p"
                level="8"
              >
                {`At ${currentTeam.name}, we believe trust must be earned. By providing access to our policies, important
                  documents, and certifications, as well as information related to how data is processed and stored,
                  TrustShare helps us prove that compliance and transparency are very important to us.`}
              </ProgramIntroductionLabel>
            </LoginStack>
          </MediaQueryContainer>
          <SSOStack
            as={motion.div}
            space="xl"
            initial="visible"
            variants={SSO_CONTAINER_ANIMATION_VARIANTS}
            animate={ssoBoxControls}
          >
            <GoogleSso />
            <MicrosoftSso />
          </SSOStack>
          <EmailPasswordAnimationContainer
            as={motion.div}
            initial="hidden"
            variants={INPUT_CONTAINER_ANIMATION_VARIANTS}
            animate={emailPasswordBoxControls}
          >
            <EmailPassword
              handleBackButtonClick={showSSOLogin}
              setLoginError={setLoginError}
              onEmailUpdate={onEmailUpdate}
            />
          </EmailPasswordAnimationContainer>
          <AnimatePresence>
            {loginMethod === LOGIN_METHOD.SSO && (
              <TypographyDivider
                as={motion.div}
                initial="initial"
                variants={SIGN_IN_WITH_EMAIL_ANIMATION_VARIANTS}
                exit="exit"
              />
            )}
          </AnimatePresence>
          <AnimateSharedLayout type="switch">
            <ButtonStack
              as={Flex}
              direction="column"
              alignItems="center"
              space="lg"
            >
              <AnimatePresence>
                {loginMethod === LOGIN_METHOD.SSO && (
                  <>
                    <FixedWidthButton
                      as={motion.button}
                      initial="initial"
                      variants={SIGN_IN_WITH_EMAIL_ANIMATION_VARIANTS}
                      exit="exit"
                      variant="primary"
                      onClick={showEmailLogin}
                    >
                      Sign in with email
                    </FixedWidthButton>
                    {!isPreviewEnvironment && !isRequestAccessHidden && (
                      <SpaceAround
                        as={motion(Typography)}
                        level="8"
                        top="2rem"
                        initial="initial"
                        variants={SIGN_IN_WITH_EMAIL_ANIMATION_VARIANTS}
                        exit="exit"
                      >
                        Don’t have an account?&nbsp;&nbsp;
                        <TypographyLink
                          as={Link}
                          href={requestAccessRedirectionUrl}
                          level="8"
                        >
                          Request Access
                        </TypographyLink>
                      </SpaceAround>
                    )}
                  </>
                )}
              </AnimatePresence>
            </ButtonStack>
          </AnimateSharedLayout>
        </LoginStack>
      </LoginOptionsContainer>
    </AuthenticationCard>
  );
}

export const Login = withTrustShareNavigationBar(({ error = null }) => {
  const {
    data: { span },
  } = useCurrentRoute();
  span.setAttribute('page-name', 'login');
  useMeasureRoute(span);
  // State
  const [loginMethod, setLoginMethod] = useState(LOGIN_METHOD.SSO);
  const [loginError, setLoginError] = useState(error); // Instantiate to error/null
  const [email, setEmail] = useState(null);

  const tree = (
    <>
      <Helmet pageTitle="Login" />
      {loginError ? (
        <LoginErrors
          loginMethod={loginMethod}
          loginError={loginError}
          setLoginMethod={setLoginMethod}
          setLoginError={setLoginError}
          email={email}
        />
      ) : (
        <LoginOptionsWrapper
          loginMethod={loginMethod}
          setLoginMethod={setLoginMethod}
          setLoginError={setLoginError}
          onEmailUpdate={(newEmail) => setEmail(newEmail)}
        />
      )}
    </>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});
