import React from 'react';

import * as Styles from './Content.styles';
import Statement from './Statement';
import PrimarySection from './PrimarySection';
import NotificationSection from './NotificationSection';
import DocumentsSection from './DocumentsSection';
import CertificationSection from './CertificationSection';

function Content() {
  return (
    <Styles.ContentContainer data-testid="enterprise-content">
      <Statement />
      <Styles.MasonryGrid>
        <PrimarySection />
        <NotificationSection />
        <DocumentsSection />
      </Styles.MasonryGrid>
      <CertificationSection />
    </Styles.ContentContainer>
  );
}

export default Content;
