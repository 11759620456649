import styled from '@emotion/styled';
import { base, flat, rgb, Table, translucent } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';

export const NotificationCenterContainer = styled.div`
  margin-top: 72px;
  background-color: ${({ theme }) => rgb(theme.color.system.white)};

  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  border-radius: 8px;

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '10%')};

  overflow: hidden;

  div[role='toolbar'] {
    display: none;
  }
`;

export const NotificationCenterFooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  padding: 12px 16px;
`;

export const SeeMoreNotificationLink = styled(Flex)`
  width: fit-content;
  color: ${({ theme }) => rgb(theme.color.system.white)};
  cursor: pointer;
  text-underline-offset: 2px;

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const NotificationTable = styled(Table)`
  .TableHeader {
    display: none;
  }

  td > .text {
    display: block;
  }
`;
