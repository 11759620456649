import React, { useState } from 'react';
import Select from 'react-select';
import { useCurrentRoute, useNavigation } from 'react-navi';
import { ExternalTheme as externalTheme, flat, Typography } from '@kintent/glide';
import { UilCheck as CheckIcon } from '@iconscout/react-unicons';
import styled from '@emotion/styled';

import { COMPLIANCE_STANDARD_SECTION_DESCRIPTOR, COMPLIANCE_STANDARDS } from '../../../../lib/constants';
import { useFrameworkSectionList } from '../../../../lib/state';
import { Flex } from '../../../../components/Flex';

const STANDARDS_TO_NOT_SHOW_REFERENCE_ID = [
  COMPLIANCE_STANDARDS.CMMC_L2,
  COMPLIANCE_STANDARDS.HIPAA,
  COMPLIANCE_STANDARDS.NIST_CSF,
  COMPLIANCE_STANDARDS.NIST_SP_800_171,
];

const STANDARDS_TO_NOT_SHOW_DISPLAY_IDENTIFIER = [
  COMPLIANCE_STANDARDS.CMMC_L2,
  COMPLIANCE_STANDARDS.NIST_CSF,
  COMPLIANCE_STANDARDS.NIST_SP_800_171,
];

const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    position: 'relative',
    boxShadow: 'none',
    border: `1px solid ${flat(externalTheme.color.system.gray, '20%')}`,
    borderRadius: '4px',
    minHeight: '40px',
    cursor: 'pointer',
    minWidth: '328px',
    '&:hover': {
      borderColor: flat(externalTheme.color.system.gray, '20%'),
      boxShadow: `0 0 0 1px ${flat(externalTheme.color.system.gray, '20%')}`,
    },
  }),
  option: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    minHeight: '44px',
    backgroundColor: 'white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: flat(externalTheme.color.category.forest, '10%'),
    },
  }),
  menu: (provided) => ({
    ...provided,
    position: 'absolute',
    zIndex: 1,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '40vh',
    padding: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
  }),
};

const SectionDropdownOptionContainer = styled(Flex)`
  padding: 0 8px;
`;
const SectionDropdownOption = ({ framework, currentSection, referenceId, title, displayIdentifier, hideCheckIcon }) => {
  return (
    <SectionDropdownOptionContainer
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
    >
      <Typography>
        {displayIdentifier && !STANDARDS_TO_NOT_SHOW_DISPLAY_IDENTIFIER.includes(framework.shortName)
          ? displayIdentifier
          : !STANDARDS_TO_NOT_SHOW_REFERENCE_ID.includes(framework.shortName) && referenceId}
        &nbsp;
        {[COMPLIANCE_STANDARDS.CMMC_L1].includes(framework.shortName) ? '' : title}
      </Typography>
      {!hideCheckIcon && currentSection.referenceId === referenceId && (
        <CheckIcon
          size={20}
          strokeWidth={3}
          color={flat(externalTheme.color.system.gray)}
        />
      )}
    </SectionDropdownOptionContainer>
  );
};
function CertificationDropdown({ framework, currentSection }) {
  const { navigate } = useNavigation();
  const { data: sections } = useFrameworkSectionList(framework.id);

  const route = useCurrentRoute();
  const subtype = route.url.query.subtype || '';

  const [selectedOption] = useState(currentSection ?? null);
  return (
    <Select
      options={sections}
      formatOptionLabel={(sectionOption) => (
        <SectionDropdownOption
          framework={framework}
          currentSection={currentSection}
          {...sectionOption}
        />
      )}
      onChange={(selectedSectionOption) => {
        navigate(`/certifications/${framework.shortName}${subtype}/${selectedSectionOption.id}?subtype=${subtype}`);
      }}
      value={selectedOption}
      placeholder={`Navigate to ${COMPLIANCE_STANDARD_SECTION_DESCRIPTOR[framework.shortName]?.singular ?? 'section'}`}
      styles={SELECT_STYLES}
      components={{
        SingleValue: () => (
          <SectionDropdownOption
            framework={framework}
            currentSection={currentSection}
            hideCheckIcon
            {...currentSection}
          />
        ),
        IndicatorSeparator: () => null,
      }}
      isSearchable={false}
    />
  );
}

export default CertificationDropdown;
