import React from 'react';
import styled from '@emotion/styled';
import { Link, useCurrentRoute } from 'react-navi';
import { Typography } from '@kintent/glide';

import { BREAKPOINTS } from '../../../../lib/constants';
import { Flex } from '../../../../components/Flex';
import { hexOpacity } from '../../../../lib/utils';

const StyledContainer = styled(Flex)`
  display: none;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: flex;
    padding-top: 9px;
    padding-right: 40px;

  width: 240px;
`;

const StyledTypography = styled(Typography)`
  display: block;

  padding: 4px 8px;

  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.palette.volare};

    border-radius: 4px;

    background-color: ${({ theme }) => hexOpacity(theme.palette.volare, 0.1)};
  }
  text-decoration: none;

  &:hover:not(.active) {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }

  transition: color 250ms ease-in;
`;

function SubprocessorLeftPanel({ subprocessors, selectedSubprocessor, onSubprocessorClick }) {
  const {
    data: { name },
  } = useCurrentRoute();

  return (
    <StyledContainer
      direction="column"
      gap="16px"
    >
      {subprocessors.map((subprocessor) => {
        const isSubprocessorSelected = (name ?? selectedSubprocessor.name) === subprocessor.name;
        return (
          <StyledTypography
            key={subprocessor.id}
            onClick={() => onSubprocessorClick(subprocessor)}
            className={isSubprocessorSelected ? 'active' : null}
            as={Link}
            href={`/subprocessors/${encodeURIComponent(subprocessor.name)}`}
          >
            {subprocessor.name}
          </StyledTypography>
        );
      })}
    </StyledContainer>
  );
}

export default SubprocessorLeftPanel;
