import React, { useMemo } from 'react';
import { Link, Typography } from '@kintent/glide';
import { UilFileAlt } from '@iconscout/react-unicons';

import * as Styles from './DocumentsSection.styles';
import CustomTag from '../../../../../components/CustomTag/CustomTag';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import { useAuthService, usePolicyList, useProgramContent, useTeamDocumentList } from '../../../../../lib/state';
import { getPublicDocuments, getPublicPolicies, transformDocuments } from '../../../../../lib/utils';

function DocumentListItem({ artifact }) {
  return (
    <li className="document-list-item">
      <UilFileAlt size="20" />
      <Typography
        as={Link}
        className="document-list-item-title"
        href={`/documents/${artifact.id}`}
        level="8"
        strength="80%"
      >
        {artifact.title}
      </Typography>
    </li>
  );
}

function DocumentsSection() {
  const { currentTeam } = useAuthService();
  const [policies] = usePolicyList();
  const [documents] = useTeamDocumentList(currentTeam?.id);
  const [{ maxDocumentLimit }] = useProgramContent();

  const publicPolicies = useMemo(() => getPublicPolicies(policies), [policies]);
  const publicDocuments = useMemo(() => getPublicDocuments(documents), [documents]);

  const allPublicDocuments = useMemo(
    () => transformDocuments(publicPolicies, publicDocuments),
    [publicPolicies, publicDocuments]
  );

  const allPublicDocumentsToDisplay = allPublicDocuments.slice(0, maxDocumentLimit);
  const remainingDocumentCount = allPublicDocuments.length - maxDocumentLimit;

  return (
    <Styles.DocumentsGridItem data-testid="document-section">
      <Styles.GridItemHeading>
        <Typography
          as="h5"
          level="5"
          className="documents-section-title"
        >
          Documents
        </Typography>
        <CustomTag>{allPublicDocuments.length}</CustomTag>
      </Styles.GridItemHeading>
      <Styles.Card>
        <Styles.UnstyledList>
          {allPublicDocumentsToDisplay.map((artifact) => {
            return (
              <DocumentListItem
                key={artifact.id}
                artifact={artifact}
              />
            );
          })}
        </Styles.UnstyledList>
        <CustomButton asChild>
          <Link href="/documents">Show {remainingDocumentCount} More</Link>
        </CustomButton>
      </Styles.Card>
    </Styles.DocumentsGridItem>
  );
}

export default DocumentsSection;
