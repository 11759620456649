import styled from '@emotion/styled';

export const Hero = styled.header`
  background-color: #032d42;
  grid-area: hero;

  padding-top: 12rem;
`;

export const HeroProgramContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  & p.hero-subheader {
    padding-bottom: 3rem;
  }
`;
