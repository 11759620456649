import React from 'react';
import { Helmet } from 'components/Helmet';

import * as Styles from './HomeV2.styles';
import Hero from './components/hero/Hero';
import Content from './components/content/Content';

function HomeV2() {
  return (
    <>
      <Helmet pageTitle="Home" />
      <Styles.MainGrid data-testid="enterprise-main-home-layout">
        <Hero />
        <Content />
      </Styles.MainGrid>
    </>
  );
}

export default HomeV2;
