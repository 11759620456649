import { UilEnvelope } from '@iconscout/react-unicons';
import React from 'react';
import { Checkbox, useCheckboxState } from 'reakit';
import styled from '@emotion/styled';

import { SpaceAround } from '../../design-system';
import { AuthenticationCard, GoToSignInButton } from '../user/components/AuthFlowComponents';
import { CardTitleLabel } from '../../components/PageElements';
import { GoogleSso } from '../../components/Google';
import { MicrosoftSso } from '../../components/Microsoft';
import { TermsAndPrivacyPolicy } from '../../components/TermsAndPrivacyPolicy';
import { TypographyDivider } from '../../components/TypographyDivider';
import { Flex } from '../../components/Flex';

// Styled components
const SSOLayoutContainer = styled(Flex)`
  & > p:last-child {
    padding-bottom: 24px;
  }
`;

const SSOButtonContainer = styled(Flex)`
  padding-top: 24px;
  height: 180px;
`;

const Heading = styled(CardTitleLabel)`
  margin-bottom: 44px;
`;

const CreateAccountWithSSO = ({ handleCreateAccountOption }) => {
  const checkbox = useCheckboxState({ state: false });
  return (
    <AuthenticationCard>
      <Heading>Create Account</Heading>
      <SSOLayoutContainer
        gap="36px"
        direction="column"
      >
        <SSOButtonContainer
          gap="1.875rem"
          direction="column"
          alignItems="center"
        >
          <GoogleSso disabled={!checkbox.state} />
          <MicrosoftSso disabled={!checkbox.state} />
        </SSOButtonContainer>
        <TypographyDivider />
        <Flex
          gap="12px"
          justifyContent="center"
        >
          <Checkbox {...checkbox} />
          <TermsAndPrivacyPolicy />
        </Flex>
        <Flex
          as={SpaceAround}
          justifyContent="center"
          bottom="24px"
        >
          <GoToSignInButton
            icon={<UilEnvelope size={20} />}
            onClick={handleCreateAccountOption}
          >
            Sign up with email
          </GoToSignInButton>
        </Flex>
      </SSOLayoutContainer>
    </AuthenticationCard>
  );
};

export default CreateAccountWithSSO;
