import React, { useState } from 'react';
import { flat, Heading, Typography } from '@kintent/glide';
import { useTheme } from '@emotion/react';
import { Link } from 'react-navi';
import { UilExternalLinkAlt, UilImport, UilPadlock } from '@iconscout/react-unicons';

import { FEATURE_FLAG } from 'lib/featureFlags';
import { Spinner } from 'components/Spinner';
import * as Styles from './DashboardCertificationItemCard.styles';
import { Flex } from '../../../../components/Flex';
import {
  useAuthService,
  useCertificationDownload,
  useCertificationViewedActivity,
  useFeatureFlag,
  useProgramContent,
  useRequestAccessRedirectionUrl,
} from '../../../../lib/state';
import {
  getCertificationCardTitle,
  getCertificationLogo,
  getCertificationLogoSrcSet,
  TRUST_SHARE_ACCESS_LEVELS,
} from '../../../../lib/constants';
import { canDownloadCertification, canViewCertification, downloadFileFromAPI } from '../../../../lib/utils';
import { GlideButton } from '../../../../components/GlideButton';

// Constants
const ACTION_ICON_SIZE = 20;

function getCertificationDetailPageUrl(featureFlagEnabled = false, shortName, subtype = null) {
  if (featureFlagEnabled) {
    return subtype ? `/certifications/${shortName}${subtype}` : `/certifications/${shortName}`;
  }
  return '/certifications';
}

// This component encapsulates the logic for displaying the correct button for a certification
function ActionButton({
  canDownload = false,
  canView = false,
  certificationId,
  certificationUrl,
  isDownloading,
  setDownloading,
  user = null,
  showLearnMore,
  setShouldShowDownloadErrorToast,
}) {
  const { currentTeam } = useAuthService();
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const downloadCertification = useCertificationDownload(currentTeam.id, certificationId);
  const { trackCertificationView } = useCertificationViewedActivity();

  const shouldShowRequestAccessButton = !isRequestAccessHidden && !canDownload && !canView && !user;

  const handleCertificationDownload = async (certificationFilename) => {
    try {
      setDownloading(true);
      await downloadFileFromAPI(() => downloadCertification(), certificationFilename);
      setDownloading(false);
    } catch (e) {
      setShouldShowDownloadErrorToast(true);
      setDownloading(false);
    }
  };

  const handleCertificationClick = () => {
    trackCertificationView(certificationId);
    window.open(certificationUrl);
  };

  // Handles the case when a certification can be downloaded but not viewed
  if (canDownload && showLearnMore) {
    return (
      <GlideButton
        variant="secondary"
        icon={isDownloading ? <Spinner /> : <UilImport size={ACTION_ICON_SIZE} />}
        onClick={handleCertificationDownload}
        disabled={isDownloading}
        className="secondary"
      >
        Download
      </GlideButton>
    );
  }

  // Handles the case when a certification can be viewed but not downloaded
  if (canView && !canDownload && showLearnMore) {
    return (
      <Styles.LinkButton
        icon={<UilExternalLinkAlt size={ACTION_ICON_SIZE} />}
        onClick={handleCertificationClick}
        target="_blank"
        variant="secondary"
        className="secondary"
      >
        View
      </Styles.LinkButton>
    );
  }

  // Handles the case when a certification can neither be downloaded nor viewed and the user has not authenticated
  if (shouldShowRequestAccessButton) {
    return (
      <GlideButton
        as={Link}
        variant="secondary"
        icon={<UilPadlock size={ACTION_ICON_SIZE} />}
        href={requestAccessRedirectionUrl}
        className="secondary"
      >
        Request Access
      </GlideButton>
    );
  }

  return null;
}

function DashboardCertificationSectionItemCard({
  accessLevel,
  certificationUrl,
  description,
  filename,
  shortName,
  subtype,
  certificationId,
  documentCount = 0,
  setShouldShowDownloadErrorToast,
}) {
  const theme = useTheme();

  const { authenticatedUser, currentTeam } = useAuthService();
  const isNewCertificationDetailEnabled = useFeatureFlag(FEATURE_FLAG.CERTIFICATION_DETAIL);
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);
  const disableMultipleCertifications = useFeatureFlag(FEATURE_FLAG.DISABLE_MULTIPLE_CERTIFICATIONS);
  const [programContent] = useProgramContent();
  const [isDownloading, setDownloading] = useState(false);

  const canDownloadFile = canDownloadCertification(accessLevel, filename, authenticatedUser);
  const canViewFile = canViewCertification(accessLevel, certificationUrl, authenticatedUser);

  const certificationDetailPageUrl = getCertificationDetailPageUrl(isNewCertificationDetailEnabled, shortName, subtype);
  const isCertificationPageVisible = programContent?.isCertificationVisible;

  const shouldHideOverlay =
    isRequestAccessHidden &&
    !canDownloadFile &&
    !canViewFile &&
    !authenticatedUser &&
    currentTeam.trustShareAccessLevel !== TRUST_SHARE_ACCESS_LEVELS.TRANSPARENT;

  const isTsTransparentOrAuthd = () => {
    if (currentTeam.trustShareAccessLevel === TRUST_SHARE_ACCESS_LEVELS.TRANSPARENT) {
      return true;
    }
    if (currentTeam.trustShareAccessLevel === TRUST_SHARE_ACCESS_LEVELS.PRIVATE && authenticatedUser) {
      return true;
    }
    return false;
  };

  const showLearnMore = !shouldHideOverlay && isCertificationPageVisible && isTsTransparentOrAuthd();

  return (
    <>
      <Styles.Card>
        <Flex justifyContent="space-between">
          <Styles.LogoWrapper>
            <img
              alt="Certification Logo"
              src={getCertificationLogo(shortName, subtype)}
              srcSet={getCertificationLogoSrcSet(shortName)}
            />
          </Styles.LogoWrapper>
        </Flex>
        <div>
          <Heading
            level="6"
            color={flat(theme.color.system.gray, '100%')}
          >
            {getCertificationCardTitle(shortName, subtype)}
            {documentCount !== 0 && (
              <span style={{ color: flat(theme.color.system.gray, '60%') }}> · {documentCount}</span>
            )}
          </Heading>
          <Typography level="body">{description}</Typography>
        </div>
        {!isCertificationPageVisible || !isTsTransparentOrAuthd() ? null : (
          <Styles.Overlay className="overlay">
            {showLearnMore && (
              <GlideButton
                as={Link}
                href={certificationDetailPageUrl}
                variant="secondary"
                className="secondary"
              >
                Learn More
              </GlideButton>
            )}
            {disableMultipleCertifications && (
              <ActionButton
                canDownload={canDownloadFile}
                canView={canViewFile}
                certificationId={certificationId}
                certificationUrl={certificationUrl}
                isDownloading={isDownloading}
                setDownloading={setDownloading}
                user={authenticatedUser}
                showLearnMore={showLearnMore}
                setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
              />
            )}
          </Styles.Overlay>
        )}
      </Styles.Card>
    </>
  );
}

export default DashboardCertificationSectionItemCard;
