import React, { useMemo } from 'react';

import * as Styles from './NotificationsV2.styles';
import NotificationListItem from './components/notification-item/NotificationItem';
import { Helmet } from '../../../components/Helmet';
import { useTrustShareNotifications } from '../../../lib/state';
import { getPublicNotifications } from '../../../lib/utils';

function NotificationsV2() {
  const { data: allNotifications } = useTrustShareNotifications();
  const publicNotifications = useMemo(() => getPublicNotifications(allNotifications), [allNotifications]);

  return (
    <>
      <Helmet pageTitle="Notifications" />
      <Styles.MainGrid data-testid="enterprise-main-notification-list-layout">
        <Styles.NotificationContainer>
          <Styles.UnstyledList>
            {publicNotifications.map((notification, index) => {
              return (
                <NotificationListItem
                  key={notification.id}
                  notification={notification}
                  notificationLength={publicNotifications.length}
                  index={index}
                />
              );
            })}
          </Styles.UnstyledList>
        </Styles.NotificationContainer>
      </Styles.MainGrid>
    </>
  );
}

export default NotificationsV2;
