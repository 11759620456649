import React, { useEffect, useState } from 'react';
import { unstable_Form as Form, unstable_useFormState as useFormState, useRoverState } from 'reakit';
import { UilCheckCircle, UilNavigator } from '@iconscout/react-unicons';

import * as Styles from './ContactUsSection.styles';
import {
  FormContext,
  FormInput,
  FormLabel,
  FormMessage,
  FormTextArea,
  Step,
  useModal,
} from '../../../../design-system';
import { api } from '../../../../lib/api';
import { CONTACT_US_REQUEST_TYPES, EMAIL_REGEX } from '../../../../lib/constants';
import { useAuthService } from '../../../../lib/state';

// Constants
const CONTACT_US_STEPS = {
  CONTACT_US: 'CONTACT_US',
  CONTACT_US_SUCCESS: 'CONTACT_US_SUCCESS',
};

function ContactUsFormStep({ contactUsForm, currentTeam }) {
  return (
    <>
      <Styles.StyledHeader level="4">Contact Us</Styles.StyledHeader>
      <Styles.StyledTypography level="7">We are excited to hear from you!</Styles.StyledTypography>
      <Styles.StyledTypography level="7">
        Please leave some details about yourself and your message below.
      </Styles.StyledTypography>
      <FormContext.Provider value={contactUsForm}>
        <Form {...contactUsForm}>
          <Styles.FormContainerGrid>
            <Styles.StyledFlexForm gap="12px">
              <Styles.StyledGrid>
                <FormLabel
                  name="firstName"
                  required
                >
                  First Name
                </FormLabel>
                <FormInput
                  name="firstName"
                  placeholder="First name"
                  type="text"
                  required
                />
                <FormMessage name="firstName" />
              </Styles.StyledGrid>
              <Styles.StyledGrid>
                <FormLabel
                  name="lastName"
                  required
                >
                  Last Name
                </FormLabel>
                <FormInput
                  name="lastName"
                  placeholder="Last name"
                  type="text"
                  required
                />
                <FormMessage name="lastName" />
              </Styles.StyledGrid>
            </Styles.StyledFlexForm>
            <Styles.StyledGrid>
              <FormLabel
                name="email"
                type="email"
                required
              >
                Email
              </FormLabel>
              <FormInput
                name="email"
                placeholder="Email"
                required
              />
              <FormMessage name="email" />
            </Styles.StyledGrid>
            <Styles.StyledGrid>
              <FormLabel
                name="prospectMessage"
                required
              >
                Message
              </FormLabel>
              <FormTextArea
                name="prospectMessage"
                placeholder={`Hello ${currentTeam.name}, I would love to speak more on your compliance standing...`}
                required
              />
              <FormMessage name="prospectMessage" />
            </Styles.StyledGrid>
          </Styles.FormContainerGrid>
        </Form>
      </FormContext.Provider>
    </>
  );
}

function ContactUsSuccessStep({ email, teamName }) {
  return (
    <>
      <Styles.StyledHeader level="4">
        <UilCheckCircle size="20px" /> Message Sent!
      </Styles.StyledHeader>
      <Styles.StyledTypography
        level="7"
        as="p"
      >
        Thank you for reaching out to us. A representative from {teamName} will respond back to your email{' '}
        <Styles.ProspectEmailLabel level="7">{email}</Styles.ProspectEmailLabel> shortly!
      </Styles.StyledTypography>
    </>
  );
}

function ContactUsDialog({ isOpen, setIsOpen, currentTeam }) {
  const { authenticatedUser } = useAuthService();
  const [stepModalProps, setModalProps] = useState({});
  const [shouldEnablePrimary, setEnablePrimary] = useState(false);

  const contactUsModal = useModal();
  const contactUsSteps = useRoverState({ currentId: CONTACT_US_STEPS.CONTACT_US });

  const contactUsForm = useFormState({
    onSubmit: async ({ firstName, lastName, email, prospectMessage }) => {
      setModalProps({
        enablePrimary: false,
      });
      try {
        await api.team.contactUs({
          firstName,
          lastName,
          email,
          prospectMessage,
          type: CONTACT_US_REQUEST_TYPES.PROSPECT_EMAIL,
        });
        switch (contactUsSteps.currentId) {
          case CONTACT_US_STEPS.CONTACT_US:
            contactUsSteps.move(CONTACT_US_STEPS.CONTACT_US_SUCCESS);
            setModalProps({
              primaryLabel: 'Close',
              hideSecondary: true,
              enablePrimary: true,
              primaryButtonStartIcon: null,
              onPrimary: () => setIsOpen(false),
            });
            break;
          case CONTACT_US_STEPS.CONTACT_US_SUCCESS:
            setIsOpen(false);
            break;
          default:
            setIsOpen(false);
        }
      } catch (e) {
        // TODO - Error state not implemented
      }
    },
    onValidate: async ({ firstName, lastName, email, prospectMessage }) => {
      const errors = {};

      if (firstName.trim().length === 0) {
        errors.firstName = 'First Name cannot be empty';
      }
      if (lastName.trim().length === 0) {
        errors.lastName = 'Last Name cannot be empty';
      }
      if (email.trim().length === 0) {
        errors.email = 'Email cannot be empty';
      }
      if (!EMAIL_REGEX.test(email)) {
        errors.email = 'Please enter a valid email address';
      }
      if (prospectMessage.trim().length === 0) {
        errors.prospectMessage = 'Message cannot be empty';
      }
      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    validateOnBlur: true,
    values: {
      firstName: authenticatedUser ? authenticatedUser.name.split(' ')[0] : '',
      lastName: authenticatedUser ? authenticatedUser.name.split(' ')[1] : '',
      email: authenticatedUser ? authenticatedUser.email : '',
      prospectMessage: '',
    },
  });

  const modalOnCancel = () => setIsOpen(false);

  const shouldDisablePrimary =
    contactUsForm.values.firstName === '' ||
    contactUsForm.values.lastName === '' ||
    contactUsForm.values.email === '' ||
    contactUsForm.values.prospectMessage === '';

  useEffect(() => {
    if (isOpen) {
      contactUsModal.show();
    }
  }, [isOpen]);

  useEffect(() => {
    if (shouldDisablePrimary) {
      setEnablePrimary(false);
      return;
    }
    if (Object.keys(contactUsForm.errors).length > 0) {
      setEnablePrimary(false);
    } else {
      setEnablePrimary(true);
    }
  }, [contactUsForm.errors, contactUsForm.values]);

  return (
    <Styles.StyledModal
      title=""
      showHeader={false}
      primaryLabel="Send"
      primaryButtonStartIcon={<UilNavigator size="18px" />}
      primaryButtonVariant="primary"
      secondaryLabel="Close"
      secondaryButtonVariant="secondary"
      onPrimary={contactUsForm.submit}
      onSecondary={modalOnCancel}
      onCancel={modalOnCancel}
      enablePrimary={shouldEnablePrimary}
      ignoreVerticalPosition
      ignoreSidebar
      showCloseIcon
      hideOnEsc={false}
      {...stepModalProps}
      {...contactUsModal}
    >
      <Step
        step={CONTACT_US_STEPS.CONTACT_US}
        {...contactUsSteps}
      >
        <ContactUsFormStep
          contactUsForm={contactUsForm}
          currentTeam={currentTeam}
        />
      </Step>
      <Step
        step={CONTACT_US_STEPS.CONTACT_US_SUCCESS}
        {...contactUsSteps}
      >
        <ContactUsSuccessStep
          email={contactUsForm.values.email}
          teamName={currentTeam.name}
        />
      </Step>
    </Styles.StyledModal>
  );
}

export default ContactUsDialog;
