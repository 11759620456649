import styled from '@emotion/styled';

export const Footer = styled.footer`
  grid-area: footer;
  background-color: white;

  padding: 24px 6rem;
`;

export const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-areas: 'footer-copy links';

  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  & p.footer-section-copy {
    grid-area: footer-copy;
  }
`;

export const UnstyledList = styled.ul`
  list-style-type: none;

  grid-area: links;

  display: flex;
  align-items: center;
  gap: 20px;

  /* Align the ul element to the right most edge */
  margin-left: auto;
`;
