import React from 'react';
import { flat, Heading } from '@kintent/glide';
import { useTheme } from '@emotion/react';

import { TRUSTCLOUD_WEBSITE_URL } from '../../../../lib/constants';
import { useFeatureFlag, useProgramContent, useSubprocessorList } from '../../../../lib/state';
import { filterSubprocessorsFromProgramVendors } from '../../../../lib/utils';
import { FEATURE_FLAG } from '../../../../lib/featureFlags';

import { DashboardSubprocessorItemCard } from './DashboardSubprocessorItemCard';
import * as Styles from './DashboardSubprocessorsSection.styles';
import { Flex } from '../../../../components/Flex';
import { SpaceAround } from '../../../../design-system';
import Heartbeat from '../../../../components/Heartbeat';
import { BrandedLink } from '../../../../components/BrandedLink';

function DashboardSubprocessorsSection() {
  const theme = useTheme();
  const [subprocessors] = useSubprocessorList();
  const [{ isSubprocessorVisible, subprocessorDescription, subprocessorTitle }] = useProgramContent();

  const allSubprocessors = filterSubprocessorsFromProgramVendors(subprocessors);

  const isDisableContinuouslyMonitored = useFeatureFlag(FEATURE_FLAG.DISABLE_CONTINUOUSLY_MONITORED);

  if (!isSubprocessorVisible || !allSubprocessors.length) {
    return null;
  }

  return (
    <Styles.SubprocessorsSectionContainer as="section">
      <Flex
        direction="column"
        gap="16px"
      >
        <Flex
          gap="16px"
          alignItems="center"
        >
          <Styles.CountBadge variant="primary">{allSubprocessors.length}</Styles.CountBadge>
          <Heading level="3">{subprocessorTitle || 'Subprocessors'}</Heading>
          <Heartbeat strokeColor={flat(theme.color.system.positive)} />
        </Flex>
        <SpaceAround bottom="8px">
          <Styles.DescriptionLabel level="8">
            {`Our security, privacy, and compliance controls that govern our subprocessors are ${
              isDisableContinuouslyMonitored ? 'monitored ' : 'continuously monitored '
            }by`}
            &nbsp;
            <BrandedLink
              href={TRUSTCLOUD_WEBSITE_URL}
              target="_blank"
            >
              TrustCloud
            </BrandedLink>
            .
            <br />
            <br />
            {subprocessorDescription}
          </Styles.DescriptionLabel>
        </SpaceAround>
        <Flex
          gap="12px"
          wrap
        >
          {allSubprocessors.map((subprocessor) => (
            <DashboardSubprocessorItemCard
              key={subprocessor.id}
              name={subprocessor.name}
              logoUrl={subprocessor.logoUrl}
            />
          ))}
        </Flex>
      </Flex>
    </Styles.SubprocessorsSectionContainer>
  );
}

export default DashboardSubprocessorsSection;
