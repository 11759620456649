// Package modules
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-navi';
import { motion } from 'framer-motion';

// Local modules
import { Stack } from '../../../design-system';
import { Flex } from '../../../components/Flex';
import { GoToSignInButton } from '../components/AuthFlowComponents';
import { ErrorDescriptionLabel, ErrorTitleLabel } from '../../../components/PageElements';

// Constants
const INVITATION_ERROR_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, x: 40 },
  visible: { opacity: 1, x: 0 },
};

// Styled Components
const StyledFlex = styled(Flex)`
  padding-bottom: 2rem;
`;

// Exports
export function InvitationNotFoundError() {
  return (
    <>
      <ErrorTitleLabel>Invitation Expired</ErrorTitleLabel>
      <Stack
        as={motion.div}
        initial="hidden"
        animate="visible"
        variants={INVITATION_ERROR_ANIMATION_VARIANTS}
        space="xl"
      >
        <ErrorDescriptionLabel align="justify">
          Oops! It looks like your invitation has expired. To request a new invitation, please fill out the Access
          Request form. Invitations are valid for 7 days once your request has been approved.
        </ErrorDescriptionLabel>
        <StyledFlex justifyContent="flex-end">
          <GoToSignInButton
            as={Link}
            href="/request-access"
          >
            Request Access
          </GoToSignInButton>
        </StyledFlex>
      </Stack>
    </>
  );
}
