/* eslint-disable import/no-cycle */
import axios from 'axios';
import * as Sentry from '@sentry/react';
import axiosRetry from 'axios-retry';
import opentelemetry from '@opentelemetry/api';
import UniversalCookies from 'universal-cookie';

import {
  CLIENT_ID,
  CLIENT_SECRET,
  CONTACT_US_REQUEST_TYPES,
  HOST,
  USER_TRACKING_COOKIE_HEADER_NAME,
  USER_TRACKING_COOKIE_NAME,
  USER_TYPE,
} from './constants';
import ApiError, { CREATE_ACCOUNT_MESSAGES } from './errors/ApiError';
import { AuthService } from './authService';
import { GlobalService } from './globalService';
import { parseJwt } from './utils';

const tracer = opentelemetry.trace.getTracer('api-call-tracer');

// Constants
const BASIC_AUTH_STRING = `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`;

export const getRequestDetails = (payload) => {
  const requestBody = JSON.stringify(payload);
  const additionalHeaders = { 'Content-Type': 'application/json' };
  return {
    requestBody,
    additionalHeaders,
  };
};

const withJSONHeader = (headers = {}) => ({
  'Content-Type': 'application/json',
  ...headers,
});

function handleAxiosError(axiosError, options = null) {
  const jsonError = axiosError.toJSON();
  if (options?.apiUrl) {
    Sentry.setContext('Request Details', {
      apiUrl: options.apiUrl,
    });
  }
  Sentry.captureException(jsonError.message);
  return jsonError;
}

const axiosInstance = axios.create({ baseURL: HOST });

const axiosRetryInstance = axios.create({ baseURL: HOST });
axiosRetry(axiosRetryInstance, {
  retries: 2,
  retryDelay: (retryCount) => {
    return retryCount * 2000;
  },
  retryCondition: () => {
    return true;
  },
});

const fetchTrackingCookie = () => {
  const cookies = new UniversalCookies();
  return cookies.get(USER_TRACKING_COOKIE_NAME);
};

/**
 * Authentication refreshing logic based on and modified from
 * https://gist.github.com/ModPhoenix/f1070f1696faeae52edf6ee616d0c1eb (instead of using an additional package)
 */
let isRefreshing = false;
let subscribers = [];

function waitForAuth(cb) {
  subscribers.push(cb);
}

function resumePausedRequests(token) {
  subscribers.forEach((cb) => cb(token));
  subscribers = [];
}

axiosInstance.interceptors.request.use((config) => {
  const latestToken =
    AuthService.getAuthServiceInstance().getAuthToken() || AuthService.getAuthServiceInstance().getPublicAuthToken();
  return {
    ...config,
    headers: {
      ...config.headers,
      'X-Kintent-Auth': config.url === '/auth/public/login' ? BASIC_AUTH_STRING : `Bearer ${latestToken}`,
    },
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    GlobalService.getGlobalServiceInstance().setLastUpdatedAt(Date.now());
    return response;
  },
  async (error) => {
    const {
      config: originalRequest,
      response: { status },
    } = error;

    const authService = AuthService.getAuthServiceInstance();

    /**
     * This is required to allow a user to log in to multiple Trust Shares at the same time.
     * If the application encounters a 404, the client attempts to selectTeam
     */
    if (status === 404) {
      if (!isRefreshing) {
        isRefreshing = true;
        const authToken = authService.getAuthToken() || null;
        const publicTeamId = authService.getPublicTeamId();
        if (authToken !== null) {
          await authService.selectTeam(publicTeamId, authToken);
          // This non-atomic update is guarded by the `!isRefreshing` check above, and as such should not be able to cause a race condition.
          // eslint-disable-next-line require-atomic-updates
          isRefreshing = false;
          resumePausedRequests(authToken);
        }
      }
    }

    if (status === 401) {
      if (!isRefreshing) {
        // eslint-disable-next-line require-atomic-updates
        isRefreshing = true;
        const {
          data: { token },
        } = await axiosInstance.post(
          '/auth/public/login',
          {},
          { headers: withJSONHeader({ 'X-Kintent-Auth': BASIC_AUTH_STRING }) }
        );
        authService.setPublicAuthToken(token);
        // eslint-disable-next-line require-atomic-updates
        isRefreshing = false;
        resumePausedRequests(token);
      }

      return new Promise((resolve) => {
        waitForAuth((updatedToken) => {
          originalRequest.headers['X-Kintent-Auth'] = `Bearer ${updatedToken}`;
          resolve(axios(originalRequest));
        });
      });
    }
    if (error.response?.data) {
      throw new ApiError(error.response?.data || error);
    }
    throw error;
  }
);

export const api = {};
api.policy = {
  async list() {
    return tracer.startActiveSpan('list-policies', async (span) => {
      const response = await axiosInstance.get('/policies');
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async get(id) {
    const response = await fetch(`${HOST}/policies/${id}`, {
      headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
    });

    const responseBody = await response.json();

    if (response.status !== 200) {
      throw new ApiError(responseBody);
    }

    return responseBody;
  },

  /**
   *
   * @param {*} policyId - UUID of a program policy
   * @returns Compliance Mappings for a given policy
   */
  async getComplianceMappings(policyId) {
    return tracer.startActiveSpan('get-policy-compliance-mappings', async (span) => {
      const response = await axiosInstance.get(`/policies/${policyId}/compliance-mappings`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async download(policyId) {
    return tracer.startActiveSpan('download-policy', async (span) => {
      let headers = AuthService.getAuthServiceInstance().withAuthHeaders();
      headers = {
        ...headers,
        [USER_TRACKING_COOKIE_HEADER_NAME]: fetchTrackingCookie(),
      };
      try {
        const response = await axiosRetryInstance.post(
          `${HOST}/policies/${policyId}/export?obeyIndividualWatermark=true`,
          {},
          {
            headers,
            responseType: 'blob',
          }
        );
        span.addEvent('response-received');
        let filename = null;
        const contentDispositionHeader = response.headers['content-disposition'];
        if (contentDispositionHeader) {
          filename = contentDispositionHeader.substring(contentDispositionHeader.indexOf('filename=') + 9).trim();
        }
        span.end();
        return [await response.data, filename];
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          span.end();
          return handleAxiosError(e);
        }
      }
    });
  },

  async export(id, version, options) {
    const base = `${HOST}/policies/${id}/export`;
    let query = '';

    if (options?.watermark && options?.viewOnly) {
      query = '?obeyIndividualWatermark=true&viewOnly=true';
    } else if (options?.watermark) {
      query = '?obeyIndividualWatermark=true';
    } else if (options?.viewOnly) {
      query = '?viewOnly=true';
    }

    const url = base + query;

    const response = await fetch(url, {
      method: 'POST',
      headers: AuthService.getAuthServiceInstance().withAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({ version }),
    });

    if (response.status !== 200) {
      const responseBody = await response.json();
      throw new ApiError(responseBody);
    }

    let filename = null;
    const contentDispositionHeader = response.headers.get('Content-Disposition');
    if (contentDispositionHeader) {
      filename = decodeURI(
        contentDispositionHeader.substring(contentDispositionHeader.indexOf('filename=') + 9).trim()
      );
    }

    return [await response.blob(), filename];
  },
};

api.control = {
  async list() {
    return tracer.startActiveSpan('list-controls', async (span) => {
      const response = await axiosInstance.get('/controls?includeComplianceMapping=true');
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async get(id) {
    return tracer.startActiveSpan('get-control', async (span) => {
      const response = await axiosInstance(`/controls/${id}`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async categories() {
    return tracer.startActiveSpan('get-control-categories', async (span) => {
      const response = await axiosInstance.get('/control-categories');
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },
};

api.certifications = {
  async list(teamId) {
    return tracer.startActiveSpan('list-certifications', async (span) => {
      const response = await axiosInstance.get(`/teams/${teamId}/certifications`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async download(teamId, teamCertificationId) {
    return tracer.startActiveSpan('download-policy', async (span) => {
      let headers = AuthService.getAuthServiceInstance().withAuthHeaders();
      headers = {
        ...headers,
        [USER_TRACKING_COOKIE_HEADER_NAME]: fetchTrackingCookie(),
      };
      try {
        const response = await axios.post(
          `${HOST}/teams/${teamId}/certifications/${teamCertificationId}/download`,
          {},
          {
            headers,
            responseType: 'blob',
          }
        );
        span.addEvent('response-received');
        let filename = null;
        const contentDispositionHeader = response.headers['content-disposition'];
        if (contentDispositionHeader) {
          filename = contentDispositionHeader.substring(contentDispositionHeader.indexOf('filename=') + 9).trim();
        }
        span.end();
        return [await response.data, filename];
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          span.end();
          return handleAxiosError(e);
        }
      }
    });
  },

  async trackCertificationViewedActivity(teamId, teamCertificationId) {
    tracer.startActiveSpan('download-policy', async (span) => {
      try {
        await axios.post(
          `${HOST}/teams/${teamId}/certifications/${teamCertificationId}/view`,
          {},
          { headers: AuthService.getAuthServiceInstance().withAuthHeaders() }
        );
        span.addEvent('response-received');
        span.end();
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },
};

api.questionnaires = {
  async list(teamId) {
    return tracer.startActiveSpan('list-questionnaires', async (span) => {
      const response = await axiosInstance.get(`/teams/${teamId}/questionnaires`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },
  async listSharedQuestionnaires(teamId) {
    return tracer.startActiveSpan('list-shared-questionnaires', async (span) => {
      const response = await axiosInstance.get(`/questionnaires/${teamId}/shared-questionnaires`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },
  async download(qrId, teamId) {
    return tracer.startActiveSpan('download-shared-questionnaire', async (span) => {
      try {
        const response = await axios.post(
          `${HOST}/questionnaires/${qrId}/downloadexportedquestionnaire/${teamId}`,
          {},
          {
            headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
            responseType: 'blob',
          }
        );
        let filename = null;
        const contentDispositionHeader = response.headers['content-disposition'];
        if (contentDispositionHeader) {
          filename = decodeURI(
            contentDispositionHeader.substring(contentDispositionHeader.indexOf('filename=') + 9).trim()
          );
        }
        span.end();
        return [await response.data, filename];
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          span.end();
          return handleAxiosError(e);
        }
      }
    });
  },
};

api.leaders = {
  async list(teamId) {
    return tracer.startActiveSpan('list-team-leaders', async (span) => {
      const response = await axiosInstance.get(`/teams/${teamId}/leaders`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async getLeaderAvatar(teamId, teamLeaderId) {
    return tracer.startActiveSpan('get-leader-avatar', async (span) => {
      try {
        const response = await axiosInstance.get(`/teams/${teamId}/leaders/${teamLeaderId}/avatar`, {
          responseType: 'blob',
          headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
        });
        span.addEvent('response-received');
        span.end();
        return URL.createObjectURL(response.data);
      } catch (e) {
        if (e.response?.status === 404) {
          span.end();
          return null;
        }
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data ?? e.message);
      }
    });
  },
};

api.documents = {
  async list(teamId) {
    return tracer.startActiveSpan('list-documents', async (span) => {
      const response = await axiosInstance.get(`/teams/${teamId}/documents`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async download(teamId, teamDocumentId) {
    return tracer.startActiveSpan('download-document', async (span) => {
      let headers = AuthService.getAuthServiceInstance().withAuthHeaders();
      headers = {
        ...headers,
        [USER_TRACKING_COOKIE_HEADER_NAME]: fetchTrackingCookie(),
      };
      try {
        const response = await axios.post(
          `${HOST}/teams/${teamId}/documents/${teamDocumentId}/download`,
          {},
          {
            headers,
            responseType: 'blob',
          }
        );
        span.addEvent('response-received');
        let filename = null;
        const contentDispositionHeader = response.headers['content-disposition'];
        if (contentDispositionHeader) {
          filename = contentDispositionHeader.substring(contentDispositionHeader.indexOf('filename=') + 9).trim();
        }
        span.end();
        return [await response.data, filename];
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          span.end();
          return handleAxiosError(e);
        }
      }
    });
  },

  async trackDocumentViewedActivity(teamId, teamDocumentId) {
    tracer.startActiveSpan('track-document-viewed', async (span) => {
      try {
        await axios.post(
          `${HOST}/teams/${teamId}/documents/${teamDocumentId}/view`,
          {},
          { headers: AuthService.getAuthServiceInstance().withAuthHeaders() }
        );
        span.addEvent('response-received');
        span.end();
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },
};

api.subprocessors = {
  async list() {
    return tracer.startActiveSpan('list-subprocessors', async (span) => {
      const response = await axiosInstance.get('/vendors');
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },
};

api.role = {
  async list() {
    return tracer.startActiveSpan('list-roles', async (span) => {
      try {
        const response = await axios.get(`${HOST}/roles`, {
          headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
        });
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        span.end();
        return null;
      }
    });
  },
};

api.team = {
  async get(teamId) {
    return tracer.startActiveSpan('get-team', async (span) => {
      const response = await axiosInstance.get(`/teams/${teamId}`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async getInvitation(token, authToken) {
    return tracer.startActiveSpan('get-invitation', async (span) => {
      try {
        const response = await axios.get(`${HOST}/invitations?token=${token}`, {
          headers: { 'X-Kintent-Auth': `Bearer ${authToken}` },
        });
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },

  async getSettings(teamId) {
    return tracer.startActiveSpan('get-team-settings', async (span) => {
      const response = await axiosInstance.get(`/teams/${teamId}/settings`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async getLoginSettings(teamId) {
    return tracer.startActiveSpan('get-team-settings', async (span) => {
      const response = await axiosInstance.get(`/teams/${teamId}/login-settings`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  // We used the default axios object here as we need to handle the 404 case and return null.
  async getLogo(teamId) {
    return tracer.startActiveSpan('get-team-logo', async (span) => {
      try {
        const response = await axios.get(`${HOST}/teams/${teamId}/logo`, {
          responseType: 'blob',
          headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
        });
        span.addEvent('response-received');
        span.end();
        return URL.createObjectURL(response.data);
      } catch (e) {
        span.end();
        return null;
      }
    });
  },

  async getTrustShareLogo(programContentId) {
    return tracer.startActiveSpan('get-trustshare-logo', async (span) => {
      try {
        const response = await axios.get(`${HOST}/trustshare/program-content/${programContentId}/logo`, {
          responseType: 'blob',
          headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
        });
        span.addEvent('response-received');
        span.end();
        return URL.createObjectURL(response.data);
      } catch (e) {
        span.end();
        return null;
      }
    });
  },
  /**
   * Use the default axios object here as we need to handle the 404 case and return null
   * when an icon does not exist
   */
  async getIcon(teamId) {
    return tracer.startActiveSpan('get-team-icon', async (span) => {
      try {
        const response = await axios.get(`${HOST}/teams/${teamId}/icon`, {
          responseType: 'blob',
          headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
        });
        span.addEvent('response-received');
        span.end();
        // Return the content-type to be used as the favicon type
        return {
          teamIcon: URL.createObjectURL(response.data),
          contentType: response.headers['content-type'],
        };
      } catch (e) {
        if (e.response?.status === 404) {
          span.end();
          return null;
        }
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data ?? e.message);
      }
    });
  },

  async contactUs({
    teamId,
    firstName,
    lastName,
    company,
    email,
    companyUrl,
    type,
    requestedContent = null,
    businessPurpose,
    prospectMessage,
  }) {
    return tracer.startActiveSpan('contact-us', async (span) => {
      /**
       * The startActiveSpan returns the ReturnType of the second arugment that is passed into it - in this case it is an async function (Promise)
       * To return appropriately, we need to scope the request to a variable and return it after ending the span operation i.e. span.end()
       */
      const payload = {
        firstName,
        lastName,
        email,
      };

      if (type === CONTACT_US_REQUEST_TYPES.CONTACT_US) {
        payload.company = company;
        payload.companyUrl = companyUrl;
        payload.type = type;
        payload.requestedContent = requestedContent;
        payload.businessPurpose = businessPurpose;
      } else if (type === CONTACT_US_REQUEST_TYPES.PROSPECT_EMAIL) {
        payload.prospectMessage = prospectMessage;
        payload.type = type;
      }

      const contactRequestAttempt = await axiosInstance.post(`${HOST}/teams/${teamId}/contact-us`, payload, {
        headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
      });
      span.end();
      return contactRequestAttempt;
    });
  },

  async getContentAvailability(teamId) {
    return tracer.startActiveSpan('get-content-availability', async (span) => {
      const response = await axiosInstance.get(`/teams/${teamId}/trustshare-content-availability`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },

  async createNDA(teamId, payload) {
    return tracer.startActiveSpan('create-nda', async (span) => {
      const { requestBody, additionalHeaders } = getRequestDetails(payload);
      const response = await fetch(`${HOST}/teams/${teamId}/nda`, {
        method: 'POST',
        headers: AuthService.getAuthServiceInstance().withAuthHeaders(additionalHeaders),
        body: requestBody,
      });
      span.addEvent('response-received');

      if (response.status !== 201) {
        throw new ApiError(await response.json());
      }

      const responseBody = await response.json();

      span.end();
      return responseBody;
    });
  },
};

api.auth = {
  async publicLogin() {
    return tracer.startActiveSpan('public-login', async (span) => {
      try {
        const response = await axios.post(
          `${HOST}/auth/public/login`,
          {}, // This route requires an empty body
          {
            headers: withJSONHeader({
              'X-Kintent-Auth': BASIC_AUTH_STRING,
              [USER_TRACKING_COOKIE_HEADER_NAME]: fetchTrackingCookie(),
            }),
          }
        );
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          const jsonError = handleAxiosError(e, { apiUrl: `${HOST}/auth/public/login` });
          span.end();
          throw new ApiError(jsonError);
        }
      }
    });
  },

  async login({ email, password, userType, invitationToken }) {
    return tracer.startActiveSpan('login', async (span) => {
      const [teamName = null] = window.location.hostname.split('.');
      Sentry.setContext('login-attempt', {
        teamId: AuthService.getAuthServiceInstance().getPublicTeamId(),
        teamName,
      });
      try {
        const response = await axios.post(`${HOST}/auth/login`, { email, password, invitationToken, userType });
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        Sentry.captureException(e);
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },

  async loginWithSSO(provider, token, invitationToken = null, userType) {
    return tracer.startActiveSpan('login-with-sso', async (span) => {
      try {
        const response = await axios.post(`${HOST}/auth/openid/sso-login`, {
          provider,
          token,
          ...(userType !== null && { userType }),
          ...(invitationToken !== null && { invitationToken }),
        });
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },

  async selectTeam(teamId, authToken) {
    return tracer.startActiveSpan('select-team', async (span) => {
      try {
        const response = await axios.post(
          `${HOST}/auth/select-team`,
          { teamId },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Kintent-Auth': `Bearer ${authToken}`,
            },
          }
        );
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },

  async forgotPassword(email, invitationToken) {
    let payload = { email };
    if (invitationToken != null) {
      payload = {
        ...payload,
        invitationToken,
      };
    }
    return tracer.startActiveSpan('forgot-password', async (span) => {
      try {
        const response = await axios.post(`${HOST}/auth/forgot-password`, payload, {
          headers: { 'Content-Type': 'application/json' },
        });
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },

  async resetPassword(newPassword, resetToken, invitationToken) {
    let payload = { newPassword, resetToken };
    if (invitationToken != null) {
      payload = {
        ...payload,
        invitationToken,
      };
    }
    return tracer.startActiveSpan('reset-password', async (span) => {
      try {
        const response = await axios.post(`${HOST}/auth/reset-password`, payload, {
          headers: { 'Content-Type': 'application/json' },
        });
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },

  async changePassword(oldPassword, newPassword, token) {
    return tracer.startActiveSpan('change-password', async (span) => {
      try {
        const response = await axios.post(
          `${HOST}/auth/change-password`,
          { oldPassword, newPassword },
          { headers: { 'X-Kintent-Auth': `Bearer ${token}` } }
        );
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },
};

api.user = {
  async create(payload) {
    return tracer.startActiveSpan('create-user', async (span) => {
      try {
        const response = await axios.post(`${HOST}/users`, payload);
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },

  async getSelf(authTokenOverride) {
    return tracer.startActiveSpan('get-self', async (span) => {
      let headers;
      if (authTokenOverride) {
        headers = { 'X-Kintent-Auth': `Bearer ${authTokenOverride}` };
      } else {
        headers = AuthService.getAuthServiceInstance().withAuthHeaders();
      }
      headers[USER_TRACKING_COOKIE_HEADER_NAME] = fetchTrackingCookie();

      try {
        const response = await axios.get(`${HOST}/users/me`, { headers });
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        span.setAttribute('error', e.response?.statusText);
        span.end();
        throw new ApiError(e.response?.data);
      }
    });
  },

  async joinTeam(userId, invitationToken, authToken) {
    return tracer.startActiveSpan('join-team', async (span) => {
      const response = await axios.post(
        `${HOST}/users/${userId}/join-team`,
        { invitationToken },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Kintent-Auth': `Bearer ${authToken}`,
          },
        }
      );
      span.addEvent('response-received');
      if (response.status !== 204) {
        span.end();
        throw new ApiError(response.data);
      }
      span.end();
    });
  },

  async getAppState(id) {
    const res = await fetch(`${HOST}/users/${id}/app-state`, {
      headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
};

api.invitations = {
  async get(invitationToken) {
    return tracer.startActiveSpan('get-invitations', async (span) => {
      const response = await axios.get(`${HOST}/invitations?token=${invitationToken}`);
      span.addEvent('response-received');

      if (response.status !== 200) {
        span.end();
        throw new ApiError(response.data);
      }
      span.end();
      return response.data;
    });
  },
};

api.programContent = {
  async get(programContentId) {
    return tracer.startActiveSpan('get-program-content', async (span) => {
      const response = await axiosInstance.get(`/trustshare/program-content/${programContentId}`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },
};

api.tests = {
  async count() {
    return tracer.startActiveSpan('test-count', async (span) => {
      const response = await fetch(`${HOST}/tests/_count`, {
        headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
      });
      span.addEvent('response-received');

      const responseBody = await response.json();

      if (response.status !== 200) {
        span.end();
        throw new ApiError(responseBody);
      }
      span.end();
      return responseBody;
    });
  },
};

api.trustshare = {
  async downloadNdaPdf() {
    return tracer.startActiveSpan('download-nda', async (span) => {
      try {
        const response = await axios.get(`${HOST}/trustshare/nda/download`, {
          headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
          responseType: 'blob',
        });
        span.addEvent('response-received');
        const contentType = response.headers['content-type'];
        let blobUrl = null;
        if (response.data) {
          const blob = new Blob([response.data], { type: contentType });
          blobUrl = URL.createObjectURL(blob);
        }
        span.end();
        return blobUrl;
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          span.end();
          return handleAxiosError(e);
        }
      }
    });
  },
  async verifySelfServiceProspectUser(verificationToken) {
    const jwt = parseJwt(verificationToken);
    if (!jwt) {
      throw new ApiError({ error: CREATE_ACCOUNT_MESSAGES.INVALID_TOKEN, message: 'jwt invalid' });
    }
    try {
      const response = await axios.post(
        `${HOST}/users/${jwt?.verificationId}/verify`,
        {
          userType: USER_TYPE.TEMPORARY_GUEST,
          verificationToken,
        },
        {
          headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
        }
      );
      return response.data;
    } catch (e) {
      throw new ApiError(e.response?.data);
    }
  },
  async updateProspectUserCompany(payload) {
    try {
      const response = await axios.patch(`${HOST}/trustshare/complete-account-setup`, payload, {
        headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
      });

      return response.data;
    } catch (e) {
      if (e.response) {
        throw new ApiError(e.response.data);
      } else {
        return handleAxiosError(e);
      }
    }
  },
  async resend(email, teamId) {
    const response = await axios.post(
      `${HOST}/users/send-verification`,
      {
        email,
        userType: USER_TYPE.TEMPORARY_GUEST,
        teamId,
      },
      {
        headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
      }
    );
    if (response.status !== 204) {
      throw new ApiError(response);
    }

    return response;
  },
  async joinTrustShareTeam(token) {
    try {
      const response = await axios.post(
        `${HOST}/trustshare/join-team`,
        {
          token,
        },
        {
          headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
        }
      );
      return response.data;
    } catch (e) {
      throw new ApiError(e.response?.data);
    }
  },
  async getTrustShareNotifications() {
    return tracer.startActiveSpan('get-trustshare-notifications', async (span) => {
      const response = await axiosInstance('/trustshare/notifications');
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },
};

api.companies = {
  async update(id, teamId, updates) {
    return tracer.startActiveSpan('update-company', async (span) => {
      const response = await fetch(`${HOST}/teams/${teamId}/companies/${id}`, {
        method: 'PATCH',
        headers: AuthService.getAuthServiceInstance().withAuthHeaders({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(updates),
      });
      span.addEvent('response-received');

      if (response.status !== 200) {
        span.end();
        throw new ApiError(await response.json());
      }

      const responseBody = await response.json();

      span.end();
      return responseBody;
    });
  },
};

api.nda = {
  async verifyProspectAttestation(prospectName, prospectCompany, prospectEmail) {
    return tracer.startActiveSpan('verify-prospect-attestation', async (span) => {
      try {
        const response = await axios.post(
          `${HOST}/trustshare/nda/verifyProspectAttestation`,
          {
            prospectName,
            prospectCompany,
            prospectEmail,
          },
          {
            headers: AuthService.getAuthServiceInstance().withAuthHeaders(),
          }
        );
        span.end();
        return response.data;
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          span.end();
          return handleAxiosError(e);
        }
      }
    });
  },
};

api.search = async (query) => {
  const response = await fetch(`${HOST}/v2/search`, {
    method: 'POST',
    headers: AuthService.getAuthServiceInstance().withAuthHeaders({
      'Content-Type': 'application/json',
      [USER_TRACKING_COOKIE_HEADER_NAME]: fetchTrackingCookie(),
    }),
    body: JSON.stringify({ query }),
  });

  const responseBody = await response.json();
  if (response.status !== 200) {
    throw new ApiError(responseBody);
  }
  return responseBody;
};

api.framework = {
  async list() {
    return tracer.startActiveSpan('list-frameworks', async (span) => {
      try {
        const response = await fetch(`${HOST}/frameworks`, {
          headers: AuthService.getAuthServiceInstance().withAuthHeaders({ 'Content-Type': 'application/json' }),
        });
        span.addEvent('response-received');

        const responseBody = await response.json();

        span.end();
        return responseBody;
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          span.end();
          return handleAxiosError(e);
        }
      }
    });
  },
  async listSections(frameworkId) {
    return tracer.startActiveSpan('list-sections', async (span) => {
      try {
        const response = await fetch(`${HOST}/frameworks/${frameworkId}/sections`, {
          headers: AuthService.getAuthServiceInstance().withAuthHeaders({ 'Content-Type': 'application/json' }),
        });
        span.addEvent('response-received');

        const responseBody = await response.json();

        span.end();
        return responseBody;
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          span.end();
          return handleAxiosError(e);
        }
      }
    });
  },
};

api.track = {
  async insertActivity(activity) {
    return tracer.startActiveSpan('activity-tracking', async (span) => {
      let headers = AuthService.getAuthServiceInstance().withAuthHeaders();
      headers = {
        ...headers,
        [USER_TRACKING_COOKIE_HEADER_NAME]: fetchTrackingCookie(),
        Connection: 'keep-alive',
      };
      try {
        const response = await axios.post(`${HOST}/trustshare/activity-tracking`, activity, {
          headers,
        });
        span.addEvent('response-received');
        span.end();
        return response.data;
      } catch (e) {
        if (e.response) {
          span.setAttribute('error', e.response.statusText);
          span.end();
          throw new ApiError(e.response.data);
        } else {
          span.end();
          return handleAxiosError(e);
        }
      }
    });
  },
};

api.segment = {
  async get(id) {
    return tracer.startActiveSpan('get-segment', async (span) => {
      const response = await axiosInstance.get(`/segments/${id}`);
      span.addEvent('response-received');
      span.end();
      return response.data;
    });
  },
};
