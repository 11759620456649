import styled from '@emotion/styled';
import { base, flat, Heading, translucent, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import { GlideButton } from '../../../../components/GlideButton';

export const HeadingTitle = styled(Heading)`
  color: ${({ theme }) => flat(theme.color.system.gray)};
`;

export const HeadingSubtitle = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
`;

export const RequestAccessButton = styled(GlideButton)`
  white-space: nowrap;
`;

export const HeaderContainer = styled(Flex)`
  background-color: white;
  border-radius: 8px;

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '20%')};
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};

  padding: 32px;
`;
