// Package modules
import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-navi';
import { unstable_Form as BaseForm, unstable_useFormState as useFormState } from 'reakit';
import { Typography } from '@kintent/glide';

// Local modules
import { FormContext, FormInput, FormMessage, SpaceAround } from '../../../design-system';
import { FormContainer, GoToSignInButton } from '../components/AuthFlowComponents';
import { CardDescriptionLabel, CardTitleLabel } from '../../../components/PageElements';
import { Flex } from '../../../components/Flex';
import ApiError, { INVALID_CREDENTIALS, TOKEN_EXPIRED } from '../../../lib/errors/ApiError';
import { BREAKPOINTS } from '../../../lib/constants';
import { api } from '../../../lib/api';
import { hasPasswordBeenBreached } from '../../../lib/utils';
import { GlideButton } from '../../../components/GlideButton';
import { useInvitationToken } from '../../../lib/hooks';

// Styled components
const StyledFlex = styled(Flex)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      justify-content: right;
    }
  }
`;

const FormWrapper = styled(SpaceAround)`
  & > * + * {
    margin-top: 1rem;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    & > * + * {
      margin-top: 1.5rem;
    }
  }
`;

const TimeoutMessage = () => (
  <span>
    The password reset link you clicked has expired. If you still need to reset your password, please request a fresh
    link through the&nbsp;
    <Link href="/password-reset">forgot password page.</Link>
  </span>
);

export const ResetPasswordForm = ({ resetToken }) => {
  const [isResetComplete, setResetComplete] = useState(false);
  const newPasswordFieldRef = useRef();
  const confirmPasswordFieldRef = useRef();
  const invitationToken = useInvitationToken();

  const resetPasswordForm = useFormState({
    onSubmit: async ({ password }) => {
      try {
        await api.auth.resetPassword(password.trim(), resetToken, invitationToken);
        setResetComplete(true);
      } catch (e) {
        const errors = { confirmPassword: e.sourceError?.error };

        if (e instanceof ApiError && [TOKEN_EXPIRED, INVALID_CREDENTIALS].includes(e.sourceError?.error)) {
          errors.confirmPassword = <TimeoutMessage />;
        }

        if (Object.keys(errors).length > 0) throw errors;
      }
    },
    onValidate: async ({ password, confirmPassword }) => {
      const errors = {};

      const hasBeenBreached = await hasPasswordBeenBreached(password.trim());

      if (newPasswordFieldRef.current.checkValidity() === false || password.length < 8) {
        errors.password = 'Please enter at least 8 characters';
      } else if (password !== confirmPassword) {
        errors.confirmPassword = 'Your confirmation password must match your new password';
      } else if (hasBeenBreached) {
        errors.password = (
          <>
            This password has previously been exposed in a data breach. Please choose a different password.{' '}
            <Link href="https://haveibeenpwned.com/About">Learn More</Link>
          </>
        );
      }

      if (Object.keys(errors).length > 0) throw errors;
    },
    values: {
      password: '',
      confirmPassword: '',
    },
  });

  /**
   * On the first form render, form.valid is true (not sure why).
   * This is a workaround to disable the submit button with additional logic that ensures
   * it is disabled when the values are empty, or when the passwods do not match,
   * or when the form is not valid
   */
  const disableChangePasswordButton =
    resetPasswordForm.values.password.trim().length === 0 ||
    resetPasswordForm.values.confirmPassword.trim().length === 0 ||
    resetPasswordForm.values.password.trim() !== resetPasswordForm.values.confirmPassword.trim() ||
    !resetPasswordForm.valid;

  return (
    <FormContainer bottom="50px">
      <CardTitleLabel>{isResetComplete ? 'Password Reset' : 'Enter your new password'}</CardTitleLabel>
      {!isResetComplete ? (
        <FormContext.Provider value={resetPasswordForm}>
          <BaseForm {...resetPasswordForm}>
            <FormWrapper
              bottom="48px"
              top="48px"
            >
              <Flex direction="column">
                <FormInput
                  type="password"
                  name="password"
                  required
                  placeholder="New Password"
                  ref={newPasswordFieldRef}
                />
                <FormMessage name="password" />
              </Flex>
              <Flex direction="column">
                <FormInput
                  type="password"
                  name="confirmPassword"
                  required
                  placeholder="Confirm your new password"
                  ref={confirmPasswordFieldRef}
                />
                <FormMessage name="confirmPassword" />
              </Flex>
            </FormWrapper>
            <StyledFlex justifyContent="center">
              <GlideButton
                onClick={resetPasswordForm.submit}
                disabled={disableChangePasswordButton}
              >
                Change Password
              </GlideButton>
            </StyledFlex>
          </BaseForm>
        </FormContext.Provider>
      ) : (
        <SpaceAround
          as={CardDescriptionLabel}
          top="60px"
        >
          Your new password has been reset. Click the button below to&nbsp;
          <Typography
            as={Link}
            href="/login"
          >
            sign in
          </Typography>
          <SpaceAround
            as={Flex}
            top="60px"
            justifyContent="center"
          >
            <GoToSignInButton
              as={Link}
              href="/login"
            >
              Back to Sign in
            </GoToSignInButton>
          </SpaceAround>
        </SpaceAround>
      )}
    </FormContainer>
  );
};
