// Package modules
import React, { Component } from 'react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { Heading, Typography } from '@kintent/glide';
import { Link } from 'react-navi';

// Local modules
import { SpaceAround, Stack } from '../design-system';
import { AuthService } from '../lib/authService';
import { AppFooter } from './AppFooter';
import { Flex } from './Flex';
import { ResponsiveContainer } from './ResponsiveContainer';
import { BREAKPOINTS } from '../lib/constants';
import { PAGE_NOT_FOUND_ERROR, TOKEN_EXPIRED, TRUST_SHARE_NOT_FOUND } from '../lib/errors/ApiError';

// Assets
import { ReactComponent as UnderConstructionIllustration } from '../assets/under-construction.svg';
import { ReactComponent as ErrorPopsicleIllustration } from '../assets/error-popsicle.svg';

// Styled components
const IllustrationWrapper = styled(SpaceAround)`
  width: 100%;
  height: 175px;

  & > svg {
    width: 100%;
    height: 100%;
    max-width: 250px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & > svg {
      max-width: 350px;
      max-height: 300px;
    }
  }
`;

const UnderConstructionMessageContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.bone};
  min-height: 100vh;
`;

const StyledLink = styled(Typography)`
  color: ${({ theme }) => theme.palette.peacock};
`;

const CenteredHeading = styled(Heading)`
  text-align: center;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
`;

// Components.
function UnderConstructionErrorMessage() {
  return (
    <UnderConstructionMessageContainer direction="column">
      <SpaceAround
        as={Flex}
        alignItems="center"
        direction="column"
        top="100px"
      >
        <IllustrationWrapper
          as={Flex}
          bottom="48px"
          justifyContent="center"
        >
          <UnderConstructionIllustration />
        </IllustrationWrapper>
        <Stack>
          <CenteredHeading level="1">We’re putting the pieces together, check back soon!</CenteredHeading>
        </Stack>
      </SpaceAround>
      <AppFooter />
    </UnderConstructionMessageContainer>
  );
}

function GenericErrorMessage({ error }) {
  return (
    <ResponsiveContainer>
      <SpaceAround
        as={Flex}
        alignItems="center"
        direction="column"
        top="100px"
      >
        <IllustrationWrapper
          as={Flex}
          bottom="48px"
          justifyContent="center"
        >
          <ErrorPopsicleIllustration />
        </IllustrationWrapper>
        <SpaceAround
          as={Stack}
          right="20px"
        >
          {error?.name === PAGE_NOT_FOUND_ERROR ? (
            <>
              <CenteredHeading level="1">Page not found!</CenteredHeading>
              <Typography as="p">
                Something went wrong — take me&nbsp;
                <StyledLink
                  as={Link}
                  href="/home"
                >
                  back home
                </StyledLink>
              </Typography>
            </>
          ) : (
            <>
              <CenteredHeading level="1">Error encountered!</CenteredHeading>
              <Typography as="p">
                Something went wrong — take me&nbsp;
                <StyledLink
                  as={Link}
                  href="/home"
                >
                  back home
                </StyledLink>
              </Typography>
              <StyledTypography
                as="p"
                level="8"
              >
                {error?.displayMessage || error?.message}
              </StyledTypography>
            </>
          )}
        </SpaceAround>
      </SpaceAround>
    </ResponsiveContainer>
  );
}

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error) {
    const { message } = error;
    if (message === TOKEN_EXPIRED) {
      AuthService.getAuthServiceInstance().logout();
    }
    if (error) {
      Sentry.captureException(error);
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      return error?.sourceError?.error === TRUST_SHARE_NOT_FOUND ? (
        <UnderConstructionErrorMessage />
      ) : (
        <GenericErrorMessage error={error} />
      );
    }
    const { children } = this.props;
    return children;
  }
}
