import styled from '@emotion/styled';

export const MainGrid = styled.main`
  grid-area: main;

  display: grid;
  grid-template-areas:
    'hero'
    'content';
  grid-template-rows: minmax(360px, auto) 1fr;
`;
