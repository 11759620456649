import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { UilAngleDown } from '@iconscout/react-unicons';
import { Link, useCurrentRoute } from 'react-navi';
import { Heading, Typography } from '@kintent/glide';

import { AnimatePresence, motion } from 'framer-motion';
import { Flex } from '../../../../components/Flex';
import { hexOpacity } from '../../../../lib/utils';

const COLLAPSE_VARIANTS = {
  collapsed: {
    height: 0,
    opacity: 0,
  },
  expanded: {
    height: 'auto',
    opacity: 1,
  },
};

// Styled components
const StyledContainer = styled.div`
  & svg.open {
    transform: rotate(180deg);

    transition: transform 250ms ease-in;
  }

  & svg.closed {
    transform: rotate(0deg);

    transition: transform 250ms ease-out;
  }

  & > div {
    cursor: pointer;
  }
`;

const StyledDisclosureContent = styled(motion(DisclosureContent))`
  overflow: hidden;
  padding-left: 23px;

  & a {
    display: block;
    margin-top: 12px;
    cursor: pointer;

    padding: 4px 8px;

    border-radius: 4px;

    text-decoration: none;

    &.active {
      background-color: ${({ theme }) => hexOpacity(theme.palette.volare, 0.1)};
    }

    &:hover:not(.active) {
      color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    }

    transition: color 0.25s ease-in;
  }
`;

function PolicyGroupDisclosure({
  group,
  policiesBySecurityGroup,
  selectedPolicy,
  selectedPolicyGroup,
  onPolicyGroupToggle,
}) {
  const { data } = useCurrentRoute();

  const [initiateAnimation, setInitiateAnimation] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitiateAnimation(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);
  const isCurrentPolicySelected = group === selectedPolicyGroup;
  const disclosure = useDisclosureState({ visible: group === selectedPolicy.securityGroup });
  const memoizedHandlePolicyClick = useCallback((policy) => onPolicyGroupToggle(policy), [onPolicyGroupToggle]);

  return (
    <StyledContainer className="policy-group-items">
      <Disclosure
        as={Flex}
        alignItems="center"
        justifyContent="space-between"
        {...disclosure}
        toggle={() => memoizedHandlePolicyClick(group)}
      >
        <Heading level="7">{group}</Heading>
        <UilAngleDown
          size={20}
          className={isCurrentPolicySelected ? 'open' : 'closed'}
        />
      </Disclosure>
      <AnimatePresence>
        {isCurrentPolicySelected && (
          <StyledDisclosureContent
            layout
            variants={COLLAPSE_VARIANTS}
            initial={initiateAnimation ? 'collapsed' : false}
            animate="expanded"
            exit="collapsed"
            transition="tween"
            {...disclosure}
          >
            {policiesBySecurityGroup.map((policy) => {
              return (
                <Typography
                  key={policy.id}
                  as={Link}
                  level="8"
                  className={
                    // This check ensures the policy item is shown as active
                    data?.shortName === policy.shortName || selectedPolicy.shortName === policy.shortName
                      ? 'active'
                      : null
                  }
                  href={`/policies/${encodeURIComponent(policy.shortName)}`}
                >
                  {policy.title.replace('Policy', '').trim()}
                </Typography>
              );
            })}
          </StyledDisclosureContent>
        )}
      </AnimatePresence>
    </StyledContainer>
  );
}

export default PolicyGroupDisclosure;
