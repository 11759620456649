import React from 'react';
import styled from '@emotion/styled';

import { Stack } from '../../../../design-system';
import { BREAKPOINTS } from '../../../../lib/constants';
import PolicyGroupDisclosure from './PolicyGroupDisclosure';

// Styled components
const PolicySecurityGroupContainer = styled(Stack)`
  display: none;
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: block;
    padding-top: 9px;
    padding-right: 40px;

    width: 240px;
  }
`;

function PolicyNavigationPanel({
  selectedPolicy,
  sortedPolicyGroups,
  onPolicyGroupClick,
  selectedPolicyGroup,
  adoptedPolicyGroups,
}) {
  return (
    <PolicySecurityGroupContainer>
      {sortedPolicyGroups.map((group) => {
        const policiesBySecurityGroup = adoptedPolicyGroups[group];
        if (policiesBySecurityGroup.length === 0) return null;
        return (
          <PolicyGroupDisclosure
            key={group}
            group={group}
            policiesBySecurityGroup={policiesBySecurityGroup}
            selectedPolicy={selectedPolicy}
            selectedPolicyGroup={selectedPolicyGroup}
            onPolicyGroupToggle={onPolicyGroupClick}
          />
        );
      })}
    </PolicySecurityGroupContainer>
  );
}

export default PolicyNavigationPanel;
