import React from 'react';
import { motion } from 'framer-motion';

import { ReactComponent as SSOLoginFail } from '../../../assets/sso-login-fail.svg';
import { Description, Heading, NotFoundWrapper } from './EntityNotFound.styles';
import { SignInAndRequestAccessButtonsWrapper } from '../components/SignInAndRequesAccessButtonsWrapper';
import { Stack } from '../../../design-system';
import { GlideButton } from '../../../components/GlideButton';

// Constants
const ERROR_MESSAGE_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, x: 40 },
  visible: { opacity: 1, x: 0 },
};

function EntityNotFoundError({ handleBackToSignIn }) {
  return (
    <Stack
      as={motion.div}
      initial="hidden"
      animate="visible"
      variants={ERROR_MESSAGE_ANIMATION_VARIANTS}
      space="xl"
    >
      <NotFoundWrapper>
        <SSOLoginFail />
        <Heading level="1">Oops! Something Went Wrong</Heading>
        <Description>
          We&apos;re unable to process your request at the moment. Please try again later. Thank you for your patience.
        </Description>
        <SignInAndRequestAccessButtonsWrapper>
          <GlideButton
            variant="secondary"
            onClick={handleBackToSignIn}
            className="secondary"
          >
            Back to Sign In
          </GlideButton>
        </SignInAndRequestAccessButtonsWrapper>
      </NotFoundWrapper>
    </Stack>
  );
}

export default EntityNotFoundError;
