import styled from '@emotion/styled';
import { base, color } from '@kintent/glide';

export const NotificationGridItem = styled.article`
  grid-area: notifications;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const GridItemHeading = styled.div`
  display: flex;
  justify-content: space-between;

  & .notification-section-title {
    font-weight: 600;
  }
`;

export const Card = styled.div`
  background-color: white;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => color(theme.colorV2.system.border, 600)};

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => color(theme.colorV2.system.gray, 100)};

  padding: 28px;
`;

export const List = styled.ul`
  list-style: disc;

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding-left: 14px;

  & li::marker {
    color: #62d84e;
    font-size: 1rem;
  }

  & .notification-item-title {
    text-decoration: underline;
  }
`;

export const ShowMoreNotificationsContainer = styled.div`
  margin-left: -12px;

  padding-top: 32px;
  padding-left: 14px;
`;
