import React from 'react';
import { Typography } from '@kintent/glide';

import * as Styles from './Hero.styles';
import { useProgramContent } from '../../../../../lib/state';

function Hero() {
  const [{ heroHeader = null, heroSubheader = null, heroStyle = null }] = useProgramContent();

  // Extract hero header and subheader styles if they exist
  const headerWrapper = heroStyle?.wrapper ?? {};
  const heroHeaderStyle = heroStyle?.header ?? {};
  const heroSubheaderStyle = heroStyle?.subheader ?? {};

  return (
    <Styles.Hero
      data-testid="enterprise-app-hero-section"
      style={headerWrapper}
    >
      <Styles.HeroProgramContentContainer>
        {heroHeader && (
          <Typography
            color="white"
            level="1"
            as="h1"
            style={heroHeaderStyle}
          >
            {heroHeader}
          </Typography>
        )}
        {heroSubheader && (
          <Typography
            color="white"
            className="hero-subheader"
            level="6"
            as="p"
            style={heroSubheaderStyle}
            dangerouslySetInnerHTML={{ __html: heroSubheader }}
          />
        )}
      </Styles.HeroProgramContentContainer>
    </Styles.Hero>
  );
}

export default Hero;
