// Package modules
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTheme } from '@emotion/react';

// Local modules
import { CONTENT_VISIBILITY_ACCESS_LEVELS, DEFAULT_STALE_TIME, DOCUMENT_TYPE } from './constants';
import { configCatClient } from './featureFlags';
import { api } from './api';
import { AuthService } from './authService';
import { GlobalService } from './globalService';
import { deepMerge, isPublishedUrl, transformCustomerConfigToTheme, useQueryError } from './utils';

// Constants
const STATE_TYPES = {
  POLICY: 'POLICY',
  POLICY_LIST: 'POLICY_LIST',
  CONTROL_LIST: 'CONTROL_LIST',
  DOCUMENT_LIST: 'DOCUMENT_LIST',
  SUBPROCESSOR_LIST: 'SUBPROCESSOR_LIST',
  TEAM_LOGO: 'TEAM_LOGO',
  TRUST_SHARE_LOGO: 'TRUST_SHARE_LOGO',
  TEAM_ICON: 'TEAM_ICON',
  TEAM: 'TEAM',
  CONTENT_AVAILABILITY: 'CONTENT_AVAILABILITY',
  CERTIFICATION_LIST: 'CERTIFICATION_LIST',
  QUESTIONNAIRE_LIST: 'QUESTIONNAIRE_LIST',
  DATA_ROOM_QUESTIONNAIRE_LIST: 'DATA_ROOM_QUESTIONNAIRE.LIST',
  LEADER_LIST: 'LEADER_LIST',
  LEADER_AVATAR: 'LEADER_AVATAR',
  LOGIN: 'LOGIN',
  CONTACT_US: 'CONTACT_US',
  PROGRAM_CONTENT: 'PROGRAM_CONTENT',
  FEATURE_FLAG: 'FEATURE_FLAG',
  TEST_COUNT: 'TEST_COUNT',
  NDA_DOCUMENT: 'NDA_DOCUMENT',
  SEARCH: 'search',
  TEAM_LOGIN_SETTINGS: 'TEAM_LOGIN_SETTINGS',
  VERIFY_SELF_SERVICE_PROSPECT: 'VERIFY_SELF_SERVICE_PROSPECT',
  FRAMEWORK: 'framework',
  FRAMEWORK_SECTION: 'framework-section',
  APP_STATE: 'app-state',
  TRUST_SHARE_NOTIFICATIONS: 'TRUST_SHARE_NOTIFICATIONS',
  ROLE: 'role',
  POLICY_PDF: 'POLICY_PDF',
  DOCUMENT_PDF: 'DOCUMENT_PDF',
  CONTROL_CATEGORIES: 'CONTROL_CATEGORIES',
  SEGMENT: 'SEGMENT',
};

const authService = AuthService.getAuthServiceInstance();
const globalService = GlobalService.getGlobalServiceInstance();

export function usePolicyList() {
  const { data, error } = useQuery(STATE_TYPES.POLICY_LIST, api.policy.list, {
    notifyOnChangeProps: ['data', 'error'],
  });
  return [data, error];
}

export function usePolicy(policyId) {
  const downloadPolicy = () => api.policy.download(policyId);

  return { downloadPolicy };
}

export function usePolicyData(policyId) {
  const { setError } = useQueryError();

  const { data, error } = useQuery([STATE_TYPES.POLICY, policyId], () => api.policy.get(policyId), {
    notifyOnChangeProps: ['data', 'error'],
    onError: (err) => setError(err),
    enabled: !!policyId,
    staleTime: DEFAULT_STALE_TIME,
  });

  return { data, error };
}

/**
 * @returns Compliance standard mappings for a specific policy
 */
export function usePolicyComplianceMappings(policyId, shouldSuspense = false) {
  const getComplianceMappings = () => api.policy.getComplianceMappings(policyId);
  const { data: complianceMappings, isLoading } = useQuery([STATE_TYPES.POLICY_LIST, policyId], getComplianceMappings, {
    suspense: shouldSuspense,
    staleTime: Infinity,
  });

  return { complianceMappings, isLoading };
}

export function usePolicyPDF(policyId, options) {
  const { setError } = useQueryError();
  const getPDFData = () => api.policy.export(policyId, null, options);
  const { data, refetch } = useQuery([STATE_TYPES.POLICY_PDF, policyId], getPDFData, {
    onError: setError,
    enabled: !!policyId,
    staleTime: Infinity,
  });
  return { blob: data[0], fileName: data[1], refetch };
}

export function useControlList() {
  const { data } = useQuery(STATE_TYPES.CONTROL_LIST, api.control.list, { notifyOnChangeProps: ['data', 'error'] });
  return [data];
}

export function useControl(id) {
  const queryClient = useQueryClient();
  const { data } = useQuery([STATE_TYPES.CONTROL_LIST, id], () => api.control.get(id), {
    notifyOnChangeProps: ['data', 'error'],
    initialData: () => {
      const cachedEntity =
        queryClient.getQueryData([STATE_TYPES.CONTROL_LIST, id]) ||
        queryClient.getQueryData(STATE_TYPES.CONTROL_LIST)?.find((entity) => entity.id === id);
      return cachedEntity;
    },
  });

  return [data];
}

export function useSubprocessorList() {
  const { data } = useQuery(STATE_TYPES.SUBPROCESSOR_LIST, api.subprocessors.list, {
    notifyOnChangeProps: ['data', 'error'],
  });

  return [data];
}

export function useTeamLogo(teamId) {
  const { data } = useQuery([STATE_TYPES.TEAM_LOGO, teamId], () => api.team.getLogo(teamId), { staleTime: Infinity });
  return [data];
}

export function useTeamIcon(teamId) {
  const { data } = useQuery([STATE_TYPES.TEAM_ICON, teamId], () => api.team.getIcon(teamId), { staleTime: Infinity });
  return [data];
}

export function useTeam(teamId) {
  // Using the enabled prop for the query to prevent the API call from executing when the teamId does not exist
  const { data } = useQuery([STATE_TYPES.TEAM, teamId], () => api.team.get(teamId), {
    enabled: !!teamId,
    staleTime: Infinity,
  });
  return [data];
}

export function useContentAvailability(teamId) {
  const { data } = useQuery([STATE_TYPES.CONTENT_AVAILABILITY, teamId], () => api.team.getContentAvailability(teamId), {
    staleTime: Infinity,
  });
  return [data];
}

export function useTeamSettings(teamId) {
  const { data } = useQuery([STATE_TYPES.TEAM_SETTINGS, teamId], () => api.team.getSettings(teamId), {
    staleTime: Infinity,
  });
  return [data];
}

export function useTeamCertificationList(teamId) {
  const { data } = useQuery([STATE_TYPES.CERTIFICATION_LIST, teamId], () => api.certifications.list(teamId), {
    notifyOnChangeProps: ['data', 'error'],
  });
  return [data];
}

export function useCertificationDownload(teamId, teamCertificationId) {
  const downloadCertification = () => api.certifications.download(teamId, teamCertificationId);
  return downloadCertification;
}

export function useTeamQuestionnaireList(teamId) {
  const { data } = useQuery([STATE_TYPES.QUESTIONNAIRE_LIST, teamId], () => api.questionnaires.list(teamId), {
    notifyOnChangeProps: ['data', 'error'],
  });
  return [data];
}

export function useTeamLeaderList(teamId) {
  const { data } = useQuery([STATE_TYPES.LEADER_LIST, teamId], () => api.leaders.list(teamId), {
    notifyOnChangeProps: ['data', 'error'],
  });
  return [data];
}

export function useTeamLeaderAvatar(teamId, teamLeaderId, avatarFilename) {
  const { data } = useQuery(
    [STATE_TYPES.LEADER_AVATAR, teamLeaderId],
    () => api.leaders.getLeaderAvatar(teamId, teamLeaderId),
    {
      suspense: false, // This is to prevent page blocking when loading avatars
      enabled: !!avatarFilename,
      notifyOnChangeProps: ['data', 'error'],
    }
  );
  return [data];
}

export function useTeamDocumentList(teamId) {
  const { data } = useQuery([STATE_TYPES.DOCUMENT_LIST, teamId], () => api.documents.list(teamId), {
    notifyOnChangeProps: ['data', 'error'],
  });

  return [data];
}

export function useDocumentDownload(teamId, teamDocumentId) {
  const downloadDocument = () => api.documents.download(teamId, teamDocumentId);
  return downloadDocument;
}

export function useContactUs() {
  const contactUs = (...args) => api.team.contactUs(...args);
  const { mutateAsync: wrappedContactUs } = useMutation((values) => contactUs(values));
  return { submitContactUs: wrappedContactUs };
}

export function useAuthService() {
  const publicTeamId = authService.getPublicTeamId();
  const authenticatedUser = authService.getAuthenticatedUser();
  const [currentTeam] = useTeam(publicTeamId);

  const props = useMemo(
    () => ({
      authService,
      login: authService.login.bind(authService),
      logout: authService.logout.bind(authService),
      updateAuth: authService.updateAuth.bind(authService),
      selectTeam: authService.selectTeam.bind(authService),
      joinAndSwitchTeam: authService.joinAndSwitchTeam.bind(authService),
    }),
    []
  );

  return {
    publicTeamId,
    authenticatedUser,
    currentTeam,
    ...props,
  };
}

/**
 *
 * @returns This hooks is used to fetch a TrustShare logo for a team
 */
export function useCustomerLogo() {
  const { currentTeam } = useAuthService();

  const isPublishedEnvironment = isPublishedUrl(currentTeam.trustShareUrl);
  const programContentId = isPublishedEnvironment
    ? currentTeam.trustShareProgramContentPublishedId
    : currentTeam.trustShareProgramContentPreviewId;

  // Fetch and return the specific TS logo based on the program content id/version
  const { data: trustShareLogo } = useQuery(
    [STATE_TYPES.TRUST_SHARE_LOGO, programContentId],
    () => api.team.getTrustShareLogo(programContentId),
    { staleTime: Infinity }
  );

  return [trustShareLogo];
}

export function useProgramContent() {
  const { currentTeam } = useAuthService();

  // Retrieve the appropriate program content based on the current url of the TS website
  const isPublishedEnvironment = isPublishedUrl(currentTeam.trustShareUrl);
  const programContentId = isPublishedEnvironment
    ? currentTeam.trustShareProgramContentPublishedId
    : currentTeam.trustShareProgramContentPreviewId;

  const { data } = useQuery(STATE_TYPES.PROGRAM_CONTENT, () => api.programContent.get(programContentId), {
    notifyOnChangeProps: ['data', 'error'],
  });

  return [data];
}

/**
 * To deduce the customer theme from the base theme, the customer specific properties are first mapped based on the components
 * defined in the default theme. The result of the transformation is deeply merged into the default theme.
 */
export function useCustomerTheme(customerTheme) {
  const theme = useTheme();
  return deepMerge(transformCustomerConfigToTheme(customerTheme), theme);
}

export function useGlobalService() {
  return { lastUpdatedAt: globalService.getLastUpdatedAt() };
}

export function useCertificationViewedActivity() {
  const { currentTeam } = useAuthService();
  const trackDocumentViewedActivity = ({ teamCertificationId }) =>
    api.certifications.trackCertificationViewedActivity(currentTeam.id, teamCertificationId);
  const { mutateAsync: mutateTrackCertificationViewedActivity } = useMutation((values) =>
    trackDocumentViewedActivity(values)
  );

  return {
    trackCertificationView: (teamCertificationId) => mutateTrackCertificationViewedActivity({ teamCertificationId }),
  };
}

export function useDocumentViewedActivity() {
  const { currentTeam } = useAuthService();
  const trackDocumentViewedActivity = ({ teamDocumentId }) =>
    api.documents.trackDocumentViewedActivity(currentTeam.id, teamDocumentId);
  const { mutateAsync: mutateTrackDocumentViewedActivity } = useMutation((values) =>
    trackDocumentViewedActivity(values)
  );

  return { trackDocumentViewedActivity: (teamDocumentId) => mutateTrackDocumentViewedActivity({ teamDocumentId }) };
}

export function useFeatureFlag(flagName, team) {
  const { authenticatedUser, currentTeam } = useAuthService();

  // Allow ConfigCat to specify feature flags by user ID or email, and team ID or name.
  const flagUser = useMemo(
    () => ({
      identifier: authenticatedUser?.id,
      email: authenticatedUser?.email,
      custom: {
        teamId: team?.id ?? currentTeam?.id,
        teamName: team?.name ?? currentTeam?.name,
      },
    }),
    [authenticatedUser, currentTeam, team]
  );

  const getFeatureFlagValue = () => configCatClient.getValueAsync(flagName, false, flagUser);

  const { data, refetch } = useQuery([STATE_TYPES.FEATURE_FLAG, flagName], getFeatureFlagValue, {
    // ConfigCat has its own caching mechanism.
    staleTime: 0,
    notifyOnChangeProps: ['data', 'error'],
  });

  useEffect(() => {
    refetch();
  }, [team, refetch, currentTeam]);

  return data;
}

// Returns true if at least one of a set of feature flags is enabled for the current user.
export function useFeatureFlags(flagNameArray = []) {
  const { authenticatedUser, currentTeam } = useAuthService();

  // Allow ConfigCat to specify feature flags by user ID or email, and team ID or name.
  const flagUser = useMemo(
    () => ({
      identifier: authenticatedUser?.id,
      email: authenticatedUser?.email,
      custom: {
        teamId: currentTeam?.id,
        teamName: currentTeam?.name,
      },
    }),
    [authenticatedUser, currentTeam]
  );

  const areSomeFeatureFlagsEnabled = async () => {
    const flagsEnabled = await Promise.all(
      flagNameArray.map((flagName) => configCatClient.getValueAsync(flagName, false, flagUser))
    );
    return flagsEnabled.some((enabled) => enabled);
  };

  const { data } = useQuery([STATE_TYPES.FEATURE_FLAG_SET, ...flagNameArray], areSomeFeatureFlagsEnabled, {
    // ConfigCat has its own caching mechanism.
    staleTime: 0,
    notifyOnChangeProps: ['data', 'error'],
  });

  return data;
}

export function useFeatureFlagsSetting(flagName) {
  const { authenticatedUser, currentTeam } = useAuthService();

  // Allow ConfigCat to specify feature flags by user ID or email, and team ID or name.
  const flagUser = useMemo(
    () => ({
      identifier: authenticatedUser?.id,
      email: authenticatedUser?.email,
      custom: {
        teamId: currentTeam?.id,
        teamName: currentTeam?.name,
      },
    }),
    [authenticatedUser, currentTeam]
  );

  const settingValue = async () => {
    await configCatClient.getValueAsync(flagName, '', flagUser);
  };

  const { data } = useQuery([STATE_TYPES.FEATURE_FLAG_SET, flagName], settingValue, {
    // ConfigCat has its own caching mechanism.
    staleTime: 0,
    notifyOnChangeProps: ['data', 'error'],
  });

  return data;
}

// Test state
export const QUERY_TYPES = {
  CONTROL: 'programControlId',
};

export function useTestCountForControls() {
  const getTestCountForControls = () => api.tests.count();

  const { data } = useQuery([STATE_TYPES.TEST_COUNT], getTestCountForControls, {
    staleTime: Infinity, // Since this value will not change frequently, we can cache it for a long time.
  });

  const testCountByControlId = useMemo(() => {
    return (data ?? []).reduce((acc, item) => acc.set(item.programControlId, item.count), new Map());
  }, [data]);

  return {
    testCount: testCountByControlId,
  };
}

export function useNDA() {
  const { currentTeam } = useAuthService();
  const createNda = (payload) => api.team.createNDA(currentTeam.id, payload);
  return { createNda };
}

export function useCompany(id) {
  const queryClient = useQueryClient();

  const {
    currentTeam: { id: teamId },
  } = useAuthService();

  const patchCompany = async (updates) => {
    await api.companies.update(id, teamId, updates);
    queryClient.invalidateQueries([STATE_TYPES.NDA_DOCUMENT, id], { refetchActive: true, refetchInactive: true });
  };

  return {
    patchCompany,
  };
}

export function useDownloadNDADocument() {
  const { data } = useQuery(STATE_TYPES.NDA_DOCUMENT, api.trustshare.downloadNdaPdf, {
    suspense: false,
  });
  return { data };
}

export function useVerifyProspectAttestation() {
  const verifyProspectAttestation = ({ name, company, email }) =>
    api.nda.verifyProspectAttestation(name, company, email);
  const { mutateAsync: mutateVerifyProspectAttestation } = useMutation((values) => verifyProspectAttestation(values));

  return { verifyProspectAttestation: (...args) => mutateVerifyProspectAttestation(...args) };
}

export function useChangePassword() {
  const authorizationToken = authService.getAuthToken();
  const changePassword = ({ oldPassword, newPassword }) =>
    api.auth.changePassword(oldPassword.trim(), newPassword.trim(), authorizationToken);
  const { mutateAsync: mutateVerifyProspectAttestation } = useMutation((values) => changePassword(values));

  return { changePassword: (...args) => mutateVerifyProspectAttestation(...args) };
}

export function useSearch() {
  const queryClient = useQueryClient();

  const handler = useCallback(
    (query) => {
      return queryClient.fetchQuery({
        queryKey: [STATE_TYPES.SEARCH, query],
        queryFn: () => api.search(query),
      });
    },
    [api.search, queryClient]
  );

  return handler;
}

export function useSelfServiceProspectUser(verificationToken) {
  const { data, error } = useQuery(
    [STATE_TYPES.VERIFY_SELF_SERVICE_PROSPECT],
    () => api.trustshare.verifySelfServiceProspectUser(verificationToken),
    {
      notifyOnChangeProps: ['data', 'error'],
      retry: false,
      suspense: false,
      enabled: !!verificationToken,
      staleTime: Infinity,
    }
  );

  return { data, error };
}

export function useTeamLoginSettings(teamId) {
  const { data } = useQuery([STATE_TYPES.TEAM_LOGIN_SETTINGS, teamId], () => api.team.getLoginSettings(teamId), {
    staleTime: Infinity,
  });
  return [data];
}

/**
 * The correct URL to redirect to after a user has requested access to a team.
 */
export function useRequestAccessRedirectionUrl() {
  const { currentTeam } = useAuthService();
  const [teamSettings] = useTeamSettings(currentTeam?.id);

  const requestAccessRedirectionUrl = useMemo(() => {
    return teamSettings.trustShare.nda?.isAutoApproveAccessRequestEnabled ? '/create-account' : '/request-access';
  }, [teamSettings.trustShare.nda?.isAutoApproveAccessRequestEnabled]);

  return { requestAccessRedirectionUrl };
}

export function useFrameworkList() {
  const { setError } = useQueryError();

  const { data, refetch } = useQuery(STATE_TYPES.FRAMEWORK, api.framework.list, {
    notifyOnChangeProps: ['data', 'error'],
    onError: setError,
    staleTime: DEFAULT_STALE_TIME,
  });

  return { data, refetch };
}

export function useFrameworkSectionList(frameworkId) {
  const { setError } = useQueryError();

  const getFrameworkSections = () => api.framework.listSections(frameworkId);
  const { data, refetch } = useQuery([STATE_TYPES.FRAMEWORK_SECTION, frameworkId], getFrameworkSections, {
    notifyOnChangeProps: ['data', 'error'],
    onError: setError,
    staleTime: DEFAULT_STALE_TIME,
  });

  return {
    data,
    refetch,
  };
}

const _shouldHideBasedOnContentVisibility = (accessLevel, authenticatedUser) => {
  if (accessLevel === CONTENT_VISIBILITY_ACCESS_LEVELS.HIDDEN) {
    return true;
  }
  // Weak check to ensure undefined/null both cases are handled
  if (accessLevel === CONTENT_VISIBILITY_ACCESS_LEVELS.PRIVATE && authenticatedUser == null) {
    return true;
  }
  // This case implies TRANSPARENT or Authenticated User accessing a PRIVATE level
  return false;
};

export const useContentVisibility = () => {
  const { authenticatedUser } = useAuthService();
  const [
    {
      policyOverviewAccessLevel,
      policyDetailAccessLevel,
      controlOverviewAccessLevel,
      controlDetailAccessLevel,
      certificationStandardMappingDetailAccessLevel,
    },
  ] = useProgramContent();

  const shouldHidePolicyOverview = useMemo(() => {
    return _shouldHideBasedOnContentVisibility(policyOverviewAccessLevel, authenticatedUser);
  }, [policyOverviewAccessLevel, authenticatedUser]);

  const shouldHidePolicyDetail = useMemo(() => {
    return _shouldHideBasedOnContentVisibility(policyDetailAccessLevel, authenticatedUser);
  }, [policyDetailAccessLevel, authenticatedUser]);

  const shouldHideControlOverview = useMemo(() => {
    return _shouldHideBasedOnContentVisibility(controlOverviewAccessLevel, authenticatedUser);
  }, [controlOverviewAccessLevel, authenticatedUser]);

  const shouldHideControlDetail = useMemo(() => {
    return _shouldHideBasedOnContentVisibility(controlDetailAccessLevel, authenticatedUser);
  }, [controlDetailAccessLevel, authenticatedUser]);

  const certificationAccessLevelMap = useMemo(() => {
    const shouldHideCertification = { ...certificationStandardMappingDetailAccessLevel };
    Object.keys(shouldHideCertification).forEach((cert) => {
      shouldHideCertification[cert] = _shouldHideBasedOnContentVisibility(
        certificationStandardMappingDetailAccessLevel[cert],
        authenticatedUser
      );
    });
    return shouldHideCertification;
  }, [certificationStandardMappingDetailAccessLevel, authenticatedUser]);

  return {
    shouldHidePolicyOverview,
    shouldHidePolicyDetail,
    shouldHideControlOverview,
    shouldHideControlDetail,
    certificationAccessLevelMap,
  };
};

export const useSharedQuestionnaireList = (teamId) => {
  const { authenticatedUser } = useAuthService();

  const { data } = useQuery(
    [STATE_TYPES.DATA_ROOM_QUESTIONNAIRE_LIST, teamId],
    () => api.questionnaires.listSharedQuestionnaires(teamId),
    {
      enabled: !!authenticatedUser,
      notifyOnChangeProps: ['data', 'error'],
    }
  );

  return data || [];
};

export const useSharedQuestionnaire = (qrId) => {
  const { currentTeam } = useAuthService();

  const downloadQuestionnaire = () => api.questionnaires.download(qrId, currentTeam.id);
  return {
    downloadQuestionnaire,
  };
};

export function useAppState(id) {
  const { setError } = useQueryError();

  const getAppState = () => api.user.getAppState(id);
  const { data: appState } = useQuery([STATE_TYPES.APP_STATE, id], getAppState, {
    staleTime: Infinity,
    onError: setError,
    enabled: !!id,
    notifyOnChangeProps: ['data', 'error'],
  });

  return {
    data: appState,
  };
}

export function useRoleList() {
  const { currentTeam } = useAuthService();
  const { setError } = useQueryError();
  const { data } = useQuery(STATE_TYPES.ROLE, api.role.list, {
    notifyOnChangeProps: ['data', 'error'],
    staleTime: Infinity,
    onError: setError,
    enabled: !!currentTeam?.id,
  });

  return data;
}

export function useTrustShareNotifications() {
  const { setError } = useQueryError();

  const { data, refetch } = useQuery(STATE_TYPES.TRUST_SHARE_NOTIFICATIONS, api.trustshare.getTrustShareNotifications, {
    notifyOnChangeProps: ['data', 'error'],
    onError: setError,
    staleTime: DEFAULT_STALE_TIME,
  });

  return { data, refetch };
}

export function useControlCategoriesList() {
  const { setError } = useQueryError();

  const { data: controlCategories } = useQuery(STATE_TYPES.CONTROL_CATEGORIES, api.control.categories, {
    notifyOnChangeProps: ['data', 'error'],
    onError: setError,
    staleTime: 60000,
  });

  return { controlCategories };
}

export function useSegment(id) {
  const { setError } = useQueryError();

  const { data } = useQuery([STATE_TYPES.SEGMENT, id], () => api.segment.get(id), {
    notifyOnChangeProps: ['data', 'error'],
    onError: (err) => setError(err),
    enabled: !!id,
    staleTime: DEFAULT_STALE_TIME,
  });

  return { data };
}

export function useDocumentPDF(documentId) {
  const { currentTeam } = useAuthService();
  const { setError } = useQueryError();
  const getPDFData = () => api.documents.download(currentTeam.id, documentId);
  const { data, refetch } = useQuery([STATE_TYPES.DOCUMENT_PDF, documentId], getPDFData, {
    onError: setError,
    enabled: !!documentId,
    staleTime: Infinity,
  });
  return { blob: data[0], fileName: data[1], refetch };
}

export function useArtifactForPDFViewer(artifactType, artifactId) {
  const { currentTeam } = useAuthService();
  const { setError } = useQueryError();

  const getPolicyPDF = () => api.policy.export(artifactId, null, { viewOnly: true });
  const getDocumentPDF = () => api.documents.download(currentTeam?.id, artifactId);

  const stateType = artifactType === DOCUMENT_TYPE.POLICY ? STATE_TYPES.POLICY_PDF : STATE_TYPES.DOCUMENT_PDF;
  const getPDFData = artifactType === DOCUMENT_TYPE.POLICY ? getPolicyPDF : getDocumentPDF;

  const [policies] = usePolicyList();
  const [documents] = useTeamDocumentList(currentTeam?.id);

  // Find the artifact in the list of policies or documents
  const artifact =
    artifactType === DOCUMENT_TYPE.POLICY
      ? policies.find((policy) => policy.id === artifactId)
      : documents.find((document) => document.id === artifactId);

  const { data, refetch } = useQuery([stateType, artifactId], getPDFData, {
    onError: setError,
    enabled: !!artifactId,
    staleTime: Infinity,
  });

  return { blob: data[0], fileName: data[1], refetch, artifact };
}
