// Package modules.
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Box } from 'reakit';

// Local modules.
import { theme as defaultTheme } from '../components/theme';

// Exports.
const Stack = styled(Box, { shouldForwardProp: (prop) => prop !== 'space' })`
  & > * + * {
    margin-top: ${({ space, theme }) => theme.spacing[space]};
  }
`;

Stack.defaultProps = { space: 'md' };

Stack.propTypes = { space: PropTypes.oneOf(Object.keys(defaultTheme.spacing)) };

// Exports.
export default Stack;
