import React from 'react';
import { Link } from 'react-navi';

import { FEATURE_FLAG } from 'lib/featureFlags';
import * as Styles from './DashboardSubprocessorItemCard.styles';
import { Flex } from '../../../../components/Flex';
import { PAGE_ROUTES } from '../../../../lib/constants';
import { getSubpageUrl } from '../../../../lib/utils';
import { useAuthService, useFeatureFlag } from '../../../../lib/state';

function FallbackState({ name, vendorSecurityPageUrl }) {
  return (
    <Styles.FallbackLabel
      as={vendorSecurityPageUrl ? Link : 'p'}
      href={vendorSecurityPageUrl}
      level="8"
      target="blank"
    >
      {name[0]}
    </Styles.FallbackLabel>
  );
}

export function DashboardSubprocessorItemCard({ name, logoUrl }) {
  const { authenticatedUser, currentTeam } = useAuthService();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);
  const accessLevel = currentTeam.trustShareAccessLevel;
  const cardLink = getSubpageUrl(accessLevel, authenticatedUser, '/subprocessors');

  const shouldHideOverlay = isRequestAccessHidden && cardLink === PAGE_ROUTES.REQUEST_ACCESS;

  return (
    <Styles.FlexItem
      gap="12px"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        alignItems="center"
        gap="8px"
      >
        {logoUrl ? (
          <Styles.LogoWrapper
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={logoUrl}
              alt={name}
            />
          </Styles.LogoWrapper>
        ) : (
          <Flex
            justifyContent="center"
            alignItems="center"
          >
            <FallbackState
              name={name}
              vendorSecurityPageUrl={cardLink}
            />
          </Flex>
        )}
        <Styles.NameTypography
          as="p"
          level="7"
        >
          {name}
        </Styles.NameTypography>
      </Flex>
      {!shouldHideOverlay && (
        <Styles.Overlay className="overlay">
          <Styles.LinkButton
            onClick={() => {
              if (cardLink)
                window.open(
                  `${cardLink}/${cardLink === PAGE_ROUTES.REQUEST_ACCESS ? '' : encodeURIComponent(name)}`,
                  '_self'
                );
            }}
            variant="secondary"
          >
            View
          </Styles.LinkButton>
        </Styles.Overlay>
      )}
    </Styles.FlexItem>
  );
}
