// Package modules.
import styled from '@emotion/styled';
import { Link as BaseLink } from 'react-navi';

// Exports.
export const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.palette.volare};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.palette.antigua};
  }

  transition: color 250ms ease-in;
`;
