import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-navi';
import {
  unstable_Form as BaseForm,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_useFormState as useFormState,
} from 'reakit';
import { Heading, Typography } from '@kintent/glide';

import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { Helmet } from '../../../components/Helmet';
import CardContainer from '../../../components/CardContainer';
import { Avatar } from '../../../components/Avatar';
import { FormContext, FormInput, FormLabel, FormMessage, SpaceAround, useModal } from '../../../design-system';
import { Flex } from '../../../components/Flex';
import { hasPasswordBeenBreached } from '../../../lib/utils';
import HomePageContainer from '../home/components/HomePageContainer';
import { useAuthService, useChangePassword, useTeamLoginSettings } from '../../../lib/state';
import ApiError, { INSUFFICIENT_PERMISSIONS } from '../../../lib/errors/ApiError';
import { ChangePasswordSuccessModal } from './ChangePasswordSuccessModal';
import DefaultProfileIcon from '../../../assets/default-profile.svg';
import { GlideButton } from '../../../components/GlideButton';

const StyledLeaderAvatar = styled(Avatar)`
  cursor: default;
  min-height: 80px;
`;

const StyledFlex = styled(Flex)`
  max-width: 348px;
`;

const StyledFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

export const AccountSummary = withTrustShareNavigationBar(() => {
  const { currentTeam, authenticatedUser, logout } = useAuthService();
  const [teamLoginSettings] = useTeamLoginSettings(currentTeam.id);
  const { changePassword } = useChangePassword();

  const changePasswordSuccessModal = useModal();

  const oldPasswordFieldRef = useRef(null);
  const newPasswordFieldRef = useRef(null);
  const confirmPasswordFieldRef = useRef(null);

  const changePasswordForm = useFormState({
    onSubmit: async ({ oldPassword, newPassword }) => {
      try {
        await changePassword({
          oldPassword,
          newPassword,
        });
        changePasswordSuccessModal.show();
        setTimeout(() => {
          changePasswordSuccessModal.hide();
          logout();
          window.location.href = '/login';
        }, 5000);
      } catch (e) {
        changePasswordSuccessModal.hide();
        const errors = { confirmPassword: e.sourceError?.error };
        if (e instanceof ApiError && errors.confirmPassword === INSUFFICIENT_PERMISSIONS) {
          errors.oldPassword = <>Your password is incorrect. Please enter the correct password.</>;
        }

        if (Object.keys(errors).length > 0) throw errors;
      }
    },
    onValidate: async ({ newPassword, confirmNewPassword }) => {
      const errors = {};

      const hasBeenBreached = await hasPasswordBeenBreached(newPassword.trim());

      if (newPasswordFieldRef.current.checkValidity() === false || newPassword.length < 8) {
        errors.newPassword = 'Please enter at least 8 characters';
      } else if (newPassword !== confirmNewPassword) {
        errors.confirmNewPassword = 'Your confirmation password must match your new password';
      } else if (hasBeenBreached) {
        errors.newPassword = (
          <>
            This password has previously been exposed in a data breach. Please choose a different password.{' '}
            <Link href="https://haveibeenpwned.com/About">Learn More</Link>
          </>
        );
      }

      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    values: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    resetOnSubmitSucceed: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const { oldPassword, newPassword, confirmNewPassword } = changePasswordForm.values;
  const shouldDisableSubmitButton = oldPassword === '' || newPassword === '' || confirmNewPassword === '';

  if (authenticatedUser === null) {
    return null;
  }

  return (
    <>
      <Helmet pageTitle="Account Summary" />
      <HomePageContainer
        as={Flex}
        direction="column"
        gap="24px"
      >
        <Heading level="2">Account Summary</Heading>
        <CardContainer gap="1rem">
          <StyledLeaderAvatar
            size={80}
            src={authenticatedUser.pictureUrl || DefaultProfileIcon}
            name={authenticatedUser.name}
          />
          <Flex
            direction="column"
            justifyContent="center"
            gap="8px"
          >
            <Heading level="5">{authenticatedUser.name}</Heading>
            <Typography
              level="7"
              as="p"
            >
              {authenticatedUser.email}
            </Typography>
          </Flex>
        </CardContainer>
        {!teamLoginSettings?.disableEmailLogin && (
          <CardContainer
            direction="column"
            gap="8px"
          >
            <Heading level="6">Account Password</Heading>
            <Typography
              level="8"
              as="p"
            >
              Set a new password for your TrustCloud Account. This will impact your login experience across the
              platform.
            </Typography>
            <FormContext.Provider value={changePasswordForm}>
              <BaseForm {...changePasswordForm}>
                <StyledFieldset disabled={changePasswordForm.submitting}>
                  <StyledFlex
                    as={SpaceAround}
                    direction="column"
                    gap="8px"
                    top="24px"
                  >
                    <Flex
                      direction="column"
                      gap="8px"
                      justifyContent="space-between"
                    >
                      <FormLabel
                        name="oldPassword"
                        required
                      >
                        Current Password
                      </FormLabel>
                      <FormInput
                        type="password"
                        name="oldPassword"
                        ref={oldPasswordFieldRef}
                        required
                      />
                      <FormMessage
                        element="span"
                        name="oldPassword"
                      />
                    </Flex>
                    <Flex
                      direction="column"
                      gap="8px"
                    >
                      <FormLabel
                        name="newPassword"
                        required
                      >
                        New Password
                      </FormLabel>
                      <FormInput
                        name="newPassword"
                        type="password"
                        ref={newPasswordFieldRef}
                        required
                      />
                      <FormMessage name="newPassword" />
                    </Flex>
                    <Flex
                      direction="column"
                      gap="8px"
                    >
                      <FormLabel
                        name="confirmNewPassword"
                        required
                      >
                        Confirm New Password
                      </FormLabel>
                      <FormInput
                        name="confirmNewPassword"
                        type="password"
                        ref={confirmPasswordFieldRef}
                        required
                      />
                      <FormMessage name="confirmNewPassword" />
                    </Flex>
                  </StyledFlex>
                  <Flex
                    as={SpaceAround}
                    top="8px"
                    justifyContent="flex-end"
                  >
                    <FormSubmitButton
                      as={GlideButton}
                      variant="primary"
                      disabled={shouldDisableSubmitButton || !changePasswordForm.valid || changePasswordForm.submitting}
                    >
                      Save Password
                    </FormSubmitButton>
                  </Flex>
                </StyledFieldset>
              </BaseForm>
            </FormContext.Provider>
          </CardContainer>
        )}
        <ChangePasswordSuccessModal modal={changePasswordSuccessModal} />
      </HomePageContainer>
    </>
  );
});
