import React, { useState } from 'react';
import { Toast } from '@kintent/glide';

import { useAuthService, useProgramContent, useTeamCertificationList } from '../../../../lib/state';
import { DOCUMENT_ACCESS_LEVEL, DOCUMENT_TYPE } from '../../../../lib/constants';
import * as Styles from './DocumentCertificationSection.styles';
import { Flex } from '../../../../components/Flex';
import { getDefaultCertificationDescription, getTeamCertificationsWithChildren } from '../../../../lib/utils';
import DashboardCertificationSectionItemCard from '../../home/components/DashboardCertificationItemCard';
import { ScrollTarget } from './DocumentDetailPanel.styles';

const getPublicFacingDescription = (publicFacingStandards, shortName, subtype) => {
  if (shortName && publicFacingStandards) {
    return publicFacingStandards[`${shortName}${subtype ?? ''}`];
  }
  return false;
};

function DocumentCertificationSection() {
  const { currentTeam } = useAuthService();
  const [rawCertifications] = useTeamCertificationList(currentTeam.id);
  const [programContent] = useProgramContent();
  const [shouldShowDownloadErrorToast, setShouldShowDownloadErrorToast] = useState(false);

  const publicFacingStandards = programContent?.publicFacingDescriptions?.standards;

  const certifications = getTeamCertificationsWithChildren(rawCertifications);
  const visibleCertifications = certifications.filter((item) => {
    return (
      item.children.filter((child) => {
        return [DOCUMENT_ACCESS_LEVEL.PUBLIC, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(child.trustShareAccessLevel);
      })?.length > 0
    );
  });

  if (!visibleCertifications.length) {
    return null;
  }

  return (
    <>
      <Flex
        direction="column"
        gap="16px"
      >
        <ScrollTarget id={DOCUMENT_TYPE.CERTIFICATION} />
        <Styles.DocumentTitle level="5">Certifications</Styles.DocumentTitle>
        <Styles.Grid>
          {visibleCertifications?.map((item) => {
            // this sets the firstChild as the parentCertification if the parent is hidden
            const parentCertification =
              item.children.find((child) => child.parentId === null) ?? item.children[0] ?? {};
            return (
              <DashboardCertificationSectionItemCard
                key={item.id}
                accessLevel={parentCertification.trustShareAccessLevel}
                certificationUrl={parentCertification?.certificationUrl}
                documentCount={item.children.length}
                certificationId={parentCertification.id}
                description={
                  getPublicFacingDescription(
                    publicFacingStandards,
                    item.certification?.shortName,
                    item.children[0]?.subtype ?? item.subtype
                  ) ??
                  getDefaultCertificationDescription(
                    currentTeam?.name,
                    item.certification.shortName,
                    item.children[0]?.status ?? item.status,
                    item.children[0]?.subtype ?? item.subtype
                  )
                }
                filename={item.children[0]?.filename ?? item.filename}
                shortName={item.certification.shortName}
                subtype={item.children[0]?.subtype ?? item.subtype}
                setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
              />
            );
          })}
        </Styles.Grid>
      </Flex>
      <Toast
        variant="negative"
        label="Error"
        description="We couldn't generate the download file, please contact support."
        open={shouldShowDownloadErrorToast}
        duration={1000}
        onOpenChange={() => setShouldShowDownloadErrorToast(false)}
      />
    </>
  );
}

export default DocumentCertificationSection;
