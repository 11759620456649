import React from 'react';
import { useTheme } from '@emotion/react';
import { UilClipboardNotes, UilDiary, UilLifeRing } from '@iconscout/react-unicons';
import { flat, Typography } from '@kintent/glide';

import * as Styles from './NotificationCard.styles';
import { Flex } from '../../../../components/Flex';
import { NotificationLink } from './NotificationCard.styles';
import DateTime from '../../../../components/DateTime';
import { TRUSTSHARE_NOTIFICATION_TYPE } from '../../../../lib/constants';

const getNotificationTypeIcon = (type) => {
  const theme = useTheme();

  switch (type) {
    case TRUSTSHARE_NOTIFICATION_TYPE.COMPLIANCE:
      return (
        <UilClipboardNotes
          size={16}
          color={flat(theme.color.system.gray, '80%')}
        />
      );
    case TRUSTSHARE_NOTIFICATION_TYPE.INCIDENT:
      return (
        <UilLifeRing
          size={16}
          color={flat(theme.color.system.gray, '80%')}
        />
      );
    default:
      return (
        <UilDiary
          size={16}
          color={flat(theme.color.system.gray, '80%')}
        />
      );
  }
};

function NotificationCard({ title, description, dateTime, type, links }) {
  const notificationTypeDisplayString = type ? type[0].toUpperCase() + type.substring(1) : '';

  return (
    <Styles.NotificationCardWrapper
      gap="24px"
      direction="column"
    >
      <Flex
        direction="column"
        gap="12px"
      >
        <Typography
          level="6"
          color="gray"
          strength="100%"
        >
          {title}
        </Typography>
        <Flex
          gap="12px"
          alignItems="center"
        >
          <Styles.NotificationType
            alignItems="center"
            gap="4px"
          >
            {getNotificationTypeIcon(type)}
            <Typography
              level="8"
              color="gray"
              strength="100%"
            >
              {notificationTypeDisplayString}
            </Typography>
          </Styles.NotificationType>
          <Typography
            level="9"
            color="gray"
            strength="100%"
          >
            Published{' '}
            <DateTime
              date={new Date(dateTime)}
              includeTime
              format="MM/dd/yyyy"
            />
          </Typography>
        </Flex>
      </Flex>
      <Typography
        level="7"
        color="gray"
        strength="100%"
      >
        {description}
        <NotificationLink>{links}</NotificationLink>
      </Typography>
    </Styles.NotificationCardWrapper>
  );
}

export default NotificationCard;
