import { useEffect, useLayoutEffect } from 'react';

export const useMeasureRoute = (span) => {
  useLayoutEffect(() => {
    if (span.ended !== true) {
      span.addEvent('before-render');
    }
  }, []);
};

export const useMeasureRender = (span) => {
  useLayoutEffect(() => {
    if (span.ended !== true) {
      span.addEvent('before-paint');
    }
  }, []);
};

export const useMeasurePaint = (span) => {
  useEffect(() => {
    if (span.ended !== true) {
      span.addEvent('after-paint');
      span.end();
    }
  }, []);
};
