// Package modules
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Box } from 'reakit';
import PropTypes from 'prop-types';

// Local modules
import { BREAKPOINTS, DEVICE_TYPES } from '../lib/constants';

// Styled components
export const ResponsiveContainer = styled(Box)`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
`;

export const MediaQueryContainer = styled(Box, {
  shouldForwardProp(props) {
    return !['hideFrom', 'showFor'].includes(props);
  },
})`
  // Hide component from all resolutions
  ${({ hideFrom }) =>
    hideFrom === DEVICE_TYPES.MOBILE &&
    css`
      & {
        display: none;
      }
    `}

  // Hide component from Tablet or Desktop resolutions
  ${({ hideFrom }) =>
    [DEVICE_TYPES.TABLET, DEVICE_TYPES.DESKTOP].includes(hideFrom) &&
    css`
      @media (min-width: ${BREAKPOINTS[hideFrom]}px) {
        & {
          display: none;
        }
      }
    `}
  
  // Display component for Tablet or Desktop resolutions
  // For Mobile devices, it is displayed by default
  ${({ showFor }) =>
    [DEVICE_TYPES.TABLET, DEVICE_TYPES.DESKTOP].includes(showFor) &&
    css`
      @media (min-width: ${BREAKPOINTS[showFor]}px) {
        & {
          display: block;
        }
      }
    `}
`;

MediaQueryContainer.propTypes = {
  /** If specified, then the child element will be hidden by 'display: none;' from the specified resolutions. */
  showFor: PropTypes.oneOf(Object.values(DEVICE_TYPES)),
  /** If specified, then the child element will be displayed by 'display: block;' from the specified resolutions. */
  hideFrom: PropTypes.oneOf(Object.values(DEVICE_TYPES)),
};
