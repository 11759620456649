// Package modules
import React from 'react';
import { motion } from 'framer-motion';

// Local modules
import { Stack } from '../../../design-system';
import { CardTitle, ErrorLabel } from '../../../components/PageElements';
import { SignInAndRequestAccessButtonsWrapper } from '../components/SignInAndRequesAccessButtonsWrapper';
import { GlideButton } from '../../../components/GlideButton';

// Constants
const ERROR_MESSAGE_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, x: 40 },
  visible: { opacity: 1, x: 0 },
};

export function UnauthorizedAccessError({ loginMethod, handleBackToSignIn, handleRequestAccess }) {
  return (
    <>
      <CardTitle>{loginMethod === 'sso' ? 'Sign in with SSO failed' : 'Sign in failed'}</CardTitle>
      <Stack
        as={motion.div}
        initial="hidden"
        animate="visible"
        variants={ERROR_MESSAGE_ANIMATION_VARIANTS}
        space="xl"
      >
        <ErrorLabel>
          Oops! This email is not associated with a TrustShare account. You may not have created an account, or your
          invitation or account may have expired. To request an invitation, please fill out the Access Request form.
        </ErrorLabel>
        <SignInAndRequestAccessButtonsWrapper>
          <GlideButton
            variant="secondary"
            onClick={handleBackToSignIn}
            className="secondary"
          >
            Back to Sign In
          </GlideButton>
          <GlideButton
            variant="primary"
            onClick={handleRequestAccess}
          >
            Request Access
          </GlideButton>
        </SignInAndRequestAccessButtonsWrapper>
      </Stack>
    </>
  );
}
