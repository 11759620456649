// Package modules
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useCurrentRoute, useNavigation } from 'react-navi';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { createContainer, useContainer } from 'unstated-next';
import { Menu, MenuButton, MenuItem, useDialogState, useMenuState } from 'reakit';
import { motion } from 'framer-motion';
import { UilAngleDown as ChevronDownIcon, UilBars as MenuIcon } from '@iconscout/react-unicons';
import { color, Typography } from '@kintent/glide';

// Local modules
import { Avatar } from './Avatar';
import { TimestampLabel } from './PageElements';
import { Flex } from './Flex';
import Heartbeat from './Heartbeat';
import { MobileNavigationDialog, MobileNavigationDisclosure } from './MobileNavigationDialog';
import {
  useAuthService,
  useContentVisibility,
  useCustomerLogo,
  useFeatureFlags,
  usePolicyList,
  useProgramContent,
  useSubprocessorList,
  useTeamCertificationList,
  useTeamDocumentList,
  useTeamLogo,
  useTrustShareNotifications,
} from '../lib/state';
import { useDeviceResolution } from '../lib/hooks';
import {
  BREAKPOINTS,
  NAVIGATION_BAR_ITEMS,
  PRIVATE_TRUST_SHARE_NAVIGATION_ITEMS,
  TRUST_SHARE_ACCESS_LEVELS,
} from '../lib/constants';
import { filterSubprocessorsFromProgramVendors, hexOpacity } from '../lib/utils';
import { AccountMenu } from '../app/trust-share/account-summary/AccountMenu';

import { ReactComponent as TrustShareLogo } from '../assets/ts-logo.svg';
import { GlideButton } from './GlideButton';

// Styled components
const NavigationFrame = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 60px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;

  box-shadow: 2px 8px 10px 2px ${({ theme }) => hexOpacity(theme.palette.ariel, 0.1)},
    2px 4px 8px 1px ${({ theme }) => hexOpacity(theme.palette.shadow, 0.1)};
  background-color: ${({ theme }) => theme.components.header.navigation.backgroundColor};
  transition: height 0.5s ease;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      top: 20px;
      width: calc(100% - 4rem);
      height: 58px;
      padding: 0.24rem 1rem;

      border-radius: 8px;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      width: calc(100% - 6rem);
      height: 82px;
      padding: 1rem;
    }

    & > div:first-of-type {
      display: none;
    }
  }
`;

const StyledUnorderedList = styled(Flex)`
  display: none;
  list-style-type: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      display: flex;
    }
  }
`;

const MobileMenuList = styled(Flex)`
  list-style-type: none;
`;

export const StyledTrustShareLogo = styled.img`
  max-width: 100%;
  max-height: 100%;

  object-fit: contain;
  object-position: left;
`;

const LinkWithEmbeddedLogo = styled(motion(Link))`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 50px;

  text-decoration: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      padding-left: 8px;
    }
  }
`;

const TrustShareIndicatorContainer = styled(Flex)`
  // Hide TrustShareLabel from mobile
  & > div:first-of-type > a {
    display: none;
  }

  // Hide TrustShareLogo on mobile devices
  & svg.ts-logo {
    display: none;
  }

  // Positioning TimestampLabel only for mobile devices
  & > p {
    display: none;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      gap: 4px;
      flex-direction: column;
    }

    & > div:first-of-type > a {
      display: block;
    }

    & > p {
      display: block;
      width: auto;
    }

    & svg.ts-logo {
      display: initial;
    }
  }
`;

const AuthenticationActionsFlex = styled(Flex)`
  position: relative;
  white-space: nowrap;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      width: 300px;
      max-width: 350px;
    }
  }
`;

const HeaderAnimationContainer = styled(Flex)`
  position: relative;
`;

const HeaderUserInfo = styled(Flex)`
  display: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      display: flex;
    }
  }
`;

const AnimatedAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.components.header.avatar.background};
  box-shadow: inset 1px 2px 4px 0px ${({ theme }) => hexOpacity(theme.palette.chalkboard, 0.2)};
  border: none;

  & small {
    color: ${({ theme }) => theme.components.header.avatar.textColor};
  }
`;

const BorderedAvatar = styled(Avatar, { shouldForwardProp: (props) => !['isCustomized'].includes(props) })`
  border: 2px solid
    ${({ theme, isCustomized }) =>
      isCustomized ? theme.components.header.trustShareLabel.textColor : hexOpacity(theme.palette.chalkboard, 0.7)};
`;

// Override MenuButton
const StyledMenuButton = styled(MenuButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  cursor: pointer;
  border: none;
  background: none;
  outline: none;

  padding: 0;

  border-bottom: 2px solid transparent;

  transition: border-bottom 0.25s ease-in-out;

  & > small {
    border-bottom: 2px solid transparent;
    padding: 3px 0;
  }

  &.active-parent > small {
    border-color: ${({ theme }) => theme.components.header.navigation.textColor};
  }

  & svg {
    transition: transform 0.2s ease-in-out;
  }

  &[aria-expanded='true'] svg {
    transform: rotate(180deg);
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    &:hover > small {
      border-color: ${({ theme }) => theme.components.header.navigation.textColor};
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      width: auto;
      height: 100%;
    }

    &:hover {
      border-color: ${({ theme }) => theme.components.header.navigation.textColor};
    }

    & > svg {
      width: 14px;
      height: 14px;
      transform: translateY(-2px);
    }

    &.active-parent {
      border-color: ${({ theme }) => theme.components.header.navigation.textColor};
    }

    & > small {
      border: none;
      padding: 0;
    }

    &[aria-expanded='true'] svg {
      transform: rotate(0deg);
    }
  }
`;

// Override Menu
const StyledMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.components.header.navigation.backgroundColor};
  border-radius: 4px;
  outline: none;

  // Disclosure animation
  opacity: 0;
  gap: 0;
  padding-top: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  transition: opacity 300ms ease-in-out, gap 300ms ease-in-out, padding-top 300ms ease-in-out;

  & > small {
    opacity: 0;
    height: 0;
    outline: none;
    transition: opacity 300ms ease-in-out, height 300ms ease-in-out;
  }

  &[data-enter] {
    gap: 0.75rem;
    padding-top: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    opacity: 1;

    & > small {
      opacity: 1;
      height: 25px;
    }
  }

  // Reset absolute positioning of popup menu on mobile.
  @media (min-width: ${BREAKPOINTS.MOBILE}px) and (max-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      transform: unset !important;
      position: relative !important;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      padding: 1rem 0.75rem;
      box-shadow: 1px 4px 4px 2px ${({ theme }) => hexOpacity(theme.palette.shadow, 0.1)};
    }
  }
`;

const StyledTypography = styled(Typography)`
  display: inline-block;
  height: 25px;
  cursor: pointer;
  outline: none;

  border-bottom: 2px solid transparent;

  color: ${({ theme }) => theme.components.header.navigation.textColor};

  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;

  &.active {
    border-color: ${({ theme }) => theme.components.header.navigation.textColor};
  }

  transition: border-bottom 0.25s ease-in-out;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    &:hover {
      border-color: ${({ theme }) => theme.components.header.navigation.textColor};
    }
  }
`;

const MenuButtonLabel = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 2px;
  outline: none;

  color: ${({ theme }) => theme.components.header.navigation.textColor};

  height: 100%;
`;

const SubMenuItem = styled(Typography)`
  position: relative;

  display: inline-block;
  height: 25px;
  min-width: 184px;
  cursor: pointer;

  color: ${({ theme }) => theme.components.header.navigation.textColor};

  border-bottom: 2px solid transparent;

  letter-spacing: 2px;
  text-decoration: none;

  &.active-sub-menu-item {
    border-color: ${({ theme }) => theme.components.header.navigation.textColor};
  }

  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    // Hover effect should not be present on the active sub menu item
    &:hover:not(.active-sub-menu-item) {
      transform: translateY(2px);

      &:before {
        position: absolute;
        bottom: 0px;

        content: '';
        display: block;

        width: 100%;
        height: 2px;

        background-color: ${({ theme }) => theme.components.header.navigation.textColor};
      }

      transition: transform 0.35s;
    }
  }
`;

const StyledFAB = styled(motion(GlideButton))`
  font-size: 0.875rem;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  border: none;

  &:hover {
    box-shadow: 0px 1px 4px 1px ${({ theme }) => hexOpacity(theme.components.header.primaryCTAButton.background, 0.4)};
  }

  &:active {
    box-shadow: inset 0px 2px 4px 1px
      ${({ theme }) => hexOpacity(theme.components.header.primaryCTAButton.background, 0.4)};
  }

  &:focus {
    outline: none;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
    }
  }

  transition: box-shadow 0.25s ease-in-out;
`;

export const NoLogoLabel = styled(Typography)`
  display: flex;
  align-items: center;

  height: 38px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 24px;

  background-color: ${({ theme }) => color(theme.colorV2.system.gray, 200)};
`;

const MobileNavigationLayoutContainer = styled(Flex)`
  padding: 1rem;
  min-height: 100%;
`;

/**
 * This function is used to hide navigation items from the navigation bar if there is no content associated with it.
 * Additionally, the request-access item is hidden when a user has not logged in via email/password or SSO.
 */
function filterEmptyContentFromNavigationBar(
  teamCertifications = [],
  teamDocuments = [],
  policies = [],
  isSubprocessorVisible,
  isCertificationVisible,
  hideRequestAccess = true,
  shouldHideControlDetail,
  shouldHidePolicyDetail,
  trustshareNotifications,
  shouldDisplayFaq
) {
  return NAVIGATION_BAR_ITEMS.map((item) => {
    if (item.title === 'Compliance') {
      // If both - subprocessors and certifications are hidden, then hide the Compliance menu item
      if (!isSubprocessorVisible && !isCertificationVisible) return null;
      return {
        ...item,
        subMenu: item.subMenu.filter((subMenuItem) => {
          // Hide the subprocessors submenu item based on the program content setting
          if (subMenuItem.path === '/subprocessors') return isSubprocessorVisible;
          if (subMenuItem.path === '/certifications') return isCertificationVisible;
          return false;
        }),
      };
    }
    if (item.title === 'Resources') {
      if (!trustshareNotifications.length > 0 && !shouldDisplayFaq) return null;
      return {
        ...item,
        subMenu: item.subMenu.filter((subMenuItem) => {
          if (subMenuItem.path === '/notifications') return trustshareNotifications.length > 0;
          if (subMenuItem.path === '/faq') return shouldDisplayFaq;
          return false;
        }),
      };
    }
    if (item.title === 'Policies' && (policies.length === 0 || shouldHidePolicyDetail)) return null;
    if (
      item?.path === '/documents' &&
      teamDocuments.length === 0 &&
      teamCertifications.length === 0 &&
      policies.length === 0
    )
      return null;
    if (item?.path === '/request-access' && hideRequestAccess === true) return null;
    if (item?.path === '/controls' && shouldHideControlDetail) return null;
    return item;
  }).filter(Boolean);
}

function useNavigationBar(initialState = {}) {
  const [menuItems, setMenuItems] = useState(initialState.menuItems);

  const result = useMemo(
    () => ({
      menuItems,
      setMenuItems,
    }),
    [menuItems, setMenuItems]
  );

  return result;
}

export const NavigationBarProvider = createContainer(useNavigationBar);
// Provider Higher Order Component
export const withNavigationBar = (Component) => (props) =>
  (
    <NavigationBarProvider.Provider>
      <Component {...props} />
    </NavigationBarProvider.Provider>
  );

export const withTrustShareNavigationBar = (Component) => (props) => {
  const { currentTeam, authenticatedUser } = useAuthService();
  const [policies] = usePolicyList();
  const [teamCertifications] = useTeamCertificationList(currentTeam.id);
  const [subprocessors] = useSubprocessorList();
  const filteredSubprocessors = filterSubprocessorsFromProgramVendors(subprocessors);
  const [teamDocuments] = useTeamDocumentList(currentTeam.id);
  const [{ isSubprocessorVisible, isCertificationVisible, faq }] = useProgramContent();
  const { setMenuItems } = useContainer(NavigationBarProvider);
  const { data: trustshareNotifications } = useTrustShareNotifications();

  const { shouldHideControlDetail, shouldHidePolicyDetail } = useContentVisibility();

  const shouldDisplayCertifications = isCertificationVisible && teamCertifications.length > 0;
  const shouldDisplaySubprocessors = isSubprocessorVisible && filteredSubprocessors.length > 0;
  const shouldDisplayFaq = faq ? Object.keys(faq).length > 0 : false;

  useEffect(() => {
    if (currentTeam.trustShareAccessLevel === TRUST_SHARE_ACCESS_LEVELS.PRIVATE) {
      if (authenticatedUser === null) {
        setMenuItems(PRIVATE_TRUST_SHARE_NAVIGATION_ITEMS);
      } else {
        setMenuItems(
          filterEmptyContentFromNavigationBar(
            teamCertifications,
            teamDocuments,
            policies,
            shouldDisplaySubprocessors,
            shouldDisplayCertifications,
            true,
            shouldHideControlDetail,
            shouldHidePolicyDetail,
            trustshareNotifications,
            shouldDisplayFaq
          )
        );
      }
    } else {
      setMenuItems(
        filterEmptyContentFromNavigationBar(
          teamCertifications,
          teamDocuments,
          policies,
          shouldDisplaySubprocessors,
          shouldDisplayCertifications,
          authenticatedUser !== null,
          shouldHideControlDetail,
          shouldHidePolicyDetail,
          trustshareNotifications,
          shouldDisplayFaq
        )
      );
    }
  }, [
    setMenuItems,
    authenticatedUser,
    currentTeam.trustShareAccessLevel,
    teamCertifications,
    teamDocuments,
    policies,
    shouldDisplaySubprocessors,
    shouldDisplayCertifications,
    shouldHideControlDetail,
    shouldHidePolicyDetail,
  ]);

  return (
    <NavigationBarProvider.Provider>
      <Component {...props} />
    </NavigationBarProvider.Provider>
  );
};

const NavigationSubMenuItem = ({ item, navigate, menu, mobileNavigationDialogState, href }) => {
  const includedForFeatureFlags = item.includedForFeatureFlags || [];

  const areFeatureFlagsEnabled = useFeatureFlags(includedForFeatureFlags);

  if (includedForFeatureFlags.length && !areFeatureFlagsEnabled) {
    return null;
  }

  return (
    <MenuItem
      as="small"
      onClick={() => {
        navigate(item.path);
        menu.hide();
        mobileNavigationDialogState?.hide();
      }}
      {...menu}
    >
      <SubMenuItem
        as={Link}
        className={item.path === href ? 'active-sub-menu-item' : null}
        level="8"
      >
        {item.title}
      </SubMenuItem>
    </MenuItem>
  );
};

function NavigationItemWithSubMenu({ title, subMenu, mobileNavigationDialogState }) {
  const {
    url: { href },
  } = useCurrentRoute();
  const { navigate } = useNavigation();
  const theme = useTheme();
  const { isDesktop } = useDeviceResolution();
  const menu = useMenuState({ placement: 'top', unstable_offset: [10, 10], animated: true });

  const classNames = ['parent'];
  const isActiveParent = subMenu.some((innerTab) => href.includes(innerTab.path));
  if (isActiveParent) {
    classNames.push('active-parent');
  }

  return (
    <li>
      <StyledMenuButton
        className={classNames.join(' ')}
        {...menu}
      >
        <MenuButtonLabel
          level="8"
          as="span"
        >
          {title}
        </MenuButtonLabel>
        <ChevronDownIcon
          size={24}
          color={theme.components.header.navigation.textColor}
        />
      </StyledMenuButton>
      <StyledMenu
        as={Flex}
        direction="column"
        gap="0.75rem"
        aria-label={title}
        hideOnClickOutside={isDesktop}
        {...menu}
      >
        {subMenu.map((item) => (
          <NavigationSubMenuItem
            key={item.title}
            item={item}
            navigate={navigate}
            menu={menu}
            mobileNavigationDialogState={mobileNavigationDialogState}
            href={href}
          />
        ))}
      </StyledMenu>
    </li>
  );
}

function NavigationItem({ title, path = null, subMenu, mobileNavigationDialogState, includedForFeatureFlags = [] }) {
  const areFeatureFlagsEnabled = useFeatureFlags(includedForFeatureFlags);

  if (includedForFeatureFlags.length && !areFeatureFlagsEnabled) {
    return null;
  }

  if (subMenu.length > 0) {
    return (
      <NavigationItemWithSubMenu
        title={title}
        subMenu={subMenu}
        mobileNavigationDialogState={mobileNavigationDialogState}
      />
    );
  }
  return (
    <li>
      <StyledTypography
        as={Link}
        activeClassName="active"
        level="8"
        href={path}
        onClick={mobileNavigationDialogState?.hide}
      >
        {title}
      </StyledTypography>
    </li>
  );
}

function TrustShareIndicator() {
  const theme = useTheme();

  return (
    <TrustShareIndicatorContainer
      direction="row-reverse"
      gap="0.375rem"
    >
      <Flex
        alignItems="center"
        gap="0.5rem"
      >
        <TrustShareLogo
          className="ts-logo"
          color={theme.components.header.trustShareLabel.textColor}
        />
        <Heartbeat strokeColor={theme.components.header.connectionIndicator.backgroundColor} />
      </Flex>
      <TimestampLabel />
    </TrustShareIndicatorContainer>
  );
}

function MobileTrustShareIndicator() {
  const theme = useTheme();

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      gap={{ y: '0.1rem' }}
    >
      <Flex
        alignItems="center"
        gap={{ x: '0.45rem' }}
      >
        <TrustShareLogo />
        <Heartbeat strokeColor={theme.components.header.connectionIndicator.backgroundColor} />
      </Flex>
      <TimestampLabel />
    </Flex>
  );
}

function LoggedOutState() {
  return (
    <StyledFAB
      as={Link}
      variant="fab"
      href="/login"
    >
      Sign In
    </StyledFAB>
  );
}

function ProgramHeader() {
  const { currentTeam } = useAuthService();
  const [teamLogo] = useTeamLogo(currentTeam.id);
  const [customerLogo] = useCustomerLogo();

  // Display the TS logo if a customer has uploaded one, if not, display the logo uploaded in their TC
  const logoToDisplay = customerLogo ?? teamLogo;

  return (
    <Flex>
      <LinkWithEmbeddedLogo
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.25, type: 'ease-in-out' }}
        href={currentTeam.websiteUrl}
        target="blank"
      >
        {logoToDisplay ? (
          <StyledTrustShareLogo
            src={logoToDisplay}
            alt={`${currentTeam.name} logo`}
          />
        ) : (
          <NoLogoLabel
            level="8"
            text="chalkboard"
            as="p"
          >
            {currentTeam.name}
          </NoLogoLabel>
        )}
      </LinkWithEmbeddedLogo>
    </Flex>
  );
}

function MobileNavigationLayout({ mobileNavigationDialogState, hasCustomerModifiedTSLabelColor }) {
  const { menuItems } = NavigationBarProvider.useContainer();

  const { navigate } = useNavigation();
  const { authenticatedUser, logout } = useAuthService();
  const handleLogout = () => {
    mobileNavigationDialogState.hide();
    logout();
    window.location.href = '/home';
  };

  const handleSignIn = () => {
    mobileNavigationDialogState.hide();
    navigate('/login');
  };

  return (
    <MobileNavigationDialog mobileNavigationDialogState={mobileNavigationDialogState}>
      <MobileNavigationLayoutContainer direction="column">
        <Flex
          className="flex-grow"
          direction="column"
          gap="32px"
        >
          <Flex
            justifyContent={authenticatedUser ? 'space-between' : 'flex-start'}
            alignItems="center"
          >
            <MobileTrustShareIndicator hasCustomerModifiedTSLabelColor={hasCustomerModifiedTSLabelColor} />
            {authenticatedUser && (
              <BorderedAvatar
                size={36}
                name={authenticatedUser.name}
                src={authenticatedUser.pictureUrl}
                isCustomized={hasCustomerModifiedTSLabelColor}
              />
            )}
          </Flex>
          <MobileMenuList
            direction="column"
            gap="1.25rem"
          >
            {menuItems?.map((item) => (
              <NavigationItem
                key={item.title}
                title={item.title}
                path={item.path}
                subMenu={item.subMenu}
                mobileNavigationDialogState={mobileNavigationDialogState}
                includedForFeatureFlags={item.includedForFeatureFlags}
              />
            ))}
          </MobileMenuList>
        </Flex>
        {authenticatedUser ? (
          <StyledFAB
            variant="fab"
            onClick={handleLogout}
          >
            Sign Out
          </StyledFAB>
        ) : (
          <StyledFAB onClick={handleSignIn}>Sign In</StyledFAB>
        )}
      </MobileNavigationLayoutContainer>
    </MobileNavigationDialog>
  );
}

export function NavigationBar() {
  const { menuItems } = NavigationBarProvider.useContainer();
  const { authenticatedUser } = useAuthService();
  const [{ headerStyleConfig }] = useProgramContent();

  const theme = useTheme();
  const mobileNavigationDialogState = useDialogState({ animated: true });
  const { isDesktop } = useDeviceResolution();

  const hasCustomerModifiedTSLabelColor = Boolean(headerStyleConfig.trustShareLabel?.textColor);
  const menu = useMenuState({ placement: 'bottom-end', unstable_offset: [15, 24], animated: 250 });

  useEffect(() => {
    if (isDesktop && mobileNavigationDialogState.visible) {
      mobileNavigationDialogState.hide();
    }
  }, [isDesktop, mobileNavigationDialogState]);

  return (
    <>
      <NavigationFrame
        aria-label="TrustShare Navigation Bar"
        role="navigation"
      >
        <MobileNavigationDisclosure mobileNavigationDialogState={mobileNavigationDialogState}>
          <MenuIcon
            size="24"
            color={theme.components.header.navigation.textColor}
          />
        </MobileNavigationDisclosure>
        <ProgramHeader />
        <StyledUnorderedList gap="2rem">
          {menuItems?.map((item) => (
            <NavigationItem
              key={item.title}
              title={item.title}
              path={item.path}
              subMenu={item.subMenu}
              includedForFeatureFlags={item.includedForFeatureFlags}
            />
          ))}
        </StyledUnorderedList>
        <AuthenticationActionsFlex
          justifyContent="flex-end"
          gap="1rem"
        >
          <TrustShareIndicator />
          <HeaderUserInfo>
            {authenticatedUser ? (
              <HeaderAnimationContainer
                alignItems="center"
                justifyContent="center"
              >
                <MenuButton
                  as={AnimatedAvatar}
                  size={44}
                  name={authenticatedUser.name}
                  src={authenticatedUser.pictureUrl}
                  {...menu}
                />
                <AccountMenu menu={menu} />
              </HeaderAnimationContainer>
            ) : (
              <LoggedOutState />
            )}
          </HeaderUserInfo>
        </AuthenticationActionsFlex>
      </NavigationFrame>
      <MobileNavigationLayout
        mobileNavigationDialogState={mobileNavigationDialogState}
        hasCustomerModifiedTSLabelColor={hasCustomerModifiedTSLabelColor}
      />
    </>
  );
}
