// Package modules
import styled from '@emotion/styled';

// Local modules
import { SpaceAround } from '../../../design-system';
import { ResponsiveContainer } from '../../../components/ResponsiveContainer';
import { BREAKPOINTS } from '../../../lib/constants';
import { hexOpacity } from '../../../lib/utils';
import { GlideButton } from '../../../components/GlideButton';

// Styled components
export const FormContainer = styled(SpaceAround)`
  & > form {
    margin-top: 2rem;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding-left: 1rem;
      padding-right: 1rem;

      & > form {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
`;

export const GoToSignInButton = styled(GlideButton)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      width: auto;
    }
  }
`;

export const AuthenticationCard = styled(ResponsiveContainer)`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      box-shadow: -15px -15px 25px 0px ${({ theme }) => hexOpacity(theme.palette.white, 0.9)},
        10px 10px 15px 4px ${({ theme }) => hexOpacity(theme.palette.shadow, 0.1)};
      border-radius: ${({ theme }) => theme.radius.xl};

      padding: 1rem 1.5rem;
    }
  }
`;
