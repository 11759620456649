import React from 'react';
import styled from '@emotion/styled';
import { Heading, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import CountBox from '../../../../components/CountBox';

const StyledFlex = styled(Flex)`
  width: 100%;
`;

function ControlPageSection({ title, count, description, children }) {
  return (
    <StyledFlex
      direction="column"
      gap="16px"
    >
      <Flex
        gap="8px"
        alignItems="center"
      >
        <CountBox count={count} />
        <Heading level="5">{title}</Heading>
      </Flex>
      <Typography
        level="8"
        as="p"
      >
        {description}
      </Typography>
      <div>{children}</div>
    </StyledFlex>
  );
}

export default ControlPageSection;
