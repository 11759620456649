import styled from '@emotion/styled';
import { flat, rgb } from '@kintent/glide';
import { Dialog } from 'reakit';
import { Link } from 'react-navi';

const CustomizeDialog = styled(Dialog)`
  position: fixed;
  bottom: 12px;
  right: 12px;
  width: 387px;
  height: 474px;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  z-index: 25000;
  padding: 32px;
  border-radius: 12px;
  background: ${({ theme }) => rgb(theme.color.system.white)};
  box-shadow: 0px 2px 2px 0px rgba(153, 153, 153, 0.2);
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};

  b {
    font-weight: 700;
  }
`;

const CustomizeDialogLink = styled(Link)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  text-decoration: none;

  color: ${({ theme }) => rgb(theme.color.system.white)};
  & > button {
    background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    &:hover {
      background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    }
  }
`;

export { CustomizeDialog, CustomizeDialogLink };
