export const TOKEN_EXPIRED = 'TokenExpired';
export const ENTITY_NOT_FOUND = 'EntityNotFound';
export const TRUST_SHARE_NOT_FOUND = 'TrustShareNotFound';
export const INVALID_CREDENTIALS = 'InvalidCredentials';
export const VALIDATION_ERRORS = 'ValidationErrors';
export const ACCOUNT_EXPIRED = 'AccountExpired';
export const UNAUTHORIZED = 'Unauthorized';
export const CONTACT_REQUEST_ALREADY_EXISTS = 'ContactRequestAlreadyExists';
export const USER_ALREADY_INVITED = 'UserAlreadyInvited';
export const USER_ALREADY_EXISTS = 'UserAlreadyExists';
export const PAGE_NOT_FOUND_ERROR = 'NotFoundError';
export const INSUFFICIENT_PERMISSIONS = 'InsufficientPermissions';
export const ACCOUNT_SETUP_INCOMPLETE = 'AccountSetupIncomplete';
export const USER_VERIFICATION_PENDING = 'UserVerificationPending';

export default class ApiError extends Error {
  constructor(sourceError) {
    super(sourceError?.error);
    this.name = 'ApiError';
    this.displayMessage = sourceError?.displayMessage || this.message;
    this.sourceError = sourceError;
  }
}
export const CREATE_ACCOUNT_MESSAGES = {
  USER_ALREADY_VERIFIED: 'UserAlreadyVerified',
  USER_ALREADY_COMPLETED_ACCOUNT_SETUP: 'UserAlreadyCompletedAccountSetup',
  USER_VERIFICATION_PENDING: 'UserVerificationPending',
  ACCOUNT_SETUP_INCOMPLETE: 'AccountSetupIncomplete',
  INVALID_TOKEN: 'InvalidToken',
  ORIGIN_TEAM_CHANGED: 'OriginTeamChanged',
  USER_ALREADY_EXISTS_FOR_TRUSTSHARE: 'UserAlreadyExistsForTrustShare',
  INVALID_VERIFICATION_LINK: 'InvalidVerificationLink',
  USER_ALREADY_EXISTS: 'UserAlreadyExists',
  TOKEN_EXPIRED: 'TokenExpiredError',
  JSON_WEB_TOKEN_ERROR: 'JsonWebTokenError',
  ACCOUNT_EXPIRED: 'AccountExpired',
  ENTITY_NOT_FOUND: 'EntityNotFound', // TODO: How to handle this? - this is for account disabled, error sent backend
  // AUTO_APPROVAL_ACCESS_REQUEST_DISABLED: 'AutoApprovalAccessRequestDisabled', // This case will not appear on frontend.
  ACCOUNT_CREATED: 'AccountCreated', // Generated on frontend when POST for api.user.create succeeds
  EMAIL_RESENT: 'EmailResent', // Generated on frontend when email is resent successfully
  EMAIL_RESENDING_FAILED: 'EmailResendingFailed', // Generated on frontend when email resending fails
  SOMETHING_WENT_WRONG: 'SomethingWentWrong', // Generated on frontend or backend, generic message
  UNAUTHORIZED: 'Unauthorized',
};
