import { addSpanNetworkEvent, hasKey, PerformanceTimingNames as PTN } from '@opentelemetry/sdk-trace-web';
import { otperformance } from '@opentelemetry/core';
import { SemanticAttributes } from '@opentelemetry/semantic-conventions';
import opentelemetry from '@opentelemetry/api';

const tracer = opentelemetry.trace.getTracer('page-load-tracer');

export default function documentLoad() {
  tracer.startActiveSpan('document-load', (span) => {
    span.setAttribute(SemanticAttributes.HTTP_USER_AGENT, navigator.userAgent);
    const performanceNavigationTiming = otperformance.getEntriesByType('navigation')[0];
    const keys = Object.values(PTN);
    const entries = {};
    keys.forEach((key) => {
      if (hasKey(performanceNavigationTiming, key)) {
        entries[key] = performanceNavigationTiming[key];
      }
    });
    addSpanNetworkEvent(span, PTN.FETCH_START, entries);
    addSpanNetworkEvent(span, PTN.UNLOAD_EVENT_START, entries);
    addSpanNetworkEvent(span, PTN.UNLOAD_EVENT_END, entries);
    addSpanNetworkEvent(span, PTN.DOM_INTERACTIVE, entries);
    addSpanNetworkEvent(span, PTN.DOM_CONTENT_LOADED_EVENT_START, entries);
    addSpanNetworkEvent(span, PTN.DOM_CONTENT_LOADED_EVENT_END, entries);
    addSpanNetworkEvent(span, PTN.DOM_COMPLETE, entries);
    addSpanNetworkEvent(span, PTN.LOAD_EVENT_START, entries);
    addSpanNetworkEvent(span, PTN.LOAD_EVENT_END, entries);

    const performancePaintNames = {
      'first-paint': 'firstPaint',
      'first-contentful-paint': 'firstContentfulPaint',
    };

    const paintEntries = otperformance.getEntriesByType('paint');
    paintEntries.forEach(({ name, startTime }) => {
      if (hasKey(performancePaintNames, name)) {
        span.addEvent(performancePaintNames[name], startTime);
      }
    });
    span.end();
  });
}
